import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationshipSelectors } from "../../../../../AppStateManagement";
import { RouteStepDataService } from "../../../Services";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { environment } from "../../../../../../environments/environment";
import { CompanyProfileRelationship } from "src/app/Models";

@Component({
	selector: "ls-program-confirmation",
	templateUrl: "./program-confirmation.component.html",
	styleUrls: ["./program-confirmation.component.scss"]
})
export class ProgramConfirmationComponent extends RouteStepDataBaseComponent implements OnInit {
	constructor(
		public store: Store<any>,
		public router: Router,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	protected readonly environment = environment;
	public program!: CompanyProfileRelationship;

	ngOnInit(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
			.pipe(
				filter((cprs) => !!cprs && cprs.length > 0),
				takeUntil(this.componentTeardown$),
				map((cprs) => {
					// Note: This currently assumes the first object is the correct relationship.
					// This logic needs to be reviewed and updated to determine the correct relationship after the program updates.
					this.program = cprs![0];
				})
			)
			.subscribe();
	}

	onClick() {
		this.nav();
	}
}
