import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Page } from "src/app/Models";
import { IColumnHeader } from "src/app/Models/Interfaces/IColumnHeader";
import { GridSort, PageRequest, SortDirection } from "@limestone/ls-shared-modules";

@Injectable()
export class GridService<T> {
	public activeSort?: GridSort;
	public activePage?: Page<T> = { page: 1, size: 10, totalElements: 1, content: [] };

	sortFilterChange$ = new Subject<PageRequest>();

	public sortColumn(column: IColumnHeader): void {
		let direction;
		if (this.activeSort?.active === column.relatedField) {
			direction = this.activeSort?.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
		} else {
			direction = SortDirection.ASC;
		}
		this.activeSort = {
			active: column.relatedField,
			direction
		};
		this.handlePageChange(this.activePage);
	}

	public handlePageChange(page?: Page<T>): void {
		const pr = new PageRequest(page?.page ?? 1, page?.size ?? 10, this.activeSort ? [this.activeSort] : []);
		this.sortFilterChange$.next(pr);
	}

	public getIcon(column: IColumnHeader): string {
		if (this.activeSort?.active === column.relatedField) {
			return this.activeSort.direction === SortDirection.ASC ? "keyboard_arrow_up" : "keyboard_arrow_down";
		}
		return "unfold_more";
	}
}
