import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, filter, take, map } from "rxjs";
import { SalesforceSupportActions, SalesforceSupportSelectors } from "../AppStateManagement";
import { FeatureFlags, FeatureFlagSelectors } from "@limestone/ls-shared-modules";

@Injectable()
export class SalesforceSupportCasesDataResolver implements Resolve<any> {
	constructor(
		private store: Store<any>,
		private salesforceSupportSelectors: SalesforceSupportSelectors,
		private ffSelectors: FeatureFlagSelectors
	) {}

	cases$ = this.store.select(this.salesforceSupportSelectors.selectCases).pipe(
		filter((c) => !!c),
		take(1)
	);

	resolve(): Observable<any> {
		return this.store.select(this.ffSelectors.selectFeatureFlags).pipe(
			filter((ff) => !!ff),
			take(1),
			map((ff) => {
				if (ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_1)!.enabled) {
					this.initPrefetchData();
					return this.cases$;
				}
				return;
			})
		);
	}

	initPrefetchData(): void {
		this.store.dispatch(SalesforceSupportActions.getCases());
	}
}
