<div class="grid">
    <div class="g-col-12 g-col-md-6">
        <label class="s">Preferred Payment Method<ls-info-dialog-icon (iconClick)="showPaymentInfo()">
                <ls-tooltip>
                    <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <label class="l-bold text-wrap">Payment methods</label>
                        <p class="m text-wrap ls-mb-0">Different methods of payment affect the speed your application
                            can be processed,
                            any fees, and other factors.</p>
                    </div>
                </ls-tooltip>
            </ls-info-dialog-icon></label>
        <p class="l-bold">{{ companyProfileRelationship?.preferredPaymentMethodId! | lookup : preferredPayments }}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">Customer Notification<ls-info-dialog-icon [header]="SharedText.CustomerSettings.notifyHeader"
                [content]="SharedText.CustomerSettings.notifyContent">
                <ls-tooltip>
                    <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <label class="l-bold text-wrap">Notifying your customer</label>
                        <p class="m text-wrap">If you decide to notify your customer to pay Raistone directly, we'll
                            provide them with a
                            Notice of Assignment (NOA) to sign and return.</p>
                        <p class="m text-wrap">We can manage the notification and do all the collections without your
                            intervention.</p>
                        <p class="m text-wrap ls-mb-0">You can receive more favorable pricing if you notify your
                            customer to pay
                            Raistone directly.</p>
                    </div>
                </ls-tooltip>
            </ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.financeDisclosureYesNoId! | lookup : financeDisclosures }}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">Invoice Approvals<ls-info-dialog-icon [header]="SharedText.InvoiceApprovals.tooltipHeader"
                [content]="SharedText.InvoiceApprovals.tooltipContent">
                <ls-tooltip>
                    <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <label class="l-bold text-wrap">Invoice approvals</label>
                        <p class="m text-wrap ls-mb-0">Your customer can provide approval status for your invoices.
                            Getting invoice
                            approval from your customers will result in better pricing.</p>
                    </div>
                </ls-tooltip>

            </ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.isInstrumentApprovalConfirmed! | booleanDisplay : 'YesNo'}}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">autoXcel<ls-info-dialog-icon [header]="SharedText.AutoXcel.tooltipHeader"
                [content]="SharedText.AutoXcel.tooltipContent">
                <ls-tooltip>
                    <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <label class="l-bold text-wrap">autoXcel</label>
                        <p class="m text-wrap ls-mb-0">Automatically accelerating payment of your invoices lowers
                            pricing and speeds up
                            financing. By disabling autoXcel, you or someone at your business must log into our platform
                            to manually
                            select each invoice you’d like to get financed.</p>
                    </div>
                </ls-tooltip>

            </ls-info-dialog-icon></label>

        <p class="l-bold">{{ companyProfileRelationship?.isAutoXcel! | booleanDisplay : 'OnOff'}}</p>
    </div>
    <div class="g-col-12 g-col-md-6">
        <label class="s">Expedited Financing<ls-info-dialog-icon [header]="SharedText.ExpeditedFinancing.tooltipHeader"
                [content]="SharedText.ExpeditedFinancing.tooltipContent">
                <ls-tooltip>
                    <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <label class="l-bold text-wrap">Expedited financing</label>
                        <p class="m text-wrap ls-mb-0">Before funding begins, we confirm your bank account via a
                            passthrough payment. If
                            you want to begin funding prior to confirming your bank account, we can do so at a higher
                            price. Usually
                            this adds a business day to the funding process.</p>
                    </div>
                </ls-tooltip>

            </ls-info-dialog-icon>
        </label>

        <p class="l-bold">{{ companyProfileRelationship?.isFinanceExpedited! | booleanDisplay : 'YesNo'}}</p>
    </div>
</div>