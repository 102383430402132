import { Component, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { filter, forkJoin, Observable, of, Subject, take } from "rxjs";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyDocument, LsHttpErrorResponse } from "../Models";
import { CompanyDocumentType } from "../Models/Enums";
import { FileUploadError, SortEvent } from "../Models/Interfaces";
import { IColumnHeader } from "../Models/Interfaces/IColumnHeader";
import { DeleteDocumentComponent } from "../Modules/COT-Module/Modals";
import { UploadStatus } from "../Models/Enums/UploadStatus";
import { FileHandle } from "../../Elements/upload-widget/FileHandle";
import { CompanyDocumentActions } from "../Modules/COT-Module/OnboardingStateManagement/CompanyDocument/company-document-actions";
import { ModalActions } from "./ModalActions";
import { DomSanitizer } from "@angular/platform-browser";
import { RouteStepDataBaseComponent } from "./route-step-data-base.component";
import { RouteStepDataService } from "../Modules/COT-Module/Services";
import { SortDirection } from "@limestone/ls-shared-modules";
import { FileUtilityService } from "../../services/file-utility.service";

@Component({ selector: "ls-rmh", template: "" })
export abstract class FileUploadBaseComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	public componentTeardown$ = new Subject();
	public files?: CompanyDocument[];
	public validFiles?: CompanyDocument[] = [];
	public erroredFiles?: CompanyDocument[] = [];
	public allowedFileExtensionsMap: Map<string, boolean>;
	public fileUtilityService: FileUtilityService = new FileUtilityService();
	public allowedFileExtensions: string[];
	public displayableFileExtensions: string[];
	public errors: string[] = [];
	public maxFileSize = 50; // In MB
	public maxFileCount = 10;
	public disabled = false;
	public submitted = false;
	private companyId?: number;
	private docType?: CompanyDocumentType;

	public uploadErrors: Map<string, string[]> = new Map<string, string[]>();
	public duplicateFiles: string[] = [];
	public unsupportedFileTypeFiles: string[] = [];
	public oversizedFiles: string[] = [];

	constructor(
		public router: Router,
		public store: Store,
		public dialog: MdbModalService,
		public actions$: Actions,
		public sanitizer: DomSanitizer,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.docType = this.activeRouteData!.docType!;
		this.allowedFileExtensionsMap = FileUtilityService.getAllowedFileExtensions();
		this.allowedFileExtensions = Array.from(this.allowedFileExtensionsMap.keys());
		this.displayableFileExtensions = Array.from(
			new Map([...this.allowedFileExtensionsMap.entries()].filter(([, value]) => value)).keys()
		);
	}

	ngOnInit() {
		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyDocumentActions.saveUnsuccessful),
				map((act) => {
					const errMap = act.result.errors as Map<string, string[]>;
					this.handleErrorMap(errMap);
				})
			)
			.subscribe();
	}

	public handleErrorMap(errMap: Map<string, string[]>) {
		errMap.forEach((val, key) => {
			let errString = null;
			switch (key) {
				case "DuplicateFileExists": {
					errString = this.sanitizer.sanitize(
						SecurityContext.HTML,
						`You’ve already uploaded <strong>${val}</strong>. Please try again by uploading a different file.`
					);

					break;
				}
				case "FileSizeExceeded": {
					errString = this.sanitizer.sanitize(
						SecurityContext.HTML,
						`<strong>${val}</strong> exceeds the maximum file size. Please upload documents smaller than 50 MB.`
					);

					break;
				}
				case "UnsupportedFileType": {
					errString = this.sanitizer.sanitize(
						SecurityContext.HTML,
						`The file type for <strong>${val}</strong> isn’t permitted. Try again with any of the allowed file types: ${this.allowedFileExtensions.join(", ")}.`
					);

					break;
				}
			}
			if (errString !== null && !this.errors.includes(errString)) this.errors.push(errString!);

			const currentFileName = val[0];

			this.files?.forEach((f) => {
				if (f.status === UploadStatus.IN_PROGRESS && currentFileName === f.fileName) {
					f.status = UploadStatus.FAILED;
				}
			});
		});

		this.checkIfShouldDisable();
	}

	public getDocType(): CompanyDocumentType {
		return this.docType!;
	}

	public getComponentTearDown(): Subject<any> {
		return this.componentTeardown$;
	}

	public setCompanyId(id: number): void {
		this.companyId = id;
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public navTo() {}

	public handleSortChange(sort: SortEvent) {
		const sortOrder = sort.sort.direction;
		const sortColumn = sort.sort.active ?? "fileName";

		if (!CompanyDocument.ColumnNames().some((c: IColumnHeader) => c.relatedField === sortColumn)) {
			throw new Error(`Column ${sortColumn} does not exist on CompanyDocument.`);
		}
		if (!this.files || this.files.length === 0) {
			return;
		}

		const isDescending = sortOrder === SortDirection.DESC;
		const isFileSizeColumn = sortColumn === "fileSize";

		this.files.sort((a: any, b: any) => {
			const valueA = isFileSizeColumn
				? FileUtilityService.convertFileSizeToBytes(a[sortColumn])
				: a[sortColumn]?.toString().toLowerCase();

			const valueB = isFileSizeColumn
				? FileUtilityService.convertFileSizeToBytes(b[sortColumn])
				: b[sortColumn]?.toString().toLowerCase();

			if (valueA < valueB) return isDescending ? 1 : -1;
			if (valueA > valueB) return isDescending ? -1 : 1;
			return 0;
		});
	}

	public handleError(error: FileUploadError) {
		this.errors.push(error.errorMessage);
		this.files?.push(
			new CompanyDocument(
				this.companyId,
				error.file.name,
				FileUtilityService.convertFileSize(error.file.size),
				new Date(),
				UploadStatus.FAILED,
				this.docType
			)
		);
	}

	public openFile(file: CompanyDocument) {
		this.store.dispatch(
			CompanyDocumentActions.downloadFile({
				companyId: file.companyId!,
				documentType: this.docType!,
				fileName: file.fileName!
			})
		);
	}

	public deleteDocument(document: CompanyDocument) {
		if (document.status === UploadStatus.FAILED) {
			this.files = this.files?.filter((f) => f.fileName !== document.fileName);
			this.errors = this.errors?.filter((e) => !e.includes(document.fileName!));
		} else {
			this.dialog
				.open(DeleteDocumentComponent, {
					modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
					ignoreBackdropClick: true,
					data: { fileName: document.fileName }
				})
				.onClose.pipe(
					filter((result: ModalActions) => result === ModalActions.PRIMARY),
					take(1),
					map(() => {
						this.store.dispatch(
							CompanyDocumentActions.deleteCompanyDocument({
								id: document.companyId!,
								documentType: document.documentType!,
								document
							})
						);
					})
				)
				.subscribe();
		}
		this.checkIfShouldDisable();
	}

	public uploadFiles(files: FileHandle[]) {
		// Check if file count is over limit.
		if (this.fileCountOverLimit(files) || this.submitted) {
			return;
		}

		const obvs: Observable<{ processedFile: boolean | undefined; fileHandle: FileHandle }>[] = [];
		files.forEach((f) => {
			this.fileUtilityService
				.fileTypeSupported(f, this.allowedFileExtensions)
				.pipe(
					take(1),
					map((result) => obvs.push(of({ processedFile: result, fileHandle: f })))
				)
				.subscribe(() => {
					forkJoin(obvs)
						.pipe(take(1))
						.subscribe((results) => {
							results.forEach((result) => {
								const processedFile = result.processedFile;
								const file = result.fileHandle;
								this.validateFiles(processedFile, file);
							});
							this.handleProcessedAndValidatedFiles(files);
							this.checkIfShouldDisable();
						});
				});
		});
	}

	public handleProcessedAndValidatedFiles(files: FileHandle[]) {
		const filesToUpload = files.filter((f) => this.validFiles?.find((validFile) => f.file.name === validFile.fileName));
		const failedFiles = files.filter((f) =>
			this.erroredFiles?.find((erroredFile) => f.file.name === erroredFile.fileName)
		);

		if (filesToUpload.length > 0) {
			this.store.dispatch(
				CompanyDocumentActions.uploadFiles({
					companyId: this.companyId!,
					documentType: this.docType!,
					files: filesToUpload
				})
			);
		}

		if (this.erroredFiles!.length > 0 && filesToUpload.length === 0) {
			const errorResponses = this.createErrorResponses(this.uploadErrors);
			errorResponses.forEach((e) => {
				this.dispatchSaveUnsuccessful(e, failedFiles);
			});
		}
	}
	public validateFiles(supportedFile: boolean | undefined, file: FileHandle) {
		let documentInErrorState = false;
		const companyDocument: CompanyDocument = new CompanyDocument(
			this.companyId,
			file.file.name,
			FileUtilityService.convertFileSize(file.file.size),
			new Date(),
			UploadStatus.IN_PROGRESS,
			this.docType
		);
		// Check if supportedFile is false, if false, add to unsupportedFileTypeFiles else perform otgher checks

		if (!supportedFile) {
			this.unsupportedFileTypeFiles.push(file.file.name);
			this.uploadErrors.set("UnsupportedFileType", this.unsupportedFileTypeFiles);
			this.erroredFiles!.push(companyDocument);
		} else {
			// Check if duplicate files exist.
			const duplicateFile = this.getDuplicateFilesIfExists(companyDocument);
			if (duplicateFile != undefined) {
				documentInErrorState = true;
				this.duplicateFiles.push(duplicateFile);
				companyDocument.status = UploadStatus.FAILED;
				this.uploadErrors.set("DuplicateFileExists", [duplicateFile.fileName]);
			}
			// Check if file size is over limit.

			if (FileUtilityService.isFileSizeOverLimit(file, this.maxFileSize)) {
				documentInErrorState = true;
				this.oversizedFiles.push(file.file.name);
				this.uploadErrors.set("FileSizeExceeded", this.oversizedFiles);
			}

			if (documentInErrorState) {
				this.erroredFiles!.push(companyDocument);
			} else {
				this.validFiles!.push(companyDocument);
			}
			if (!this.files?.some((f) => f.fileName === companyDocument.fileName)) this.files?.push(companyDocument);
		}
	}

	private createErrorResponses(uploadErrors: Map<string, string[]>): LsHttpErrorResponse[] {
		const errorResponses: LsHttpErrorResponse[] = [];
		for (const [key, value] of uploadErrors) {
			value.forEach((e) => {
				const errResp = new LsHttpErrorResponse(undefined, undefined, undefined, undefined, undefined);
				errResp.errors = new Map<string, string[]>([[key, [e]]]);
				errorResponses.push(errResp);
			});
		}
		return errorResponses;
	}

	public rebuildFilesListAfterUpload(uploadedFiles: CompanyDocument[]): any[] {
		const failedFiles = this.files?.filter((f) => f.status == UploadStatus.FAILED);
		return [...(failedFiles ?? []), ...(uploadedFiles ?? [])];
	}

	private dispatchSaveUnsuccessful(errResp: LsHttpErrorResponse, files: FileHandle[]) {
		this.store.dispatch(CompanyDocumentActions.saveUnsuccessful({ files, result: errResp }));
	}

	private getDuplicateFilesIfExists(file: CompanyDocument): any {
		return this.files?.find((f) => f.fileName === file.fileName && f.status !== UploadStatus.FAILED);
	}

	public fileCountOverLimit(files: FileHandle[]): boolean {
		const completeOrInprogressFiles = this.getCompletedOrInProgressFiles();
		if (completeOrInprogressFiles) {
			return completeOrInprogressFiles.length + files.length > this.maxFileCount;
		} else {
			return files.length > this.maxFileCount;
		}
	}

	private getCompletedOrInProgressFiles(): CompanyDocument[] {
		return this.files?.filter((file) => [UploadStatus.IN_PROGRESS, UploadStatus.COMPLETE].includes(file.status!)) || [];
	}

	private checkIfShouldDisable(): void {
		this.disabled = this.getCompletedOrInProgressFiles().length === this.maxFileCount;
	}

	public abstract initData(): void;
}
