import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { GenericLookup, GenericSelectors, CurrencyCode, LookupTables } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-customer-relationship",
	templateUrl: "./customer-relationship.component.html",
	styleUrls: ["./customer-relationship.component.scss"]
})
export class CustomerRelationshipComponent implements OnInit, OnDestroy {
	private _componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;

	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
			this.currencyCode = companyProfileRelationship.currencyCode;
			this.isCalculating = !companyProfileRelationship.isCodatCalculationsComplete;
			this.isManualSetup = companyProfileRelationship.isManualSetup;
			this.setFormGroup();
		}
	}

	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}

	@Input() readOnly = false;
	public isCalculating?: boolean = false;
	public isManualSetup?: boolean = false;
	public customerRelationshipForm: FormGroup = new FormGroup({});
	public relationshipDurations?: GenericLookup<string>[];
	public onboardPaymentMethods?: GenericLookup<string>[];
	public onboardRevenueRanges?: GenericLookup<string>[];
	public onboardInstrumentRanges?: GenericLookup<string>[];
	public currencies?: GenericLookup<string>[];
	public currencyCode? = CurrencyCode.USD;
	public static FORM_CONTROL_NAME = "customerRelationshipForm";
	public LENGTH_OF_RELATIONSHIP = "relationshipDurationId";
	public PAYMENT_TERMS = "paymentTerms";
	public CURRENCY = "currencyCode";
	public ANNUAL_SALES_FROM_CUSTOMER = "onboardRevenueRangeId";
	public CUSTOMER_PAYMENT_METHOD = "onboardPaymentMethodId";
	max = 365;

	constructor(
		private fgd: FormGroupDirective,
		private store: Store<any>,
		private genericSelectors: GenericSelectors
	) {
		this.customerRelationshipForm.addControl(this.LENGTH_OF_RELATIONSHIP, new FormControl(null, Validators.required), {
			emitEvent: false
		});
		this.customerRelationshipForm.addControl(
			this.PAYMENT_TERMS,
			new FormControl(null, [
				Validators.required,
				Validators.min(0),
				Validators.max(this.max),
				Validators.pattern("^[0-9]+$")
			])
		);
		this.customerRelationshipForm.addControl(this.CURRENCY, new FormControl(null, Validators.required), {
			emitEvent: false
		});
		this.customerRelationshipForm.addControl(
			this.ANNUAL_SALES_FROM_CUSTOMER,
			new FormControl(null, Validators.required),
			{ emitEvent: false }
		);
		this.customerRelationshipForm.addControl(this.CUSTOMER_PAYMENT_METHOD, new FormControl(null, Validators.required), {
			emitEvent: false
		});
	}

	public ngOnDestroy() {
		this._componentTeardown$.next(null);
		this._componentTeardown$.complete();
	}

	public ngOnInit(): void {
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.RelationshipDuration)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardInstrumentRange)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardRevenueRange)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardPaymentMethod)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.Currency))
		])
			.pipe(
				filter(([rd, oir, rr, opm, currencies]) => !!rd && !!oir && !!rr && !!opm && !!currencies),
				takeUntil(this._componentTeardown$),
				map(([rd, oir, rr, opm, currencies]) => {
					this.relationshipDurations = rd!;
					this.onboardPaymentMethods = opm!;
					this.onboardRevenueRanges = rr!;
					this.onboardInstrumentRanges = oir!;
					this.currencies = currencies;
					this.currencyCode = !this.companyProfileRelationship?.isOtherCurrency
						? this.companyProfileRelationship?.currencyCode
						: this.currencyCode;
				})
			)
			.subscribe();

		this.customerRelationshipForm
			.get(this.CURRENCY)
			?.valueChanges.pipe(
				takeUntil(this._componentTeardown$),
				map((value) => (this.currencyCode = value))
			)
			.subscribe();
	}

	setFormGroup() {
		const relDurationCtrl = this.customerRelationshipForm.get(this.LENGTH_OF_RELATIONSHIP);
		const pymtTermsCtrl = this.customerRelationshipForm.get(this.PAYMENT_TERMS);
		const rvRangeCtrl = this.customerRelationshipForm.get(this.ANNUAL_SALES_FROM_CUSTOMER);

		relDurationCtrl?.patchValue(this.companyProfileRelationship?.relationshipDurationId, { emitEvent: false });
		pymtTermsCtrl?.patchValue(this.companyProfileRelationship?.paymentTerms, { emitEvent: false });
		this.customerRelationshipForm
			.get(this.CURRENCY)
			?.patchValue(this.companyProfileRelationship?.currencyCode, { emitEvent: false });
		rvRangeCtrl?.patchValue(this.companyProfileRelationship?.onboardRevenueRangeId, { emitEvent: false });
		this.customerRelationshipForm
			.get(this.CUSTOMER_PAYMENT_METHOD)
			?.patchValue(this.companyProfileRelationship?.onboardPaymentMethodId, { emitEvent: false });

		if (!this.isManualSetup) {
			relDurationCtrl?.clearValidators();
			pymtTermsCtrl?.clearValidators();
			rvRangeCtrl?.clearValidators();
		}
		this.fgd.form?.addControl(CustomerRelationshipComponent.FORM_CONTROL_NAME, this.customerRelationshipForm, {
			emitEvent: false
		});
	}
}
