<h1 class="l ls-mb-4 ls-mb-xxl-8">Link your accounts</h1>
<p class="l ls-mb-6 ls-mb-xxl-8">You’ll be asked to log into your accounting system in order to authorize access.</p>
<div class="d-flex justify-content-center justify-content-md-start ls-mb-6 ls-mb-xxl-8">
  <div class="grid">
    <img class="w-100 g-col-12 g-col-sm-4 ls-mb-3"
         *ngFor="let integration of integrations"
         [src]="integration.logoUrl"
         (click)="onClick(integration)" />
  </div>
</div>
<div class="d-flex flex-column"><a href="" class="ls-hyperlink" (click)="openModal($event)">Don't see your accounting system?</a></div>