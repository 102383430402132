<div *ngIf="!readOnly; else readonly" class="ls-mt-8" [formGroup]="customerRelationshipForm">
  <ng-container>
    <div *ngIf="isManualSetup" class="d-flex flex-column flex-lg-row justify-content-between">
      <div class="d-flex flex-column">
        <div>
          <ls-payment-terms-form-control [formControlName]="PAYMENT_TERMS"></ls-payment-terms-form-control>
        </div>
        <div class="ls-mt-8">
          <mdb-form-control>
            <mdb-select [formControlName]="ANNUAL_SALES_FROM_CUSTOMER" [id]="ANNUAL_SALES_FROM_CUSTOMER" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
              <mdb-option *ngFor="let range of onboardRevenueRanges" [value]="range.id">{{ currencyCode + ' ' + range.name }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" [for]="ANNUAL_SALES_FROM_CUSTOMER">Annual Sales from Customer</label>
          </mdb-form-control>
        </div>
      </div>
      <div class="d-flex flex-column ls-mt-11 ls-mt-lg-0">
        <mdb-form-control>
          <mdb-select [formControlName]="LENGTH_OF_RELATIONSHIP" [id]="LENGTH_OF_RELATIONSHIP" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
            <mdb-option *ngFor="let relationshipDuration of relationshipDurations" [value]="relationshipDuration.id">{{ relationshipDuration.name }}</mdb-option>
          </mdb-select>
          <label mdbLabel class="form-label" [for]="LENGTH_OF_RELATIONSHIP">Length of Relationship</label>
        </mdb-form-control>
        <div class="ls-mt-8">
          <mdb-form-control>
            <mdb-select [formControlName]="CURRENCY" [id]="CURRENCY" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
              <mdb-option *ngFor="let currency of currencies" [value]="currency.id">{{ currency.id }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" [for]="CURRENCY">Currency</label>
          </mdb-form-control>
        </div>
        <div class="ls-mt-8">
          <mdb-form-control>
            <mdb-select [formControlName]="CUSTOMER_PAYMENT_METHOD" [id]="CUSTOMER_PAYMENT_METHOD" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
              <mdb-option *ngFor="let paymentMethod of onboardPaymentMethods" [value]="paymentMethod.id">{{ paymentMethod.name }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" [for]="CUSTOMER_PAYMENT_METHOD">Customer Payment Method</label>
          </mdb-form-control>
        </div>
      </div>
    </div>
    <div *ngIf="!isManualSetup" class="d-flex flex-column flex-lg-row justify-content-between">
      <div class="d-flex flex-column">
        <div class="ls-mt-8">
          <div class="g-col-12 g-col-md-4">
            <ng-container *ngTemplateOutlet="isCalculating ? calcLabel : label; context: { $implicit: 'Payment Terms' }"></ng-container>
            <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">Net {{companyProfileRelationship?.paymentTerms!}} days</p>
          </div>
        </div>
        <div class="ls-mt-8">
          <div class="g-col-12 g-col-md-4">
            <ng-container *ngTemplateOutlet="isCalculating ? calcLabel : label; context: { $implicit: 'Annual Sales' }"></ng-container>
            <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">{{ currencyCode }} {{ companyProfileRelationship?.onboardRevenueRangeId! | lookup : onboardRevenueRanges }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column ls-mt-11 ls-mt-lg-0">
        <div class="g-col-12 g-col-md-4">
          <ng-container *ngTemplateOutlet="isCalculating ? calcLabel : label; context: { $implicit: 'Length of Relationship' }"></ng-container>
          <p class="ls-mt-1 l-bold" *lsIsCalculating="isCalculating!">{{companyProfileRelationship?.relationshipDurationId! | lookup : relationshipDurations}}</p>
        </div>
        <div class="ls-mt-8">
          <mdb-form-control>
            <mdb-select [formControlName]="CURRENCY" [id]="CURRENCY" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
              <mdb-option *ngFor="let currency of currencies" [value]="currency.id">{{ currency.id }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" [for]="CURRENCY">Currency</label>
          </mdb-form-control>
        </div>
        <div class="ls-mt-8">
          <mdb-form-control>
            <mdb-select [formControlName]="CUSTOMER_PAYMENT_METHOD" [id]="CUSTOMER_PAYMENT_METHOD" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
              <mdb-option *ngFor="let paymentMethod of onboardPaymentMethods" [value]="paymentMethod.id">{{ paymentMethod.name }}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" [for]="CUSTOMER_PAYMENT_METHOD">Customer Payment Method</label>
          </mdb-form-control>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #readonly>
  <ls-customer-relationship-readonly *ngIf="readOnly" [companyProfileRelationship]="companyProfileRelationship" [readOnly]="readOnly"></ls-customer-relationship-readonly>
</ng-template>

<ng-template #label let-label>
  <label class="xs-bold">{{label}}</label>
</ng-template>
<ng-template #calcLabel let-label>
  <label class="xs-bold">{{label}}
    <ls-tooltip>
      <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
      <div content>
        <p class="m ls-mb-0 text-wrap">We're currently calculating this field using your accounting and/or banking data you gave Raistone access to. Please come back later to see the result.</p>
      </div>
    </ls-tooltip>
  </label>
</ng-template>