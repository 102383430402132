import { LsStep } from "src/Elements/stepper/limestone-element-stepper.component";
import { IRouteStepData } from "./Interfaces";

export class StepperConfiguration {
	public id: string;
	public base: string;
	public description: string;
	public routeStepData: Map<string, IRouteStepData> = new Map<string, IRouteStepData>();
	public steps: LsStep[] = [];

	constructor(id: string, description: string, routeStepData: any, steps: any) {
		this.id = id;
		this.base = `/onboarding/${id}`;
		this.description = description;
		if (routeStepData) this.parseRouteStepData(routeStepData);
		if (steps) this.steps = this.parseSteps(steps);
	}

	parseRouteStepData(rsd: any) {
		Object.keys(rsd).forEach((key) => {
			const value: IRouteStepData = rsd[key];
			if (value && value.nextUrl)
				value.nextUrl = value.nextUrl.map((url) => (url.startsWith("/home") ? url : this.base + url));
			key = this.base + key;
			this.routeStepData.set(key, value);
		});
	}

	parseSteps(steps: any): LsStep[] {
		steps.forEach((step: LsStep) => {
			step.route = this.base + step.route;
			step.children?.forEach((childStep) => {
				childStep.route = this.base + childStep.route;
			});
			step.displaySettings = step.displaySettings ?? true;
		});
		return steps;
	}
}
