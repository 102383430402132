<footer class="d-flex flex-column justify-content-lg-between flex-lg-row ls-mb-9 ls-mb-md-7 ls-mb-lg-3">
    <div class="d-flex flex-row flex-lg-column">
        <p class="d-inline d-lg-none s">© {{currentYear}} Raistone Financial Corp & Raistone Purchasing LLC All Rights Reserved</p>
        
        <div class="d-none d-lg-inline">
            <p class="s ls-mb-2">© {{currentYear}} Raistone Financial Corp & Raistone Purchasing LLC</p> 
            <p class="s">All Rights Reserved</p>
        </div>
    </div>
    <div class="d-flex">
        <a class="footer-link ls-mr-3" target="_blank" href="https://raistone.com/privacy-policy/">Privacy Policy</a> 
        <span class="footer-link ls-mr-3">|</span>
        <a class="footer-link" target="_blank" href="https://raistone.com/terms-conditions/">Terms & Conditions</a>
    </div>
</footer>