import { Action, createReducer, on } from "@ngrx/store";
import { Application, CompanyProfileRelationship, Page } from "src/app/Models";
import { CompanyProfileRelationshipActions } from "./company-profile-relationship-actions";
import copy from "fast-copy";

export interface CompanyProfileRelationshipState {
	companyProfileRelationships?: CompanyProfileRelationship[];
	unenrolledCompanyProfileRelationships?: CompanyProfileRelationship[];
	companyProfileRelationship?: CompanyProfileRelationship;
	applications?: Application[];
	page?: Page<Application>;
}

export const initialState: CompanyProfileRelationshipState = {};

abstract class CompanyReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(
			CompanyProfileRelationshipActions.setCompanyProfileRelationship,
			(state: CompanyProfileRelationshipState, { companyProfileRelationship }) => ({
				...state,
				companyProfileRelationship
			})
		),
		on(
			CompanyProfileRelationshipActions.setCompanyProfileRelationships,
			(state: CompanyProfileRelationshipState, { companyProfileRelationships }) => ({
				...state,
				companyProfileRelationships
			})
		),
		on(CompanyProfileRelationshipActions.setApplications, (state: CompanyProfileRelationshipState, { page }) => ({
			...state,
			page: copy(page)
		})),
		on(
			CompanyProfileRelationshipActions.setUnenrolledCompanyProfileRelationships,
			(state: CompanyProfileRelationshipState, { companyProfileRelationships }) => ({
				...state,
				unenrolledCompanyProfileRelationships: companyProfileRelationships
			})
		)
	);
}

export abstract class CompanyProfileRelationshipReducer {
	static reducer = (state: CompanyProfileRelationshipState | undefined, action: Action) => {
		return CompanyReducerHandler.appReducer(state, action);
	};
}
