<form [formGroup]="formGroup" class="address-form d-flex flex-column">

    <mdb-form-control class="address-input ls-mb-4">
        <input [formControlName]="ADDRESS1" autocomplete="null" mdbInput mdbValidate type="string" id="address1" class="form-control form-control-lg" [mdbAutocomplete]="address1" />
        <span class="address-search-text material-icons" id="search-icon">search</span>
        <label mdbLabel class="form-label" for="address1">Street Address</label>
        <mdb-autocomplete #address1="mdbAutocomplete">
            <mdb-option *ngFor="let option of predictions" [value]="option.description" (click)="onSelect(option)">
                {{ option.description }}
            </mdb-option>
        </mdb-autocomplete>
    </mdb-form-control>
    
    <mdb-form-control class="address-input ls-mb-4">
        <input [formControlName]="ADDRESS2" mdbInput mdbValidate type="string" id="address2" class="form-control form-control-lg" />
        <label mdbLabel class="form-label" for="address2">Suite / Unit (optional)</label>
    </mdb-form-control>
    
    <mdb-form-control class="address-input ls-mb-4">
        <input [formControlName]="CITY" mdbInput mdbValidate type="string" id="city" class="form-control form-control-lg" />
        <label mdbLabel class="form-label" for="city">City</label>
    </mdb-form-control>
    
    <mdb-form-control class="address-input ls-mb-4">
        <mdb-select [formControlName]="COUNTRY" [filter]="true" mdbInput mdbValidate type="string" id="country"
                    [optionHeight]="56" class="form-control form-control-lg" [compareWith]="compareCountryOrStateProvince">
            <mdb-option *ngFor="let country of countries" [value]="country">
                {{country.name}}
            </mdb-option>
        </mdb-select>
        <label mdbLabel class="form-label" for="country">Country</label>
    </mdb-form-control>
    
    <div class="d-flex flex-column flex-md-row">
        <mdb-form-control class="address-input-sm ls-mb-3 ls-mb-md-0 ls-mr-md-3 ls-mr-0 flex-fill">
            <mdb-select [formControlName]="STATE" [filter]="true" [attr.disabled]="!stateProvinceEnabled()" mdbInput mdbValidate 
                        type="string" id="state" class="select form-control form-control-lg" [compareWith]="compareCountryOrStateProvince"
                        [optionHeight]="56">
                <mdb-option *ngFor="let stateProvince of filteredStateProvinces" [value]="stateProvince">
                    {{stateProvince.name}}
                </mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" for="state">State / Province</label>
        </mdb-form-control>
        <mdb-form-control class="address-input-sm flex-fill">
            <input [formControlName]="POSTAL_CODE" mdbInput mdbValidate type="string" id="postalCode" class="form-control form-control-lg" />
            <label mdbLabel class="form-label" for="postalCode">ZIP / Postal Code</label>
        </mdb-form-control>
    </div>
</form>