import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Subject, map, takeUntil } from "rxjs";
import { Page } from "src/app/Models";
import { PaginationButtons } from "src/app/Models/Enums";

@Component({
	selector: "ls-pagination",
	templateUrl: "./pagination.component.html",
	styleUrls: ["./pagination.component.scss"]
})
export class PaginationComponent implements OnInit {
	private componentTeardown$ = new Subject();
	@Output() update: EventEmitter<Page<any>> = new EventEmitter<Page<any>>();

	private _page?: Page<any>;
	@Input() get page() {
		return this._page;
	}

	set page(p) {
		this._page = p;
		this.currentPage = p?.page ?? 1;
		this.size = p?.size ?? 10;
		this.totalResults = p?.totalElements ?? 0;
		this.totalPages = this.totalResults > 0 ? Math.ceil(this.totalResults / this.size) : 1;
		this.pageOptions = [];
		for (let i = 1; i <= this.totalPages; i++) {
			this.pageOptions.push(i);
		}
		this.setFormControls();
	}

	public get PaginationButtons(): typeof PaginationButtons {
		return PaginationButtons;
	}

	currentPage: number = this.page?.page ?? 1;
	size: number = this.page?.size ?? 10;
	totalResults: number = this.page?.totalElements ?? 0;
	totalPages: number = this.totalResults > 0 ? Math.ceil(this.totalResults / this.size) : 1;
	pageOptions: number[] = [];
	rowOptions: (number | string)[] = [10, 25, 50, 100, "All"];

	ROWS = "ROWS";
	PAGE = "PAGE";
	public formGroup: FormGroup = new FormGroup({});

	constructor() {}

	ngOnInit(): void {
		this.handleFormChange();
	}

	setFormControls(): void {
		this.formGroup.addControl(this.ROWS, new FormControl(this.size), { emitEvent: false });
		this.formGroup.addControl(this.PAGE, new FormControl(this.currentPage), { emitEvent: false });
	}

	public handleFormChange(): void {
		this.formGroup!.valueChanges.pipe(
			takeUntil(this.componentTeardown$),
			map(() => {
				this.page!.page = this.formGroup.get(this.PAGE)?.value;
				this.page!.size = this.formGroup.get(this.ROWS)?.value;
				this.update.emit(this.page);
			})
		).subscribe();
	}

	changePage(p: PaginationButtons): void {
		switch (p) {
			case PaginationButtons.FIRST:
				if (this.page!.page! !== 1) {
					this.formGroup.get(this.PAGE)?.setValue(1);
				}
				break;
			case PaginationButtons.PREV:
				if (this.page!.page! !== 1) {
					this.formGroup.get(this.PAGE)?.setValue(this.page!.page! - 1);
				}
				break;
			case PaginationButtons.NEXT:
				if (this.page!.page! !== this.pageOptions.length) {
					this.formGroup.get(this.PAGE)?.setValue(this.page!.page! + 1);
				}
				break;
			case PaginationButtons.LAST:
				if (this.page!.page! !== this.pageOptions.length) {
					this.formGroup.get(this.PAGE)?.setValue(this.pageOptions.length);
				}
				break;
		}
	}
}
