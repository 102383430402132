import { Component } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { ModalActions } from "../../../../Core";

@Component({
	selector: "ls-connect-financials-persuasion",
	templateUrl: "./connect-financials-persuasion.component.html",
	styleUrls: ["./connect-financials-persuasion.component.scss"]
})
export class ConnectFinancialsPersuasionComponent {
	constructor(public modalRef: MdbModalRef<ConnectFinancialsPersuasionComponent>) {}
	public get ModalActions(): typeof ModalActions {
		return ModalActions;
	}

	public close(action: ModalActions): void {
		this.modalRef.close(action);
	}
}
