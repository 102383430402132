import { SafeUrl } from "@angular/platform-browser";
import { FileHandle } from "src/Elements/upload-widget/FileHandle";

export class Attachment {
	public id?: number;
	public body?: FileHandle;
	public bodyLength?: number;
	public contentType?: string;
	public name?: string;
	public parentId?: string;
	public url?: SafeUrl;
	public blobPath?: string;

	constructor(
		id: number,
		body?: FileHandle,
		bodyLength?: number,
		contentType?: string,
		name?: string,
		parentId?: string,
		url?: SafeUrl,
		blobPath?: string
	) {
		this.id = id;
		this.body = body;
		this.bodyLength = bodyLength;
		this.contentType = contentType;
		this.name = name;
		this.parentId = parentId;
		this.url = url;
		this.blobPath = blobPath;
	}
}
