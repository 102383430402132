import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "../../../../../Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { LookupKeys } from "src/app/Models/Enums";
import { IHtmlContent } from "src/app/Models/Interfaces";
import { YesNo, PreferredPaymentMethod, CollectionPreference } from "src/app/Models/Enums";

@Component({
	selector: "ls-facility-configuration",
	templateUrl: "./facility-configuration.component.html",
	styleUrls: ["./facility-configuration.component.scss"]
})
export class FacilityConfigurationComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		private genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		const htmlContent: any = this.activeRouteData!.htmlContent;
		this.data = new FacilityConfigurationContent(htmlContent.header, htmlContent.content, htmlContent.firstButtonText);
		this.initData();
	}

	public get YesNo() {
		return YesNo;
	}
	public get PreferredPaymentMethod() {
		return PreferredPaymentMethod;
	}
	selectedFinanceDisclosure?: string;
	LIEN_COLLABORATION = "lienCollaboration";
	FINANCE_DISCLOSURE = "financeDisclosure";
	COLLECTION_PREFERENCE = "collectionPreference";
	INVOICE_APPROVAL = "invoiceApproval";
	PREFERRED_PAYMENT_METHOD = "preferredPaymentMethod";

	data: FacilityConfigurationContent;
	yesNo: GenericLookup<string>[] = [];
	finyn: GenericLookup<string>[] = [];
	collections: GenericLookup<string>[] = [];
	ppm: GenericLookup<string>[] = [];
	companyProfileRelationship?: CompanyProfileRelationship;
	facilityConfigForm: FormGroup = new FormGroup({});
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	public initData(): void {
		combineLatest([
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.YesNo)),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinancialDisclosureYesNo)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.CollectionPreference))
		])
			.pipe(
				filter(([cpr, yn, finyn, ppm, cp]) => !!cpr && !!yn && !!finyn && !!ppm && !!cp),
				takeUntil(this.componentTeardown$),
				map(([cpr, yn, finyn, ppm, cp]) => {
					this.yesNo = yn!;
					this.finyn = finyn!;
					this.ppm = ppm!;
					this.collections = cp!;

					this.companyProfileRelationship = cpr!;
				})
			)
			.subscribe();

		this.facilityConfigForm = new FormGroup({
			lienCollaboration: new FormControl(
				this.companyProfileRelationship?.lienCollaborationYesNoId,
				Validators.required
			),
			financeDisclosure: new FormControl(
				this.companyProfileRelationship?.financeDisclosureYesNoId,
				Validators.required
			),
			collectionPreference: new FormControl(
				this.companyProfileRelationship?.collectionPreferenceId,
				Validators.required
			),
			invoiceApproval: new FormControl(this.companyProfileRelationship?.invoiceApprovalYesNoId, Validators.required),
			preferredPaymentMethod: new FormControl(
				this.companyProfileRelationship?.preferredPaymentMethodId,
				Validators.required
			)
		});
		if (this.companyProfileRelationship?.financeDisclosureYesNoId)
			this.handleFinancialDisclosureChange(this.companyProfileRelationship!.financeDisclosureYesNoId!);
		this.facilityConfigForm
			.get(this.FINANCE_DISCLOSURE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v: string) => {
					this.handleFinancialDisclosureChange(v);
				})
			)
			.subscribe();
	}
	private handleFinancialDisclosureChange(value: string) {
		this.selectedFinanceDisclosure = value;
		if (this.selectedFinanceDisclosure === YesNo.NO) {
			this.facilityConfigForm.get(this.COLLECTION_PREFERENCE)?.setValidators(Validators.required);
		} else {
			//do not validate the hidden control, db value will be set in getCompanyProfileRelationshipForUpdate
			//db value is not used for scoring so should not be set on the form
			this.facilityConfigForm.get(this.COLLECTION_PREFERENCE)?.clearValidators();
			this.facilityConfigForm.get(this.COLLECTION_PREFERENCE)?.setValue(null);
		}
	}

	markAsDirty() {
		this.facilityConfigForm.markAsDirty();
	}
	isFormInvalid() {
		return this.facilityConfigForm.invalid;
	}
	getCompanyProfileRelationshipForUpdate() {
		const lienCollaborationId = this.facilityConfigForm.get(this.LIEN_COLLABORATION)?.value;
		const financeDisclosureId = this.facilityConfigForm.get(this.FINANCE_DISCLOSURE)?.value;
		let collectionPreferenceId = this.facilityConfigForm.get(this.COLLECTION_PREFERENCE)?.value;
		const invoiceApprovalId = this.facilityConfigForm.get(this.INVOICE_APPROVAL)?.value;
		const preferredPaymentMethodId = this.facilityConfigForm.get(this.PREFERRED_PAYMENT_METHOD)?.value;
		if (financeDisclosureId === YesNo.YES || financeDisclosureId === YesNo.IDK) {
			collectionPreferenceId = CollectionPreference.INFORM;
		}
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);

		updatedCompanyProfileRelationship.lienCollaborationYesNoId = lienCollaborationId;
		updatedCompanyProfileRelationship.financeDisclosureYesNoId = financeDisclosureId;
		updatedCompanyProfileRelationship.collectionPreferenceId = collectionPreferenceId;
		updatedCompanyProfileRelationship.invoiceApprovalYesNoId = invoiceApprovalId;
		updatedCompanyProfileRelationship.preferredPaymentMethodId = preferredPaymentMethodId;
		return updatedCompanyProfileRelationship;
	}
	continue() {
		if (this.facilityConfigForm.dirty) {
			const updatedCompanyProfileRelationship = this.getCompanyProfileRelationshipForUpdate();

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
export class FacilityConfigurationContent implements IHtmlContent {
	constructor(
		public header: string,
		public content: string,
		public firstButtonText: string
	) {}
}
