<div class="d-flex flex-column ls-mt-8 ls-mb-8">
  <form [formGroup]="companyProfileForm">
    <div class="ls-mb-3">
      <label class="s-caps">BUSINESS NAMES</label>
    </div>
    <mdb-form-control class="legal-name ls-mb-4 ls-w-md-50">
      <input mdbInput [formControlName]="LEGAL_NAME" mdbValidate type="text" id="legalName"
             class="form-control form-control-lg"/>
      <label mdbLabel class="form-label" for="legalName">Legal Company Name</label>
    </mdb-form-control>
    <div class="ls-mb-8 ls-w-md-50">
      <div class="ls-mb-3">
        <label class="s-caps">OFFICE PHONE NUMBER</label>
      </div>
      <ls-phone-form-control [formControlName]="PHONE" ></ls-phone-form-control>
    </div>
    <div class="ls-mb-7 ls-w-md-50">
      <div class="ls-mb-3">
        <label class="s-caps">PRIMARY ADDRESS</label>
      </div>
      <ls-address-form-control [formControlName]="PRIMARY"></ls-address-form-control>
    </div>
  </form>
</div>
