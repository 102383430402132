import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Flow, Product } from "src/app/Models/Enums";
import { FeatureFlags, FeatureFlagService } from "@limestone/ls-shared-modules";
import { map, take } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class FlowService {
	private isSunfyre_ARFPricingGauge_2024718Enabled = false;
	constructor(private featureFlagService: FeatureFlagService) {
		this.featureFlagService
			.isEnabled(FeatureFlags.Sunfyre_ARFPricingGauge_2024718)
			.pipe(
				take(1),
				map((enabled) => (this.isSunfyre_ARFPricingGauge_2024718Enabled = enabled))
			)
			.subscribe();
	}
	getProductIdFromRoute(snapshot: ActivatedRouteSnapshot[]): Product | undefined {
		const flowId = this.getFlowIdFromRoute(snapshot);
		return this.mapFlowToProduct(flowId);
	}

	getFlowIdFromRoute(pathFromRoot: ActivatedRouteSnapshot[]): Flow | undefined {
		for (const snapshot of pathFromRoot) {
			const flowId = snapshot.params["flowId"]?.toString().toUpperCase();
			if (flowId && Object.values(Flow).includes(flowId as Flow)) {
				return flowId;
			}
		}
		return undefined;
	}

	getFlowIdFromRouteOrDefault(pathFromRoot: ActivatedRouteSnapshot[]): Flow {
		return this.getFlowIdFromRoute(pathFromRoot) ?? this.getDefaultArfFlow();
	}

	getDefaultArfFlow(): Flow {
		return this.isSunfyre_ARFPricingGauge_2024718Enabled ? Flow.ARF_PG : Flow.ARF_V1;
	}

	mapFlowToProduct(flowId?: Flow): Product | undefined {
		switch (flowId) {
			case Flow.ARF_V1:
			case Flow.ARF_PG:
				return Product.ARF;
			case Flow.SCF_V1:
				return Product.SCF;
			default:
				return undefined;
		}
	}
}
