import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "../../../Utils";
import { AppActions, CompanyProfileActions, CompanyProfileRelationshipActions } from "../../AppStateManagement";
import { Store } from "@ngrx/store";
import { FlowService } from "../../../services";
import { filter, Subject, take, map } from "rxjs";
import { CompanyResolverService } from "../../Modules/COT-Module/Services";
import { InvitationService } from "../../../services";

@Component({
	selector: "ls-invitation",
	template: ""
})
export class InvitationComponent implements OnInit, OnDestroy {
	private componentTeardown$ = new Subject();
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private localStorageService: LocalStorageService,
		private store: Store,
		private flowService: FlowService,
		private cpResolver: CompanyResolverService,
		private invitationService: InvitationService
	) {}

	ngOnInit() {
		if (this.router.url.startsWith("/invitation")) {
			this.getParamsAndNavigate();
		} else if (this.activatedRoute.snapshot.queryParams["invitationCode"]) {
			this.handleSCFInvitation();
		}
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	getParamsAndNavigate() {
		const params = this.activatedRoute.snapshot.paramMap;
		if (!params.has("flowId")) throw new Error("FlowId is missing");
		const queryParams = this.activatedRoute.snapshot.queryParamMap;
		if (queryParams.has("companyId") && queryParams.has("userEmail")) {
			const companyIdStr = queryParams.get("companyId");
			const userEmail = queryParams.get("userEmail");
			if (!!companyIdStr && !!userEmail) {
				const companyId = parseInt(companyIdStr);
				this.localStorageService.setCompanyId(companyId);
				this.localStorageService.setEmailAddress(userEmail);
				this.localStorageService.setB2CSignupState();
			}
		}
		this.router.navigate([`/onboarding/${params.get("flowId")}/begin-application`], {
			relativeTo: this.activatedRoute
		});
	}

	handleSCFInvitation() {
		const flowId = this.flowService.getFlowIdFromRoute(this.activatedRoute.snapshot.pathFromRoot);
		this.cpResolver
			.resolve(this.activatedRoute.snapshot, this.router.routerState.snapshot)
			.pipe(
				filter((companyProfile) => !!companyProfile),
				take(1),
				map((companyProfile) => {
					if (this.invitationService.setInvitationContext()) {
						if (!companyProfile!.inProgressFlows?.includes(this.invitationService.getFlow()!)) {
							companyProfile!.inProgressFlows?.push(flowId!);
						}
						this.store.dispatch(CompanyProfileActions.setCompanyProfile({ companyProfile: companyProfile! }));
						this.store.dispatch(
							CompanyProfileRelationshipActions.handleInvitation({
								context: this.invitationService.createInviteContext(companyProfile!.companyId!)
							})
						);
					}
				})
			)
			.subscribe();
	}
}
