import { Action, createReducer } from "@ngrx/store";

export interface ValidationState {}

export const initialState: ValidationState = {};

abstract class ValidationReducerHandler {
	static appReducer = createReducer(initialState);
}

export abstract class ValidationReducer {
	static reducer = (state: ValidationState | undefined, action: Action) => {
		return ValidationReducerHandler.appReducer(state, action);
	};
}
