<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
	[isNextButtonDisabled]="isFormInvalid()" [nextButtonText]="config('actionButtons', 'b1')?.text">
	<div *ngIf="display(HEADER)" class="ls-mb-11">
		<ls-text id='h-p1' [displayConfig]="config(HEADER,'h-p1')"></ls-text>
		<ls-text id='h-p2' [displayConfig]="config(HEADER,'h-p2')"></ls-text>
	</div>

	<form [formGroup]="facilityConfigForm" class="d-flex flex-column">
		<div class="ls-mb-11" *ngIf="display('lienCollaboration')">
			<ls-text id='q1' extraClasses="ls-mb-3" [displayConfig]="config('lienCollaboration','q1')"></ls-text>
			<ls-text id='q1-p1' extraClasses="ls-mb-3"  [displayConfig]="config('lienCollaboration','q1-p1')"></ls-text>
			<ls-text id='q1-p2' extraClasses="ls-mb-3"  [displayConfig]="config('lienCollaboration','q1-p2')"></ls-text>

			<ls-radio-buttons [lookups]="yesNo" [controlName]="LIEN_COLLABORATION"
				[toolTips]="toolTips('lienCollaboration')"></ls-radio-buttons>
		</div>

		<div class="ls-mb-11" *ngIf="display('financeDisclosure')">
			<ls-text id='q2' extraClasses="ls-mb-3"  [displayConfig]="config('financeDisclosure','q2')"></ls-text>
			<ls-text id='q2-p1' extraClasses="ls-mb-3"  [displayConfig]="config('financeDisclosure','q2-p1')"></ls-text>
			<ls-text id='q2-p2' extraClasses="ls-mb-3"  [displayConfig]="config('financeDisclosure','q2-p2')"></ls-text>

			<div class="ls-mb-3">
				<ls-accordion
					[displayConfig]="config('financeDisclosure','q2-accordion')"
					[expanded]="false">
					<ul class="no-indent-list">
						<li>
							<ls-text extraClasses="ls-mb-2"  id='q2-a1' [displayConfig]="config('financeDisclosure','q2-a1')"></ls-text>
						</li>
						<li>
							<ls-text id='q2-a2' [displayConfig]="config('financeDisclosure','q2-a2')"></ls-text>
						</li>
					</ul>
				</ls-accordion>
			</div>

			<ls-radio-buttons [lookups]="finyn" [toolTips]="toolTips('financeDisclosure')"
				[controlName]="FINANCE_DISCLOSURE"></ls-radio-buttons>
		</div>

		<div class="ls-mb-11" *ngIf="display('collectionPreference') && selectedFinanceDisclosure === YesNo.NO">
			<ls-text id='q3' extraClasses="ls-mb-3"  [displayConfig]="config('collectionPreference','q3')"></ls-text>
			<ls-text id='q3-p1' extraClasses="ls-mb-3"  [displayConfig]="config('collectionPreference','q3-p1')"></ls-text>

			<ls-radio-buttons [lookups]="collections" [controlName]="COLLECTION_PREFERENCE"
				[toolTips]="toolTips('collectionPreference')"></ls-radio-buttons>
		</div>

		<div class="ls-mb-11" *ngIf="display('invoiceApproval')">
			<ls-text id='q4' extraClasses="ls-mb-3"  [displayConfig]="config('invoiceApproval','q4')"></ls-text>
			<ls-text id='q4-p1'  extraClasses="ls-mb-3" [displayConfig]="config('invoiceApproval','q4-p1')"></ls-text>
			<ul>
				<li>
					<ls-text id='q4-b1' [displayConfig]="config('invoiceApproval','q4-b1')"></ls-text>
				</li>
				<li>
					<ls-text id='q4-b2' [displayConfig]="config('invoiceApproval','q4-b2')"></ls-text>
				</li>
				<li>
					<ls-text id='q4-b3' [displayConfig]="config('invoiceApproval','q4-b3')"></ls-text>
				</li>
			</ul>
			<ls-radio-buttons [lookups]="yesNo" [controlName]="INVOICE_APPROVAL"
				[toolTips]="toolTips('invoiceApproval')"></ls-radio-buttons>
		</div>
		<div *ngIf="display('preferredPaymentMethod')">
			<ls-text id='q5' extraClasses="ls-mb-3"  [displayConfig]="config('preferredPaymentMethod','q5')"></ls-text>
			<ls-text id='q5-p1' extraClasses="ls-mb-3"  [displayConfig]="config('preferredPaymentMethod','q5-p1')"></ls-text>

			<ls-radio-buttons [lookups]="ppm" [toolTips]="toolTips('preferredPaymentMethod')"
				[controlName]="PREFERRED_PAYMENT_METHOD"></ls-radio-buttons>
		</div>
	</form>
</ls-questionnaire>
<ls-pricing-gauge [percent]="percent"></ls-pricing-gauge>