import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { GoogleAutocompleteDetails, GooglePlaceDetails } from "src/app/Models";
import { HttpService } from "src/services/HTTP";

@Injectable()
export class GoogleService extends HttpService {
	constructor(private http: HttpClient) {
		super(http);
		this.path = "/api/google";
	}

	private sessionToken? = "";

	getAutocomplete(input: string): Observable<GoogleAutocompleteDetails> {
		return this.http.get<GoogleAutocompleteDetails>(`${this.path}/autocomplete?input=${input}`).pipe(
			tap((details) => {
				this.sessionToken = details.sessionToken;
			})
		);
	}

	getPlaceDetails(placeId: string): Observable<GooglePlaceDetails> {
		return this.http.get<GooglePlaceDetails>(
			`${this.path}/details?placeId=${placeId}&sessionToken=${this.sessionToken}`
		);
	}
}
