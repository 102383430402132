<div class="d-flex flex-column" *ngIf="display(HEADER)">
  <ls-text extraClasses="ls-mb-6" [displayConfig]="config(HEADER,'hl1')"></ls-text>

  <div class="ls-mb-6 d-flex flex-column">
    <ls-text extraClasses="ls-mb-2" [displayConfig]="config(HEADER,'p1')"></ls-text>
    <ls-text extraClasses="ls-mb-4" [displayConfig]="config(HEADER,'p2')"></ls-text>
    <div class="legal-name ls-mb-2 d-flex flex-column">
      <div class="ls-mb-3 d-flex align-items-center">
        <input mdbRadio class="radio legal-name-input" type="radio" name="isLegalName" id="legal-name-yes"
          [disabled]="form.disabled" (click)="legalName(true)" [checked]="confirmedLegalName" />
        <ls-text extraClasses="legal-name-label" [displayConfig]="config(HEADER,'yes-label')"
        forLabel="legal-name-yes"></ls-text>
      </div>
      <div class="d-flex align-items-center">
        <input mdbRadio class="radio legal-name-input" type="radio" name="isLegalName" id="legal-name-no"
          [disabled]="form.disabled" (click)="legalName(false)" />
        <ls-text extraClasses="legal-name-label" [displayConfig]="config(HEADER,'no-label')"
          forLabel="legal-name-no"></ls-text>
      </div>
    </div>
  </div>
  <form class="d-flex flex-column" [formGroup]="form" *ngIf="display(LEGAL_NAME)">
    <div class="d-flex flex-column ls-mb-6" [class.d-none]="companyNameIsLegalName || !radioClicked">
      <div class="d-flex flex-column">
        <ls-text extraClasses="ls-mb-2" [displayConfig]="config(LEGAL_NAME,'q1')"></ls-text>
        <ls-text [displayConfig]="config(LEGAL_NAME,'q1-p1')"></ls-text>
      </div>
      <mdb-form-control class="legal-name">
        <input mdbInput [formControlName]="LEGAL_NAME" mdbValidate type="text" id="legalName"
          class="form-control form-control-lg" />
        <label mdbLabel class="form-label" for="legalName">
          <ls-text [displayConfig]="config(LEGAL_NAME,'label')"></ls-text>
        </label>

      </mdb-form-control>
    </div>
  </form>
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" #lsNextBtn [disabled]="isInvalid()">
    <ls-text [displayConfig]="config(BUTTONS,'next')"></ls-text><span class="material-icons md-18"> arrow_forward</span>
  </button>
</div>