import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpService } from "./HTTP";
import { HttpClient } from "@angular/common/http";
import { StepperConfiguration } from "src/app/Models";

@Injectable()
export class StepperService extends HttpService {
	constructor(private http: HttpClient) {
		super(http);
		this.path = "/api/onboardingconfiguration";
	}

	public getStepperConfiguration(companyId: number): Observable<StepperConfiguration> {
		const url = `${this.path}/${companyId}`;
		return this.http
			.get<any>(url)
			.pipe(
				map(
					(config: any) =>
						new StepperConfiguration(config.id, config.description, config.routeStepConfig, config.stepConfig)
				)
			);
	}
}
