import { LsAction } from "../../../../Core";
import { createAction, props } from "@ngrx/store";
import { ActionType } from "../../../../Core/LsAction";
import { CompanyDocument, LsHttpErrorResponse } from "../../../../Models";
import { CompanyDocumentType } from "../../../../Models/Enums";
import { FileHandle } from "../../../../../Elements/upload-widget/FileHandle";

export abstract class CompanyDocumentActions extends LsAction {
	/* eslint-disable */
	static COMPANY_DOCUMENT: Readonly<string> = 'COMPANY_DOCUMENT';
	/* eslint-enable */

	static getFiles = createAction(
		CompanyDocumentActions.getActionName(CompanyDocumentActions.COMPANY_DOCUMENT, ActionType.GET),
		props<{ companyId: number; documentType: CompanyDocumentType }>()
	);

	static setFiles = createAction(
		CompanyDocumentActions.getActionName(CompanyDocumentActions.COMPANY_DOCUMENT, ActionType.SET),
		props<{ documentType: CompanyDocumentType; documents: CompanyDocument[] }>()
	);

	static deleteCompanyDocument = createAction(
		CompanyDocumentActions.getActionName(CompanyDocumentActions.COMPANY_DOCUMENT, ActionType.DELETE),
		props<{ id: number; documentType: CompanyDocumentType; document: CompanyDocument }>()
	);

	static uploadFiles = createAction(
		CompanyDocumentActions.getActionName(CompanyDocumentActions.COMPANY_DOCUMENT, ActionType.UPLOAD),
		props<{ companyId: number; documentType: CompanyDocumentType; files: FileHandle[] }>()
	);

	static downloadFile = createAction(
		CompanyDocumentActions.getActionName(CompanyDocumentActions.COMPANY_DOCUMENT, ActionType.DOWNLOAD),
		props<{ companyId: number; documentType: CompanyDocumentType; fileName: string }>()
	);

	static saveUnsuccessful = createAction(
		CompanyDocumentActions.getActionName(CompanyDocumentActions.COMPANY_DOCUMENT, ActionType.ERROR),
		props<{ files?: FileHandle[]; result: LsHttpErrorResponse }>()
	);
}
