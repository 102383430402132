export const allowedSpecialKeys = [
	"Delete",
	"Backspace",
	"Tab",
	"Control",
	"ArrowRight",
	"ArrowLeft",
	"ArrowUp",
	"ArrowDown"
];
export function defaultAllowedKey(keyPressed: string) {
	return allowedSpecialKeys.indexOf(keyPressed) != -1;
}
export function defaultAllowedKeyOrPaste(keyPressed: KeyboardEvent) {
	return defaultAllowedKey(keyPressed.key) || (keyPressed.key == "v" && keyPressed.ctrlKey);
}
