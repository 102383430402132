<label class="toggle" for="{{ id }}">
    <input type="checkbox" 
		   id="{{ id }}"
		   class="toggle__input"
		   [ngClass]="{ 'toggle__input:checked': toggled, 'toggle__input:disabled': disabled }"
		   [disabled]="disabled"
		   [checked]="toggled"		   
		   (click)="onToggle()" />
    <span class="toggle-track">
		<span class="toggle-indicator">
			<span class="material-icons md-18 checkMark">done</span>
		</span>
	</span>
</label>
