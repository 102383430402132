import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { mergeMap, withLatestFrom, switchMap, Observable, filter } from "rxjs";
import { StepperActions } from "./stepper-actions";
import { Action, Store } from "@ngrx/store";
import { StepperSelectors } from "./stepper-selectors";
import { LsStep } from "../../../Elements/stepper/limestone-element-stepper.component";
import { CompanyProfileActions, CompanyProfileSelectors, CompanyProfileRelationshipSelectors } from "..";
import { IRouteStepData } from "../../Models/Interfaces";
import { ActiveState, ChildStepType, StepperState } from "./stepper-reducer";
import { OnboardCompanyProgress, CompanyProfileRelationship } from "src/app/Models";

@Injectable()
export class StepperEffects {
	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private stepperSelectors: StepperSelectors,
		private companyProfileSelectors: CompanyProfileSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	dispatchNextStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.setActiveRouteData),
			filter((act) => act.ignoreNextStep === undefined || !act.ignoreNextStep),
			switchMap((act) => [StepperActions.setNextStep({})])
		)
	);

	setNextStep$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.setNextStep),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
				this.store.select(this.companyProfileSelectors.selectCompanyProfile)
			),
			filter(
				([act, state, cpProgress, cpr, cp]) =>
					!!act && !!state && state.activeState !== undefined && !!cpProgress && !!cp
			),
			mergeMap(([act, state, cpProgress, cpr, cp]) => {
				let actions: Action[] = [];
				if (state.activeState?.childStepsActive) {
					const childStepType = state.activeState.childStepType;
					actions = this.handleChildStepChange(state, cpProgress, false, childStepType, false);
				}
				return actions.concat(this.handleStepChange(state, cpProgress, cpr, false));
			})
		)
	);

	initializeStepper$ = createEffect(
		(): Observable<Action> =>
			this.actions$.pipe(
				ofType(StepperActions.initializeStepper),
				withLatestFrom(
					this.store.select(this.stepperSelectors.selectState),
					this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
					this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
				),
				filter(([act, state, cpProgress, cpr]) => !!state && !!cpProgress),
				mergeMap(([act, state, cpProgress, cpr]) => {
					return this.handleStepChange(state, cpProgress, cpr, true);
				})
			)
	);
	handleStepChange(
		state: StepperState,
		cpProgress: OnboardCompanyProgress | undefined,
		cpr: CompanyProfileRelationship | undefined,
		isInit: boolean
	): Action[] {
		const actions: Action[] = [];
		const customerSetupStep = state.steps.findIndex((s) => s.childStepType === ChildStepType.CUSTOMER_SETUP);
		const steps = state.steps;
		let currentStep = cpProgress?.currentStep ?? 0;
		let currentSubStep = cpProgress?.currentSubStep ?? 0;

		if (!!currentStep && state.steps[currentStep].displaySettings) {
			actions.push(StepperActions.setSettingsDisplay({ display: true }));
			state.displaySettings = true;
		}

		if (currentStep === customerSetupStep && cpr?.currentSubStep) {
			currentSubStep = cpr!.currentSubStep;
		}

		if (state.activeRouteStepData?.step === undefined) {
			state.activeRouteStepData = this.getRouteStepData(state.routeStepData!, currentStep, currentSubStep);
		}
		if (
			!isInit &&
			(cpProgress?.currentSubStep === undefined ||
				state.activeRouteStepData!.step! > cpProgress!.currentStep! ||
				(state.activeRouteStepData!.step! === cpProgress!.currentStep &&
					state.activeRouteStepData!.substep! > cpProgress!.currentSubStep!))
		) {
			cpProgress!.currentStep = state.activeRouteStepData!.step;
			cpProgress!.currentSubStep = state.activeRouteStepData!.substep;
			actions.push(CompanyProfileActions.updateOnboardCompanyProgress({ progress: cpProgress! }));
		}
		if (cpProgress!.currentStep! > currentStep) {
			currentStep = cpProgress!.currentStep!;
			currentSubStep = cpProgress!.currentSubStep!;
		}
		this.updateProgress(currentStep, currentSubStep, steps, isInit);
		return actions.concat(this.getActionsToSynchronizeStepsAndActiveState(steps, state));
	}

	initializeChildSteps$ = createEffect(() =>
		this.actions$.pipe(
			ofType(StepperActions.initializeChildSteps),
			withLatestFrom(
				this.store.select(this.stepperSelectors.selectState),
				this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			),
			filter(([act, state, cpr]) => !!state && state.steps.length > 0 && !!cpr),
			mergeMap(([act, state, cpr]) => {
				return this.handleChildStepChange(state, cpr, false, act.childStepType);
			})
		)
	);

	handleChildStepChange(
		state: StepperState,
		cpr: CompanyProfileRelationship | undefined,
		isInit: boolean,
		childStepType?: ChildStepType,
		dispatchActions = true
	): Action[] {
		const steps = state.steps;

		let childSteps: LsStep[] = [];
		const childProgress = cpr!.currentSubStep!;
		switch (childStepType) {
			case ChildStepType.CUSTOMER_SETUP:
				const customerSetupStep = steps.findIndex((s) => s.childStepType === ChildStepType.CUSTOMER_SETUP);
				//No customer setup in SCF
				if (!isInit && customerSetupStep !== -1) {
					childSteps = steps[customerSetupStep].children!;
				}
				this.updateChildProgress(childSteps, childProgress);
		}
		return dispatchActions ? this.getActionsToSynchronizeStepsAndActiveState(steps, state) : [];
	}
	private getActionsToSynchronizeStepsAndActiveState(steps: LsStep[], state: StepperState): Action[] {
		return [
			StepperActions.updateSteps({ steps }),
			StepperActions.setActiveState({
				activeState: this.configureActiveState(state.activeRouteStepData!, steps, state.displaySettings)
			})
		];
	}

	private configureActiveState(routeData: IRouteStepData, steps: LsStep[], displaySettings: boolean): ActiveState {
		let activeState: ActiveState;
		if (
			routeData.section !== undefined &&
			steps[routeData.step!].childDisplayStep != undefined &&
			steps[routeData.step!].childDisplayStep! <= routeData.substep!
		) {
			const childSteps = steps[routeData.step!].children;
			activeState = {
				activeSteps: childSteps!,
				activeStep: routeData.section,
				activeSubstep: routeData.subSection!,
				childStepsActive: true,
				childStepType: childSteps![routeData.section!].childStepType,
				displaySettingsButton: displaySettings,
				displayNavBackButton: true,
				navBackName: steps![routeData.step!].label,
				parent: {
					activeSteps: steps,
					activeStep: routeData.step!,
					activeSubstep: routeData.substep!,
					childStepsActive: false,
					displaySettingsButton: displaySettings,
					displayNavBackButton: false
				}
			};
		} else {
			activeState = {
				activeSteps: steps,
				activeStep: routeData.step!,
				activeSubstep: routeData.substep!,
				childStepsActive: false,
				displaySettingsButton: displaySettings,
				displayNavBackButton: false
			};
		}

		return activeState;
	}

	private setPercentComplete(step: LsStep, activeSubStep: number): void {
		if (step.percentComplete !== 100) {
			step.completedSubsteps = activeSubStep;
			step.percentComplete = (step.completedSubsteps / (step.substeps ?? 1)) * 100;
		}
	}

	private updateStep(previousStep: LsStep): void {
		previousStep.completedSubsteps = previousStep.substeps;
		previousStep.percentComplete = 100;
	}

	private getRouteStepData(
		routeStepData: Map<string, IRouteStepData>,
		stepVal: number,
		substepVal: number
	): IRouteStepData {
		return [...routeStepData.values()].filter(
			(routeStepData) => routeStepData.step === stepVal && routeStepData.substep === substepVal
		)[0];
	}

	private resetChildProgress(childSteps: LsStep[]): void {
		childSteps.forEach((step) => {
			if (!step.childStepAlwaysComplete) {
				step.completedSubsteps = 0;
				step.percentComplete = 0;
			}
		});
	}

	private updateChildProgress(childSteps: LsStep[], childProgress: number): void {
		this.resetChildProgress(childSteps);
		childSteps.forEach((step) => {
			if (childProgress > step.substeps) {
				this.updateStep(step);
				childProgress = childProgress - step.substeps;
			} else if (childProgress > 0) {
				step.completedSubsteps = childProgress;
				childProgress = 0;
				this.setPercentComplete(step, step.completedSubsteps);
			}
		});
	}

	private updateProgress(progressStep: number, progressSubStep: number, steps: LsStep[], isInit = false): void {
		const init = isInit ? 0 : progressStep === 0 ? 0 : progressStep - 1;
		for (let i = init; i <= progressStep; i++) {
			if (progressStep > i) {
				this.updateStep(steps[i]);
			} else {
				this.setPercentComplete(steps[i], progressSubStep);
			}
		}
	}
}
