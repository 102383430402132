import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CompanyDocumentSelectors } from "../../../OnboardingStateManagement";
import { Actions } from "@ngrx/effects";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { FileUploadBaseComponent } from "../../../../../Core";
import { filter, map, takeUntil, withLatestFrom } from "rxjs";
import { CompanyProfileActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import copy from "fast-copy";
import { CompanyProfile } from "src/app/Models";
import { LsValidators } from "@limestone/ls-shared-modules";
import { DomSanitizer } from "@angular/platform-browser";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-w9-tax-form-upload",
	templateUrl: "./w9-tax-form-upload.component.html",
	styleUrls: ["./w9-tax-form-upload.component.scss"]
})
export class W9TaxFormUploadComponent extends FileUploadBaseComponent {
	public companyProfile?: CompanyProfile;
	public TAX_ID = "taxId";
	public taxIdForm = new FormGroup({});
	public taxIdControl = new FormControl<string | undefined>(undefined, [Validators.pattern(/^\d{9}$/)]);

	public allowedKeys: string[] = [
		"Backspace",
		"Delete",
		"Control",
		"ArrowRight",
		"ArrowLeft",
		"ArrowUp",
		"ArrowDown",
		"v"
	];

	constructor(
		public router: Router,
		public store: Store,
		public companyDocumentSelectors: CompanyDocumentSelectors,
		public dialog: MdbModalService,
		public companyProfileSelectors: CompanyProfileSelectors,
		public actions$: Actions,
		public sanitizer: DomSanitizer,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, dialog, actions$, sanitizer, routeStepDataService);
		this.maxFileCount = 1;
		this.taxIdForm.addControl(this.TAX_ID, this.taxIdControl);
		this.initData();
	}

	public navTo() {
		this.nav();
	}

	controlHasError(controlName: string): boolean {
		if (this.taxIdForm.get(controlName)) {
			return (
				this.taxIdForm.get(controlName)!.invalid &&
				(this.taxIdForm.get(controlName)!.dirty || this.taxIdForm.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		const error: string[] = [];
		if (this.taxIdForm.get(controlName)?.errors!["pattern"]) {
			error.push("Please enter a 9-digit EIN.");
		}
		return error.join(" ");
	}

	public isNextButtonDisabled(): boolean {
		return this.controlHasError(this.TAX_ID);
	}

	public continue() {
		if (this.taxIdForm.dirty) {
			const updatedCompanyProfile = copy(this.companyProfile!);
			updatedCompanyProfile.taxId = this.taxIdForm.get(this.TAX_ID)?.value;
			this.store.dispatch(
				CompanyProfileActions.updateCompanyProfile({
					companyProfile: updatedCompanyProfile
				})
			);
		}
		this.nav();
	}

	public initData(): void {
		this.store
			.select(this.companyDocumentSelectors.selectDocumentsFor(this.getDocType()))
			.pipe(
				takeUntil(this.getComponentTearDown()),
				withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
				filter(([docs, cp]) => !!docs && !!cp),
				map(([docs, cp]) => {
					this.files = this.rebuildFilesListAfterUpload(docs!);

					this.disabled = this.files!.filter((f) => f.status !== "Failed").length! >= this.maxFileCount;
					this.companyProfile = cp;
					this.setCompanyId(cp!.companyId!);

					this.submitted = cp!.hasSubmittedApplications ?? false;

					if (!this.taxIdControl.dirty) {
						this.taxIdControl.patchValue(cp!.taxId);
					}

					if (this.submitted) {
						this.taxIdForm.disable();
					} else {
						this.taxIdForm.enable();
					}
				})
			)
			.subscribe();
	}

	onKeyboardEvent(event: KeyboardEvent) {
		const isHighlighted = window.getSelection && window.getSelection()!.toString().length > 0;
		if (
			!LsValidators.allowedInput(event, /([0-9])+/g, this.allowedKeys) ||
			(this.taxIdForm.get(this.TAX_ID)?.value !== null && this.taxIdForm.get(this.TAX_ID)?.value?.length >= 9)
		) {
			if (event.key !== "Delete" && event.key !== "Backspace" && !isHighlighted) {
				event.preventDefault();
			}
		}
	}

	onClipboardEvent(event: ClipboardEvent) {
		if (
			!LsValidators.allowedInput(event, /^\d{9}$/, this.allowedKeys) ||
			(this.taxIdForm.get(this.TAX_ID)?.value !== null && this.taxIdForm.get(this.TAX_ID)?.value.length >= 9)
		) {
			event.preventDefault();
		}
	}
}
