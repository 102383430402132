import copy from "fast-copy";
export class ModelService {
	static unflattenToMap<T>(obj: object): Map<string, T> {
		const result = new Map<string, T>();
		if (!obj) return result;

		const keys = Object.keys(obj);
		keys.forEach((key) => {
			const value = copy(obj[key as keyof typeof obj] as T);
			result.set(key, value);
		});
		return result;
	}
}
