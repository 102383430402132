<div class="grid ls-mt-8" style="--bs-gap:3.5rem;">
    <div class="g-col-12">
        <label class="xs-bold">Invoice Source</label>
        <p class="l-bold">{{ companyProfileRelationship?.instrumentPlatform?.name }}</p>
    </div>
    <div class="g-col-12">
        <label class="xs-bold">Invoice Approval Status Source
            <ls-info-dialog-icon [header]="SharedText.InvoiceApprovals.tooltipHeader"
                [content]="SharedText.InvoiceApprovals.tooltipContent">
                <ls-tooltip [position]="'right'">
                    <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <label class="l-bold text-wrap">Invoice approvals</label>
                        <p class="m ls-mb-0 ls-mt-2 text-wrap">Your customer can provide approval status for your
                            invoices.
                            Getting invoice approval from your customers will result in better pricing.</p>
                    </div>
                </ls-tooltip>

            </ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.instrumentApprovalPlatform?.name }}</p>
    </div>
    <div class="g-col-12">
        <label class="xs-bold">Remittance Data Source
            <ls-info-dialog-icon content="Remittance history refers to the transaction history between
                    you and your customer. We use it to confirm that your customer has paid you">

                <ls-tooltip [position]="'right'">
                    <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <p class="m ls-mb-0 ls-mt-2 text-wrap">Remittance history refers to the transaction history
                            between
                            you and your customer. We use it to confirm that your customer has paid you.</p>
                    </div>
                </ls-tooltip>

            </ls-info-dialog-icon>
        </label>
        <p class="l-bold">{{ companyProfileRelationship?.remittancePlatform?.name }}</p>
    </div>
</div>