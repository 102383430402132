<div class="ls-modal-container">
		<h2 class="no-text-transform ls-mb-3">Skip connecting accounting platform?</h2>
		<p class="l">By connecting your accounting system, we’ll be able to approve your company faster and get you paid sooner.</p>
		<ls-accordion header="Why is this process lengthier?">
				<ul class="no-indent-list ls-mb-0">
						<li>
								<p class="l ls-mb-2">The manual process asks for documents that you have to find, scan, and upload, and includes additional questions that are automatically answered by connecting your accounts.</p>
						</li>
						<li>
								<p class="l ls-mb-0">We like to be thorough with our clients, and the manual process has more gaps of information. With back and forth communication involved, this usually results in the process taking three to five times longer.</p>
						</li>
				</ul>
		</ls-accordion>
		<div class="modal-action-btns">
				<button type="button" class="btn btn-outline-tertiary btn-lg" (click)="close(ModalActions.SECONDARY)">Skip</button>
				<button type="button" class="btn btn-primary btn-lg" (click)="close(ModalActions.PRIMARY)">Connect my accounting</button>
		</div>
</div>