import { Component } from "@angular/core";
import { ModalActions } from "../../../../Core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";

@Component({
	selector: "ls-bank-account-persuasion",
	templateUrl: "./bank-account-persuasion.component.html",
	styleUrls: ["./bank-account-persuasion.component.scss"]
})
export class BankAccountPersuasionComponent {
	constructor(public modalRef: MdbModalRef<BankAccountPersuasionComponent>) {}
	public get ModalActions(): typeof ModalActions {
		return ModalActions;
	}

	public close(action: ModalActions): void {
		this.modalRef.close(action);
	}
}
