import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Attachment } from "src/app/Models/Attachment";

@Component({
	selector: "ls-case-message-attachments",
	templateUrl: "./case-message-attachments.component.html",
	styleUrls: ["./case-message-attachments.component.scss"]
})
export class CaseMessageAttachmentsComponent {
	@Input() attachments?: Attachment[];
	@Input() attachmentsRemovable = false;
	@Output() removeAttachment: EventEmitter<number> = new EventEmitter();
	@Output() openAttachment: EventEmitter<number> = new EventEmitter();
	@Output() downloadAttachment: EventEmitter<number> = new EventEmitter();

	constructor() {}

	public remove(index: number): void {
		this.removeAttachment.emit(index);
	}

	public open(index: number): void {
		this.openAttachment.emit(index);
	}

	public download(index: number): void {
		this.downloadAttachment.emit(index);
	}
}
