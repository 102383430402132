import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { ScoringService } from "../../../services";
import { ScoringActions } from "./scoring-actions";
import { QuestionSet } from "src/app/Models";

@Injectable()
export class ScoringEffects {
	constructor(
		private actions$: Actions,
		private scoringService: ScoringService
	) {}
	fetchScoring$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ScoringActions.getScoring),
			switchMap(() =>
				this.scoringService.getScoring().pipe(
					map((scoring: Map<string, QuestionSet>) => {
						return ScoringActions.setScoring({ pricingGauge: scoring });
					})
				)
			)
		)
	);
}
