<form [formGroup]="form" class="d-flex">
   <div class="w-100 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
      <div class="w-100 d-flex flex-row justify-content-center sticky-top">
         <ls-alert *ngIf="actionMessage" [alertType]="actionMessage.type" [icon]="actionMessage.icon" [small]="true">
            <label class="xs">{{actionMessage.message}}</label>
         </ls-alert>
      </div>
      <div class="title-action-container w-100 d-flex flex-row justify-content-between align-items-center ls-mb-8 position-relative">
         <h3 class="m ls-mb-0">Users</h3>
         <button class="btn btn-icon btn-secondary text-nowrap" (click)="inviteUser()">
            <span class="material-icons left-icon">person_add</span>Invite User
         </button>
      </div>
      <ls-alert *ngIf="users!.length < 2" alertType="secondary" [small]="true" class="ls-mb-4 w-100">
         <p class="m ls-mb-0">You only have 1 user in your account. Please add a second user to ensure business continuity.</p>
      </ls-alert>
      <div class="d-flex flex-column flex-grow-1 w-100">
         <div class="ls-mb-3 d-flex flex-row justify-content-between align-items-stretch">
            <mdb-form-control class="d-flex justify-content-start align-items-center">
               <input mdbInput
                  [formControlName]="SEARCH_CONTROL"
                  type="text"
                  class="form-control form-control-lg search-input" 
                  id="userSearch"
                  aria-describedby="search-icon" />
               <span class="input-search-icon material-icons search-icon" id="search-icon">search</span>
               <label mdbLabel class="form-label search-label" for="userSearch">Search user</label>
            </mdb-form-control>
         </div>
         <div class="d-none d-xxl-block">
            <table class="table align-middle ls-mb-0 bg-white table-hover">
               <thead> 
                  <tr>
                     <th *ngFor="let column of columns; let isFirst = first" class="header-cell">
                        <div class="d-flex align-items-center" *ngIf="column.canSort" [ngClass]="{'ls-ml-2': isFirst}">
                           <label class="s-caps">{{column.column}}</label>
                           <span type="button" class="material-icons ls-ml-2" (click)="gridService.sortColumn(column)">
                              {{gridService.getIcon(column)}}
                           </span>
                        </div>
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr *ngFor="let user of filteredUsers; let i = index" [class.table-active]="editIndex === i">
                     <td>
                        <div class="d-flex align-items-center">
                           <div class="ls-ml-2">
                              <p class="ls-mb-1 m">{{user.firstName}} {{user.lastName}}</p>
                              <p class="ls-mb-0 additional-info">{{user.emailAddress}}</p>
                           </div>
                        </div>
                     </td>
                     <td>
                        <div class="ls-ml-2">
                           <div class="ls-icon-badge"
                                 [class.ls-badge-primary-light]="user.status?.id === 'ACT'"
                                 [class.ls-badge-tertiary-light]="user.status?.id === 'PEND'">
                              <span class="material-icons">{{user.status?.id === 'ACT'? 'fiber_manual_record' : 'pending'}}</span>
                              <label class="xs semi-bold">{{user.status?.name}}</label>
                           </div>
                           <p class="ls-mb-0 additional-info" *ngIf="user.status?.id === 'ACT'">Last login: {{user.lastLogin | date:'MM/dd/YYYY h:mm aa'}}</p>
                           <p class="ls-mb-0 additional-info" *ngIf="user.status?.id === 'PEND'">Invite sent: {{user.lastInviteSent | date:'MM/dd/YYYY h:mm aa'}}</p>
                        </div>
                     </td>
                     <td (click)="openUserRoleDropdown($event, user.userId!, i)">
                        <ls-user-role-dropdown
                           [options]="options"
                           [userRoleId]="user.userRole?.id?.toString()"
                           [roleName]="getRoleName(user.userRole)"
                           [canEdit]="canEdit(user.userId!)"
                           [isEditing]="editIndex === i"
                           (userRoleChanged)="userRoleChanged($event, user.userId!)"
                           (opened)="openUserRoleDropdown($event, user.userId!, i)"
                           (hidden)="dropdownHidden(i)"
                        ></ls-user-role-dropdown>
                     </td>
                     <td>
                        <div class="d-flex flex-row justify-content-end">
                           <span class="material-icons-outlined ls-mr-4" [mdbTooltip]="getTimeUntilResend(user.lastInviteSent)" placement="bottom" [delayShow]="2000" [class.hoverable]="canEdit(user.userId!) && canResend(user.lastInviteSent)"
                           [class.disabled]="!canResend(user.lastInviteSent)" (click)="resendInvite(user)" [hidden]="user.status?.id !== 'PEND'">
                              send
                           </span>
                           <span class="material-icons-outlined ls-mr-4" mdbTooltip="Delete" placement="bottom" [delayShow]="2000" [class.hoverable]="canEdit(user.userId!)"
                              (click)="deleteUser(user)" [hidden]="!canEdit(user.userId!)">
                              delete
                           </span>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
         <div class="d-xxl-none">
            <div class="user-card ls-mb-3 ls-p-3 rounded-6" *ngFor="let user of filteredUsers; let i = index">
               <div class="d-none d-lg-grid d-xl-grid grid">
                  <div class="g-col-4 ls-ml-2">
                     <label class="ls-mb-1 l">{{user.firstName}} {{user.lastName}}</label>
                     <p class="ls-mb-0 additional-info">{{user.emailAddress}}</p>
                  </div>
                  <div class="g-col-2 align-self-center">
                     <div class="ls-icon-badge"
                        [class.ls-badge-primary-light]="user.status?.id === 'ACT'"
                        [class.ls-badge-tertiary-light]="user.status?.id === 'PEND'">
                        <span class="material-icons">{{user.status?.id === 'ACT'? 'fiber_manual_record' : 'pending'}}</span>
                        <label class="xs semi-bold">{{user.status?.name}}</label>
                     </div>
                  </div>
                  <div class="g-col-3 align-self-center">
                     <ls-user-role-dropdown
                        [options]="options"
                        [userRoleId]="user.userRole?.id?.toString()"
                        [roleName]="getRoleName(user.userRole)"
                        [canEdit]="canEdit(user.userId!)"
                        [isEditing]="editIndex === i"
                        (userRoleChanged)="userRoleChanged($event, user.userId!)"
                        (opened)="openUserRoleDropdown($event, user.userId!, i)"
                        (hidden)="dropdownHidden(i)"
                     ></ls-user-role-dropdown>
                  </div>
                  <div class="g-col-3 align-self-center">
                     <div class="d-flex flex-row justify-content-end">
                        <span class="material-icons-outlined ls-mr-4" [mdbTooltip]="getTimeUntilResend(user.lastInviteSent)" placement="bottom" [delayShow]="2000" [class.hoverable]="canEdit(user.userId!) && canResend(user.lastInviteSent)" 
                        [class.disabled]="!canResend(user.lastInviteSent)" (click)="resendInvite(user)" [hidden]="user.status?.id !== 'PEND'">
                           send
                        </span>
                        <span class="material-icons-outlined ls-mr-4" mdbTooltip="Delete" placement="bottom" [delayShow]="2000" [class.hoverable]="canEdit(user.userId!)"
                           (click)="deleteUser(user)" [hidden]="!canEdit(user.userId!)">
                           delete
                        </span>
                     </div>
                  </div>
               </div>
               <div class="d-flex flex-column d-lg-none d-xl-none">
                  <div class="d-flex flex-row justify-content-between ls-mb-3">
                     <div>
                        <label class="ls-mb-1 l">{{user.firstName}} {{user.lastName}}</label>
                        <p class="ls-mb-0 additional-info">{{user.emailAddress}}</p>
                     </div>
                     <div class="align-self-center">
                        <div class="ls-icon-badge"
                           [class.ls-badge-primary-light]="user.status?.id === 'ACT'"
                           [class.ls-badge-tertiary-light]="user.status?.id === 'PEND'">
                           <span class="material-icons">{{user.status?.id === 'ACT'? 'fiber_manual_record' : 'pending'}}</span>
                           <label class="xs semi-bold">{{user.status?.name}}</label>
                        </div>
                     </div>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                     <div class="align-self-center">
                        <ls-user-role-dropdown
                           [options]="options"
                           [userRoleId]="user.userRole?.id?.toString()"
                           [roleName]="getRoleName(user.userRole)"
                           [canEdit]="canEdit(user.userId!)"
                           [isEditing]="editIndex === i"
                           (userRoleChanged)="userRoleChanged($event, user.userId!)"
                           (opened)="openUserRoleDropdown($event, user.userId!, i)"
                           (hidden)="dropdownHidden(i)"
                        ></ls-user-role-dropdown>
                     </div>
                     <div class="align-self-center">
                        <div class="d-flex flex-row justify-content-end">
                           <span class="material-icons-outlined ls-mr-4" [mdbTooltip]="getTimeUntilResend(user.lastInviteSent)" placement="bottom" [delayShow]="2000" [class.hoverable]="canEdit(user.userId!) && canResend(user.lastInviteSent)" [class.disabled]="!canResend(user.lastInviteSent)"
                              (click)="resendInvite(user)" [hidden]="user.status?.id !== 'PEND'">
                              send
                           </span>
                           <span class="material-icons-outlined ls-mr-4" mdbTooltip="Delete" placement="bottom" [delayShow]="2000" [class.hoverable]="canEdit(user.userId!)"
                              (click)="deleteUser(user)" [hidden]="!canEdit(user.userId!)">
                              delete
                           </span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>