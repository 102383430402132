<div>
	<form [formGroup]="fgd.form">
		<div *ngFor="let lookup of lookups;let isLast = last" [ngClass]="{'ls-mb-3': !isLast}"
			 [class]="'d-flex align-items-center '+extraClasses">
			<input mdbRadio [formControlName]="controlName!" class="radio" type="radio"
				   [id]="controlName+'_'+lookup.id" [value]="lookup.id"/>
			<label class="xl" [for]="controlName+'_'+lookup.id">
				{{ lookup.name }}
				<ls-info-dialog-icon *ngIf="!!getTooltip(lookup!.id) && is103Enabled"
									 [header]="getTooltip(lookup!.id)?.header"
									 [content]="getTooltip(lookup!.id)?.content"></ls-info-dialog-icon>
				<ls-tooltip *ngIf="!!getTooltip(lookup!.id) && !is103Enabled">
					<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
					<div content [innerHTML]="getTooltip(lookup!.id)?.content">
					</div>
				</ls-tooltip>
			</label>
		</div>
	</form>
</div>