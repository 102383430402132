import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { MarketplaceMoreDetailsComponent } from "../../Modals";
import { Flow, Product } from "src/app/Models/Enums";
import { Store } from "@ngrx/store";
import { CompanyProfileActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { filter, map, Subject, take, takeUntil, withLatestFrom } from "rxjs";
import { CompanyProfile, OnboardCompanyProgress } from "src/app/Models";
import { MdbNotificationService } from "mdb-angular-ui-kit/notification";
import { Actions, ofType } from "@ngrx/effects";
import { CompanyProgressService, RouteStepDataService } from "../../../COT-Module/Services";
import { ContinueApplicationService } from "../../../../../services";
import { FeatureFlag, FeatureFlags, FeatureFlagSelectors } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-marketplace",
	templateUrl: "./marketplace.component.html",
	styleUrls: ["./marketplace.component.scss"]
})
export class MarketplaceComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	public cards: MarketplaceCard[] = [
		{
			id: Flow.ARF_PG,
			productId: Product.ARF,
			title: "Accelerate Payments for your Receivables",
			paragraphs: [
				`Get paid now for invoices that are due in 30, 60 or 90-plus days. Your business can 
                use this improved working capital to fund new sales, increase marketing, hire new personnel, 
                or just to stabilize your cash flow.`
			],
			details: {
				header: "Accelerate Your Payments",
				introduction: `With Accounts Receivable Finance from Raistone, your business is paid 
                                weeks or months earlier, while your customers’ payment terms stay the same. 
                                All without taking on a loan.`,
				benefitsTitle: "Some benefits of Accounts Receivable Finance with Raistone",
				benefits: [
					"Reduce your company’s days sales outstanding (DSO) and accept longer payment terms without hurting your cash position.",
					"Non-debt working capital that co-exists with and complement other lending facilities.",
					"No process changes or buyer involvement needed with our solution that is easy to use, integrated, and automated.",
					"Suppliers can accelerate payment on an invoice due at a later date and get paid up to 100% advance rate, less discount fee."
				],
				footer: `With Raistone, businesses can improve their cash position, allowing them to grow their 
                         business, take on new clients and reduce risk, all without taking on debt.`,
				linkUrl: "https://raistone.com/accounts-receivable-finance/",
				linkLabel: "Learn more about Accounts Receivable Finance"
			}
		},
		{
			id: Flow.SCF_V1,
			productId: Product.SCF,
			title: "Extend Payment Terms for your Accounts Payables",
			paragraphs: [
				`Improve your working capital through increasing days 
                payable outstanding without putting financial strain on your suppliers.`,
				`Grow your business, without taking on debt or raising equity. Reduce risk 
                and keep your business resilient against supply chain disruptions. Maintain 
                or extend payment terms to control your cash flow, all while unlocking lower-cost 
                access to working capital for your suppliers.`
			],
			details: {
				header: "Stabilize Your Supply Chain",
				introduction: `With Supply Chain Finance, your company can maintain or extend payment 
                                terms to control your cash flow, all while unlocking lower-cost access 
                                to working capital for your suppliers.`,
				benefitsTitle: "Some benefits of Supply Chain Finance",
				benefits: [
					`Stabilize your supply chain. Ensure solid liquidity across the supply chain, and 
                    enable your suppliers to unlock a lower cost of financing.`,
					`Flexible terms give you better control of your cashflow, and provide more financing 
                    options for your suppliers.`,
					`Onboarding can occur in days versus weeks or more for bank-only programs, thanks to 
                    use of non-bank capital.`
				],
				footer: `With Raistone, businesses can improve their cash position, allowing them to grow 
                        their business, take on new clients and reduce risk, all without taking on debt.`,
				linkUrl: "https://raistone.com/supply-chain-finance/",
				linkLabel: "Learn more about Supply Chain Finance"
			}
		}
	];
	@ViewChild("successToast", { static: true }) successToast?: TemplateRef<any>;

	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public actions$: Actions,
		private dialog: MdbModalService,
		private companyProfileSelectors: CompanyProfileSelectors,
		public notificationService: MdbNotificationService,
		private companyProgressService: CompanyProgressService,
		private continueApplicationService: ContinueApplicationService,
		private featureFlagSelectors: FeatureFlagSelectors,
		private routeStepDataService: RouteStepDataService
	) {}

	public companyProfile?: CompanyProfile;
	public hasScfLead?: boolean = false;
	public hasArfApps?: boolean = false;
	public hasArfAppWithNoCustomers?: boolean = false;
	ngOnInit(): void {
		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cp) => !!cp),
				takeUntil(this.componentTeardown$),
				withLatestFrom(this.store.select(this.featureFlagSelectors.selectFeatureFlags)),
				map(([cp, ff]) => {
					this.configureFlow(ff, cp!);
					this.hasScfLead = cp?.hasScfLead;
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.createLeadForCompanyProfileSuccess),
				map(() => {
					this.notificationService.open(this.successToast!, { autohide: true, position: "top-center" });
					this.hasScfLead = true;
				})
			)
			.subscribe();
	}

	public configureFlow(ff: FeatureFlag[] | null, cp: CompanyProfile) {
		const useARFPG = !!ff?.find((f) => f.id === FeatureFlags.Sunfyre_ARFPricingGauge_2024718 && f.enabled);
		if (useARFPG) {
			this.cards[0].id = Flow.ARF_PG;
		} else if (!cp?.inProgressFlows?.includes(Flow.ARF_PG)) {
			const card = this.cards.find((c) => c.id == Flow.ARF_PG);
			if (card) {
				card.id = Flow.ARF_V1;
			}
		}
		this.hasArfApps = cp?.inProgressFlows?.some((f) => f == Flow.ARF_PG || f == Flow.ARF_V1);
		this.companyProfile = cp;
		this.hasScfLead = cp?.hasScfLead;
		this.hasArfAppWithNoCustomers = cp?.hasArfAppWithNoCustomers;
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	canApplyNow(): boolean {
		return !this.hasScfLead!;
	}

	applyNow(card: MarketplaceCard): void {
		if (card.productId === Product.ARF) {
			if (this.hasArfAppWithNoCustomers || this.hasArfApps) {
				this.companyProgressService
					.getOnboardCompanyProgress(this.companyProfile!.companyId!, card.productId)
					.pipe(
						take(1),
						map(() => {
							const flow = this.companyProfile?.inProgressFlows?.includes(card.id)
								? card.id
								: this.companyProfile?.inProgressFlows?.find((f) => f.startsWith(Product.ARF));
							this.hasArfAppWithNoCustomers
								? this.continueApplicationService.continueApplication()
								: this.routeStepDataService.navByPath(`/onboarding/${flow}/customer-setup`);
						})
					)
					.subscribe();
			} else {
				this.companyProfile?.inProgressFlows?.push(card.id);
				this.store.dispatch(CompanyProfileActions.setCompanyProfile({ companyProfile: this.companyProfile! }));
				this.store.dispatch(
					CompanyProfileActions.createOnboardCompanyProgress({
						progress: new OnboardCompanyProgress(
							this.companyProfile!.companyId!,
							false,
							undefined,
							undefined,
							null,
							card.productId,
							card.id
						)
					})
				);
			}
		}
		if (card.productId === Product.SCF) {
			const companyId = this.companyProfile?.companyId;
			this.store.dispatch(CompanyProfileActions.createLeadForCompanyProfile({ companyId: companyId, flowId: card.id }));
		}
	}

	public openModal(details: MarketplaceCardDetail): void {
		this.dialog.open(MarketplaceMoreDetailsComponent, {
			modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
			ignoreBackdropClick: true,
			data: {
				details: details
			}
		});
	}

	protected readonly Product = Product;
}

interface MarketplaceCard {
	id: Flow;
	productId: Product;
	title: string;
	paragraphs: string[];
	details: MarketplaceCardDetail;
}

export interface MarketplaceCardDetail {
	header: string;
	introduction: string;
	benefitsTitle: string;
	benefits: string[];
	footer: string;
	linkUrl: string;
	linkLabel: string;
}
