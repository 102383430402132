<div class="ls-modal-container d-flex flex-column">
    <h2 class="l ls-mb-3">Invite other members to {{companyName}}</h2>
    <p class="l ls-mb-4">You can invite members to join your organization.</p>
    <div class="d-flex flex-column">
        <div class="form-container">
            <form class="d-flex flex-column ls-mb-6" [formGroup]="form">
                <ls-contact-form-control class="ls-mb-3" [hidePhoneControl]="true" orientation="column" [formControlName]="CONTACT"></ls-contact-form-control>
                <mdb-form-control>
                    <mdb-select [formControlName]="ROLE_CONTROL" mdbValidate id="ROLE_CONTROL"
                                [optionHeight]="56" class="form-control form-control-lg">
                        <mdb-option *ngFor="let option of roles" [value]="option.id">
                            {{getRoleName(option)}}
                        </mdb-option>
                    </mdb-select>
                    <label mdbLabel class="form-label" for="ROLE_CONTROL">Role</label>
                </mdb-form-control>
            </form>
        </div>
        <div class="modal-action-btns d-flex justify-content-end">
            <button type="button" class="btn btn-outline-tertiary btn-lg ls-mr-6" (click)="close(ModalActions.SECONDARY)">Cancel</button>
            <button type="button" class="btn btn-primary btn-lg" (click)="close(ModalActions.PRIMARY)" [disabled]="form.invalid">Send Invite</button>
        </div>
    </div>
</div>