import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { LookupKeys } from "src/app/Models/Enums";
import { YesNo, CollectionPreference, TextStyle } from "src/app/Models/Enums";
import { SharedText } from "../shared-text";

@Component({
	selector: "ls-notice-of-assignment",
	templateUrl: "./notice-of-assignment.component.html",
	styleUrls: ["./notice-of-assignment.component.scss"]
})
export class NoticeOfAssignmentComponent extends CustomerRelationshipBaseComponent implements AfterViewInit {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		public changeDetector: ChangeDetectorRef
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	public get SharedText() {
		return SharedText;
	}
	public get TextStyle() {
		return TextStyle;
	}
	public get YesNo() {
		return YesNo;
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	selectedFinanceDisclosure?: string = YesNo.NO;
	FINANCE_DISCLOSURE = "financeDisclosure";
	COLLECTION_PREFERENCE = "collectionPreference";

	noticeOfAssignmentForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;

	finyn: GenericLookup<string>[] = [];
	collections: GenericLookup<string>[] = [];

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
				})
			)
			.subscribe();

		combineLatest([
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinancialDisclosureYesNo)),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.CollectionPreference))
		])
			.pipe(
				filter(([finyn, cp]) => !!finyn && !!cp),
				takeUntil(this.componentTeardown$),
				map(([finyn, cp]) => {
					this.finyn = finyn!;
					this.collections = cp!;
				})
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		this.setupControl(
			this.noticeOfAssignmentForm,
			this.FINANCE_DISCLOSURE,
			this.companyProfileRelationship?.financeDisclosureYesNoId,
			Validators.required
		);
		this.setupControl(
			this.noticeOfAssignmentForm,
			this.COLLECTION_PREFERENCE,
			this.companyProfileRelationship?.collectionPreferenceId,
			Validators.required
		);
		this.noticeOfAssignmentForm
			.get(this.FINANCE_DISCLOSURE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v: string) => {
					this.handleFinancialDisclosureChange(v);
				})
			)
			.subscribe();

		this.handleFinancialDisclosureChange(this.companyProfileRelationship!.financeDisclosureYesNoId);
	}
	private handleFinancialDisclosureChange(value?: string) {
		this.selectedFinanceDisclosure = value;
		//if the initial state is financial disclosure = NO, the required validator is not wired up
		//on page load without the change detection because the form control is not yet visible (I think)
		this.changeDetector.detectChanges();
		const collectionPreference = this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE);
		if (this.selectedFinanceDisclosure === YesNo.NO) {
			collectionPreference?.setValidators(Validators.required);
			collectionPreference?.updateValueAndValidity();
		} else {
			//do not validate the hidden control, db value will be set in getCompanyProfileRelationshipForUpdate
			collectionPreference?.clearValidators();
			collectionPreference?.reset(null, { emitEvent: false });
		}
	}
	isFormInvalid() {
		this.noticeOfAssignmentForm.updateValueAndValidity();
		return this.noticeOfAssignmentForm.invalid;
	}
	getCompanyProfileRelationshipForUpdate() {
		const financeDisclosureId = this.noticeOfAssignmentForm.get(this.FINANCE_DISCLOSURE)?.value;
		let collectionPreferenceId = this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE)?.value;

		if (financeDisclosureId === YesNo.YES || financeDisclosureId === YesNo.IDK) {
			collectionPreferenceId = CollectionPreference.INFORM;
		}
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);

		updatedCompanyProfileRelationship.financeDisclosureYesNoId = financeDisclosureId;
		updatedCompanyProfileRelationship.collectionPreferenceId = collectionPreferenceId;
		return updatedCompanyProfileRelationship;
	}
	continue() {
		if (this.noticeOfAssignmentForm.dirty) {
			const updatedCompanyProfileRelationship = this.getCompanyProfileRelationshipForUpdate();
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.FINANCE_DISCLOSURE,
			new Layout(
				new Map<string, DisplayConfig>([
					["q1", new DisplayConfig(SharedText.FinancialDisclosure.question, TextStyle.TITLE_MEDIUM)],
					["q1-p1", new DisplayConfig(SharedText.FinancialDisclosure.paragraph1, TextStyle.BODY_LARGE)],
					["q1-p2", new DisplayConfig(SharedText.FinancialDisclosure.paragraph2, TextStyle.BODY_LARGE)],
					["q1-accordion", new DisplayConfig(SharedText.FinancialDisclosure.accordionHeader)],
					["q1-a1", new DisplayConfig(SharedText.FinancialDisclosure.accordionItem1, TextStyle.BODY_LARGE)],
					["q1-a2", new DisplayConfig(SharedText.FinancialDisclosure.accordionItem2, TextStyle.BODY_LARGE)]
				]),
				new Map<string | number, string>([
					[YesNo.YES, SharedText.FinancialDisclosure.toolTipYes],
					[YesNo.NO, SharedText.FinancialDisclosure.toolTipNo]
				])
			)
		],
		[
			this.COLLECTION_PREFERENCE,
			new Layout(
				new Map<string, DisplayConfig>([
					["q2", new DisplayConfig(SharedText.CollectionPreference.question, TextStyle.TITLE_MEDIUM)],
					["q2-p1", new DisplayConfig(SharedText.CollectionPreference.paragraph1, TextStyle.BODY_LARGE)]
				])
			)
		]
	]);
}
