<div class="ls-modal-container details-modal d-flex flex-column">
    <span class="material-icons close" (click)="close()">close</span>
    <h2 class="ls-mb-3">{{ details?.header }}</h2>
    <div class="ls-mb-2">
        <p>{{ details?.introduction }}</p>
        <span>{{ details?.benefitsTitle }}
            <ul>
                <li class="ls-pl-2" *ngFor="let benefit of details?.benefits">
                    <span>{{ benefit }}</span>
                </li>
            </ul>
        </span>
        <p>{{ details?.footer }}</p>
    </div>
    <p class="l-bold link" (click)="openLink(details?.linkUrl)">{{details?.linkLabel}}</p>
</div>