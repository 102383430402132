import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, filter, map, Observable, take } from "rxjs";
import { AppSelectors, CodatActions, CodatSelectors, CompanyProfileSelectors } from "../AppStateManagement";
import { FeatureFlags, FeatureFlagSelectors } from "@limestone/ls-shared-modules";
import { Flow } from "../Models/Enums";
import { ActivatedRouteSnapshot, UrlSegment } from "@angular/router";

@Injectable()
export class CodatDataResolver {
	constructor(
		private store: Store<any>,
		private codatSelectors: CodatSelectors,
		private appSelectors: AppSelectors,
		private featureFlagSelectors: FeatureFlagSelectors,
		private companyProfileSelectors: CompanyProfileSelectors
	) {}

	integrationDataConnection$ = this.store.select(this.codatSelectors.selectIntegrationDataConnections).pipe(
		filter((idc) => !!idc),
		take(1)
	);

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		const url = route.url;
		this.initPrefetchData(url);
		return this.integrationDataConnection$;
	}

	initPrefetchData(url: UrlSegment[]): void {
		this.store
			.select(this.codatSelectors.selectIntegrationDataConnections)
			.pipe(
				filter((idc) => !idc),
				take(1),
				map(() => this.store.dispatch(CodatActions.getIntegrations()))
			)
			.subscribe();

		combineLatest([
			this.store.select(this.appSelectors.selectUserRoles),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		])
			.pipe(
				filter(([userRole, ff, ocp]) => !!userRole && userRole.roles.length > 0 && !!ff && !!ocp),
				take(1),
				map(([userRole, ff, ocp]) => {
					if (
						ff?.find((flag) => flag.id === FeatureFlags.SCF_Onboarding_V1_0_0)?.enabled &&
						ocp?.onboardingConfiguration?.id === Flow.SCF_V1 &&
						url.pop()?.path !== "banking-success"
					) {
						this.store.dispatch(
							CodatActions.getBankAccounts({ companyId: userRole!.roles[0].companyId, product: ocp!.product })
						);
					}
				})
			)
			.subscribe();
	}
}
