import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { ITooltip } from "src/app/Models/Interfaces";
import { GenericLookup, GenericSelectors, LookupTables, OnboardExistingFinance } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-other-financing",
	templateUrl: "./other-financing.component.html",
	styleUrls: ["./other-financing.component.scss"]
})
export class OtherFinancingComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
	}

	OTHER_FINANCE_INPUT = "OTHER_FINANCE_INPUT";
	public existingFinances?: GenericLookup<string>[];
	public maxLength = 80;
	public characterCount = 0;

	public tooltips: Map<string, ITooltip> = new Map([
		[
			OnboardExistingFinance.RECEIVABLES_FINANCING,
			{
				title: "Receivables Financing",
				body: "Accounts receivable financing allows companies to receive early payment on their outstanding invoices. Companies using AR financing commits to some or all of its outstanding invoices for early payment in return for a fee.",
				linkText: "Learn more",
				link: "https://raistone.com/accounts-receivable-finance/"
			}
		],
		[
			OnboardExistingFinance.SUPPLY_CHAIN_FINANCE,
			{
				title: "Supply Chain Financing",
				body: "Also known as reverse factoring, supply chain financing allows businesses to maintain or extend payment terms to control their cash flow while providing their suppliers the option to get paid early.",
				linkText: "Learn more",
				link: "https://raistone.com/supply-chain-finance/"
			}
		]
	]);
	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardExistingFinance))
		]).pipe(
			filter(([cp, ef]) => !!cp && !!ef && ef.length > 0),
			map(([cp, ef]) => {
				this.companyProfile = cp;
				this.existingFinances = ef;
				this.setFormControls();
				return cp;
			})
		);
	}

	toggleExistingFinancesFormControls(disable: boolean) {
		this.existingFinances?.forEach((p) => {
			if (p.id !== OnboardExistingFinance.NONE) {
				if (disable) {
					this.form.get(p.id!)?.setValue(false);
					this.form.get(p.id!)?.disable();
				} else {
					this.form.get(p.id!)?.enable();
				}
			}
		});
	}

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.existingFinances = [];
		this.existingFinances?.forEach((p) => {
			if (this.form.get(p.id!)?.value) {
				const name = p.id === OnboardExistingFinance.OTHER ? this.form.get(this.OTHER_FINANCE_INPUT)?.value : p.name;
				updatedCompanyProfile.existingFinances?.push(new GenericLookup<string>(p.id!, name, p.isActive));
			}
		});

		this.emitSave(updatedCompanyProfile);
		this.nav();
	}

	isInvalid() {
		let atLeastOneChecked = false;
		this.existingFinances?.forEach((p) => {
			atLeastOneChecked = atLeastOneChecked || this.form.get(p.id!)?.value;
		});
		return this.form.invalid || !atLeastOneChecked;
	}

	setFormControls() {
		this.form.addControl(
			this.OTHER_FINANCE_INPUT,
			new FormControl(
				{
					value: this.companyProfile?.existingFinances?.find((ef) => ef.id === OnboardExistingFinance.OTHER)?.name,
					disabled: !this.companyProfile?.existingFinances?.some((ef) => ef.id === OnboardExistingFinance.OTHER)
				},
				[Validators.required, Validators.minLength(1)]
			)
		);
		this.characterCount =
			this.companyProfile?.existingFinances?.find((ef) => ef.id === OnboardExistingFinance.OTHER)?.name?.length ?? 0;

		this.existingFinances?.forEach((p) => {
			const checked = this.companyProfile?.existingFinances?.some((ef) => ef.id === p.id);
			this.form.addControl(`${p.id}`, new FormControl(checked));
		});

		this.form
			.get(OnboardExistingFinance.NONE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map(() => {
					this.toggleExistingFinancesFormControls(this.form.get(OnboardExistingFinance.NONE)?.value);
				})
			)
			.subscribe();

		if (
			!!this.companyProfile?.existingFinances &&
			this.companyProfile?.existingFinances?.some((ef) => ef.id === OnboardExistingFinance.NONE)
		) {
			this.toggleExistingFinancesFormControls(true);
		}

		this.form.get(OnboardExistingFinance.OTHER)?.valueChanges.subscribe((value) => {
			if (value) {
				this.form.get(this.OTHER_FINANCE_INPUT)?.enable();
			} else {
				this.form.get(this.OTHER_FINANCE_INPUT)?.disable();
			}
		});

		this.form.get(this.OTHER_FINANCE_INPUT)?.valueChanges.subscribe((value) => {
			this.characterCount = value?.length ?? 0;
		});
	}
}
