<div class="d-flex flex-column">
  <h1 *ngIf="display('header')" class="l ls-mb-8">
    <ls-text id='h1' [displayConfig]="config('header', 'h1')"></ls-text>
  </h1>
  <div class="ls-mb-11">
    <p class="l ls-mb-3">
      <ls-text id='p1' [displayConfig]="config('body', 'p1')"></ls-text>
    </p>
    <div *ngIf="display('accordion')">
      <ls-accordion [header]="config('accordion', 'header')?.text ?? ''" [expanded]="false">
        <ul class="no-indent-list">
          <li>
            <ls-text id='p1' [displayConfig]="config('accordion', 'p1')"></ls-text>
          </li>
          <li>
            <ls-text id='p2' [displayConfig]="config('accordion', 'p2')"></ls-text>
          </li>
          <li>
            <ls-text id='p3' [displayConfig]="config('accordion', 'p3')"></ls-text>
          </li>
        </ul>
      </ls-accordion>
    </div>
    <p class="l ls-mb-0">
      <ls-text id='p2' [displayConfig]="config('body', 'p2')"></ls-text>
    </p>
  </div>
  <div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="onClick()" class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0">
      <ls-text id='next' [displayConfig]="config('buttons', 'next')"></ls-text>
    </button>
    <button type="button" (click)="openModal()" class="btn btn-lg btn-primary-borderless">
      <ls-text id='skip' [displayConfig]="config('buttons', 'skip')"></ls-text>
    </button>
  </div>
</div>