import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil, combineLatest } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { CompanyProfileRelationshipSelectors, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { CurrencyPipe } from "@angular/common";
import { CurrencyCode, OnboardApplicationStatus } from "@limestone/ls-shared-modules";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { ContinueApplicationService } from "../../../../../../services";

@Component({
	selector: "ls-review",
	templateUrl: "./review.component.html",
	styleUrls: ["./review.component.scss"]
})
export class ReviewComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	companyProfileRelationships?: CompanyProfileRelationship[];
	hasEnrolledCustomers = true;
	private isAccountingConnected?: boolean;

	constructor(
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		private currencyPipe: CurrencyPipe,
		public routeStepDataService: RouteStepDataService,
		private continueApplicationService: ContinueApplicationService
	) {
		super(routeStepDataService);
	}

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
		])
			.pipe(
				filter(([cp, cprs]) => !!cp && !!cprs),
				takeUntil(this.componentTeardown$),
				map(([cp, cprs]) => {
					this.isAccountingConnected = cp?.isAccountingPlatformConnected;
					this.companyProfileRelationships = cprs?.filter(
						(cpr) => cpr.applicationStatusId === OnboardApplicationStatus.INPROGRESS
					);
					this.hasEnrolledCustomers =
						this.companyProfileRelationships?.find((cpr) => cpr.isCustomerSetupComplete) !== undefined;
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToCustomerContact(customer: CompanyProfileRelationship) {
		if (customer.isCustomerSetupComplete) {
			this.nav(3, new Map<string, string | number>([["relationshipCompanyId", customer.relationshipCompanyId!]]));
		} else {
			this.continueApplicationService.continueCustomerSetup(customer.relationshipCompanyId!, customer.currentSubStep!);
		}
	}

	addAnotherCustomer() {
		const index = this.isAccountingConnected ? 2 : 1;
		this.nav(index);
	}

	getRequestedCreditLimit(cpr: CompanyProfileRelationship): string {
		const currencyInfo = cpr.isOtherCurrency ? CurrencyCode.USD : cpr.currencyCode;
		return `${currencyInfo} ${this.currencyPipe.transform(
			cpr.requestedCreditLimit,
			currencyInfo,
			"symbol-narrow",
			"1.0-0"
		)} requested`;
	}

	continue() {
		this.nav();
	}
}
