<div class="d-flex flex-column">
  <h1 class="l ls-mb-8">Here are the customers you’ve set up so far</h1>
  <p class="l ls-mb-8">You can edit your customer info, add a new one to your Accounts Receivable Finance application, or continue to finalize the application.</p>
  <div class="ls-mb-11">
    <div class="d-flex flex-column justify-content-between ls-mb-0 ls-p-1_75 rounded-7 bg-light flex-sm-row" *ngFor="let cpr of companyProfileRelationships; let last = last" [class.ls-mb-3]="!last">
      <div class="d-flex flex-column flex-sm-row w-100 justify-content-between">
	  	<div class="d-flex flex-column justify-content-start">
			<div class="d-flex flex-row app-name">
				<p class="title l ls-mb-2 text-break">{{cpr.relationshipCompanyName}}</p>
				<span *ngIf="!cpr.isCustomerSetupComplete" class="d-inline ls-icon-badge ls-incomplete-warning-icon material-icons">warning</span>
			</div>
			<p class="m">
				<span>{{cpr?.billingAddress?.toDisplayString()}}</span>
			</p>
	  	</div>
        
		  <button *ngIf="!cpr.isCustomerSetupComplete" class="btn btn-lg btn-primary" (click)="navToCustomerContact(cpr!)">Continue</button>
		  <button *ngIf="cpr?.isCustomerSetupComplete" class="btn btn-lg btn-floating btn-primary" (click)="navToCustomerContact(cpr!)"><span class="material-icons md-18">mode_edit</span></button>
	  </div>
	</div>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-between">
    <button type="button" (click)="addAnotherCustomer()" class="btn btn-lg btn-primary ls-mb-4 ls-mb-md-0">Add another customer</button>
    <button type="button" (click)="continue()" class="btn btn-lg btn-outline-tertiary" [disabled]="!hasEnrolledCustomers">Continue application</button>
  </div>
</div>