import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map, Observable, take } from "rxjs";
import { AppActions, AppSelectors } from "../AppStateManagement";

@Injectable()
export class UserIPResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors
	) {}

	userIPAddress$ = this.store.select(this.appSelectors.selectUserIP).pipe(
		filter((ui) => !!ui),
		take(1)
	);

	resolve(): Observable<string | undefined> {
		this.initPrefetchData();
		return this.userIPAddress$;
	}

	initPrefetchData(): void {
		this.store
			.select(this.appSelectors.selectUserIP)
			.pipe(
				take(1),
				filter((userIPAddress) => !userIPAddress),
				map(() => {
					this.store.dispatch(AppActions.getUserIP());
				})
			)
			.subscribe();
	}
}
