import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject, filter, map, takeUntil } from "rxjs";
import {
	CompanyProfileActions,
	CompanyProfileSelectors,
	CompanyProfileRelationshipSelectors,
	AppActions,
	AppSelectors
} from "src/app/AppStateManagement";
import { CompanyProfile, UserInfo } from "src/app/Models";
import { EndSessionRequest } from "@azure/msal-browser";
import { MsalService } from "@azure/msal-angular";
import { ActivatedRoute } from "@angular/router";
import { MdbNotificationService } from "mdb-angular-ui-kit/notification";
import { IAlert } from "src/app/Models/Interfaces";
import { FormGroup } from "@angular/forms";
import { CompanyProfileReadonlyComponent } from "./company-profile-readonly/company-profile-readonly.component";
import { CompanyProfileComponent } from "./company-profile/company-profile.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { OnboardApplicationStatus } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit, OnDestroy, AfterViewInit {
	private _componentTeardown$: Subject<any> = new Subject<any>();
	public COMPANY_TAB_INDEX = 0;
	public USER_TAB_INDEX = 1;
	public hideSave = false;
	public companyId?: number;
	public userInfo?: UserInfo;
	public companyProfile?: CompanyProfile;
	public isReadOnly?: boolean;
	public integrationMessage?: IAlert;
	public tabNames: string[] = ["Company Profile", "Your Profile", "Integrations"];
	public _tabIndex?: number;
	public settingForm: FormGroup = new FormGroup({});

	@ViewChild("userProfile") userProfile?: UserProfileComponent;
	@ViewChild("cpForm") cpForm?: CompanyProfileComponent;
	@ViewChild("cpRoForm") cpRoForm?: CompanyProfileReadonlyComponent;

	get tabIndex(): number {
		return this._tabIndex!;
	}
	set tabIndex(index: number) {
		this._tabIndex = index;
		this.hideSave = ![this.COMPANY_TAB_INDEX, this.USER_TAB_INDEX].includes(index!);
	}
	@ViewChild("successToast", { static: true }) successToast?: TemplateRef<any>;

	constructor(
		private _authService: MsalService,
		private _store: Store,
		public actions$: Actions,
		public companyProfileSelectors: CompanyProfileSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public appSelectors: AppSelectors,
		public activatedRoute: ActivatedRoute,
		private notificationService: MdbNotificationService
	) {}

	public ngOnInit(): void {
		this.actions$
			.pipe(
				takeUntil(this._componentTeardown$),
				ofType(CompanyProfileActions.companyProfileSaveSuccessful, AppActions.userInfoSaveSuccessful),
				map(() => {
					this.notificationService.open(this.successToast!, { autohide: true, position: "top-center" });
					this.settingForm.markAsPristine();
				})
			)
			.subscribe();

		this._store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				takeUntil(this._componentTeardown$),
				filter((c) => !!c),
				map((c) => {
					this.companyId = c?.companyId;
					this.companyProfile = c;
				})
			)
			.subscribe();

		this._store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
			.pipe(
				filter((cprs) => !!cprs),
				takeUntil(this._componentTeardown$),
				map((cprs) => {
					cprs?.forEach((cpr) => {
						const status = cpr.applicationStatusId;
						this.isReadOnly = this.isReadOnly || (!!status && status !== OnboardApplicationStatus.INPROGRESS);
					});
				})
			)
			.subscribe();

		this._store
			.select(this.appSelectors.selectUserInfo)
			.pipe(
				filter((ui) => !!ui),
				takeUntil(this._componentTeardown$),
				map((ui?: UserInfo) => {
					this.userInfo = ui;
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.tabIndex = 0;
		this.activatedRoute.queryParams
			.pipe(
				takeUntil(this._componentTeardown$),
				map((params) => {
					if (params["tabIndex"]) this.tabIndex = parseInt(params["tabIndex"]);
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._componentTeardown$.next(null);
		this._componentTeardown$.complete();
	}

	public onClick() {
		const isCompanyProfileDirty = this.isReadOnly
			? this.cpRoForm?.companyProfileReadonlyForm.dirty
			: this.cpForm?.companyProfileForm.dirty;
		if (isCompanyProfileDirty) this.updateCompanyProfile();
		if (this.userProfile?.form.dirty) this.updateUserProfile();
	}

	public updateCompanyProfile(): void {
		if (this.isReadOnly) {
			this.cpRoForm?.updateCompanyProfile(this.companyProfile!);
		} else {
			this.cpForm?.updateCompanyProfile(this.companyProfile!);
		}

		this._store.dispatch(CompanyProfileActions.updateCompanyProfile({ companyProfile: this.companyProfile! }));
	}

	public updateUserProfile(): void {
		this.userProfile?.updateUserInfo(this.userInfo!);
		this._store.dispatch(AppActions.updateUserInfo({ userInfo: this.userInfo! }));
	}

	public logOut() {
		const request: EndSessionRequest = {
			account: this._authService.instance.getActiveAccount(),
			postLogoutRedirectUri: window.origin + "/logout",
			idTokenHint: this._authService.instance.getActiveAccount()?.idToken
		};
		this._authService.logout(request);
	}

	public showIntegrationMessage($event: IAlert): void {
		this.integrationMessage = $event;
	}
}
