<div class="d-flex flex-column">
  <div class="ls-mb-6">
    <ls-breadcrumbs [breadcrumbs]="breadcrumbs"></ls-breadcrumbs>
  </div>
  <div class ="ls-mb-11">
    <ng-content></ng-content>
  </div>
  <button type="button" (click)="next()" class="btn btn-icon btn-primary btn-lg" [disabled]="isNextButtonDisabled">
    {{nextButtonText}} <span class="material-icons md-18" *ngIf="displayNextButtonIcon"> arrow_forward</span>
  </button>
</div>