import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, switchMap } from "rxjs";
import { AppSelectors } from "src/app/AppStateManagement";
import { Store } from "@ngrx/store";

@Injectable()
export class UserIPInterceptor implements HttpInterceptor {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors
	) {}
	userIPAddress?: string;
	scratch: any;
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.store.select(this.appSelectors.selectUserIP).pipe(
			switchMap((ip) => {
				if (ip != null && request.url.indexOf("/api/") > 0) {
					const newRequest = request.clone({ setHeaders: { "X-Remote-IP": ip } });
					return next.handle(newRequest);
				}
				return next.handle(request);
			})
		);
	}
}
