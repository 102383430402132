import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, switchMap, withLatestFrom, filter } from "rxjs";
import { HttpErrorService } from "src/services/HTTP";
import { CompanyProfileRelationshipActions } from "./company-profile-relationship-actions";
import { CompanyProfileRelationshipService } from "src/services";
import { ChildStepType } from "../Stepper/stepper-reducer";
import {
	CompanyProfileActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors,
	StepperActions
} from "..";
import { Store } from "@ngrx/store";
import copy from "fast-copy";

@Injectable()
export class CompanyProfileRelationshipEffects {
	constructor(
		private actions$: Actions,
		private companyProfileRelationshipService: CompanyProfileRelationshipService,
		private httpErrorService: HttpErrorService,
		private companyProfileSelectors: CompanyProfileSelectors,
		private store: Store,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	getCompanyProfileRelationship$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getCompanyProfileRelationship),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getCompanyProfileRelationship(act.companyProfileId, act.relationshipCompanyId)
					.pipe(
						switchMap((cpr) => [
							CompanyProfileRelationshipActions.setCompanyProfileRelationship({ companyProfileRelationship: cpr })
						])
					)
			)
		)
	);

	initChildStepper$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.setCompanyProfileRelationship),
			map(() =>
				StepperActions.initializeChildSteps({
					childStepType: ChildStepType.CUSTOMER_SETUP
				})
			)
		)
	);

	createCompanyProfileRelationship$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.createCompanyProfileRelationship),
			mergeMap((act) =>
				this.companyProfileRelationshipService.createCompanyProfileRelationship(act.companyProfileRelationship).pipe(
					switchMap((cpr) => [
						CompanyProfileRelationshipActions.setCompanyProfileRelationship({ companyProfileRelationship: cpr }),
						CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful(),
						CompanyProfileActions.setIsCustomersInProgress()
					]),
					catchError((err) => {
						console.error(err);
						return this.httpErrorService
							.parseError(err)
							.pipe(
								map((resp) =>
									CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful({ errors: resp })
								)
							);
					})
				)
			)
		)
	);

	updateCompanyProfileRelationship$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.updateCompanyProfileRelationship),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.updateCompanyProfileRelationship(act.companyProfileRelationship, act.isEnrolledCodatCustomer)
					.pipe(
						withLatestFrom(
							this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
						),
						switchMap(([cpr, cprs]) => {
							const actions = [
								CompanyProfileRelationshipActions.setCompanyProfileRelationship({ companyProfileRelationship: cpr }),
								CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful(),
								CompanyProfileActions.setIsCustomersInProgress()
							];
							if (cprs) {
								const index = cprs!.findIndex((c) => c.relationshipCompanyId === cpr.relationshipCompanyId);
								if (index > -1) {
									cprs![index] = cpr;
									actions.push(
										CompanyProfileRelationshipActions.setCompanyProfileRelationships({
											companyProfileRelationships: cprs!
										})
									);
								}
							}

							return actions;
						}),
						catchError((err) => {
							console.error(err);
							return this.httpErrorService
								.parseError(err)
								.pipe(
									map((resp) =>
										CompanyProfileRelationshipActions.companyProfileRelationshipSaveUnsuccessful({ errors: resp })
									)
								);
						})
					)
			)
		)
	);

	getCompanyProfileRelationships$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getCompanyProfileRelationships),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getCompanyProfileRelationships(act.companyProfileId, act.product)
					.pipe(
						map((cprs) =>
							CompanyProfileRelationshipActions.setCompanyProfileRelationships({ companyProfileRelationships: cprs })
						)
					)
			)
		)
	);

	getUnenrolledCompanyProfileRelationships$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getUnenrolledCompanyProfileRelationships),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getCompanyProfileRelationships(act.companyProfileId, act.product, true)
					.pipe(
						map((cprs) =>
							CompanyProfileRelationshipActions.setUnenrolledCompanyProfileRelationships({
								companyProfileRelationships: cprs
							})
						)
					)
			)
		)
	);

	getApplications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.getApplications),
			mergeMap((act) =>
				this.companyProfileRelationshipService
					.getApplications(act.companyProfileId, act.pr)
					.pipe(map((apps) => CompanyProfileRelationshipActions.setApplications({ page: apps })))
			)
		)
	);

	updateCustomerProgress = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.updateCustomerProgress),
			mergeMap((act) =>
				this.companyProfileRelationshipService.updateCustomerProgress(act.companyProfileRelationship).pipe(
					switchMap(() => [
						CompanyProfileRelationshipActions.setCompanyProfileRelationship({
							companyProfileRelationship: act.companyProfileRelationship
						})
					])
				)
			)
		)
	);

	submitApplications = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.submitApplications),
			mergeMap((app) =>
				this.companyProfileRelationshipService
					.submitApplications(app.companyProfileId)
					.pipe(map(() => CompanyProfileActions.getCompanyProfile({ companyId: app.companyProfileId })))
			)
		)
	);

	submitApplication = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.submitApplication),
			mergeMap((app) =>
				this.companyProfileRelationshipService
					.submitApplication(app.companyProfileRelationship)
					.pipe(
						map(() =>
							CompanyProfileActions.getCompanyProfile({ companyId: app.companyProfileRelationship.companyProfileId! })
						)
					)
			)
		)
	);

	updateCompanyProfileOnCompanyProfileRelationshipSaveSuccessful = createEffect(() =>
		this.actions$.pipe(
			ofType(CompanyProfileRelationshipActions.companyProfileRelationshipSaveSuccessful),
			withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
			filter(([act, cp]) => !!cp && (cp!.hasArfAppWithNoCustomers ?? false)),
			map(([act, cp]) => {
				const toUpdate = copy(cp);
				toUpdate!.hasArfAppWithNoCustomers = false;
				return CompanyProfileActions.setCompanyProfile({ companyProfile: toUpdate! });
			})
		)
	);
}
