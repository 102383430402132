import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { map, Subject, takeUntil } from "rxjs";
import { IColumnHeader } from "../../../../Models/Interfaces/IColumnHeader";
import { Case } from "../../../../Models";
import { FormGroup } from "@angular/forms";
import { CaseStatus } from "../../../../Models/Enums";
import { SalesforceSupportSelectors } from "../../../../AppStateManagement";
import { Store } from "@ngrx/store";

@Component({
	selector: "ls-support",
	templateUrl: "./support.component.html",
	styleUrls: ["./support.component.scss"]
})
export class SupportComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	public columns: IColumnHeader[] = Case.ColumnNames;
	public cases?: Case[];
	public form: FormGroup = new FormGroup({});
	public get CaseStatus(): typeof CaseStatus {
		return CaseStatus;
	}
	constructor(
		public store: Store,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private salesforceSupportSelectors: SalesforceSupportSelectors
	) {}

	ngOnInit(): void {
		this.store
			.select(this.salesforceSupportSelectors.selectCases)
			.pipe(
				takeUntil(this.componentTeardown$),
				map((cases) => {
					this.cases = cases;
				})
			)
			.subscribe();
	}

	onClick(sfCase: Case): void {
		this.router.navigate([`../support/${sfCase.id}`], { relativeTo: this.activatedRoute });
	}

	onHover($event: any) {
		$event.currentTarget.children[0].classList.add("hovering-cell-fade");
		$event.currentTarget.children[0].classList.remove("cell-fade");
	}

	onMouseOut($event: any) {
		$event.currentTarget.children[0].classList.add("cell-fade");
		$event.currentTarget.children[0].classList.remove("hovering-cell-fade");
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}
}
