import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor() {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!request.url.includes("b2clogin")) {
			let appendApiRoute = false;
			let url = environment.limestoneHost;
			if (request.url.split("/").length > 0) {
				if (request.url.split("/")[1] === "api") {
					appendApiRoute = true;
					url = `${url}${request.url}`;
				}
			}
			request = request.clone({
				url: appendApiRoute ? url : request.url
			});
		}
		return next.handle(request);
	}
}
