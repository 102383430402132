<div class="d-flex flex-column">
    <ls-upload [files]="files" [nextButtonText]="buttonText('next')" [nextButtonDisabled]="isNextButtonDisabled()"
               [allowedFileExtensions]="displayableFileExtensions" [maxFileCount]="maxFileCount" [maxFileSize]="maxFileSize"
               [disabled]="disabled" [submitted]="submitted" (nextClicked)="continue()" (sortChanged)="handleSortChange($event)"
               (openFileClicked)="openFile($event)" (deleteFileClicked)="deleteDocument($event)"
               (uploadedFiles)="uploadFiles($event)" (fileUploadError)="handleError($event)">
        <ls-text header extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'hl1')"></ls-text>
        <div description class="d-flex flex-column ls-mb-8">
            <ls-text [displayConfig]="config(HEADER,'p1')">
                <ls-info-dialog-icon [header]="config(HEADER,'tt-hl1')?.text" [content]="config(HEADER,'tt-p1')?.text">
                    <ls-tooltip [position]="'right'">
                        <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
                        <div content>
                            <ls-text extraClasses="text-wrap" [displayConfig]="config(HEADER,'legacy-tt-p1')"></ls-text>
                            <ls-text extraClasses="text-wrap ls-mb-0" [displayConfig]="config(HEADER,'legacy-tt-p2')"></ls-text>
                        </div>
                    </ls-tooltip>
                </ls-info-dialog-icon>
            </ls-text>

        </div>
        <div *ngIf="display(TAX_ID)" input>
            <form [formGroup]="taxIdForm" (keydown)="onKeyboardEvent($event)" (paste)="onClipboardEvent($event)">
                <mdb-form-control class="tax-id ls-mt-8">
                    <input [formControlName]="TAX_ID" mdbInput mdbValidate type="text" id="taxId"
                           class="form-control form-control-lg" />
                    <label mdbLabel class="form-label" for="taxId">
                        <ls-text [displayConfig]="config(TAX_ID,'q1')"></ls-text>
                    </label>
                    <mdb-error class="ls-mt-4" *ngIf="controlHasError(TAX_ID)">
                        {{getControlError(TAX_ID)}}
                    </mdb-error>
                </mdb-form-control>
            </form>
            <div *ngIf="errors && errors.length > 0" class="d-flex flex-column ls-mt-4" errors>
                <div *ngFor="let error of errors">
                    <ls-alert alertType="danger" [small]="true">
                        <p [innerHTML]="error" class="s ls-mb-0"></p>
                    </ls-alert>
                </div>
            </div>
        </div>

    </ls-upload>
</div>