import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";

@Component({
	selector: "ls-application-contact-info",
	templateUrl: "./application-contact-info.component.html",
	styleUrls: ["./application-contact-info.component.scss"]
})
export class ApplicationContactInfoComponent implements OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
			this.setFormGroup();
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}
	@Input() readOnly = true;
	public customerContactForm: FormGroup = new FormGroup({});
	public static FORM_CONTROL_NAME = "contactInfo";
	public LEGAL_NAME = "legalName";
	public BILLING = "billing";

	constructor(private fgd: FormGroupDirective) {
		this.customerContactForm = new FormGroup({
			legalName: new FormControl(this.companyProfileRelationship?.relationshipCompanyName, Validators.required),
			billing: new FormControl(this.companyProfileRelationship?.billingAddress, Validators.required)
		});
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	setFormGroup() {
		const address = this.companyProfileRelationship?.billingAddress ?? null;
		this.customerContactForm.patchValue(
			{
				legalName: this.companyProfileRelationship?.relationshipCompanyName,
				billing: address
			},
			{ onlySelf: true, emitEvent: false }
		);

		if (this.fgd.form.get(ApplicationContactInfoComponent.FORM_CONTROL_NAME) === null) {
			this.fgd.form?.addControl(ApplicationContactInfoComponent.FORM_CONTROL_NAME, this.customerContactForm, {
				emitEvent: false
			});
		}

		this.customerContactForm.markAsPristine();
	}
}
