import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from "@angular/core";

@Component({
	selector: "ls-tabs",
	templateUrl: "./tabs.component.html",
	styleUrls: ["./tabs.component.scss"]
})
export class TabsComponent {
	@ViewChild("responsiveTabContainer") private _container?: ElementRef;
	@ViewChild("tabContainer") private _componentContainer?: ElementRef;
	@ViewChild("dropdown") private _dropdown?: ElementRef;
	@Input() public tabNames: string[] = [];
	@Input() public tabIndex!: number;
	@Output() public tabIndexChange: EventEmitter<number> = new EventEmitter<number>();

	public isDropdownOpen = false;

	constructor(private _renderer: Renderer2) {}

	@HostListener("document:mousedown", ["$event"])
	public collapseDropdown(event: Event): void {
		if (
			this._componentContainer?.nativeElement !== event.target &&
			!this._container?.nativeElement.contains(event.target)
		) {
			this.isDropdownOpen = false;
		}
	}
	public toggleDropdownMenu(): void {
		this.isDropdownOpen = !this.isDropdownOpen;
		this._calcDropdownPosition();
	}

	public tabChanged(index: number): void {
		this.tabIndexChange.emit(index);
	}

	public responsiveTabChanged($event: MouseEvent, index: number): void {
		$event.stopPropagation();
		this.tabChanged(index);
		this.toggleDropdownMenu();
	}

	private _calcDropdownPosition(): void {
		const borderWidth = parseInt(getComputedStyle(this._container?.nativeElement).borderBottomWidth.replace("px", ""));
		const top = this._container?.nativeElement.clientHeight + borderWidth + "px";
		this._renderer.setStyle(this._dropdown?.nativeElement, "top", top);
	}
}
