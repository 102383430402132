import { PlatformType } from "./Enums";

export class PlatformCategory {
	public static EMAIL = "EML";
	public static NOT_APPLICABLE = "NA";
	public static PLATFORM = "PLATFORM";
	public static OTHER = "OTR";
	public static IDK = "IDK";
	public static ELECTRIC_PAYMENT_DATA = "EPD";
	public static NO_REMITTANCE_DATA = "NRD";
	public static POSTAL_MAIL = "PML";

	public id: string;
	public label: string;
	public usedBy: number[];

	constructor(id: string, label: string, usedBy: number[]) {
		this.id = id;
		this.label = label;
		this.usedBy = usedBy;
	}

	public static OPTIONS: PlatformCategory[] = [
		{
			id: this.EMAIL,
			label: "Email",
			usedBy: [PlatformType.INSTRUMENT_PLATFORM, PlatformType.APPROVALS_PLATFORM, PlatformType.REMITTANCE_PLATFORM]
		},
		{
			id: this.PLATFORM,
			label: "Platform",
			usedBy: [PlatformType.INSTRUMENT_PLATFORM, PlatformType.APPROVALS_PLATFORM, PlatformType.REMITTANCE_PLATFORM]
		},
		{
			id: this.ELECTRIC_PAYMENT_DATA,
			label: "With electronic payment data",
			usedBy: [PlatformType.REMITTANCE_PLATFORM]
		},
		{
			id: this.POSTAL_MAIL,
			label: "Postal mail",
			usedBy: [PlatformType.REMITTANCE_PLATFORM]
		},
		{
			id: this.OTHER,
			label: "Other",
			usedBy: [PlatformType.INSTRUMENT_PLATFORM, PlatformType.APPROVALS_PLATFORM, PlatformType.REMITTANCE_PLATFORM]
		},
		{
			id: this.NOT_APPLICABLE,
			label: "Not applicable",
			usedBy: [PlatformType.INSTRUMENT_PLATFORM, PlatformType.APPROVALS_PLATFORM, PlatformType.REMITTANCE_PLATFORM]
		},
		{
			id: this.IDK,
			label: "I don't know",
			usedBy: [PlatformType.APPROVALS_PLATFORM]
		},
		{
			id: this.NO_REMITTANCE_DATA,
			label: "I don't receive or view remittance data",
			usedBy: [PlatformType.REMITTANCE_PLATFORM]
		}
	];
}
