import { Action, createReducer } from "@ngrx/store";

export interface AccountCreationState {}

export const initialState: AccountCreationState = {};

abstract class AccountCreationReducerHandler {
	static appReducer = createReducer(initialState);
}

export abstract class AccountCreationReducer {
	static reducer = (state: AccountCreationState | undefined, action: Action) => {
		return AccountCreationReducerHandler.appReducer(state, action);
	};
}
