import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { RouteStepDataBaseComponent } from "../../../../Core/route-step-data-base.component";
import { RouteStepDataService } from "../../Services";

@Component({
	selector: "ls-successful-application",
	templateUrl: "./successful-application.component.html",
	styleUrls: ["./successful-application.component.scss"]
})
export class SuccessfulApplicationComponent extends RouteStepDataBaseComponent {
	constructor(
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	onClick() {
		this.nav();
	}
}
