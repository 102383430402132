<div id="pricing-guide-container" class="d-flex flex-column w-100 ls-pt-5 ls-pr-8 ls-pb-5">
    <label class="title m ls-mb-3" id="pricing-guide-title">Pricing indicator</label>
	<div id="pricing-guide-track" class="d-flex w-100 ls-mb-3">
    	<div id="pricing-guide-fill" class="w-100" [style.transform]="getPercent()"></div>
	</div>
    <div id="pricing-guide-indicator-row" class="d-flex flex-row justify-content-between">
      <label class="l">Low</label>
      <label class="l">High</label>
    </div>
</div>
