import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CodatActions, CodatSelectors, CompanyProfileSelectors } from "../../../../../../AppStateManagement";
import { ConnectFinancialsResultService } from "src/services/connect-financials-result.service";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { BehaviorSubject, combineLatest, filter, map, Subject, take, takeUntil, timer } from "rxjs";
import { CompanyProfile, DisplayConfig, Layout } from "src/app/Models";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../../Services";
import { Flow, Institution, Product, TextStyle } from "src/app/Models/Enums";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { LsValidators } from "@limestone/ls-shared-modules";
import { DisplayedBankAccount } from "src/app/Models/DisplayedBankAccount";
import { defaultAllowedKey } from "src/Utils";

@Component({
	selector: "ls-bank-account-result",
	templateUrl: "./bank-account-result.component.html",
	styleUrls: ["./bank-account-result.component.scss"],
	providers: [ConnectFinancialsResultService]
})
export class BankAccountResultComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	public componentTeardown$ = new Subject();
	public success$: BehaviorSubject<boolean> = this._connectFinancialsResultService.success$;
	public companyProfile?: CompanyProfile;
	public path = "";
	public scfFlow?: boolean;
	public bankAccounts?: DisplayedBankAccount[] = [];
	public selectedBankAccounts?: string[] = [];
	public form: FormGroup = new FormGroup({});
	public ACCOUNT_NUMBER = "accountNumber";
	public isSelectedChaseAccount?: boolean;
	private validator: ValidatorFn;
	private handledCodatConnectionResponse = false;
	public allowedKeys: string[] = ["Backspace", "Delete", "Control", "ArrowRight", "ArrowLeft", "v"];
	public readonly FAILED_CONNECTION = "failed-connection";
	public readonly SCF_CONTENT = "scf-content";
	errorMessage = "Last 4 digits entered must match the last 4 digits of selected account number";
	currentError = "";
	showSpinner = true;
	spinnerStarted = false;

	constructor(
		public router: Router,
		public store: Store,
		public companyProfileSelectors: CompanyProfileSelectors,
		private _connectFinancialsResultService: ConnectFinancialsResultService,
		public routeStepDataService: RouteStepDataService,
		public codatSelectors: CodatSelectors
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);

		this.path = router.url;
		this.validator =
			Validators.compose([Validators.required, Validators.maxLength(17), Validators.minLength(5)]) ||
			Validators.nullValidator;
	}

	public ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.codatSelectors.selectBankAccounts)
		])
			.pipe(
				filter(([cp, ocp]) => !!cp && !!ocp),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp, ba]) => {
					this.companyProfile = cp;
					this.scfFlow = ocp?.onboardingConfiguration?.id === Flow.SCF_V1;
					if (!this.handledCodatConnectionResponse) {
						this._connectFinancialsResultService.init(CodatConnectionType.BANKING);
						this.handledCodatConnectionResponse = true;
					}
					this.path = `onboarding/${ocp!.onboardingConfigurationId}/connect-financials/banking-success`;
					if (ba && ba.length > 0) {
						this.bankAccounts = ba;
						this.showSpinner = false;
					} else if (this.scfFlow) {
						this.startSpinner(ocp!.product);
					}

					this.selectedBankAccounts = [];
					if (cp?.bankAccount?.codatBankAccountId && ba?.find((a) => a.id === cp?.bankAccount?.codatBankAccountId)) {
						this.selectedBankAccounts?.push(cp.bankAccount.codatBankAccountId);
					}
					this.form.addControl(this.ACCOUNT_NUMBER, new FormControl("", [this.validator]));
				})
			)
			.subscribe();

		this.form
			.get(this.ACCOUNT_NUMBER)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v) => this.setErrorBasedOnAccountNumberLength(v))
			)
			.subscribe();
	}
	startSpinner(product: Product) {
		const params = this.router.routerState.snapshot.root.queryParams;
		const statusCode = parseInt(params["statuscode"]);
		if (statusCode !== 200) return;
		if (this.spinnerStarted) return;
		this.spinnerStarted = true;
		this.store.dispatch(
			CodatActions.getBankAccounts({
				companyId: this.companyProfile!.companyId!,
				product: product
			})
		);
		const source = timer(30000);
		source
			.pipe(
				take(1),
				map(() => {
					this.showSpinner = false;
				})
			)
			.subscribe();
	}
	setErrorBasedOnAccountNumberLength(value: any): void {
		if (value?.length >= 5) this.currentError = this.errorMessage;
		else this.currentError = "";
	}

	public ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public isDisabled() {
		if (this.scfFlow && this.form.invalid) {
			return true;
		}
		return this.scfFlow && this.selectedBankAccounts?.length === 0;
	}

	public isSelected(id?: string) {
		if (id) return this.selectedBankAccounts?.includes(id);
		return false;
	}

	public connectBank() {
		if (this.scfFlow && this.selectedBankAccounts?.length === 1) {
			this.store.dispatch(
				CodatActions.saveBankAccount({
					companyId: this.companyProfile!.companyId!,
					codatConnection: { id: this.selectedBankAccounts![0] }
				})
			);
			this.continue();
		} else {
			this.store.dispatch(
				CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path })
			);
		}
	}

	public selectBankAccount(codatConnectionId: string) {
		this.selectedBankAccounts = [];
		this.selectedBankAccounts?.push(codatConnectionId);
		if (this.selectedBankAccounts?.length > 0) {
			const selectedBankAccount = this.bankAccounts?.find((ba) => ba.id?.toString() === this.selectedBankAccounts![0]);
			if (selectedBankAccount?.institution === Institution.Chase) {
				this.isSelectedChaseAccount = true;
				const lastFourDigits = selectedBankAccount?.accountNumber?.slice(-4);

				this.validator =
					Validators.compose([
						Validators.required,
						Validators.minLength(5),
						Validators.maxLength(17),
						Validators.pattern(`.*${lastFourDigits}$`)
					]) || Validators.nullValidator;
				this.form.controls[this.ACCOUNT_NUMBER].setValidators([this.validator]);
				this.form.controls[this.ACCOUNT_NUMBER].enable();
			} else {
				this.isSelectedChaseAccount = false;
				this.form.controls[this.ACCOUNT_NUMBER].disable();
			}
		}
	}

	controlHasError(controlName: string): boolean {
		if (this.form.get(controlName)) {
			return (
				this.form.get(controlName)!.invalid &&
				(this.form.get(controlName)!.dirty || this.form.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(): string {
		return this.currentError;
	}

	public onKeyboardEvent(event: KeyboardEvent): void {
		if (this.validateInput(event)) {
			if (!defaultAllowedKey(event.key)) {
				event.preventDefault();
			}
		}
	}

	public onClipboardEvent(event: ClipboardEvent): void {
		if (this.validateInput(event)) {
			event.preventDefault();
		}
	}

	private validateInput(event: KeyboardEvent | ClipboardEvent) {
		return (
			!LsValidators.allowedInput(event, /(\d)+/g, this.allowedKeys) ||
			(this.form.get(this.ACCOUNT_NUMBER)?.value !== null && this.form.get(this.ACCOUNT_NUMBER)?.value?.length >= 17)
		);
	}

	public continue() {
		const success = this.success$.value;
		this.nav(success ? 0 : 1);
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Congrats, you’re all set!", TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"t1",
						new DisplayConfig("You’ve successfully connected your business bank account.", TextStyle.TITLE_MEDIUM)
					],
					[
						"p1",
						new DisplayConfig(
							"Now you can continue your application or connect another bank account.",
							TextStyle.BODY_LARGE
						)
					],
					["tt-l1", new DisplayConfig("You can connect multiple bank accounts", TextStyle.LABEL_LARGE_BOLD)],
					[
						"tt-p1",
						new DisplayConfig(
							"For example, some customers may deposit directly into one account, but\n" +
								"others send you a check that you deposit into a different bank. In those situations, we’d like to see those\n" +
								"transactions to confirm your customers have paid you in the past.",
							TextStyle.BODY_MEDIUM
						)
					]
				])
			)
		],
		[
			this.SCF_CONTENT,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"Please select which account you would like to receive payments to:",
							TextStyle.BODY_LARGE
						)
					],
					["p2", new DisplayConfig("Loading your bank account information", TextStyle.BODY_MEDIUM_SEMI_BOLD)],
					[
						"p3",
						new DisplayConfig(
							"There was an error fetching your bank account information, please try again later.",
							TextStyle.BODY_MEDIUM_SEMI_BOLD
						)
					],
					["l1", new DisplayConfig("Confirm Bank Account Number", TextStyle.LABEL_LARGE)]
				])
			)
		],
		[
			this.FAILED_CONNECTION,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"hl1",
						new DisplayConfig("Oh, no... Looks like you didn’t connect your bank account", TextStyle.HEADLINE_LARGE)
					],
					["p1", new DisplayConfig("Let’s try that again...", TextStyle.BODY_LARGE)]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(
				new Map<string, DisplayConfig>([
					["b1", new DisplayConfig("Connect another bank account", TextStyle.BUTTON_LARGE)],
					["b2", new DisplayConfig("Continue application", TextStyle.BUTTON_LARGE)],
					["b3", new DisplayConfig("Try again", TextStyle.BUTTON_LARGE)],
					["b4", new DisplayConfig("I'll connect my bank account later", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
