import { Injectable } from "@angular/core";
import { HttpResponse } from "@angular/common/http";

@Injectable()
export class ExportService {
	/**
	 * Downloads file to user's machine.
	 *
	 * @param response
	 */
	downloadFile(response: HttpResponse<Blob>): void {
		let fileName = "undetermined-filename";
		const disposition = response.headers.get("Content-Disposition");
		fileName = disposition ? disposition.split("filename=")[1].split(";")[0] : fileName;
		fileName = fileName.replace(/"/g, ""); // remove double-quotes
		const a = document.createElement("a");
		a.download = fileName;
		a.href = URL.createObjectURL(response.body!);
		a.click();
	}
}
