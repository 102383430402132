import { Action, createReducer, on } from "@ngrx/store";
import { QuestionSet } from "../../Models";
import { ScoringActions } from "./scoring-actions";

export interface ScoringState {
	pricingGauge?: Map<string, QuestionSet>;
}

export const initialState: ScoringState = {};

abstract class ScoringReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(ScoringActions.setScoring, (state: ScoringState, scoring) => ({
			...state,
			pricingGauge: scoring.pricingGauge
		}))
	);
}

export abstract class ScoringReducer {
	static reducer = (state: ScoringState | undefined, action: Action) => {
		return ScoringReducerHandler.appReducer(state, action);
	};
}
