import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import copy from "fast-copy";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup } from "@limestone/ls-shared-modules";
import { PlatformType, TextStyle } from "src/app/Models/Enums";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-remittance-platform",
	templateUrl: "./remittance-platform.component.html",
	styleUrls: ["./remittance-platform.component.scss"]
})
export class RemittancePlatformComponent extends CustomerRelationshipBaseComponent {
	public companyProfileRelationship?: CompanyProfileRelationship;
	public platforms?: GenericLookup<string>[];
	public type = PlatformType.REMITTANCE_PLATFORM;
	selectedPlatform?: GenericLookup<string>;
	isValid = false;
	valueWasChanged = false;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					if (cpr?.remittancePlatform?.id) this.isValid = true;
				})
			)
			.subscribe();
	}

	handleFormValueChange(formValue: GenericLookup<string>) {
		this.selectedPlatform = formValue;
		this.valueWasChanged = true;
	}

	handleFormStatusChange(status: boolean) {
		this.isValid = status;
	}

	continue() {
		if (this.valueWasChanged) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.remittancePlatform = this.selectedPlatform;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					["hm1", new DisplayConfig("How do you receive or view remittance data?", TextStyle.HEADLINE_MEDIUM)],
					[
						"p2",
						new DisplayConfig(
							"What we’re asking for: once the payment has been made, there are remittance files that allow us to apply the cash accordingly and where they’re getting that from",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		]
	]);
}
