import { Component, Input } from "@angular/core";
import { Breadcrumb } from "../../Models/Interfaces/Breadcrumb";

@Component({
	selector: "ls-breadcrumbs",
	templateUrl: "./breadcrumbs.component.html",
	styleUrls: ["./breadcrumbs.component.scss"]
})
export class BreadcrumbsComponent {
	@Input() breadcrumbs?: Array<Breadcrumb> = [];
}
