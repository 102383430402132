import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "../../../../services/HTTP";
import { IValidation } from "../../../Models/Interfaces";

@Injectable()
export class ValidationService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/validation";
	}

	validate(object: IValidation): Observable<any> {
		return this.http.post<any>(`${this.path}/${object.type.toLowerCase()}`, object);
	}
}
