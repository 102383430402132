<ng-container *ngIf="(success$ | async); then connectionSuccess; else connectionFailed"></ng-container>
<ng-template #connectionSuccess>
  <h1 class="l ls-mb-4 ls-mb-xxl-8">{{data.header}}</h1>
  <p class="title m ls-mb-4 ls-mb-xxl-2">You’ve successfully connected your business bank account.</p>
  <p *ngIf="!scfFlow" class="l ls-mb-6 ls-mb-xxl-11">Now you can continue your application or connect another bank <span
      class="text-nowrap">account.
      <ls-tooltip [position]="'right'">
        <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
        <div content>
          <label class="l-bold text-wrap">You can connect multiple bank accounts</label>
          <p class="m ls-mb-0 ls-mt-2 text-wrap">For example, some customers may deposit directly into one account, but
            others send you a check that you deposit into a different bank. In those situations, we’d like to see those
            transactions to confirm your customers have paid you in the past.</p>
        </div>
      </ls-tooltip>
    </span>
  <div *ngIf="scfFlow" class="ls-mb-6 ls-mb-xxl-11">
    <p class="l ls-mb-6 ls-mb-xxl-11">{{data.paragraph}}</p>
    <div class="grid">
      <div *ngFor="let ba of bankAccounts" class="g-col-xl-4 g-col-lg-6 g-col-12">
        <button type="button" class="w-100 btn" (click)="selectBankAccount(ba.id!)"
          [ngClass]="isSelected(ba.id!) ? 'selected' : 'btn-outline-tertiary'">
          <div class="d-flex flex-row align-items-center justify-content-left">
            <span class="material-icons md-18 ls-icon-primary-no-background">account_balance</span>
            <span class="label l-bold ls-ml-2 justify-content-left">{{ba.name}} - {{ba.accountNumber}}</span>
          </div>
        </button>
      </div>
    </div>
    <ng-container>
      <form class="w-50 ls-mt-6" [formGroup]="form" [hidden]="!isSelectedChaseAccount">
        <mdb-form-control>
          <input mdbInput [formControlName]="ACCOUNT_NUMBER" mdbValidate type="text" id="accountNumber"
            class="form-control form-control-lg" (keydown)="onKeyboardEvent($event)"
            (paste)="onClipboardEvent($event)" />
          <label mdbLabel class="form-label" for="accountNumber">Confirm Bank Account Number</label>
          <mdb-error *ngIf="controlHasError(ACCOUNT_NUMBER)">{{getControlError()}}</mdb-error>
        </mdb-form-control>
      </form>
    </ng-container>
  </div>
  <div class="d-flex flex-md-row flex-column justify-content-between">
    <button type="button" (click)="connectBank()" [disabled]="isDisabled()"
      class="btn btn-primary btn-lg ls-mb-4 ls-mb-md-0">{{data.firstButtonText}}</button>
    <button *ngIf="!scfFlow" type="button" (click)="continue()" class="btn btn-outline-tertiary btn-lg">Continue
      application</button>
  </div>
</ng-template>
<ng-template #connectionFailed>
  <h1 class="l ls-mb-4 ls-mb-xxl-8">{{data.failedText}}</h1>
  <p class="l ls-mb-6 ls-mb-xxl-11">Let’s try that again...</p>
  <div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="connectBank()" class="btn btn-primary btn-lg ls-mb-2 ls-mb-sm-0">Try again</button>
    <button type="button" (click)="continue()" class="btn btn-primary-borderless btn-lg ls-p-0">I'll connect my bank
      later</button>
  </div>
</ng-template>