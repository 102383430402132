import { Component, Input } from "@angular/core";
import { Guid } from "../../Utils";

@Component({
	selector: "ls-tooltip",
	templateUrl: "./limestone-element-tooltip.component.html",
	styleUrls: ["./limestone-element-tooltip.component.scss"]
})
export class LimestoneElementTooltipComponent {
	id: string;
	@Input() small = false;
	@Input() position: "left" | "top" | "bottom" | "right" = "top";

	constructor(private guid: Guid) {
		this.id = this.guid.New(false);
	}
}
