import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { QuestionSet } from "../app/Models";
import { HttpService } from "./HTTP";
import copy from "fast-copy";
import { ModelService } from "./Scoring/model-service";

@Injectable()
export class ScoringService extends HttpService {
	constructor(private http: HttpClient) {
		super(http);
		this.path = "/api/scoring";
	}

	getScoring(): Observable<Map<string, QuestionSet>> {
		const url = `${this.path}`;
		return this.http
			.get<Map<string, QuestionSet>>(url)
			.pipe(map((resp: Map<string, QuestionSet>) => copy(ModelService.unflattenToMap<QuestionSet>(resp))));
	}
}
