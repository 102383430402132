<ls-questionnaire-toggle #questionnaireForm
        [toggleLabel]="toggleLabel"
        [(enabled)] = isFinanceExpedited
        [toggledState] = "toggledState"
        header="Would you require expedited financing?"
        [breadcrumbs]="breadcrumbs"
        (buttonClicked)="continue()">
  <div>
    <p class="l ls-mb-2">Before funding begins, <a class="ls-hyperlink" href="https://raistone.com/help/expedited-financing/" target="_blank">we confirm your bank account via a passthrough payment.</a></p> 
    <p class="l ls-mb-2">If you want to begin funding prior to confirming your bank account, we can do so at a higher rate. 
      Usually, passthrough payments add a few business days to the funding process.</p>
    <p class="l ls-mb-0">Not waiting for a passthrough payment may result in higher pricing.</p>
  </div>
</ls-questionnaire-toggle>  