<div class="d-flex flex-column">
  <form class="d-flex flex-column ls-mb-11" [formGroup]="form">
    <h1 class="no-text-transform ls-mb-6 l">Let’s get the rest of your company info</h1>
    <div class="d-flex flex-column">
      <p class="no-text-transform title m ls-mb-3">What’s your company’s state or province of formation?</p>
      <mdb-form-control class="half-max-width">
        <mdb-select [filter]="true" [formControlName]="STATE_OF_FORMATION" [id]="STATE_OF_FORMATION" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
          <mdb-option *ngFor="let state of stateProvinces" [value]="state.code">{{ state.name }}</mdb-option>
        </mdb-select>
        <label mdbLabel class="form-label" [for]="STATE_OF_FORMATION">State/Province</label>
      </mdb-form-control>
    </div>
  </form>
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="form.invalid"  #lsNextBtn>
    Next <span class="material-icons md-18"> arrow_forward</span>
  </button>
</div>