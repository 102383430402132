import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { CompanyProfileState } from "./company-profile-reducer";
import copy from "fast-copy";
import { AddressType, CountryCode } from "@limestone/ls-shared-modules";

@Injectable()
export class CompanyProfileSelectors {
	appState = (state: any) => state.companyProfile;

	selectCompanyProfile = createSelector(this.appState, (state: CompanyProfileState) => copy(state.companyProfile));
	selectOnboardCompanyProgress = createSelector(this.appState, (state: CompanyProfileState) =>
		copy(state.onboardCompanyProgress)
	);
	IsUSBased = createSelector(this.appState, (state: CompanyProfileState) =>
		state.companyProfile?.addresses?.some(
			(companyAddress) =>
				companyAddress.addressType?.id === AddressType.PRIMARY &&
				companyAddress.address?.country?.code === CountryCode.US
		)
	);
}
