import { IValidation } from "./Interfaces/IValidation";

export class PhoneValidation implements IValidation {
	type = "Phone";
	phoneCode?: string;
	countryCode?: string;
	number?: string;
	extension?: string;

	constructor(phoneCode?: string, number?: string, extension?: string, countryCode?: string) {
		this.countryCode = countryCode;
		this.number = number;
		this.extension = extension;
		this.phoneCode = phoneCode;
	}
}
