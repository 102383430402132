import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { AccountCreationActions } from "./account-creation-actions";
import { catchError, mergeMap, tap } from "rxjs";
import { AccountCreationService } from "../../Services";
import { switchMap } from "rxjs/operators";
import { LsHttpErrorResponse } from "../../../../Models";
import { HttpErrorResponse } from "@angular/common/http";
import { LocalStorageService } from "../../../../../Utils";
import { CompanyProfileActions } from "../../../../AppStateManagement";
import { ValidationActions } from "src/app/Modules/COT-Module/OnboardingStateManagement/Validation/validation-actions";
import { CreateAccountInfoComponent } from "../../Components";

@Injectable()
export class AccountCreationEffects {
	constructor(
		private actions$: Actions,
		private accountCreationService: AccountCreationService,
		private localStorageService: LocalStorageService
	) {}

	createAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountCreationActions.createAccount),
			mergeMap((act) =>
				this.accountCreationService.createAccount(act.account).pipe(
					tap((companyProfile) => {
						this.localStorageService.setCompanyId(companyProfile.companyId!);
						this.localStorageService.setEmailAddress(act.account.email!);
						this.localStorageService.setB2CSignupState();
					}),
					switchMap((companyProfile) => {
						return [CompanyProfileActions.setCompanyProfile({ companyProfile: companyProfile })];
					}),
					catchError((error: HttpErrorResponse) => {
						console.error(error);
						const errResp = new LsHttpErrorResponse(
							error.error.status,
							error.error.title,
							error.error.title,
							error.error.type,
							error.error.errors
						);
						return [
							ValidationActions.validationFailure({
								id: CreateAccountInfoComponent.id,
								validationType: act.type,
								result: errResp
							})
						];
					})
				)
			)
		)
	);
}
