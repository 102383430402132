import { Flow } from "./Enums";

export class AccountSubmissionForm {
	legalBusinessName?: string;
	annualRevenueRangeId?: string;
	firstName?: string;
	lastName?: string;
	title?: string;
	phoneCode?: string;
	countryCode?: string;
	phoneNumber?: string;
	phoneExt?: string;
	email?: string;
	agreedToTerms?: boolean;
	flowId?: Flow;
	debtorCompanyId?: number;

	constructor(
		legalBusinessName?: string,
		annualRevenueRangeId?: string,
		firstName?: string,
		lastName?: string,
		title?: string,
		phoneCode?: string,
		countryCode?: string,
		phoneNumber?: string,
		phoneExt?: string,
		email?: string,
		agreedToTerms?: boolean,
		flowId?: Flow,
		debtorCompanyId?: number
	) {
		this.legalBusinessName = legalBusinessName;
		this.annualRevenueRangeId = annualRevenueRangeId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.title = title;
		this.phoneCode = phoneCode;
		this.countryCode = countryCode;
		this.phoneNumber = phoneNumber;
		this.phoneExt = phoneExt;
		this.email = email;
		this.agreedToTerms = agreedToTerms;
		this.flowId = flowId;
		this.debtorCompanyId = debtorCompanyId;
	}
}
