import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { ConnectFinancialsPersuasionComponent } from "../../../Modals";
import { take } from "rxjs/operators";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { ModalActions } from "../../../../../Core";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { Store } from "@ngrx/store";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-connect-financials-intro",
	templateUrl: "./connect-financials-intro.component.html",
	styleUrls: ["./connect-financials-intro.component.scss"]
})
export class ConnectFinancialsIntroComponent extends RouteStepDataBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		private dialog: MdbModalService,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}
	onClick() {
		this.nav();
	}

	openModal() {
		this.dialog
			.open(ConnectFinancialsPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.nav();
					} else {
						this.nav(1);
					}
				})
			)
			.subscribe();
	}
}
