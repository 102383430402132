<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
  [isNextButtonDisabled]="lienForm.invalid">
  <div *ngIf="display(HEADER)">
    <ls-text extraClasses="no-text-transform ls-mb-3" [displayConfig]="config(HEADER,'p1')"></ls-text>
    <ls-text extraClasses="ls-mb-0" [displayConfig]="config(HEADER,'p2')">
      <ls-tooltip position='right'>
        <span class='material-icons ls-icon-primary-tooltip' reference>info</span>
        <div content>
          <ls-text extraClasses="l-bold text-wrap" [displayConfig]="config(HEADER,'tt-title')"></ls-text>
          <ls-text extraClasses="ls-mb-0 ls-mt-2 text-wrap" [displayConfig]="config(HEADER,'tt-body')"></ls-text>          
        </div>
      </ls-tooltip>
    </ls-text>
  </div>
  <form [formGroup]="lienForm" class="d-flex flex-column ls-mt-3 ls-mb-9">
    <div *ngIf="display(YESNO)">
      <ls-text extraClasses="ls-mb-8" [displayConfig]="config(YESNO,'q1')"></ls-text>
      <ls-radio-buttons [lookups]="yesNo" [toolTips]="toolTips(YESNO)" [controlName]="YESNO"></ls-radio-buttons>
    </div>
  </form>
</ls-questionnaire>