import { Component, Input } from "@angular/core";
import { Guid } from "../../Utils";

@Component({
	selector: "ls-alert",
	templateUrl: "./limestone-element-alert.component.html",
	styleUrls: ["./limestone-element-alert.component.scss"]
})
export class LimestoneElementAlertComponent {
	id: string;
	@Input() small = false;
	@Input() alertType: "success" | "secondary" | "danger" | "warning" | "info" | "light" | "dark" = "success";
	@Input() icon = "info";

	constructor(private guid: Guid) {
		this.id = this.guid.New(false);
	}

	getIcon() {
		if (this.icon !== "info") return this.icon;
		switch (this.alertType) {
			case "success":
				return "check_circle";
			case "danger":
				return "error";
			case "warning":
				return "warning";
			default:
				return this.icon;
		}
	}
}
