import { Component } from "@angular/core";
import { IRouteStepData } from "../Models/Interfaces";
import { Subject } from "rxjs";
import { Params } from "@angular/router";
import { RouteStepDataService } from "../Modules/COT-Module/Services";
import { DisplayConfig, HtmlContent } from "../Models";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";

@Component({
	selector: "ls-route-step-data-base",
	template: ""
})
/**
 * @class RouteStepDataBaseComponent should be used as the base for all onboarding screens.
 */
export abstract class RouteStepDataBaseComponent {
	public activeRouteData?: IRouteStepData;
	public content?: HtmlContent;
	protected componentTeardown$ = new Subject();
	constructor(public routeStepDataService: RouteStepDataService) {
		this.activeRouteData = this.routeStepDataService.getActiveRouteData();
		this.content = this.activeRouteData?.htmlContent;
	}

	nav(index = 0, paramMap?: Map<string, string | number>, queryParams?: Params, state?: any) {
		this.routeStepDataService.navByConfig(index, paramMap, queryParams, state);
	}

	config(contentKey: string, blockKey: string): DisplayConfig | undefined {
		if (!this.content?.layout) return undefined;
		const blocks = this.content?.layout?.get(contentKey)?.blocks;
		const display = blocks?.get(blockKey) as DisplayConfig;
		return display;
	}
	toolTips(contentKey: string): Map<string | number, string> | undefined {
		if (!this.content?.layout) return undefined;
		return this.content?.layout?.get(contentKey)?.toolTips;
	}
	setupControl(form: FormGroup, formControlName: string, value: any, validators?: ValidatorFn | ValidatorFn[]) {
		if (form.get(formControlName) == null) form.addControl(formControlName, new FormControl(value));
		form.get(formControlName)?.patchValue(value);
		//only apply validators if the control is configured to be visible
		if (this.display(formControlName) && validators) form.get(formControlName)?.setValidators(validators);
	}
	display(contentKey: string) {
		//If no config show the control
		if (!this.content?.layout) return true;
		return this.content?.layout?.get(contentKey)?.display ?? true;
	}
}
