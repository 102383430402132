<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
  [isNextButtonDisabled]="isFormInvalid()">
  <div>
    <form class="d-flex flex-column ls-mt-2" [formGroup]="noticeOfAssignmentForm">
      <div class="ls-mb-11" *ngIf="display('financeDisclosure')">
        <ls-text id='q1' extraClasses="ls-mb-3" [displayConfig]="config('financeDisclosure','q1')"></ls-text>
        <ls-text id='q1-p1' extraClasses="ls-mb-3" [displayConfig]="config('financeDisclosure','q1-p1')"></ls-text>
        <ls-text id='q1-p2' extraClasses="ls-mb-3" [displayConfig]="config('financeDisclosure','q1-p2')"></ls-text>

        <div class="ls-mb-3">
          <ls-accordion [header]="config('financeDisclosure','q1-accordion')?.text ?? ''" [expanded]="false">
            <ul class="no-indent-list">
              <li>
                <ls-text id='q1-a1' extraClasses="ls-mb-2"
                  [displayConfig]="config('financeDisclosure','q1-a1')"></ls-text>
              </li>
              <li>
                <ls-text id='q1-a2' [displayConfig]="config('financeDisclosure','q1-a2')"></ls-text>
              </li>
            </ul>
          </ls-accordion>
        </div>
        <ls-radio-buttons [lookups]="finyn" [toolTips]="toolTips('financeDisclosure')"
          [controlName]="FINANCE_DISCLOSURE"></ls-radio-buttons>
      </div>

      <div *ngIf="display('collectionPreference') && selectedFinanceDisclosure === YesNo.NO">
        <ls-text id='q2' extraClasses="ls-mb-3" [displayConfig]="config('collectionPreference','q2')"></ls-text>
        <ls-text id='q2-p1' extraClasses="ls-mb-3"
          [displayConfig]="config('collectionPreference','q2-p1')"></ls-text>
        <ls-radio-buttons [lookups]="collections" [toolTips]="toolTips('collectionPreference')"
          [controlName]="COLLECTION_PREFERENCE"></ls-radio-buttons>
      </div>
    </form>
  </div>
</ls-questionnaire>