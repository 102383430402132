import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Breadcrumb } from "src/app/Models/Interfaces/Breadcrumb";

@Component({
	selector: "ls-questionnaire",
	templateUrl: "./questionnaire.component.html",
	styleUrls: ["./questionnaire.component.scss"]
})
export class QuestionnaireComponent {
	@Input() breadcrumbs?: Array<Breadcrumb> = [];
	@Input() isNextButtonDisabled? = false;
	@Input() nextButtonText?: string = "Next";
	@Input() displayNextButtonIcon?: boolean = true;

	@Output() nextButtonClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}

	next() {
		this.nextButtonClicked.emit();
		this.isNextButtonDisabled = true;
	}
}
