export enum TextStyle {
	HEADLINE_LARGE,
	HEADLINE_MEDIUM,
	HEADLINE_SMALL,
	TITLE_LARGE,
	TITLE_MEDIUM,
	TITLE_SMALL,
	LABEL_EXTRA_LARGE,
	LABEL_LARGE,
	LABEL_LARGE_BOLD,
	LABEL_MEDIUM,
	LABEL_MEDIUM_CAPS,
	LABEL_MEDIUM_BOLD,
	LABEL_SMALL,
	LABEL_SMALL_CAPS,
	LABEL_EXTRA_SMALL,
	LABEL_EXTRA_SMALL_BOLD,
	LABEL_EXTRA_SMALL_BADGE,
	LABEL_EXTRA_EXTRA_SMALL,
	BODY_EXTRA_LARGE,
	BODY_LARGE,
	BODY_LARGE_BOLD,
	BODY_MEDIUM,
	BODY_MEDIUM_SEMI_BOLD,
	BODY_SMALL,
	BODY_SMALL_SEMI_BOLD,
	BODY_EXTRA_SMALL
}
