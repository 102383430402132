export class CodatConnection {
	id?: string;
	integrationId?: string;
	integrationKey?: string;
	linkUrl?: string;

	constructor(id?: string, integrationId?: string, integrationKey?: string, linkUrl?: string) {
		this.id = id;
		this.integrationId = integrationId;
		this.integrationKey = integrationKey;
		this.linkUrl = linkUrl;
	}
}
