import { Injectable } from "@angular/core";
import { FileHandle } from "./FileHandle";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class FileUploadService {
	constructor(private sanitizer: DomSanitizer) {}

	convert(files: FileList): Array<FileHandle> {
		const fileHandles: FileHandle[] = [];
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
			fileHandles.push(new FileHandle(file, url));
		}
		return fileHandles;
	}
}
