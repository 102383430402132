<div class="ls-modal-container">
	<h2 class="no-text-transform ls-mb-3">These are the only accounting systems we support at the moment</h2>
	<p class="l">Unfortunately, we don’t support all accounting platforms — yet. But we can still help you get financed.</p>
	<p class="l">Please share with us the name of your accounting system:</p>
	<form [formGroup]="formGroup" class="ls-mb-md-14 ls-mb-4">
		<mdb-form-control class="legal-name">
			<input mdbInput mdbValidate [formControlName]="ACC_NAME" type="text" id="accSysName" class="form-control form-control-lg"/>
			<label mdbLabel class="form-label" for="accSysName">Accounting System Name</label>
		</mdb-form-control>
	</form>
	<p class="l ls-mb-0">Please connect your bank accounts to continue the application.</p>
	<div class="modal-action-btns justify-content-sm-end justify-content-md-start align-items-end">
		<button type="button" class="btn btn-primary btn-lg" (click)="close()">Continue</button>
	</div>
</div>