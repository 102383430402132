import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "../../../../services/HTTP";
import { AccountSubmissionForm, CompanyProfile } from "../../../Models";
import { map } from "rxjs/operators";

@Injectable()
export class AccountCreationService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/onboarding/accountSubmission";
	}

	createAccount(account: AccountSubmissionForm): Observable<CompanyProfile> {
		return this.http
			.post<CompanyProfile>(`${this.path}`, account)
			.pipe(
				map(
					(c) =>
						new CompanyProfile(
							c.companyId,
							c.industryId,
							c.legalBusinessName,
							c.annualRevenueRangeId,
							c.phoneId,
							c.countryCode,
							c.phoneCode,
							c.phoneNumber,
							c.phoneExt,
							c.agreedToTerms,
							c.existingFinances,
							c.financeFrequency,
							c.financePurposes,
							c.financeRange,
							c.financeUrgency,
							c.addresses,
							c.lienCollaborationYesNoId,
							c.integrationDataConnectionId,
							c.users
						)
				)
			);
	}
}
