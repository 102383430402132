<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="lienForm.invalid">
<h2 class="no-text-transform ls-mb-3">Would you collaborate with us to resolve any conflicting liens filed against your company?</h2>
<div class="ls-mb-3">
  <span class="l">We’re going to search for any conflicting liens against the receivables we are looking to
    <span class="text-nowrap">acquire.
      <ls-tooltip [position]="'right'">
        <span class="material-icons ls-icon-primary-tooltip" reference>info</span>
        <div content>
          <label class="l-bold text-wrap">Liens</label>
          <p class="m ls-mb-0 ls-mt-2 text-wrap">When a business takes out a loan, their lender can take out a lien on the business to indicate that they could reclaim that asset in the event of non-repayment.</p>
        </div>
      </ls-tooltip>
    </span>
  </span>
</div>
<form [formGroup]="lienForm" class="d-flex flex-column ls-mt-3 ls-mb-9">
  <p class="l ls-mb-8">You can get more favorable pricing if there are no conflicting liens on the receivables we’re looking to acquire.</p>
  <div class="ls-mb-3 d-flex align-items-center" *ngFor="let yn of yesNo">
    <input mdbRadio [formControlName]="YESNO" class="radio" type="radio" [name]="YESNO" id="{{yn.id}}" value="{{yn.id}}" />
    <label class="xl" for="{{yn.id}}">{{ yn.name }}</label>
  </div>
</form>
</ls-questionnaire>