import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import copy from "fast-copy";
import { StepperState } from "./stepper-reducer";

@Injectable()
export class StepperSelectors {
	appState = (state: any) => state.stepper;
	selectState = createSelector(this.appState, (state: StepperState) => copy(state));
	selectSteps = createSelector(this.appState, (state: StepperState) => copy(state.steps));
	selectActiveState = createSelector(this.appState, (state: StepperState) => state.activeState);
	selectStepperConfiguration = createSelector(this.appState, (state: StepperState) => copy(state.routeStepData));
}
