import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "../../../../../Models";
import { CompanyProfileRelationshipSelectors, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-customer-list",
	templateUrl: "./customer-list.component.html",
	styleUrls: ["./customer-list.component.scss"]
})
export class CustomerListComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	constructor(
		public store: Store,
		public router: Router,
		public companyProfileSelectors: CompanyProfileSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	CUSTOMER_SEARCH = "customerSearch";
	public customerForm: FormGroup = new FormGroup({});

	companyCustomers: CompanyProfileRelationship[] = [];
	filteredCustomers: CompanyProfileRelationship[] = [];
	isCodatCustomerFetchComplete = false;
	firstParagraphText =
		"Based on the data you shared with us from your accounting platform, " +
		"we’re gathering a list of your customers. You can manually set up customers — " +
		"the ones you want advanced payments from — now or return later when your customer list has loaded.";

	ngOnInit() {
		this.store
			.select(this.companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cps) => !!cps),
				takeUntil(this.componentTeardown$),
				map((cp) => {
					this.isCodatCustomerFetchComplete = cp!.isCodatCustomerFetchComplete!;
					this.paragraphText();
				})
			)
			.subscribe();

		this.store
			.select(this.companyProfileRelationshipSelectors.selectUnenrolledCompanyProfileRelationships)
			.pipe(
				filter((cprs) => !!cprs),
				takeUntil(this.componentTeardown$),
				map((cprs) => {
					this.companyCustomers = cprs!;
					this.filteredCustomers = cprs!;
					this.paragraphText();
				})
			)
			.subscribe();

		this.customerForm = new FormGroup({
			customerSearch: new FormControl("")
		});

		this.customerForm
			.get(this.CUSTOMER_SEARCH)!
			.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((searchVal) => {
					this.filteredCustomers = this.companyCustomers.filter((cust) =>
						cust.relationshipCompanyName!.toLowerCase().includes(searchVal.toLowerCase())
					);
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	paragraphText() {
		if (this.isCodatCustomerFetchComplete) {
			if (this.companyCustomers.length === 0) {
				this.firstParagraphText =
					"We could not find customers in your accounting system. " +
					"Please manually set up the customers you want advanced payments from. ";
			} else {
				this.firstParagraphText =
					"Based on the data you shared with us from your accounting platform, " +
					"we've gathered a list of your customers. Select and set up which ones you want advanced payments from. " +
					"Look them up by company name or browse the list below.";
			}
		}
	}

	navToCustomer(customerId: number) {
		this.nav(0, new Map<string, string | number>([["relationshipCompanyId", customerId]]), {
			isCodatCustomer: true
		});
	}

	addNewCustomer() {
		this.nav(0, new Map<string, string | number>([["relationshipCompanyId", "new"]]));
	}
}
