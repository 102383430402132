import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable, takeUntil } from "rxjs";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { CompanyProfile } from "../../../../../Models";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-company-industry",
	templateUrl: "./company-industry.component.html",
	styleUrls: ["./company-industry.component.scss"]
})
export class CompanyIndustryComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
	}

	INDUSTRY = "industry";
	INDUSTRY_SEARCH = "industrySearch";

	industries: GenericLookup<string>[] = [];
	filteredIndustries: GenericLookup<string>[] = [];
	private isUSBased?: boolean;

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.industryId = this.form.get(this.INDUSTRY)?.value;
		this.emitSave(updatedCompanyProfile);
		const index = this.isUSBased ? 0 : 1;
		this.nav(index);
	}

	isInvalid() {
		return this.form.invalid;
	}

	init(): Observable<CompanyProfile | undefined> {
		this.form = new FormGroup({
			industrySearch: new FormControl(""),
			industry: new FormControl(null, Validators.required)
		});

		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.Industry))
			.pipe(
				filter((ind) => !!ind),
				takeUntil(this.componentTeardown$),
				map((ind) => {
					this.industries = ind!;
					this.filteredIndustries = ind!;
				})
			)
			.subscribe();

		this.form
			.get(this.INDUSTRY_SEARCH)!
			.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((searchVal) => {
					this.filteredIndustries = this.industries.filter((ind) =>
						ind.name!.toLowerCase().includes(searchVal.toLowerCase())
					);
				})
			)
			.subscribe();

		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.IsUSBased)
		]).pipe(
			filter(([cp, isUSBased]) => !!cp && isUSBased !== undefined),
			map(([cp, isUSBased]) => {
				this.companyProfile = cp;
				this.isUSBased = isUSBased;
				this.form.get(this.INDUSTRY)?.patchValue(this.companyProfile?.industryId);
				return cp;
			})
		);
	}
}
