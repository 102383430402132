import { Question } from "./Question";

export class QuestionSet {
	low: number;
	high: number;
	questions: Question[];

	constructor(low: number, high: number, questions: Question[]) {
		this.low = low;
		this.high = high;
		this.questions = questions;
	}
}
