import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { ScoringState } from "./scoring-reducer";
import { QuestionSets } from "src/app/Models/Enums";
import { QuestionSet } from "src/app/Models";

@Injectable()
export class ScoringSelectors {
	selectScoring: (set: QuestionSets) => (state: ScoringState) => QuestionSet | undefined;
	scoringState = (state: any) => state.scoring;
	constructor() {
		this.selectScoring = (set: QuestionSets) =>
			createSelector(this.scoringState, (state: ScoringState) => {
				return state.pricingGauge?.get(set);
			});
	}
}
