import { Store } from "@ngrx/store";
import { Observable, filter, map, take, takeUntil, tap } from "rxjs";
import { Injectable } from "@angular/core";
import { ScoringActions, ScoringSelectors } from "../AppStateManagement";
import { QuestionSets } from "../Models/Enums";

@Injectable()
export class PricingGaugeResolver {
	constructor(
		private store: Store<any>,
		private scoringSelectors: ScoringSelectors
	) {}

	private scoring$ = this.store.select(this.scoringSelectors.selectScoring(QuestionSets.PRICING_GAUGE)).pipe(
		filter((c) => !!c),
		take(1)
	);

	resolve(): Observable<any | undefined> {
		this.initPrefetchData();
		return this.scoring$;
	}

	initPrefetchData(): void {
		this.store
			.select(this.scoringSelectors.selectScoring(QuestionSets.PRICING_GAUGE))
			.pipe(
				take(1),
				filter((scores) => !scores || scores.questions.length === 0),
				map(() => {
					this.store.dispatch(ScoringActions.getScoring());
				})
			)
			.subscribe();
	}
}
