import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-urgency-purpose",
	templateUrl: "./urgency-purpose.component.html",
	styleUrls: ["./urgency-purpose.component.scss"]
})
export class UrgencyPurposeComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
	}

	URGENCY = "urgency";
	mcr = "MIT";
	public purposes?: GenericLookup<string>[];
	public urgencies?: GenericLookup<string>[];

	init(): Observable<CompanyProfile | undefined> {
		return combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardFinancePurpose)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardFinanceUrgency)),
			this.store.select(this.companyProfileSelectors.selectCompanyProfile)
		]).pipe(
			filter(([fp, fu, cp]) => !!fp && !!fu && !!cp),
			map(([fp, fu, cp]) => {
				this.purposes = fp!;
				this.urgencies = fu!;
				this.companyProfile = cp;
				this.setFormControls();
				return cp;
			})
		);
	}

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile.financePurposes = [];
		this.purposes?.forEach((p) => {
			if (this.form.get(p.id!)?.value) {
				updatedCompanyProfile.financePurposes?.push(p.id!);
			}
		});

		updatedCompanyProfile.financeUrgency = this.form.get(this.URGENCY)?.value;

		this.emitSave(updatedCompanyProfile);
		this.nav();
	}

	isInvalid() {
		return this.form.invalid || !this.purposes?.some((p) => this.form.get(p.id!)?.value);
	}

	setFormControls() {
		this.form = new FormGroup({
			urgency: new FormControl(this.companyProfile?.financeUrgency, Validators.required)
		});

		this.purposes?.forEach((p) => {
			this.form.addControl(`${p.id}`, new FormControl(this.companyProfile?.financePurposes?.includes(p.id!)));
		});
	}
}
