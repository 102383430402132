<form [formGroup]="formGroup" class="pagination-form">
  <div class="d-flex flex-column flex-lg-row justify-content-between ls-pagination-bar align-items-center">
    <div class="d-none d-lg-block">
      <div class="d-flex flex-row align-items-center">
        <label class="xs ls-mr-6">{{totalResults}} Results</label>
        <label class="xs ls-mr-2">Show</label>
        <mdb-form-control class="ls-pagination ls-row-select">
          <mdb-select [formControlName]="ROWS" id="page-size" class="form-control ls-row-select">
            <mdb-option *ngFor="let r of rowOptions" class="ls-option" [value]="r">{{ r }}</mdb-option>
          </mdb-select>
          <label mdbLabel class="form-label" for="page-size">Rows</label>
        </mdb-form-control>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <span class="material-icons ls-arrow ls-mr-3 hoverable" [class.ls-arrow-disabled]="currentPage === 1" (click)="changePage(PaginationButtons.FIRST)">first_page</span>
      <span class="material-icons ls-arrow ls-mr-3 hoverable" [class.ls-arrow-disabled]="currentPage === 1" (click)="changePage(PaginationButtons.PREV)">navigate_before</span>
      <div class="d-flex ls-mr-3 align-items-center">
        <mdb-form-control class="ls-pagination ls-page-select">
          <mdb-select [formControlName]="PAGE" id="current-page" class="ls-page-select">
            <mdb-option *ngFor="let p of pageOptions" class="ls-option" [value]="p">{{ p }}</mdb-option>
          </mdb-select>
          <label mdbLabel class="form-label" for="current-page">Page</label>
        </mdb-form-control>
        <label class="xs ls-ml-2">of {{totalPages}}</label>
      </div>
      <span class="material-icons ls-arrow ls-mr-3 hoverable" [class.ls-arrow-disabled]="currentPage === pageOptions.length" (click)="changePage(PaginationButtons.NEXT)">navigate_next</span>
      <span class="material-icons ls-arrow hoverable" [class.ls-arrow-disabled]="currentPage === pageOptions.length" (click)="changePage(PaginationButtons.LAST)">last_page</span>
    </div>
  </div>
</form>