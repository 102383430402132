import { Country, GenericLookup, StateProvince } from "@limestone/ls-shared-modules";

export class BankAccount {
	public address1: string;
	public address2: string;
	public alias: string;
	public bankAccountType: GenericLookup<string>;
	public bankName: string;
	public city: string;
	public companyId: number;
	public country: Country;
	public currency: GenericLookup<string>;
	public postalCode: string;
	public stateProvince: StateProvince;
	public state: StateProvince;
	public bankAccountPaymentMethods: BankAccountPaymentMethods[];
	public id: number | undefined;
	public codatBankAccountId?: string;
	public isActive = true;
	public intermediaryCorrespondentBankName?: string;
	public routingNumber?: string;
	public accountNumber?: string;
	public swift?: string;
	public iban?: string;
	constructor(
		address1: string,
		address2: string,
		alias: string,
		bankAccountType: GenericLookup<string>,
		bankName: string,
		city: string,
		companyId: number,
		country: Country,
		currency: GenericLookup<string>,
		bankAccountPaymentMethods: BankAccountPaymentMethods[],
		postalCode: string,
		stateProvince: StateProvince,
		id: number | undefined,
		isActive: boolean,
		codatBankAccountId?: string,
		intermediaryCorrespondentBankName?: string,
		routingNumber?: string,
		accountNumber?: string,
		swift?: string,
		iban?: string
	) {
		this.address1 = address1;
		this.address2 = address2;
		this.alias = alias;
		this.bankAccountType = bankAccountType;
		this.bankName = bankName;
		this.city = city;
		this.companyId = companyId;
		this.country = country;
		this.currency = currency;
		this.bankAccountPaymentMethods = bankAccountPaymentMethods;
		this.postalCode = postalCode;
		this.stateProvince = stateProvince;
		this.state = stateProvince;
		this.id = id;
		this.codatBankAccountId = codatBankAccountId;
		this.isActive = isActive;
		this.intermediaryCorrespondentBankName = intermediaryCorrespondentBankName;
		this.routingNumber = routingNumber;
		this.accountNumber = accountNumber;
		this.swift = swift;
		this.iban = iban;
	}
}

export class BankAccountPaymentMethods {
	public paymentMethod: GenericLookup<string>;

	constructor(paymentMethod: GenericLookup<string>) {
		this.paymentMethod = paymentMethod;
	}
}
