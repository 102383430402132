import { createAction, props } from "@ngrx/store";
import { ActionType } from "src/app/Core/LsAction";
import { GoogleAutocompleteDetails, GooglePlaceDetails } from "src/app/Models";
import { LsAction } from "../../Core";

export abstract class GoogleActions extends LsAction {
	static ADDRESSES: Readonly<string> = "ADDRESSES";
	static PLACE_DETAILS: Readonly<string> = "PLACE_DETAILS";

	/* eslint-disable */
  static getAutocompleteAddresses = createAction(
    GoogleActions.getActionName(`AUTOCOMPLETE\/${GoogleActions.ADDRESSES}`, ActionType.GET),
    props<{ id: string, name: string; }>()
  );

  static setAutocompleteAddresses = createAction(
    GoogleActions.getActionName(`AUTOCOMPLETE\/${GoogleActions.ADDRESSES}`, ActionType.SET),
    props<{ id: string, addresses: GoogleAutocompleteDetails }>()
  );

  static getPlaceDetails = createAction(
    GoogleActions.getActionName(GoogleActions.PLACE_DETAILS, ActionType.GET),
    props<{ id: string, placeId: string}>()
  );

  static setPlaceDetails = createAction(
    GoogleActions.getActionName(GoogleActions.PLACE_DETAILS, ActionType.SET),
    props<{ id: string, details: GooglePlaceDetails }>()
  );
   /* eslint-enable */
}
