import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CompanyAddress, CompanyProfile, Phone } from "src/app/Models";
import { AddressType } from "@limestone/ls-shared-modules";
import { environment } from "../../../../../../environments/environment";

@Component({
	selector: "ls-company-profile-readonly",
	templateUrl: "./company-profile-readonly.component.html",
	styleUrls: ["./company-profile-readonly.component.scss"]
})
export class CompanyProfileReadonlyComponent implements OnDestroy {
	protected readonly environment = environment;
	private _companyProfile?: CompanyProfile;
	public primaryAddress?: CompanyAddress;
	public billingAddress?: CompanyAddress;
	public static FORM_CONTROL_NAME = "companyProfile-ro";

	@Input() get companyProfile() {
		return this._companyProfile;
	}

	set companyProfile(cp) {
		this._companyProfile = cp;
		this.primaryAddress = this.companyProfile?.addresses?.find((a) => a.addressType?.id === AddressType.PRIMARY);
		this.setFormControls();
	}

	private componentTeardown$ = new Subject();
	public PHONE = "phone";
	public companyProfileReadonlyForm: FormGroup = new FormGroup({});

	constructor(private fgd: FormGroupDirective) {
		const phone = new Phone(
			this.companyProfile?.phoneCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt,
			this.companyProfile?.countryCode
		);

		this.companyProfileReadonlyForm = new FormGroup({
			phone: new FormControl(phone, Validators.required)
		});
	}

	setFormControls() {
		const phone = new Phone(
			this.companyProfile?.phoneCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt,
			this.companyProfile?.countryCode
		);

		this.companyProfileReadonlyForm.patchValue(
			{
				phone: phone
			},
			{ onlySelf: true, emitEvent: false }
		);

		if (this.fgd.form.get(CompanyProfileReadonlyComponent.FORM_CONTROL_NAME) === null) {
			this.fgd.form?.addControl(CompanyProfileReadonlyComponent.FORM_CONTROL_NAME, this.companyProfileReadonlyForm, {
				emitEvent: false
			});
		}
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	updateCompanyProfile(companyProfile: CompanyProfile) {
		const phone: Phone = this.companyProfileReadonlyForm.get(this.PHONE)?.value;
		companyProfile.phoneCode = phone.phoneCode;
		companyProfile.phoneNumber = phone.number;
		companyProfile.phoneExt = phone.extension;
		companyProfile.countryCode = phone.countryCode;
	}
}
