<div class="d-flex flex-column ls-mt-8 ls-mb-8">
  <form [formGroup]="companyProfileReadonlyForm">

      <div class="d-flex flex-column ls-mb-7" >
        <div class="ls-mb-3">
          <label class="s-caps">BUSINESS NAMES</label>
        </div>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column notEditable ls-mr-15">
            <label class="s ls-mb-1">Legal Business Name</label>
            <label class="l-bold">{{ companyProfile?.legalBusinessName }}</label>
          </div>
        </div>
      </div>
      
      <div class="ls-mb-7 ls-w-lg-50">
        <div class="ls-mb-3">
          <label class="s-caps">OFFICE PHONE NUMBER</label>
        </div>
        <ls-phone-form-control [formControlName]="PHONE" ></ls-phone-form-control>
      </div>
      
      <div class="d-flex flex-column ls-mb-8">
        <div class="ls-mb-3">
          <label class="s-caps">PRIMARY ADDRESS</label>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column notEditable ls-mr-15 ls-mb-7">
            <label class="s ls-mb-1">Legal Address</label>
            <label class="l-bold">{{ primaryAddress?.address?.address1 }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.address2 }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.city }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.state?.name }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.postalCode }}</label>
            <label class="l-bold">{{ primaryAddress?.address?.country?.name }}</label>
          </div>
        </div>
      </div>
    
      <p class="ls-mb-0">If you wish to modify your business names or legal address, please reach out to <a class="email-link" href="mailto:{{environment.supportEmailAddress}}">{{environment.supportEmailAddress}}.</a></p>

  </form>
</div>




