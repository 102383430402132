<div class="d-flex flex-column link-bank">
	<ls-text extraClasses="ls-mb-6" *ngIf="display(HEADER)" [displayConfig]="config(HEADER, 'hl1')"></ls-text>
	<div *ngIf="display(BODY)" class="ls-link-bank-paragraph-container">
		<ls-text class="item" extraClasses="link-bank-p ls-mb-3" [displayConfig]="config(BODY, 'p1')"></ls-text>
		<ls-text class="item" extraClasses="link-bank-p ls-mb-3" [displayConfig]="config(BODY, 'p2')"></ls-text>
	</div>
  <div class="d-flex flex-md-row flex-column justify-content-between">
    <button type="button" class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0" (click)="connectAccounts()">
		<ls-text *ngIf="display(BODY)" [displayConfig]="config(BODY, 'b1')"></ls-text>
	</button>
    <button type="button" class="btn btn-primary-borderless" (click)="openModal()">
		<ls-text *ngIf="display(BODY)" [displayConfig]="config(BODY, 'b2')"></ls-text>
	</button>
  </div>
	<div class="ls-mt-11" *ngIf="display(FOOTER)">
		<ls-text extraClasses="ls-mb-3" [displayConfig]="config(FOOTER, 't1')"></ls-text>
		<ls-text extraClasses="ls-mb-11" [displayConfig]="config(FOOTER, 'p1')"></ls-text>
	</div>
</div>
