import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { StepperActions, StepperSelectors } from "src/app/AppStateManagement";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { Subject, filter, map, takeUntil } from "rxjs";
import { NavigationEnd, Params, Router, RouterEvent, RoutesRecognized } from "@angular/router";

@Injectable()
export class RouteStepDataService implements OnDestroy {
	private activeRouteData?: IRouteStepData;
	private pendingRouteData?: IRouteStepData;
	private routeData?: Map<string, IRouteStepData>;
	private componentTeardown$ = new Subject();
	constructor(
		public store: Store,
		public router: Router,
		public stepperSelectors: StepperSelectors
	) {
		this.store
			.select(this.stepperSelectors.selectState)
			.pipe(
				filter((actState) => !!actState && !!actState.routeStepData),
				takeUntil(this.componentTeardown$),
				map((state) => {
					this.activeRouteData = state.activeRouteStepData;
					this.routeData = state.routeStepData;
				})
			)
			.subscribe();

		this.router.events
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((event: any) => event instanceof RoutesRecognized || event instanceof NavigationEnd),
				map((event: RouterEvent) => {
					if (event instanceof RoutesRecognized) {
						this.SetPendingRouteDataIfOnboardingRoute(RouteStepDataService.getActiveRouteDataKey(event.state.url));
					} else if (event instanceof NavigationEnd) {
						this.CopyPendingRouteDataToActiveRouteData();
					}
				})
			)
			.subscribe();
	}
	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}
	getPendingRouteData(): IRouteStepData {
		return this.pendingRouteData!;
	}
	getActiveRouteData(): IRouteStepData {
		return this.activeRouteData!;
	}
	/*
	On RoutesRecognized configuration data is saved into pendingRouteData
	On NavigationEnd configuration data is copied from pendingRouteData to activeRouteData
	This prevents a component from loading configuration data (HTMLContent) before navigation has taken place and showing the wrong content
	Guards and Resolvers use pendingRouteData, Components use activeRouteData
	*/
	navByPendingConfig(index = 0, paramMap?: Map<string, string | number>, queryParams?: Params, state?: any) {
		if (index >= this.pendingRouteData!.nextUrl!.length) throw new Error(`No route for index ${index}`);
		const path = this.pendingRouteData!.nextUrl![index];
		this.router.navigate([this.updateUrlParams(path, paramMap)], { queryParams, onSameUrlNavigation: "reload", state });
	}
	navByActiveConfig(index = 0, paramMap?: Map<string, string | number>, queryParams?: Params, state?: any) {
		if (index >= this.activeRouteData!.nextUrl!.length) throw new Error(`No route for index ${index}`);
		const path = this.activeRouteData!.nextUrl![index];
		this.router.navigate([this.updateUrlParams(path, paramMap)], { queryParams, onSameUrlNavigation: "reload", state });
	}

	navByPath(url: string, paramMap?: Map<string, string | number>) {
		this.SetPendingRouteDataIfOnboardingRoute(url);
		this.router.navigate([this.updateUrlParams(url, paramMap)], { replaceUrl: false, onSameUrlNavigation: "reload" });
	}

	private updateUrlParams(url: string, paramMap?: Map<string, string | number>): string {
		if (paramMap && paramMap?.size > 0) {
			paramMap.forEach((value, key) => {
				url = url!.replace(`:${key}`, `${value}`);
			});
		}
		return url;
	}
	private CopyPendingRouteDataToActiveRouteData() {
		if (this.pendingRouteData) {
			this.activeRouteData = this.pendingRouteData;
			this.store.dispatch(StepperActions.setActiveRouteData({ routeData: this.pendingRouteData }));
		}
	}
	private SetPendingRouteDataIfOnboardingRoute(url: string) {
		if (url.startsWith("/onboarding" || "onboarding")) {
			if (!this.routeData) throw new Error(`Route data is missing for ${url}}.`);
			const rd = this.routeData.get(url);
			if (!rd) throw new Error(`No route data for ${url}`);
			this.pendingRouteData = rd;
		}
	}

	static getActiveRouteDataKey(url: string): string {
		const urlParts = url.split("?");
		const segments = urlParts[0].split("/");
		const customerRoute = segments.indexOf("customer-setup");
		if (customerRoute > -1) {
			if (segments[segments.length - 1] === "new" || !isNaN(parseInt(segments[segments.length - 1])))
				segments[segments.length - 1] = ":relationshipCompanyId";
		}

		return segments.join("/");
	}
}
