<div class="d-flex flex-column" *ngIf="display(HEADER)">
	<ls-upload [files]="files" [nextButtonDisabled]="isNextButtonDisabled()"
		[allowedFileExtensions]="displayableFileExtensions" [maxFileCount]="maxFileCount" [maxFileSize]="maxFileSize"
		[disabled]="disabled" [submitted]="submitted" (nextClicked)="continue()"
		(sortChanged)="handleSortChange($event)" (openFileClicked)="openFile($event)"
		(deleteFileClicked)="deleteDocument($event)" (uploadedFiles)="uploadFiles($event)"
		(fileUploadError)="handleError($event)">
		<ls-text header extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'hl1')"></ls-text>
		<div description class="d-flex flex-column ls-mb-8">
			<ls-text extraClasses="ls-mb-0" [displayConfig]="config(HEADER,'p1')"></ls-text>
		</div>
		<div *ngIf="errors && errors.length > 0" class="d-flex flex-column ls-mt-4" errors>
			<div *ngFor="let error of errors" class="ls-mb-2">
				<ls-alert alertType="danger" [small]="true">
					<p [innerHTML]="error" class="s ls-mb-0"></p>
				</ls-alert>
			</div>
		</div>
	</ls-upload>
</div>