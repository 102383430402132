import { Injectable, TemplateRef, ViewContainerRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppSelectors } from "../app/AppStateManagement";
import { filter, map, take } from "rxjs";
import { LsUserRole } from "../app/Models";
import { LsRole } from "@limestone/ls-shared-modules";

@Injectable({
	providedIn: "root"
})
export class AuthorizeRolesService {
	private _userRoles?: LsUserRole;

	constructor(
		private store: Store,
		private selector: AppSelectors
	) {
		this.store
			.select(selector.selectUserRoles)
			.pipe(
				take(1),
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				map((userRole) => (this._userRoles = userRole))
			)
			.subscribe();
	}

	checkRoles(roles: LsRole[]): boolean {
		const userRoles = this._userRoles?.roles;
		const matchedRoles = userRoles?.map((r) => r.userRole.Id).some((r) => roles?.includes(r));
		return matchedRoles !== undefined ? matchedRoles : false;
	}

	getB2cId(): string {
		return this._userRoles!.userId! ?? null;
	}
}
