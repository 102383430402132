<div class="d-flex flex-column ">
  <div class="flex-row ls-mb-8">
    <h1 class="m ls-mb-0">Support</h1>
  </div>
  <div class="d-none d-xl-block justify-content-center align-items-center align-items-lg-start">
    <table class="table align-middle ls-mb-0 bg-white table-hover">
      <thead class="support-head">
      <tr>
        <th *ngFor="let column of columns; let isFirst = first; let i = index;" class="header-cell" [class.badge-cell]="i !== 1 && !isFirst" [class.case-number]="i === 1">
          <div class="align-items-center" [class.ls-ml-2]="isFirst">
            <label class="m-caps">{{column.column}}</label>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr class="support-row" *ngFor="let sfCase of cases" (click)="onClick(sfCase)" (mouseover)="onHover($event)" (mouseout)="onMouseOut($event)">
        <td class="cell cell-fade subject-cell">
          <span *ngIf="sfCase.newActivity" class="material-icons notification-icon cell">fiber_manual_record</span>
          <p class="xl-bold ls-mb-0 subject {{ sfCase.newActivity ? '' : 'spacer' }}">{{ sfCase.subject }}</p>
        </td>
        <td class="case-number"><p class="l ls-mb-0">{{sfCase.caseNumber}}</p></td>
        <td class="badge-cell"><p class="l ls-mb-0">{{sfCase.lastModifiedDate! | timeSinceDateDisplay }}</p></td>
        <td class="badge-cell">
          <div>
            <ng-container *ngTemplateOutlet="statusBadge; context: { $implicit: sfCase.status?.toLowerCase() }"></ng-container>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-xl-none d-flex flex-column">
    <div class="support-card bg-neutral ls-mb-3 ls-p-1_75 rounded-7" *ngFor="let sfCase of cases" (click)="onClick(sfCase)">
      <div class="d-flex ls-mb-3">
        <div *ngIf="sfCase.newActivity" class="new-notification"><span class="material-icons notification-icon">fiber_manual_record</span></div>
        <label class="p l-bold" [class.subject-offset]="sfCase.newActivity">{{sfCase.subject}}</label>
      </div>
      <div class="d-flex flex-row justify-content-between ls-mt-1_25">
        <div class="flex-column">
          <label class="m">Case ID</label>
          <p class="l-bold ls-mb-0">{{sfCase.caseNumber}}</p>
        </div>
        <div class="flex-column">
          <label class="m">Last Activity</label>
          <p class="l-bold ls-mb-0">{{sfCase.lastModifiedDate! | timeSinceDateDisplay }}</p>
        </div>
        <div class="d-none d-lg-block">
          <ng-container *ngTemplateOutlet="statusBadge; context: { $implicit: sfCase.status?.toLowerCase() }"></ng-container>
        </div>
      </div>
      <div class="d-lg-none ls-mt-3">
        <ng-container *ngTemplateOutlet="statusBadge; context: { $implicit: sfCase.status?.toLowerCase() }"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #statusBadge let-status>
  <div class="ls-badge ls-badge-support"
        [class.ls-badge-tertiary]="status === CaseStatus.OPEN"
        [class.ls-badge-primary]="status === CaseStatus.NEW"
        [class.ls-badge-primary-light]="status === CaseStatus.SOLVED"
        [class.ls-badge-secondary]="status === CaseStatus.PENDING"
        [class.ls-badge-neutral]="status === CaseStatus.CLOSED">
    <label class="s-caps">{{status}}</label>
  </div>
</ng-template>
