import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FormGroup, Validators } from "@angular/forms";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship, DisplayConfig, Layout, QuestionSet } from "../../../../../Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors,
	ScoringSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { LookupKeys, QuestionSets, TextStyle } from "src/app/Models/Enums";
import { YesNo, PreferredPaymentMethod, CollectionPreference } from "src/app/Models/Enums";
import { CalculatorService } from "src/services";
import { SharedText } from "../shared-text";

@Component({
	selector: "ls-facility-configuration",
	templateUrl: "./facility-configuration.component.html",
	styleUrls: ["./facility-configuration.component.scss"]
})
export class FacilityConfigurationComponent extends CustomerRelationshipBaseComponent implements AfterViewInit {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		private genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		public scoringSelectors: ScoringSelectors,
		public changeDetector: ChangeDetectorRef
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}
	public buttons = [];
	public scoring = new QuestionSet(0, 1, []);
	public get YesNo() {
		return YesNo;
	}

	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	selectedFinanceDisclosure?: string = YesNo.NO;
	LIEN_COLLABORATION = "lienCollaboration";
	FINANCE_DISCLOSURE = "financeDisclosure";
	COLLECTION_PREFERENCE = "collectionPreference";
	INVOICE_APPROVAL = "invoiceApproval";
	PREFERRED_PAYMENT_METHOD = "preferredPaymentMethod";

	yesNo: GenericLookup<string>[] = [];
	finyn: GenericLookup<string>[] = [];
	collections: GenericLookup<string>[] = [];
	ppm: GenericLookup<string>[] = [];

	companyProfileRelationship?: CompanyProfileRelationship;
	percent = 0;
	facilityConfigForm: FormGroup = new FormGroup({});
	calculatorService?: CalculatorService;

	public initData(): void {
		combineLatest([
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.YesNo)),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinancialDisclosureYesNo)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.PreferredPaymentMethod)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.CollectionPreference)),
			this.store.select(this.scoringSelectors.selectScoring(QuestionSets.PRICING_GAUGE))
		])
			.pipe(
				filter(([cpr, yn, finyn, ppm, cp, sc]) => !!cpr && !!yn && !!finyn && !!ppm && !!cp && !!sc),
				takeUntil(this.componentTeardown$),
				map(([cpr, yn, finyn, ppm, cp, sc]) => {
					this.yesNo = yn!;
					this.finyn = finyn!;
					this.ppm = ppm!;
					this.collections = cp!;
					this.scoring = sc!;
					this.companyProfileRelationship = cpr!;
					this.calculatorService = new CalculatorService(
						this.scoring.questions,
						this.scoring.low,
						this.scoring.high,
						this.facilityConfigForm
					);
				})
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		this.calculatorService?.percent
			.pipe(
				takeUntil(this.componentTeardown$),
				map((percent) => {
					this.percent = percent;
				})
			)
			.subscribe();
		this.setupControl(
			this.facilityConfigForm,
			this.LIEN_COLLABORATION,
			this.companyProfileRelationship?.lienCollaborationYesNoId,
			Validators.required
		);
		this.setupControl(
			this.facilityConfigForm,
			this.FINANCE_DISCLOSURE,
			this.companyProfileRelationship?.financeDisclosureYesNoId,
			Validators.required
		);
		this.setupControl(
			this.facilityConfigForm,
			this.COLLECTION_PREFERENCE,
			this.companyProfileRelationship?.collectionPreferenceId,
			Validators.required
		);
		this.setupControl(
			this.facilityConfigForm,
			this.INVOICE_APPROVAL,
			this.companyProfileRelationship?.invoiceApprovalYesNoId,
			Validators.required
		);
		this.setupControl(
			this.facilityConfigForm,
			this.PREFERRED_PAYMENT_METHOD,
			this.companyProfileRelationship?.preferredPaymentMethodId,
			Validators.required
		);

		this.facilityConfigForm
			.get(this.FINANCE_DISCLOSURE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v: string) => {
					this.handleFinancialDisclosureChange(v);
				})
			)
			.subscribe();

		this.handleFinancialDisclosureChange(this.companyProfileRelationship!.financeDisclosureYesNoId);
	}
	comparator(previous: any, current: any): boolean {
		return (
			previous.lienCollaboration === current.lienCollaboration &&
			previous.financeDisclosure === current.financeDisclosure &&
			previous.collectionPreference === current.collectionPreference &&
			previous.invoiceApproval === current.invoiceApproval &&
			previous.preferredPaymentMethod === current.preferredPaymentMethod
		);
	}

	private handleFinancialDisclosureChange(value?: string) {
		this.selectedFinanceDisclosure = value;
		//ensure control is visible before applying validators
		this.changeDetector.detectChanges();
		const collectionPreference = this.facilityConfigForm.get(this.COLLECTION_PREFERENCE);
		if (this.selectedFinanceDisclosure === YesNo.NO) {
			collectionPreference?.setValidators(Validators.required);
			collectionPreference?.updateValueAndValidity();
		} else {
			collectionPreference?.clearValidators();
			collectionPreference?.reset(null, { emitEvent: false });
		}
	}

	markAsDirty() {
		this.facilityConfigForm.markAsDirty();
	}

	isFormInvalid() {
		this.facilityConfigForm.updateValueAndValidity();
		return this.facilityConfigForm.invalid;
	}

	getCompanyProfileRelationshipForUpdate() {
		const lienCollaborationId = this.facilityConfigForm.get(this.LIEN_COLLABORATION)?.value;
		const financeDisclosureId = this.facilityConfigForm.get(this.FINANCE_DISCLOSURE)?.value;
		let collectionPreferenceId = this.facilityConfigForm.get(this.COLLECTION_PREFERENCE)?.value;
		const invoiceApprovalId = this.facilityConfigForm.get(this.INVOICE_APPROVAL)?.value;
		const preferredPaymentMethodId = this.facilityConfigForm.get(this.PREFERRED_PAYMENT_METHOD)?.value;
		if (financeDisclosureId === YesNo.YES || financeDisclosureId === YesNo.IDK) {
			collectionPreferenceId = CollectionPreference.INFORM;
		}
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);

		updatedCompanyProfileRelationship.lienCollaborationYesNoId = lienCollaborationId;
		updatedCompanyProfileRelationship.financeDisclosureYesNoId = financeDisclosureId;
		updatedCompanyProfileRelationship.collectionPreferenceId = collectionPreferenceId;
		updatedCompanyProfileRelationship.invoiceApprovalYesNoId = invoiceApprovalId;
		updatedCompanyProfileRelationship.preferredPaymentMethodId = preferredPaymentMethodId;
		return updatedCompanyProfileRelationship;
	}

	continue() {
		if (this.facilityConfigForm.dirty) {
			const updatedCompanyProfileRelationship = this.getCompanyProfileRelationshipForUpdate();

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["h-p1", new DisplayConfig("Configure your facility", TextStyle.HEADLINE_SMALL)],
					[
						"h-p2",
						new DisplayConfig(
							"The following questions will provide information that will assist Raistone in the pricing and configuration of your facility. The gauge at the bottom of the page helps provide you with better visibility into how your preferences may affect estimated pricing",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			"lienCollaboration",
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q1",
						new DisplayConfig(
							"Would you collaborate with us to obtain lien releases from existing creditors who may have a lien on your accounts receivable in exchange for a lower discount rate?",
							TextStyle.TITLE_MEDIUM
						)
					],
					[
						"q1-p1",
						new DisplayConfig(
							"We’re going to search for any conflicting liens against the receivables we are looking to acquire.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"q1-p2",
						new DisplayConfig(
							"You can get more favorable pricing if there’s no conflicting liens on the receivables we’re looking to acquire.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			"financeDisclosure",
			new Layout(
				new Map<string, DisplayConfig>([
					["q2", new DisplayConfig(SharedText.FinancialDisclosure.question, TextStyle.TITLE_MEDIUM)],
					["q2-p1", new DisplayConfig(SharedText.FinancialDisclosure.paragraph1, TextStyle.BODY_LARGE)],
					["q2-p2", new DisplayConfig(SharedText.FinancialDisclosure.paragraph2, TextStyle.BODY_LARGE)],
					[
						"q2-accordion",
						new DisplayConfig(SharedText.FinancialDisclosure.accordionHeader, TextStyle.BODY_LARGE_BOLD)
					],
					["q2-a1", new DisplayConfig(SharedText.FinancialDisclosure.accordionItem1, TextStyle.BODY_LARGE)],
					["q2-a2", new DisplayConfig(SharedText.FinancialDisclosure.accordionItem2, TextStyle.BODY_LARGE)]
				]),
				new Map<string | number, string>([
					["Y", SharedText.FinancialDisclosure.toolTipYes],
					["N", SharedText.FinancialDisclosure.toolTipNo]
				])
			)
		],
		[
			"collectionPreference",
			new Layout(
				new Map<string, DisplayConfig>([
					["q3", new DisplayConfig(SharedText.CollectionPreference.question, TextStyle.TITLE_MEDIUM)],
					["q3-p1", new DisplayConfig(SharedText.CollectionPreference.paragraph1, TextStyle.BODY_LARGE)]
				])
			)
		],
		[
			"invoiceApproval",
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q4",
						new DisplayConfig(
							"Would you prefer to have your customer provide invoice approvals in exchange for a lower discount rate?",
							TextStyle.TITLE_MEDIUM
						)
					],
					[
						"q4-p1",
						new DisplayConfig("Getting invoice approval from your customers will result in:", TextStyle.BODY_LARGE)
					],
					[
						"q4-b1",
						new DisplayConfig("Lower discount fee — we can provide you with better pricing.", TextStyle.BODY_LARGE)
					],
					[
						"q4-b2",
						new DisplayConfig(
							"Increased advance rate — we’ll be able to buy a larger amount of each invoice.",
							TextStyle.BODY_LARGE
						)
					],
					["q4-b3", new DisplayConfig("Larger line of credit we can provide your business.", TextStyle.BODY_LARGE)]
				])
			)
		],
		[
			"preferredPaymentMethod",
			new Layout(
				new Map<string, DisplayConfig>([
					["q5", new DisplayConfig("How do you prefer to get paid?", TextStyle.TITLE_MEDIUM)],
					[
						"q5-p1",
						new DisplayConfig(
							"Different methods of payment affect the speed your application can be processed, any fees, and other factors. Different methods of payment affect the speed your application can be processed, any fees, and other factors",
							TextStyle.BODY_LARGE
						)
					]
				]),
				new Map<string | number, string>([[PreferredPaymentMethod.CARD, SharedText.vCardToolTip]])
			)
		],
		["actionButtons", new Layout(new Map<string, DisplayConfig>([["b1", new DisplayConfig("Continue")]]))]
	]);
}
