<div class="d-flex flex-column">
  <h1 class="l ls-mb-6">Your company name</h1>
  <div class="ls-mb-6 d-flex flex-column">
    <p class="title m ls-mb-2">Is your legal company name {{ companyProfile?.legalBusinessName }}?</p>
    <p class="l ls-mb-4">Your legal company name is the one you used when you incorporated your business.</p>
    <div class="legal-name ls-mb-2 d-flex flex-column">
      <div class="ls-mb-3 d-flex align-items-center">
        <input mdbRadio class="radio legal-name-input" type="radio" name="isLegalName" id="legal-name-yes" [disabled]="form.disabled"
          (click)="legalName(true)" [checked]="confirmedLegalName"/>
        <label class="xl legal-name-label" for="legal-name-yes">Yes</label>
      </div>
      <div class="d-flex align-items-center">
        <input mdbRadio class="radio legal-name-input" type="radio" name="isLegalName" id="legal-name-no" [disabled]="form.disabled"
          (click)="legalName(false)"/>
        <label class="xl legal-name-label" for="legal-name-no">No</label>
      </div>
    </div>
  </div>
  <form class="d-flex flex-column" [formGroup]="form">
    <div class="d-flex flex-column ls-mb-6" 
          [class.d-none]="companyNameIsLegalName || !radioClicked">
      <div class="d-flex flex-column">
        <p class="title m ls-mb-2">What is your company’s registered name?</p>
        <p class="l">As shown in your article of incorporation documents.</p>
      </div>
      <mdb-form-control class="legal-name">
        <input mdbInput [formControlName]="LEGAL_NAME" mdbValidate type="text" id="legalName"
          class="form-control form-control-lg"/>
        <label mdbLabel class="form-label" for="legalName">Legal Company Name</label>
      </mdb-form-control>
    </div>
  </form>
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" #lsNextBtn
    [disabled]="isInvalid()">
    Next <span class="material-icons md-18"> arrow_forward</span>
  </button>
</div>