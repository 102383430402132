import { LsRole } from "@limestone/ls-shared-modules";

export class LsUserRole {
	userId: string;
	get roles(): Array<{ userRole: UserRole; companyId: number }> {
		return this._roles;
	}
	set roles(roles: Array<{ userRole: UserRole; companyId: number }>) {
		this._roles = roles.map((r) => {
			return {
				userRole: new UserRole(r.userRole.Id, r.userRole.Name, r.userRole.IsActive),
				companyId: r.companyId
			};
		});
	}
	private _roles = new Array<{ userRole: UserRole; companyId: number }>();

	constructor(userId: string) {
		this.userId = userId;
	}
}

export class UserRole {
	Id: LsRole;
	Name: string;
	IsActive: boolean;

	constructor(id: string, name: string, isActive: boolean) {
		this.Id = id as LsRole;
		this.Name = name;
		this.IsActive = isActive;
	}
}
