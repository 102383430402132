<div class="d-flex flex-column">
	<div *ngIf="display(HEADER)">
		<ls-text [displayConfig]="config(HEADER, 'hl1')" extraClasses="ls-mb-8"></ls-text>
		<ls-text [displayConfig]="config(HEADER, 'p1')" extraClasses="ls-mb-8"></ls-text>
	</div>
	<div class="ls-mb-11">
		<div class="d-flex flex-column justify-content-between ls-mb-0 ls-p-1_75 rounded-7 bg-light flex-sm-row"
			 *ngFor="let cpr of companyProfileRelationships; let last = last" [class.ls-mb-3]="!last">
			<div class="d-flex flex-column flex-sm-row w-100 justify-content-between">
				<div class="d-flex flex-column justify-content-start">
					<div class="d-flex flex-row app-name">
						<p class="title l ls-mb-2 text-break">{{ cpr.relationshipCompanyName }}</p>
						<span *ngIf="!cpr.isCustomerSetupComplete"
							  class="d-inline ls-icon-badge ls-incomplete-warning-icon material-icons">warning</span>
					</div>
					<p class="m">
						<span>{{ cpr?.billingAddress?.toDisplayString() }}</span>
					</p>
				</div>

				<button *ngIf="!cpr.isCustomerSetupComplete" class="btn btn-lg btn-primary"
						(click)="navToCustomerContact(cpr!)">
					<ls-text [displayConfig]="config(BODY, 'cpr-b1')"></ls-text>
				</button>
				<button *ngIf="cpr?.isCustomerSetupComplete" class="btn btn-lg btn-floating btn-primary"
						(click)="navToCustomerContact(cpr!)"><span class="material-icons md-18">mode_edit</span>
				</button>
			</div>
		</div>
	</div>
	<div class="d-flex flex-column flex-md-row justify-content-between">
		<button type="button" (click)="addAnotherCustomer()" class="btn btn-lg btn-primary ls-mb-4 ls-mb-md-0">
			<ls-text [displayConfig]="config(BODY, 'b1')"></ls-text>
		</button>
		<button type="button" (click)="continue()" class="btn btn-lg btn-outline-tertiary" [disabled]="!hasEnrolledCustomers">
			<ls-text [displayConfig]="config(BODY, 'b2')"></ls-text>
		</button>
	</div>
</div>