<div class="d-flex flex-column">
	<h1 class="l ls-mb-8">Now, let’s set up your program</h1>
    <p class="l ls-mb-3">For your information, here are all of the companies that are part of this program.</p>
	<p class="l ls-mb-8">Please note that all receivables submitted by any of the companies listed below will be automatically accelerated. If you’re interested in manually accelerating your receivables, please reach out to us via <a href="mailto:{{environment.supportEmailAddress}}"><strong>{{environment.supportEmailAddress}}</strong></a> or by contacting a Raistone sales representative.</p>
    <div class="w-100 program-list d-flex flex-column ls-mb-11" *ngIf="program">
        <label class="xl ls-mb-3" for="{{program.relationshipCompanyName}}">{{ program.relationshipCompanyName }}</label>
            <ng-container *ngFor="let subsidiaryCompany of program.subsidiaryCompanies ?? []">
                <label class="xl ls-mb-3" for="{{subsidiaryCompany}}">{{ subsidiaryCompany }}</label>
            </ng-container>
    </div>
    <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" #lsNextBtn>
        Next <span class="material-icons md-18"> arrow_forward</span>
    </button>
</div>