import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationshipSelectors } from "../../../../../AppStateManagement";
import { RouteStepDataService } from "../../../Services";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { environment } from "../../../../../../environments/environment";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { TextStyle } from "../../../../../Models/Enums";

@Component({
	selector: "ls-program-confirmation",
	templateUrl: "./program-confirmation.component.html",
	styleUrls: ["./program-confirmation.component.scss"]
})
export class ProgramConfirmationComponent extends RouteStepDataBaseComponent implements OnInit {
	constructor(
		public store: Store<any>,
		public router: Router,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	protected readonly environment = environment;
	public program!: CompanyProfileRelationship;

	ngOnInit(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationships)
			.pipe(
				filter((cprs) => !!cprs && cprs.length > 0),
				takeUntil(this.componentTeardown$),
				map((cprs) => {
					// Note: This currently assumes the first object is the correct relationship.
					// This logic needs to be reviewed and updated to determine the correct relationship after the program updates.
					this.program = cprs![0];
				})
			)
			.subscribe();
	}

	onClick() {
		this.nav();
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Now, let’s set up your program", TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"For your information, here are all of the companies that are part of this program:",
							TextStyle.BODY_LARGE
						)
					],
					[
						"p2",
						new DisplayConfig(
							'Please note that all receivables submitted by any of the companies listed below will be automatically accelerated. If you’re interested in manually accelerating your receivables, please reach out to us via <a href="mailto:{{environment.supportEmailAddress}}"><strong>{{environment.supportEmailAddress}}</strong></a> or by contacting a Raistone sales representative.',
							TextStyle.BODY_LARGE
						)
					],
					["b1", new DisplayConfig("Next", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
