<div class="d-flex flex-row">
    <div *ngFor="let breadcrumb of breadcrumbs; let first = first; let last = last" class="d-flex flex-row breadcrumb-color">
        <span *ngIf="!first" class="material-icons">
            arrow_right
        </span>
        <span *ngIf="!last">
            <a [routerLink]="[breadcrumb.routerPath]" class="breadcrumb-link">{{breadcrumb.displayText}}</a>
        </span>
        <span *ngIf="last">
            {{breadcrumb.displayText}}
        </span>
    </div>
</div>
