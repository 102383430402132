import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import {
	AppSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors
} from "../AppStateManagement";
import { filter, map, Observable, take } from "rxjs";
import { ApplicationsComponent } from "../Modules/Root-Module/Components";
import { PageRequest } from "@limestone/ls-shared-modules";

@Injectable()
export class ApplicationsDataResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	applications$ = this.store.select(this.companyProfileRelationshipSelectors.selectApplications).pipe(
		filter((apps) => !!apps),
		take(1)
	);

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		this.initPrefetchData();
		return this.applications$;
	}

	initPrefetchData(): void {
		this.store
			.select(this.appSelectors.selectUserRoles)
			.pipe(
				take(1),
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				map((userRole) => {
					this.store.dispatch(
						CompanyProfileRelationshipActions.getApplications({
							companyProfileId: userRole!.roles[0].companyId,
							pr: new PageRequest(1, 10, [ApplicationsComponent.INITIAL_SORT])
						})
					);
				})
			)
			.subscribe();
	}
}
