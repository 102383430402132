<div class="d-flex flex-column">
	<div *ngIf="display(HEADER)">
		<ls-text [displayConfig]="config(HEADER, 'hm1')" extraClasses="ls-mb-5"></ls-text>
		<ls-text [displayConfig]="config(HEADER, 'p1')" extraClasses="ls-mb-0_75"></ls-text>
		<ls-text [displayConfig]="config(HEADER, 'p2')" extraClasses="ls-mb-6"></ls-text>
	</div>

	<div *ngIf="!isCodatCustomerFetchComplete || (isCodatCustomerFetchComplete && companyCustomers.length === 0)" class="text-center ls-mt-6">
		<div *ngIf="!isCodatCustomerFetchComplete" class="spinner-border ls-spinner" role="status">
			<span class="visually-hidden">{{ config(BODY, "loader")?.text }}</span>
		</div>
		<img *ngIf="isCodatCustomerFetchComplete && companyCustomers.length === 0" class="image-container" src="../../../../../../assets/search-list-small.svg"/>
		<div class="ls-spinner-text">
			<ng-container *ngIf="!isCodatCustomerFetchComplete; then loading; else loadingComplete">
			</ng-container>
			<ng-template #loading>
				<ls-text [displayConfig]="config(BODY, 'loader-l1')"></ls-text>
			</ng-template>
			<ng-template #loadingComplete>
				<ls-text [displayConfig]="config(BODY, 'loader-l2')"></ls-text>
			</ng-template>
		</div>
	</div>

	<div *ngIf="isCodatCustomerFetchComplete && companyCustomers.length > 0">
		<form [formGroup]="customerForm" class="ls-mb-4">
			<mdb-form-control>
				<input mdbInput
						[formControlName]="CUSTOMER_SEARCH"
						type="text"
						placeholder="Customer Name"
						class="form-control form-control-lg search-input"
						id="customerSearch"
						aria-describedby="search-icon" />
				<span class="input-search-icon material-icons search-icon" id="search-icon">search</span>
				<label mdbLabel class="form-label search-label" for="customerSearch">{{ config(BODY, "q1-l1")?.text }}</label>
			</mdb-form-control>
		</form>

		<div class="w-100 ls-p-4 customer-list ls-mb-6">
			<div class="scroll">
				<div *ngFor="let customer of filteredCustomers" class="customer d-flex flex-row">
					<span class="material-icons md-24 ls-mr-0_75 hoverable" (click)="navToCustomer(customer.relationshipCompanyId!)">add</span>
					<label class="l-bold" for="{{customer.relationshipCompanyId}}">{{ customer.relationshipCompanyName }}</label>
				</div>
			</div>
		</div>
	</div>

	<div class="d-flex flex-row">
		<button type="button" (click)="addNewCustomer()" class="btn btn-primary btn-lg">
			<ng-container *ngIf="isCodatCustomerFetchComplete && companyCustomers.length > 0; then b1; else b2">
			</ng-container>
			<ng-template #b1>
				<ls-text [displayConfig]="config(BODY, 'b1')"></ls-text>
			</ng-template>
			<ng-template #b2>
				<ls-text [displayConfig]="config(BODY, 'b2')"></ls-text>
			</ng-template>
		</button>
	</div>
</div>