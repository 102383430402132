import { Component, Input } from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validators
} from "@angular/forms";
import { takeUntil, Subject, tap, map, debounce, interval, filter } from "rxjs";
import { LsPlatform, Platform as PlatformType } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-platform-select",
	templateUrl: "./platform-select.component.html",
	styleUrls: ["./platform-select.component.scss"],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: PlatformSelectComponent, multi: true },
		{ provide: NG_VALIDATORS, useExisting: PlatformSelectComponent, multi: true }
	]
})
export class PlatformSelectComponent implements ControlValueAccessor {
	protected componentTeardown$ = new Subject();

	private _platform!: LsPlatform;

	set platform(platform: LsPlatform) {
		if (platform) {
			this._platform = platform;
			this.setForm();
		}
	}

	get platform(): LsPlatform {
		return this._platform;
	}

	@Input() platforms?: LsPlatform[];
	@Input() label?: string;

	formGroup: FormGroup = new FormGroup({});
	PLATFORM = "platform";
	OTHER_PLATFORM = "otherPlatform";

	public maxLength = 80;
	hideOtherPlatformInput = false;

	constructor() {
		this.formGroup.addControl(this.PLATFORM, new FormControl(null, Validators.required));

		this.formGroup.addControl(
			this.OTHER_PLATFORM,
			new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(this.maxLength)])
		);
	}

	comparePlatform(a: LsPlatform, b: LsPlatform): boolean {
		return a !== null && b !== null && a.id === b.id;
	}

	writeValue(platform: LsPlatform): void {
		this.platform = platform;
		this.formGroup.get(this.PLATFORM)?.setValue(platform ?? null, { emitEvent: false });
	}

	registerOnChange(onChange: (value: LsPlatform | null) => void): void {
		this.formGroup.valueChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				tap(({ platform }) => onChange(platform))
			)
			.subscribe();
	}

	onTouched() {}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled ? this.formGroup.disable() : this.formGroup.enable();
	}

	validate(control: AbstractControl<any, any>): ValidationErrors | null {
		if (this.formGroup.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, this.PLATFORM);
		errors = this.addControlErrors(errors, this.OTHER_PLATFORM);

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };

		const controlErrors = this.formGroup.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}

		return errors;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.validatorChange = fn;
	}

	validatorChange() {}

	setForm() {
		this.hideOtherPlatformInput = this.platform?.id !== PlatformType.Other;
		this.formGroup.setControl(
			this.OTHER_PLATFORM,
			new FormControl({
				value: this.platform?.id === PlatformType.Other ? this.platform?.name : "",
				disabled: this.platform?.id !== PlatformType.Other
			}),
			{ emitEvent: false }
		);

		this.formGroup
			.get(this.PLATFORM)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((value: LsPlatform) => {
					if (value.id === PlatformType.Other) {
						this.hideOtherPlatformInput = false;
						this.formGroup
							.get(this.OTHER_PLATFORM)
							?.setValidators([Validators.required, Validators.minLength(1), Validators.maxLength(this.maxLength)]);
						this.formGroup.get(this.OTHER_PLATFORM)?.enable();
					} else {
						this.hideOtherPlatformInput = true;
						this.formGroup.get(this.OTHER_PLATFORM)?.clearValidators();
						this.formGroup.get(this.OTHER_PLATFORM)?.disable();
					}
				})
			)
			.subscribe();

		this.formGroup
			.get(this.OTHER_PLATFORM)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				debounce(() => interval(500)),
				filter((p) => !!p && this.formGroup.get(this.PLATFORM)?.value.name !== p),
				map((value) => {
					if (this.formGroup.get(this.PLATFORM)?.value.id === PlatformType.Other) {
						this.formGroup.get(this.PLATFORM)?.patchValue({ id: PlatformType.Other, name: value, isActive: true });
					}
				})
			)
			.subscribe();
	}
}
