import { Component, Input } from "@angular/core";

@Component({
	selector: "ls-pricing-gauge",
	templateUrl: "./pricing-gauge.component.html",
	styleUrls: ["./pricing-gauge.component.scss"]
})
export class PricingGaugeComponent {
	constructor() {}
	private _percent = 0;
	@Input() set percent(value: number) {
		if (value < 0) value = 0;
		if (value > 100) value = 100;
		this._percent = value;
	}
	get percent() {
		return this._percent;
	}

	getPercent(): string {
		return `translateX(${this.percent}%)`;
	}
}
