import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, Observable, takeUntil } from "rxjs";
import { CompanyProfile } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-amount-frequency",
	templateUrl: "./amount-frequency.component.html",
	styleUrls: ["./amount-frequency.component.scss"]
})
export class AmountFrequencyComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
	}

	RANGE = "range";
	FREQUENCY = "frequency";
	public ranges?: GenericLookup<string>[] = [];
	public frequencies?: GenericLookup<string>[] = [];

	onClick() {
		const updatedCompanyProfile = copy(this.companyProfile!);
		updatedCompanyProfile!.financeRange = this.form.get(this.RANGE)?.value;
		updatedCompanyProfile!.financeFrequency = this.form.get(this.FREQUENCY)?.value;

		this.emitSave(updatedCompanyProfile);
		this.nav();
	}

	isInvalid() {
		return this.form.invalid;
	}
	init(): Observable<CompanyProfile | undefined> {
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.FinanceRange)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.FinanceFrequency))
		])
			.pipe(
				filter(([fr, ff]) => !!fr && !!ff),
				takeUntil(this.componentTeardown$),
				map(([fr, ff]) => {
					this.ranges = fr!;
					this.frequencies = ff!;
				})
			)
			.subscribe();
		return this.store.select(this.companyProfileSelectors.selectCompanyProfile).pipe(
			filter((cp) => !!cp),
			map((cp) => {
				this.companyProfile = cp;

				this.form = new FormGroup({
					range: new FormControl(this.companyProfile?.financeRange, Validators.required),
					frequency: new FormControl(this.companyProfile?.financeFrequency, Validators.required)
				});
				return cp;
			})
		);
	}
}
