import { Component, SecurityContext } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { MarketplaceCardDetail } from "../../Components/marketplace/marketplace.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "ls-marketplace-more-details",
	templateUrl: "./marketplace-more-details.component.html",
	styleUrls: ["./marketplace-more-details.component.scss"]
})
export class MarketplaceMoreDetailsComponent {
	details?: MarketplaceCardDetail;

	constructor(
		public modalRef: MdbModalRef<MarketplaceMoreDetailsComponent>,
		private readonly _sanitizer: DomSanitizer
	) {}

	public close(): void {
		this.modalRef.close();
	}

	public openLink(link?: string): void {
		if (!link) return;

		const url = this._sanitizer.sanitize(SecurityContext.URL, link!);
		window.open(url!, "_blank");
	}
}
