<div class="d-flex flex-column">
	<ls-text *ngIf="display(HEADER)" extraClasses="ls-mb-8" [displayConfig]="config(HEADER, 'hl1')"></ls-text>
	<div *ngIf="display(BODY)">
		<ls-text extraClasses="ls-mb-8" [displayConfig]="config(BODY, 'p1')"></ls-text>
		<div class="w-100 program-list d-flex flex-column ls-mb-8" *ngIf="program">
			<label class="xl" [class.ls-mb-3]="program.subsidiaryCompanies? program.subsidiaryCompanies.length > 0 : false">{{ program.relationshipCompanyName }}</label>
			<ng-container *ngFor="let subsidiaryCompany of program.subsidiaryCompanies ?? [];  let last = last">
				<label class="xl" [class.ls-mb-3]="!last">{{ subsidiaryCompany }}</label>
			</ng-container>
		</div>
		<ls-text extraClasses="ls-mb-11" [displayConfig]="config(BODY, 'p2')"></ls-text>
	</div>
	<button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" #lsNextBtn>
        <ls-text [displayConfig]="config(BODY, 'b1')"></ls-text>
		<span class="material-icons md-18"> arrow_forward</span>
    </button>
</div>