<form [formGroup]="formGroup" class="d-flex flex-column">
  <div class="ls-mb-3">
    <ng-content></ng-content>
  </div>
  <div class="grid gap-3">
    <div class="platform g-col-12" *ngFor="let o of options">
      <div class="d-flex flex-row align-items-center">
        <input mdbRadio [formControlName]="OPTION" class="radio" type="radio" 
          [name]="OPTION" [id]="o.id" [value]="o.id"/>
        <label class="xl" [for]="o.id">{{ o.label }}</label>
      </div>
      
      <div *ngIf="o.id === PLATFORM" [hidden]="formGroup.get(OPTION)?.value !== PLATFORM">
        <mdb-form-control class="ls-mt-2">
          <input mdbInput 
            [formControlName]="PLATFORM_SEARCH" 
            type="text" 
            placeholder="Search your platform" 
            class="form-control form-control-lg search-input" 
            id="platformSearch" 
            [hidden]="o.id !== PLATFORM"
            aria-describedby="search-icon" />
          <span class="input-search-icon material-icons search-icon" id="search-icon">search</span>
          <label mdbLabel class="form-label search-label" for="platformSearch">Platform</label>
        </mdb-form-control>
        <div class="w-100 ls-p-4 platform-list ls-mt-2">
          <div class="scroll">
            <div *ngFor="let platform of filteredPlatforms" class="d-flex align-items-center ls-pb-3">
              <input mdbRadio [formControlName]="PLATFORM" class="radio platform-input" type="radio" [name]="PLATFORM" id="{{platform.id}}" value="{{platform.id}}" />
              <label class="xl" for="{{platform.id}}">{{ platform.name }}</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="o.id === OTHER" [class.ls-mt-4]="!hideOtherPlatformInput" [hidden]="hideOtherPlatformInput">
        <mdb-form-control>
          <input mdbInput [formControlName]="OTHER_PLATFORM_INPUT" mdbValidate type="text" [maxlength]="maxLength" id="OTHER_PLATFORM_INPUT" class="form-control form-control-lg" />
          <label mdbLabel class="form-label" for="OTHER_PLATFORM_INPUT">Specify</label>
          <div class="form-helper">
            <div class="form-counter">{{ characterCount }} / {{ maxLength }}</div>
          </div>
        </mdb-form-control>
      </div> 
    </div>
  </div> 
</form>