<ls-questionnaire-toggle #questionnaireForm
        toggleLabel="autoXcel"
        [(enabled)] = isAutoXcel
        [toggledState] = "toggledState"
        [displayConfig]="config(AUTOEXCEL,'q1')"
        [breadcrumbs]="breadcrumbs"
        (buttonClicked)="continue()">
        <ls-text [displayConfig]="config(AUTOEXCEL,'q1-p1')" extraClasses="lb-mb-0"></ls-text>
        <ls-text [displayConfig]="config(AUTOEXCEL,'q1-p2')" extraClasses="lb-mb-0"></ls-text>  
</ls-questionnaire-toggle>
  