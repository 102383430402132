import { LsAction } from "../../Core";
import { createAction, props } from "@ngrx/store";
import { ActionType } from "../../Core/LsAction";
import { LsStep } from "../../../Elements/stepper/limestone-element-stepper.component";
import { IRouteStepData } from "../../Models/Interfaces";
import { ActiveState, ChildStepType } from "./stepper-reducer";

export abstract class StepperActions extends LsAction {
	/* eslint-disable */
	static STEPS: Readonly<string> = 'STEPS';
	static ACTIVE_STATE: Readonly<string> = 'ACTIVE_STATE';
	static DISPLAY_SETTINGS: Readonly<string> = 'DISPLAY_SETTINGS';
	static INITIALIZE: Readonly<string> = 'INITIALIZE';
	static INITIALIZE_CUSTOMER_SETUP: Readonly<string> = 'INITIALIZE_CUSTOMER_SETUP';
	static STEPPER_CONFIGURATION: Readonly<string> = "STEPPER_CONFIGURATION";
	static ACTIVE_STEPPER_CONFIGURATION: Readonly<string> = "ACTIVE_STEPPER_CONFIGURATION";
	/* eslint-enable */

	static updateSteps = createAction(
		StepperActions.getActionName(StepperActions.STEPS, ActionType.UPDATE),
		props<{ steps: LsStep[] }>()
	);

	static setRouteStepConfiguration = createAction(
		StepperActions.getActionName(StepperActions.STEPPER_CONFIGURATION, ActionType.SET),
		props<{ data: Map<string, IRouteStepData> }>()
	);

	static setActiveRouteData = createAction(
		StepperActions.getActionName(StepperActions.ACTIVE_STEPPER_CONFIGURATION, ActionType.SET),
		props<{ routeData: IRouteStepData; ignoreNextStep?: boolean }>()
	);

	static setActiveState = createAction(
		StepperActions.getActionName(StepperActions.ACTIVE_STATE, ActionType.SET),
		props<{ activeState: ActiveState }>()
	);

	/**
	 * This will automatically set the state to the next substep, or step if all
	 * substeps are completed.
	 */
	static setNextStep = createAction(
		StepperActions.getActionName(StepperActions.STEPS, ActionType.TRIGGER),
		props<{ childStepType?: ChildStepType }>()
	);

	static setSettingsDisplay = createAction(
		StepperActions.getActionName(StepperActions.DISPLAY_SETTINGS, ActionType.SET),
		props<{ display: boolean }>()
	);

	static initializeStepper = createAction(StepperActions.getActionName(StepperActions.INITIALIZE, ActionType.TRIGGER));

	static initializeChildSteps = createAction(
		StepperActions.getActionName(StepperActions.INITIALIZE_CUSTOMER_SETUP, ActionType.TRIGGER),
		props<{ childStepType: ChildStepType }>()
	);
}
