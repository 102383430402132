<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
	[isNextButtonDisabled]="isFormInvalid()">
	<form [formGroup]="customerRelationship2Form" class="d-flex flex-column">
		<ls-text id="hs1" extraClasses="ls-mb-6" [displayConfig]="config(HEADER,'hs1')"></ls-text>
		<div *ngIf="display(CURRENCY)">			
			<div class="ls-mb-6">
				<ls-text id="q1-p1" [displayConfig]="config(CURRENCY,'q1')"></ls-text>
				<ls-radio-buttons [lookups]="currencies" [controlName]="CURRENCY"
					[toolTips]="toolTips(CURRENCY)"></ls-radio-buttons>

			</div>
		</div>
		<div *ngIf="display(ONBOARD_PAYMENT_METHOD)">
			<ls-text id="q2" [displayConfig]="config(ONBOARD_PAYMENT_METHOD,'q2')"></ls-text>
			<ls-radio-buttons [lookups]="onboardPaymentMethods" [controlName]="ONBOARD_PAYMENT_METHOD"
				[toolTips]="toolTips(ONBOARD_PAYMENT_METHOD)"></ls-radio-buttons>
		</div>

	</form>
</ls-questionnaire>