import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { DisplayConfig, Layout } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-company-details-intro",
	templateUrl: "./company-details-intro.component.html",
	styleUrls: ["./company-details-intro.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class CompanyDetailsIntroComponent extends RouteStepDataBaseComponent {
	extraClasses = new Map<string, string>();
	constructor(
		public router: Router,
		public store: Store<any>,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	onClick() {
		this.nav();
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Now let’s talk a bit about your company", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							"To get started, we'll just need some basic information about your company, contact info, address, and a few questions on financing.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(new Map<string, DisplayConfig>([["next", new DisplayConfig("Next", TextStyle.BUTTON_LARGE)]]))
		]
	]);
}
