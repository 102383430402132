import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IColumnHeader } from "../../Models/Interfaces/IColumnHeader";
import { CompanyDocument } from "../../Models";
import { SortEvent } from "../../Models/Interfaces";
import { UploadStatus } from "../../Models/Enums/UploadStatus";
import { GridSort, PageRequest, SortDirection } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-file-data-grid",
	templateUrl: "./file-data-grid.component.html",
	styleUrls: ["./file-data-grid.component.scss"]
})
export class FileDataGridComponent {
	@Input() files?: CompanyDocument[];
	@Input() submitted?: boolean = false;
	public columns: IColumnHeader[] = CompanyDocument.ColumnNames();
	private activeSort?: GridSort;
	@Output() sortChanged: EventEmitter<SortEvent> = new EventEmitter<SortEvent>();
	@Output() openFileClicked: EventEmitter<CompanyDocument> = new EventEmitter<CompanyDocument>();
	@Output() deleteFileClicked: EventEmitter<CompanyDocument> = new EventEmitter<CompanyDocument>();

	constructor() {}

	public get UploadStatus(): typeof UploadStatus {
		return UploadStatus;
	}

	public sortColumn(column: IColumnHeader) {
		let direction;
		if (this.activeSort?.active === column.relatedField) {
			direction = this.activeSort?.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
		} else {
			direction = SortDirection.ASC;
		}
		this.activeSort = {
			active: column.relatedField,
			direction
		};
		const pr = new PageRequest(1, 100, [this.activeSort]);
		this.sortChanged.emit({ sort: this.activeSort, pageRequest: pr });
	}

	public getIcon(column: IColumnHeader): string {
		if (this.activeSort?.active === column.relatedField) {
			return this.activeSort.direction === SortDirection.ASC ? "keyboard_arrow_up" : "keyboard_arrow_down";
		}
		return "unfold_more";
	}

	public getBadgeIcon(status: UploadStatus): string {
		switch (status) {
			case UploadStatus.COMPLETE:
				return "check_circle";
			case UploadStatus.IN_PROGRESS:
				return "refresh";
			case UploadStatus.FAILED:
				return "error";
		}
		return "";
	}

	public openFile(file: CompanyDocument) {
		this.openFileClicked.emit(file);
	}

	public deleteFile(file: CompanyDocument) {
		if (!this.submitted) this.deleteFileClicked.emit(file);
	}
}
