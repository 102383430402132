<div>
  <span class="ls-alert" [ngClass]="
    {
      'small': small,
      'ls-alert-success': alertType === 'success',
      'ls-alert-secondary': alertType === 'secondary',
      'ls-alert-danger': alertType === 'danger',
      'ls-alert-warning': alertType === 'warning',
      'ls-alert-info': alertType === 'info',
      'ls-alert-light': alertType === 'light',
      'ls-alert-dark': alertType === 'dark'
    }
  ">
    <span class="material-icons ls-mr-0_75 alert-icon">{{getIcon()}}</span>
    <ng-content></ng-content>
  </span>
</div>