<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
	[isNextButtonDisabled]="ppmForm.invalid" [nextButtonText]="nextButtonText" [displayNextButtonIcon]="false">
	<form [formGroup]="ppmForm" class="d-flex flex-column">
		<ls-text extraClasses="no-text-transform ls-mb-6" [displayConfig]="config(HEADER,'hm1')"></ls-text>
		<div class="d-flex flex-column">
			<ls-text extraClasses="ls-mb-3" [displayConfig]="config(BODY,'p2')"></ls-text>
			<div *ngIf="display(PREFERRED_PAYMENT_METHOD)">
				<ls-radio-buttons [lookups]="preferredPaymentMethods" [toolTips]="toolTips(PREFERRED_PAYMENT_METHOD)"
					[controlName]="PREFERRED_PAYMENT_METHOD"></ls-radio-buttons>
			</div>
		</div>
	</form>
</ls-questionnaire>