<div *ngIf="!readOnly; else readOnlyView" class="d-flex flex-column" [formGroup]="platformsUsedForm">
    <ls-platform-select
        [platforms]="instrumentPlatforms"
        [formControlName]="INSTRUMENT_PLATFORM"
        label="Invoice Source">
    </ls-platform-select>
    <ls-platform-select
        [platforms]="approvalPlatforms"
        [formControlName]="INSTRUMENT_APPROVAL_PLATFORM"
        label="Invoice Approval Status Source">
    </ls-platform-select>
    <ls-platform-select
        [platforms]="remittancePlatforms"
        [formControlName]="REMITTANCE_PLATFORM"
        label="Remittance Data Source">
    </ls-platform-select>
</div>
  
<ng-template #readOnlyView>
    <ls-platforms-used-readonly [companyProfileRelationship]="companyProfileRelationship"></ls-platforms-used-readonly>
</ng-template>