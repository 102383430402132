import { Injectable } from "@angular/core";
import { HttpService } from "./HTTP";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { Case, EmailMessage } from "src/app/Models";

@Injectable()
export class SalesforceSupportService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/salesforcesupport";
	}

	getCase(caseId: number): Observable<Case> {
		const url = `${this.path}/user/case/${caseId}`;

		return this.http
			.get(url)
			.pipe(
				map(
					(c: any) =>
						new Case(
							c.recordId,
							c.id,
							c.email,
							c.limestoneContactId,
							c.caseNumber,
							c.subject,
							c.lastModifiedDate,
							c.viewDate,
							c.status,
							c.createdDate,
							c.ownerName,
							c.messages,
							c.newActivity
						)
				)
			);
	}

	getCases(): Observable<Case[]> {
		const url = `${this.path}/user/cases`;
		return this.http
			.get<Case[]>(url)
			.pipe(
				map((cs: Case[]) =>
					cs.map(
						(c: any) =>
							new Case(
								c.recordId,
								c.id,
								c.email,
								c.limestoneContactId,
								c.caseNumber,
								c.subject,
								c.lastModifiedDate,
								c.viewDate,
								c.status,
								c.createdDate,
								c.ownerName,
								c.messages,
								c.newActivity
							)
					)
				)
			);
	}

	updateCase(sfCase: Case): Observable<Case> {
		return this.http
			.put<Case>(`${this.path}/user/case/${sfCase.id}`, sfCase)
			.pipe(
				map(
					(c) =>
						new Case(
							c.recordId,
							c.id,
							c.email,
							c.limestoneContactId,
							c.caseNumber,
							c.subject,
							c.lastModifiedDate,
							c.viewDate,
							c.status,
							c.createdDate,
							c.ownerName,
							c.messages,
							c.newActivity
						)
				)
			);
	}

	sendEmailMessage(message: EmailMessage, userId: number, caseId: number, files?: File[]): Observable<string> {
		const url = `${this.path}/user/${userId}/case/${caseId}/message`;
		const formData = new FormData();
		files?.map((file) => {
			formData.append("file", file, file.name);
		});
		formData.append("email", JSON.stringify(message));

		const headers = new HttpHeaders();
		headers.append("Content-Type", "multipart/form-data");
		headers.append("Accept", "application/json");
		return this.http.post<string>(url, formData, { headers });
	}

	downloadAttachment(
		blobPath: string,
		fileName: string,
		userId: number,
		caseId: number
	): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		params = params.set("blobPath", blobPath);
		params = params.set("fileName", fileName);
		return this.http.get(`${this.path}/user/${userId}/case/${caseId}/download`, {
			observe: "response",
			responseType: "blob",
			params
		});
	}
}
