import { Expectation } from "./Expectation";
import { Layout } from "./Layout";

export class HtmlContent {
	header?: string;
	content?: string;
	subHeader?: string;
	expectations?: Expectation[];
	paragraphs?: string[];
	firstButtonText?: string;
	secondButtonText?: string;
	paragraph?: string;
	failedText?: string;
	subContent?: string;
	layout?: Map<string, Layout>;

	constructor(init?: Partial<HtmlContent>) {
		Object.assign(this, init);
	}
}
