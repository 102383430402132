import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { switchMap, map } from "rxjs";
import { GoogleService } from "../../../services";
import { GoogleActions } from "./google-actions";
import { GoogleAutocompleteDetails, GooglePlaceDetails } from "src/app/Models";

@Injectable()
export class GoogleEffects {
	constructor(
		private actions$: Actions,
		private googleService: GoogleService
	) {}

	fetchAutocompleteCompanies$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GoogleActions.getAutocompleteAddresses),
			switchMap((val) =>
				this.googleService
					.getAutocomplete(val.name)
					.pipe(
						map((ac: GoogleAutocompleteDetails) =>
							GoogleActions.setAutocompleteAddresses({ id: val.id, addresses: ac })
						)
					)
			)
		)
	);

	fetchPlaceDetails$ = createEffect(() =>
		this.actions$.pipe(
			ofType(GoogleActions.getPlaceDetails),
			switchMap((val) =>
				this.googleService
					.getPlaceDetails(val.placeId)
					.pipe(map((details: GooglePlaceDetails) => GoogleActions.setPlaceDetails({ id: val.id, details: details })))
			)
		)
	);
}
