import { createSelector } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { CodatState } from "./codat-reducer";
import copy from "fast-copy";

@Injectable()
export class CodatSelectors {
	appState = (state: any) => state.codat;

	selectIntegrationDataConnections = createSelector(this.appState, (state: CodatState) =>
		copy(state.integrationDataConnection)
	);

	selectBankAccounts = createSelector(this.appState, (state: CodatState) => copy(state.bankAccounts));
}
