import { Injectable } from "@angular/core";
import { FileHandle } from "src/Elements/upload-widget/FileHandle";
import { FileTypeResult, fileTypeFromBuffer } from "file-type";
import { defer, Observable } from "rxjs";

@Injectable()
export class FileUtilityService {
	/**
	 * Returns a map of ALL allowed file extensions. The key is the file extension and the value
	 * is a boolean indicating if the file extension should be displayed in the UI.
	 * @returns Map<string, boolean>
	 */
	public static getAllowedFileExtensions(): Map<string, boolean> {
		return new Map<string, boolean>([
			["JPEG", true],
			["PNG", true],
			["GIF", true],
			["PDF", true],
			["JPG", false],
			["JFIF", false],
			["JPE", false],
			["JIF", false]
		]);
	}

	public static convertFileSize(size: number): string {
		const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];
		let order = 0;
		let len = size;
		while (len >= 1024 && order < sizes.length - 1) {
			order++;
			len = len / 1024;
		}
		return `${Math.round(len)} ${sizes[order]}`;
	}

	public static convertFileSizeToBytes(fileSize: string): number {
		const [fileSizeNumber, fileSizeUnit] = fileSize.split(" ");
		const sizeNumber = Number(fileSizeNumber);

		const unitMap: { [key: string]: number } = {
			B: 1,
			KB: 1024,
			MB: 1048576,
			GB: 1073741824,
			TB: 1099511627776
		};

		const byteValue = unitMap[fileSizeUnit];

		if (byteValue === undefined) {
			throw new Error('Invalid unit. Use "B", "KB", "MB", "GB" or "TB".');
		}

		return sizeNumber * byteValue;
	}

	/**
	 * Uses the file-type library to read determine if a file is supported.
	 * @param file the fileHandle object.
	 * @param allowedFileExtensions the collection of allowed file extensions.
	 * @link https://www.npmjs.com/package/file-type?activeTab=readme
	 * @returns true if the file type is supported; otherwise false.
	 */
	public fileTypeSupported(file: FileHandle, allowedFileExtensions: string[]): Observable<boolean | undefined> {
		return defer(() => this.isFileSupported(file, allowedFileExtensions));
	}

	private async isFileSupported(file: FileHandle, allowedFileExtensions: string[]): Promise<boolean | undefined> {
		const bufferSize = 2048;

		try {
			const arrayBuffer = await file.file.arrayBuffer();
			const fileSize = arrayBuffer.byteLength;

			// Ensure the buffer size doesn't exceed the file size
			const actualBufferSize = Math.min(bufferSize, fileSize);

			const buffer = new Uint8Array(actualBufferSize);
			buffer.set(new Uint8Array(arrayBuffer, 0, actualBufferSize));

			const fileType = await fileTypeFromBuffer(buffer);
			const isSupported =
				fileType! &&
				allowedFileExtensions.includes(fileType.ext.toUpperCase()) &&
				this.isFileTypeMatch(fileType, file.file.name.split(".").pop()?.toUpperCase() || "");
			return Promise.resolve(isSupported);
		} catch (error) {
			console.error("Error reading file:", error);
			return false;
		}
	}

	public static isFileSizeOverLimit(file: FileHandle, maxFileSize: number): boolean {
		const maxBytes = this.convertFileSizeToBytes(maxFileSize + " MB");
		const overLimit: boolean = file.file.size > maxBytes ? true : false;
		return overLimit;
	}

	private isFileTypeMatch(fileType: FileTypeResult, extension: string): boolean {
		switch (extension.toUpperCase()) {
			case "JPEG":
			case "JPG":
			case "JFIF":
			case "JPE":
				return fileType.ext.toUpperCase() === "JPG";
			case "JIF":
				return fileType.ext.toUpperCase() === "GIF";
			case "PNG":
				return fileType.ext.toUpperCase() === "PNG";
			case "PDF":
				return fileType.ext.toUpperCase() === "PDF";
			case "GIF":
				return fileType.ext.toUpperCase() === "GIF";
			default:
				return false;
		}
	}
}
