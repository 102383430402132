import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { combineLatest, filter, map, Subject, take, takeUntil } from "rxjs";
import { CompanyProfile, DisplayConfig, Layout } from "src/app/Models";
import { BankAccountPersuasionComponent } from "../../../Modals";
import { CodatActions, CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { ModalActions } from "../../../../../Core";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { Flow, TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-link-bank-account",
	templateUrl: "./link-bank-account.component.html",
	styleUrls: ["./link-bank-account.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class LinkBankAccountComponent extends RouteStepDataBaseComponent implements OnInit {
	protected componentTeardown$ = new Subject();
	public flow?: string;
	public FOOTER = "footer";
	constructor(
		public router: Router,
		public store: Store,
		public companyProfileSelectors: CompanyProfileSelectors,
		private dialog: MdbModalService,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	companyProfile?: CompanyProfile;
	path = "";

	ngOnInit() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		])
			.pipe(
				filter(([cp, ocp]) => !!cp && !!ocp),
				takeUntil(this.componentTeardown$),
				map(([cp, ocp]) => {
					this.companyProfile = cp;
					this.flow = ocp?.onboardingConfiguration?.id;
					this.path = `onboarding/${ocp!.onboardingConfigurationId}/connect-financials/banking-success`;
				})
			)
			.subscribe();
	}

	connectAccounts() {
		this.store.dispatch(
			CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path! })
		);
	}

	openModal() {
		if (this.flow === Flow.SCF_V1) {
			this.nav(1);
			return;
		}
		this.dialog
			.open(BankAccountPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.store.dispatch(
							CodatActions.createConnection({ companyProfile: this.companyProfile!, redirectPath: this.path! })
						);
					} else {
						this.nav();
					}
				})
			)
			.subscribe();
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Link your business bank account", TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.BODY,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"Visibility into your business bank account gives Raistone clarity into customer relationships and payments which is helpful as we build out the most attractive financing options for you.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"p2",
						new DisplayConfig(
							"You’ll be asked to log into your bank in order to authorize access.",
							TextStyle.BODY_LARGE
						)
					],
					["b1", new DisplayConfig("Connect bank accounts", TextStyle.BUTTON_LARGE)],
					["b2", new DisplayConfig("I'll do it later", TextStyle.BUTTON_LARGE)]
				])
			)
		]
	]);
}
