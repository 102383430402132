<div class="d-flex flex-column">
  <ls-upload
    [files]="files"
    [nextButtonDisabled]="isNextButtonDisabled()"
    [allowedFileExtensions]="displayableFileExtensions"
    [maxFileCount]="maxFileCount"
    [maxFileSize]="maxFileSize"
    [disabled]="disabled"
    [submitted]="submitted"
    (nextClicked)="continue()"
    (sortChanged)="handleSortChange($event)"
    (openFileClicked)="openFile($event)"
    (deleteFileClicked)="deleteDocument($event)"
    (uploadedFiles)="uploadFiles($event)"
    (fileUploadError)="handleError($event)">
    <h1 header class="ls-mb-8 l">Form W-9</h1>
    <div description class="d-flex flex-column ls-mb-8">
      <span>An IRS Form W-9 — Request for Taxpayer Identification Number and Certification — verifies your company’s taxpayer ID number or Employer Identification Number (EIN).
        <ls-tooltip [position]="'right'">
          <span class="material-icons li-tooltip-icon ls-icon-primary-tooltip" reference>info</span>
          <div content>
            <p class="m text-wrap">We ask every new client to fill out a Form W-9 as it’s a useful way of collecting and recording our client’s details.</p>
            <p class="m ls-mb-0 text-wrap">We use Form W-9 to get tax information from your company that helps us with informational reporting to the IRS.</p>
          </div>
        </ls-tooltip>
      </span>
    </div>
    <form [formGroup]="taxIdForm" input (keydown)="onKeyboardEvent($event)" (paste)="onClipboardEvent($event)">
      <mdb-form-control class="tax-id ls-mt-8">
        <input [formControlName]="TAX_ID" mdbInput mdbValidate type="text" id="taxId"
            class="form-control form-control-lg"/>
        <label mdbLabel class="form-label" for="taxId">EIN</label>
        <mdb-error class="ls-mt-4" *ngIf="controlHasError(TAX_ID)">
          {{getControlError(TAX_ID)}}
        </mdb-error>
      </mdb-form-control>
    </form>
    <div *ngIf="errors && errors.length > 0" class="d-flex flex-column ls-mt-4" errors>
      <div *ngFor="let error of errors">
        <ls-alert alertType="danger" [small]="true"><p [innerHTML]="error" class="s ls-mb-0"></p></ls-alert>
      </div>
    </div>
  </ls-upload>
</div>