<div class="accordion-container ls-mb-0" *ngIf="displayConfig?.display ?? true">
    <div class="accordion-header d-flex flex-row ls-mb-2" (click)="toggleExpansion()">
        <span class="material-icons accordion-icon ls-mr-2">{{ expanded? 'arrow_drop_down' : 'arrow_right'}}</span>
        <ls-text *ngIf="displayConfig; else inlineHeader" [displayConfig]="displayConfig"></ls-text>
        <ng-template #inlineHeader>
            <p class="l-bold ls-mb-0">{{header}}</p>
        </ng-template>

    </div>
    <div class="accordion-content ls-pt-1 ls-pb-0 ls-pl-4" *ngIf="expanded">
        <ng-content></ng-content>
    </div>
</div>
