import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Guid } from "../../Utils";
import { FormControl, FormGroupDirective } from "@angular/forms";

@Component({
	selector: "ls-toggle",
	templateUrl: "./limestone-element-toggle.component.html",
	styleUrls: ["./limestone-element-toggle.component.scss"]
})
export class LimestoneElementToggleComponent {
	id: string;
	private _toggled = false;
	private control?: FormControl;
	@Input() set toggled(toggled: boolean) {
		this._toggled = toggled;
		// Allows the toggle to be used directly in a form by using it in the <form> and passing a controlName
		if (this.controlName) {
			if (this.fgd.form.get(this.controlName!)) {
				this.fgd.form.get(this.controlName!)!.patchValue(toggled);
			} else {
				this.control = new FormControl(this.toggled);
				this.fgd.form.addControl(this.controlName!, this.control, { emitEvent: false });
			}
		}
	}
	get toggled(): boolean {
		return this._toggled;
	}
	@Input() disabled = false;
	@Input() controlName?: string;
	@Output() toggledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private guid: Guid,
		private fgd: FormGroupDirective
	) {
		this.id = this.guid.New(false);
	}

	onToggle() {
		this.toggled = !this.toggled;
		this.toggledChange.emit(this.toggled);
		if (this.controlName && this.fgd.form.get(this.controlName!)) {
			this.control!.patchValue(this.toggled);
			this.control!.markAsDirty();
		}
	}
}
