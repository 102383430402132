import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormControlStatus, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { debounce, filter, interval, map, Subject, takeUntil, tap } from "rxjs";
import { GenericLookup, GenericSelectors, LsPlatform } from "@limestone/ls-shared-modules";
import { PlatformType } from "src/app/Models/Enums";
import { PlatformCategory } from "src/app/Models";

@Component({
	selector: "ls-platform-choices",
	templateUrl: "./platform-choices.component.html",
	styleUrls: ["./platform-choices.component.scss"]
})
export class PlatformChoicesComponent implements OnInit {
	protected componentTeardown$ = new Subject();

	PLATFORM = "PLATFORM";
	PLATFORM_SEARCH = "PLATFORM_SEARCH";
	OPTION = "OPTION";
	OTHER = PlatformCategory.OTHER;
	OTHER_PLATFORM_INPUT = "OTHER_PLATFORM_INPUT";
	formGroup: FormGroup = new FormGroup({});
	@Input() platform?: GenericLookup<string>;
	@Input() type!: PlatformType;
	public platforms?: LsPlatform[];
	public filteredPlatforms?: LsPlatform[];
	public maxLength = 80;
	public options?: PlatformCategory[];
	characterCount = 0;
	hideOtherPlatformInput = false;

	@Output() formValue: EventEmitter<GenericLookup<string>> = new EventEmitter<GenericLookup<string>>();
	@Output() formStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private store: Store<any>,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors
	) {}

	ngOnInit(): void {
		this.options = PlatformCategory.OPTIONS.filter((o) => o.usedBy.includes(this.type));
		this.store
			.select(this.genericSelectors.selectPlatforms)
			.pipe(
				filter((p) => !!p && p.length > 0),
				takeUntil(this.componentTeardown$),
				map((p) => {
					this.platforms = this.filterPlatformsByType(p!).filter((platform) => platform.id !== PlatformCategory.OTHER);
					this.filteredPlatforms = this.platforms;
					this.setFormControls();
				})
			)
			.subscribe();
	}

	setFormControls() {
		const platformName = this.platform?.name;
		const selectedPlatformIsOption = platformName
			? this.options!.map((o) => o.label.toLowerCase()).includes(platformName.toLowerCase())
			: false;
		this.hideOtherPlatformInput = this.platform?.id !== PlatformCategory.OTHER || selectedPlatformIsOption;
		this.formGroup = new FormGroup({
			OPTION: new FormControl(null, Validators.required),
			PLATFORM_SEARCH: new FormControl(""),
			PLATFORM: new FormControl(!selectedPlatformIsOption ? this.platform?.id : null)
		});
		this.formGroup.addControl(
			this.OTHER_PLATFORM_INPUT,
			new FormControl(
				{
					value: this.platform?.id === PlatformCategory.OTHER && !selectedPlatformIsOption ? this.platform?.name : "",
					disabled: this.platform?.id !== PlatformCategory.OTHER || selectedPlatformIsOption
				},
				[Validators.required, Validators.minLength(1), Validators.maxLength(this.maxLength)]
			)
		);

		if (this.platform?.id === PlatformCategory.OTHER && !selectedPlatformIsOption) {
			this.formGroup.get(this.OPTION)?.setValue(PlatformCategory.OTHER);
			this.characterCount = this.platform?.name?.length ?? 0;
		}

		if (selectedPlatformIsOption) {
			const newVal = this.options!.find((o) => o.label.toLowerCase() === this.platform?.name?.toLowerCase());
			this.formGroup.get(this.OPTION)?.setValue(newVal?.id);
		}

		if (this.platforms!.map((p) => p.id).includes(this.platform?.id)) {
			this.formGroup.get(this.OPTION)?.setValue(this.PLATFORM);
		}

		this.formGroup
			.get(this.OTHER_PLATFORM_INPUT)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				tap((value) => (this.characterCount = value?.length ?? 0)),
				debounce(() => interval(500)),
				map((value) => {
					if (this.formGroup.get(this.OPTION)?.value === PlatformCategory.OTHER) {
						this.formValue.emit(new GenericLookup<string>(PlatformCategory.OTHER, value, true));
					}
				})
			)
			.subscribe();

		this.formGroup
			.get(this.PLATFORM)
			?.valueChanges.pipe(
				filter((value) => !!value),
				takeUntil(this.componentTeardown$),
				map((value: string) => {
					this.formGroup.get(this.OTHER_PLATFORM_INPUT)?.disable();
					const newVal = this.platforms?.find((p) => p.id === value);
					this.formValue.emit(newVal);
				})
			)
			.subscribe();

		this.formGroup
			.get(this.OPTION)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((value: string) => {
					const otherPlatformInputControl = this.formGroup.get(this.OTHER_PLATFORM_INPUT);
					if (value === PlatformCategory.OTHER) {
						this.hideOtherPlatformInput = false;
						this.updatePlatformControl();
						otherPlatformInputControl?.enable();
					} else {
						this.hideOtherPlatformInput = true;
						otherPlatformInputControl?.disable();
						if (value !== PlatformCategory.PLATFORM) {
							const newVal = this.options?.find((o) => o.id === value);
							this.updatePlatformControl();
							this.formValue.emit(new GenericLookup<string>(PlatformCategory.OTHER, newVal?.label, true));
						} else {
							this.updatePlatformControl(true);
						}
					}
				})
			)
			.subscribe();

		this.formGroup.statusChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				map((status: FormControlStatus) => this.formStatus.emit(status === "VALID"))
			)
			.subscribe();

		this.formGroup
			.get(this.PLATFORM_SEARCH)!
			.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((searchVal) => {
					this.filteredPlatforms = this.platforms?.filter((p) =>
						p.name!.toLowerCase().includes(searchVal.toLowerCase())
					);
				})
			)
			.subscribe();
	}

	filterPlatformsByType(platforms: LsPlatform[]): LsPlatform[] {
		let platformsFilteredByType: LsPlatform[] = [];
		switch (this.type) {
			case PlatformType.INSTRUMENT_PLATFORM: {
				platformsFilteredByType = platforms.filter((p) => p.isInstrumentPlatform);
				break;
			}
			case PlatformType.APPROVALS_PLATFORM: {
				platformsFilteredByType = platforms.filter((p) => p.isApprovalsPlatform);
				break;
			}
			case PlatformType.REMITTANCE_PLATFORM: {
				platformsFilteredByType = platforms.filter((p) => p.isRemittancePlatform);
				break;
			}
			default:
				throw new Error("Unknown type.");
		}
		return platformsFilteredByType;
	}

	updatePlatformControl(addValidators?: boolean): void {
		const formControl = this.formGroup.get(this.PLATFORM);
		if (addValidators) {
			formControl?.addValidators(Validators.required);
			if (formControl?.value === PlatformCategory.OTHER) formControl.setValue(null);
		} else {
			formControl?.clearValidators();
		}
		formControl?.updateValueAndValidity();
	}
}
