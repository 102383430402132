import { Store } from "@ngrx/store";
import { SalesforceSupportActions, SalesforceSupportSelectors } from "../AppStateManagement";
import { Observable, filter, take } from "rxjs";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";

@Injectable()
export class SalesforceSupportCaseDataResolver {
	constructor(
		private store: Store<any>,
		private salesforceSupportSelectors: SalesforceSupportSelectors
	) {}

	case$ = (caseId: number) =>
		this.store.select(this.salesforceSupportSelectors.selectCase).pipe(
			filter((c) => !!c && c.id === caseId),
			take(1)
		);

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		const caseId = parseInt(route.paramMap.get("caseId")!, 10);
		this.store.dispatch(SalesforceSupportActions.getCase({ caseId: caseId }));
		return this.case$(caseId);
	}
}
