<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="!isValid"
  *ngIf="display(BODY)">
  <ls-platform-choices 
    [platform]="companyProfileRelationship?.instrumentApprovalPlatform"
    [type]="type"
    (formValue)="handleFormValueChange($event)" 
    (formStatus)="handleFormStatusChange($event)">
    <ls-text extraClasses="no-text-transform" [displayConfig]="config(BODY,'q1')"></ls-text>    
  </ls-platform-choices>
</ls-questionnaire>
