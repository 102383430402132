import { Component } from "@angular/core";
import { ModalActions } from "../../../../Core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
	selector: "ls-unknown-account-platform",
	templateUrl: "./unknown-account-platform.component.html"
})
export class UnknownAccountPlatformComponent {
	public readonly ACC_NAME = "ACC_NAME";
	public formGroup: FormGroup<any> = new FormGroup({});

	constructor(public modalRef: MdbModalRef<UnknownAccountPlatformComponent>) {
		this.formGroup.addControl(this.ACC_NAME, new FormControl(null));
	}

	public close(): void {
		this.modalRef.close(this.formGroup.value[this.ACC_NAME]);
	}
}
