import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";
import { combineLatest, Subject, takeUntil } from "rxjs";
import { CompanyProfileSelectors, StepperSelectors } from "../app/AppStateManagement";
import { IRouteStepData } from "src/app/Models/Interfaces";
import { CompanyProfile, OnboardCompanyProgress } from "../app/Models";
import { RouteStepDataService } from "../app/Modules/COT-Module/Services";
import { LsStep } from "../Elements/stepper/limestone-element-stepper.component";

@Injectable({
	providedIn: "root"
})
export class ContinueApplicationService {
	protected componentTeardown$ = new Subject();
	public customerSetupStep?: number;
	public routeStepData?: Map<string, IRouteStepData> = new Map<string, IRouteStepData>();
	public steps?: LsStep[] = [];
	public companyProfile?: CompanyProfile;
	public ocp?: OnboardCompanyProgress;
	constructor(
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		private stepperSelectors: StepperSelectors,
		private routeStepDataService: RouteStepDataService
	) {
		this.init();
	}

	public init() {
		combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress),
			this.store.select(this.stepperSelectors.selectState)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([cp, ocp]) => !!cp && !!ocp),
				map(([cp, ocp, stepperState]) => {
					this.companyProfile = cp;
					this.ocp = ocp;
					this.routeStepData = stepperState.routeStepData;
					this.steps = stepperState.steps;
					this.customerSetupStep = stepperState.steps.findIndex((step) => step.label === "Customer Setup");
				})
			)
			.subscribe();
	}

	continueApplication() {
		if (!this.ocp) {
			throw new Error("OnboardCompanyProgress is missing.");
		}
		if (this.customerSetupStep !== undefined && this.ocp?.currentStep === this.customerSetupStep) {
			this.routeStepDataService.navByPath(this.steps![this.customerSetupStep].route);
		} else {
			const routePath: string = this.getPathString(this.ocp!.currentStep!, this.ocp!.currentSubStep!);
			if (routePath) {
				this.routeStepDataService.navByPath(routePath);
			}
		}
	}

	continueCustomerSetup(customerId: number, customerSubStep: number) {
		// since we are continuing a specific customer,
		// start them at the first screen for a specific customer
		// if for some reason this customer has not progressed
		// to the first customer screen yet.
		if (customerSubStep < 3) customerSubStep = 3;

		const routePath: string = this.getPathString(this.customerSetupStep!, customerSubStep);
		if (routePath) {
			this.routeStepDataService.navByPath(
				routePath,
				new Map<string, string | number>([["relationshipCompanyId", customerId]])
			);
		}
	}

	private getPathString(step: number, substep: number) {
		let retString;

		for (const [key, stepData] of this.routeStepData!) {
			if (stepData.step === step && stepData.substep === substep) {
				retString = key;
				break;
			}
		}
		if (!retString) {
			throw new Error(`No route path found for step: ${step} and substep: ${substep}`);
		}
		return retString;
	}
}
