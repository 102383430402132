import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { CompanyAddress, CompanyProfile, Phone } from "src/app/Models";
import { AddressType, GenericLookup } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-company-profile",
	templateUrl: "./company-profile.component.html",
	styleUrls: ["./company-profile.component.scss"]
})
export class CompanyProfileComponent implements OnDestroy, AfterViewInit {
	private _companyProfile?: CompanyProfile;
	public primaryAddress?: CompanyAddress;
	public static FORM_CONTROL_NAME = "companyProfile";

	@Input() get companyProfile() {
		return this._companyProfile;
	}

	set companyProfile(cp) {
		this._companyProfile = cp;
		this.primaryAddress = this.companyProfile?.addresses?.find((a) => a.addressType?.id === AddressType.PRIMARY);
	}

	private componentTeardown$ = new Subject();
	public LEGAL_NAME = "legalName";
	public PHONE = "phone";
	public PRIMARY = "primary";

	public companyProfileForm: FormGroup = new FormGroup({});

	constructor(private fgd: FormGroupDirective) {
		const phone = new Phone(
			this.companyProfile?.phoneCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt,
			this.companyProfile?.countryCode
		);
		this.companyProfileForm = new FormGroup({
			legalName: new FormControl(this.companyProfile?.legalBusinessName, Validators.required),
			phone: new FormControl(phone, Validators.required),
			primary: new FormControl(this.primaryAddress?.address, Validators.required)
		});
	}
	ngAfterViewInit(): void {
		this.setFormControls();
	}

	setFormControls() {
		const phone = new Phone(
			this.companyProfile?.phoneCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt,
			this.companyProfile?.countryCode
		);
		this.companyProfileForm.patchValue(
			{
				legalName: this.companyProfile?.legalBusinessName,
				primary: this.primaryAddress?.address,
				phone: phone
			},
			{ onlySelf: true, emitEvent: false }
		);
		if (this.fgd.form.get(CompanyProfileComponent.FORM_CONTROL_NAME) === null) {
			this.fgd.form?.addControl(CompanyProfileComponent.FORM_CONTROL_NAME, this.companyProfileForm, {
				emitEvent: false
			});
		}
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	updateCompanyProfile(companyProfile: CompanyProfile): void {
		const formValue = this.companyProfileForm.value;
		companyProfile.legalBusinessName = formValue.legalName;
		const phone: Phone = formValue.phone;
		companyProfile.phoneCode = phone.phoneCode;
		companyProfile.phoneNumber = phone.number;
		companyProfile.phoneExt = phone.extension;
		companyProfile.countryCode = phone.countryCode;

		companyProfile.addresses = [];
		const pa = formValue.primary;
		if (pa) {
			pa.id = this.primaryAddress?.address?.id;
			const primaryAddress = new CompanyAddress(
				this.primaryAddress?.companyId,
				new GenericLookup<string>(AddressType.PRIMARY, "Primary Office / Headquarters", true),
				true,
				pa
			);
			companyProfile.addresses?.push(primaryAddress);
		}
	}
}
