import { Attachment } from "./Attachment";

export class EmailMessage {
	recordId?: string;
	parentId?: string;
	attachments?: Attachment[];
	fromAddress?: string;
	toAddress?: string;
	hasAttachment?: boolean;
	htmlBody?: string;
	messageDate?: Date;
	textBody?: string;
	threadId?: string;
	replyToId?: string;

	private _regex = /width="([^"]*)"/g;

	constructor(
		recordId?: string,
		fromAddress?: string,
		toAddress?: string,
		parentId?: string,
		hasAttachment?: boolean,
		htmlBody?: string,
		messageDateTime?: Date,
		textBody?: string,
		attachments?: Attachment[],
		threadId?: string,
		replyToId?: string
	) {
		this.recordId = recordId;
		this.fromAddress = fromAddress;
		this.toAddress = toAddress;
		this.parentId = parentId;
		this.hasAttachment = hasAttachment;
		this.htmlBody = htmlBody?.replace(this._regex, "");
		this.messageDate = messageDateTime;
		this.textBody = textBody;
		this.attachments = attachments?.map(
			(attachment: any) =>
				new Attachment(
					attachment.id,
					undefined,
					attachment.bodyLength,
					attachment.contentType,
					attachment.name,
					attachment.parentId,
					undefined,
					attachment.blobPath
				)
		);
		this.threadId = threadId;
		this.replyToId = replyToId;
	}
}
