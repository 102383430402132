import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Breadcrumb } from "../../Models/Interfaces/Breadcrumb";

@Component({
	selector: "ls-questionnaire-toggle",
	templateUrl: "./questionnaire-toggle.component.html",
	styleUrls: ["./questionnaire-toggle.component.scss"]
})
export class QuestionnaireToggleComponent {
	@Input() enabled = false;
	@Input() header?: string;
	@Input() breadcrumbs?: Array<Breadcrumb> = [];
	@Input() toggledState?: string;
	@Input() toggleLabel?: string;

	@Output() enabledChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}
}
