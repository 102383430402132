import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-manual",
	templateUrl: "./manual.component.html",
	styleUrls: ["./manual.component.scss"]
})
export class ManualComponent extends RouteStepDataBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	navToAccounting() {
		this.nav(1);
	}

	continue() {
		this.nav(0, new Map<string, string | number>([["relationshipCompanyId", "new"]]));
	}
}
