<ls-questionnaire-toggle #questionnaireForm
        toggleLabel="autoXcel"
        [(enabled)] = isAutoXcel
        [toggledState] = "toggledState"
        header="Would you like to automatically accelerate payment on all your invoices to this customer?"
        [breadcrumbs]="breadcrumbs"
        (buttonClicked)="continue()">
  <p class="l ls-mb-0">Also known as <a class="ls-hyperlink" href="https://raistone.com/help/autoxcel/" target="_blank">autoXcel</a>, this feature allows you to choose to have all your customer invoices accelerated automatically instead of on an invoice-by-invoice basis.</p>
  <p class="l ls-mb-0">If you disable autoXcel, you or someone at your business will need to log in to our platform to manually select each invoice you’d like to get financed.</p>
</ls-questionnaire-toggle>
  