<form class="d-flex flex-column ls-mb-11" [formGroup]="form">
  <h1 class="l ls-mb-8">Tell us more about your financing needs</h1>
  <div class="d-flex flex-column ls-mb-8">
    <p class="title m ls-mb-3">How quickly are you looking to obtain financing?</p>
    <div class="d-flex align-items-center ls-mb-3" *ngFor="let urgency of urgencies">
      <input mdbRadio [formControlName]="URGENCY" class="radio" type="radio" name="urgency" id="{{urgency.id}}" value="{{urgency.id}}"/>
      <label class="xl" for="{{urgency.id}}">{{ urgency.name }}</label>
    </div>
  </div>
  <div class="d-flex flex-column">
    <p class="title m ls-mb-3">What’s the purpose of requesting this financing?</p>
    <div class="d-flex ls-mb-3" *ngFor="let purpose of purposes">
      <input [formControlName]="purpose.id!"
        mdbCheckbox
        class="form-check-input ls-mr-3"
        type="checkbox"
        value="{{purpose.id}}"
        id="{{purpose.id}}"
      />
      <label class="form-check-label xl" for="{{purpose.id}}">{{ purpose.name }}
        <ls-tooltip *ngIf="purpose.id === mcr" [position]="'top'">
          <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
          <div content>
            <p class="m ls-mb-0 text-wrap">Credit risk increases when a customer is unable to pay for the goods and services a business provides them. One way to manage that risk is for businesses to finance their receivables.</p>
          </div>
        </ls-tooltip>
      </label>
    </div>
  </div>
</form>

<button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="isInvalid()" #lsNextBtn>
  Next <span class="material-icons md-18"> arrow_forward</span>
</button>