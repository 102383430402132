import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { CompanyDocumentType } from "../../../../Models/Enums";
import { CompanyDocumentState } from "./company-document-reducer";
import copy from "fast-copy";

@Injectable()
export class CompanyDocumentSelectors {
	appState = (state: any) => state.companyDocument;
	selectDocumentsFor = (documentType: CompanyDocumentType) =>
		createSelector(this.appState, (state: CompanyDocumentState) => {
			switch (documentType) {
				case CompanyDocumentType.MSA:
					return copy(state.MSA_Documents);
				case CompanyDocumentType.IVH:
					return copy(state.IVH_Documents);
				case CompanyDocumentType.RMH:
					return copy(state.RMH_Documents);
				case CompanyDocumentType.TPI:
					return copy(state.TPI_Documents);
				case CompanyDocumentType.EBR:
					return copy(state.EBR_Documents);
				case CompanyDocumentType.VDC:
					return copy(state.VDC_Documents);
			}
		});
}
