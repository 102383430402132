import { Component, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { UserInfo } from "src/app/Models";

@Component({
	selector: "ls-user-profile",
	templateUrl: "./user-profile.component.html",
	styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent implements OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	public USER = "user";
	public form: FormGroup = new FormGroup({});
	private _userInfo?: UserInfo;
	public static FORM_CONTROL_NAME = "user-info";

	@Input() get userInfo() {
		return this._userInfo;
	}

	set userInfo(ui) {
		this._userInfo = ui;
		this.setFormControls();
	}

	constructor(private fgd: FormGroupDirective) {
		this.form = new FormGroup({
			user: new FormControl(this.userInfo, Validators.required)
		});
	}

	setFormControls() {
		this.form.patchValue(
			{
				user: this.userInfo
			},
			{ onlySelf: true, emitEvent: false }
		);

		if (this.fgd.form.get(UserProfileComponent.FORM_CONTROL_NAME) === null) {
			this.fgd.form?.addControl(UserProfileComponent.FORM_CONTROL_NAME, this.form, {
				emitEvent: false
			});
		}
	}

	public ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public updateUserInfo(userInfo: UserInfo) {
		const formValue = this.form.get(this.USER)?.value;
		userInfo.firstName = formValue.firstName;
		userInfo.lastName = formValue.lastName;
		userInfo.phone = formValue.phone;
		userInfo.emailAddress = formValue.email;
	}
}
