import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { ConnectFinancialsPersuasionComponent } from "../../../Modals";
import { take } from "rxjs/operators";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { ModalActions } from "../../../../../Core";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { Store } from "@ngrx/store";
import { RouteStepDataService } from "../../../Services";
import { DisplayConfig, HtmlContent, Layout } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-connect-financials-intro",
	templateUrl: "./connect-financials-intro.component.html",
	styleUrls: ["./connect-financials-intro.component.scss"]
})
export class ConnectFinancialsIntroComponent extends RouteStepDataBaseComponent {
	constructor(
		public store: Store,
		public router: Router,
		private dialog: MdbModalService,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		this.content = this.activeRouteData?.htmlContent ?? new HtmlContent({ layout: this.DefaultLayout });
	}

	onClick() {
		this.nav();
	}

	openModal() {
		this.dialog
			.open(ConnectFinancialsPersuasionComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true
			})
			.onClose.pipe(
				take(1),
				map((action: ModalActions) => {
					if (action === ModalActions.PRIMARY) {
						this.nav();
					} else {
						this.nav(1);
					}
				})
			)
			.subscribe();
	}

	DefaultLayout = new Map<string, Layout>([
		[
			"header",
			new Layout(
				new Map<string, DisplayConfig>([
					["h1", new DisplayConfig("We’ll need some financial details", TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			"body",
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"p1",
						new DisplayConfig(
							"Having current financial and customer data is necessary for our team to streamline the onboarding and underwriting process. To make this process easier and quicker, we can connect directly and securely to your accounting system and business bank account(s).",
							TextStyle.BODY_LARGE
						)
					],
					[
						"p2",
						new DisplayConfig(
							'Direct connection allows us to simplify the application process, filling in required information on your behalf. To learn more about connecting your accounting system and business bank account(s), <a class="ls-hyperlink" href="https://raistone.com/help/connecting-your-accounts/" target="_blank">please see our FAQ page</a>.',
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		],
		[
			"buttons",
			new Layout(
				new Map<string, DisplayConfig>([
					["next", new DisplayConfig("Connect my accounting", TextStyle.BUTTON_LARGE)],
					["skip", new DisplayConfig("I'll do it later", TextStyle.BODY_LARGE_BOLD)]
				])
			)
		]
	]);
}
