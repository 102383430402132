import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import copy from "fast-copy";
import { SalesforceSupportState } from "./salesforce-support-reducer";

@Injectable()
export class SalesforceSupportSelectors {
	appState = (state: any) => state.salesforceSupport;

	selectCase = createSelector(this.appState, (state: SalesforceSupportState) => copy(state.supportCase));

	selectCases = createSelector(this.appState, (state: SalesforceSupportState) => copy(state.cases));
}
