import { Store } from "@ngrx/store";
import { Observable, filter, map, take } from "rxjs";
import { Injectable } from "@angular/core";
import { AppSelectors, CompanyProfileActions, CompanyProfileSelectors } from "../AppStateManagement";

@Injectable()
export class SettingsResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors,
		private companyProfileSelectors: CompanyProfileSelectors
	) {}

	private companyProfile$ = this.store.select(this.companyProfileSelectors.selectCompanyProfile).pipe(
		filter((c) => !!c),
		take(1)
	);

	resolve(): Observable<any | undefined> {
		this.initPrefetchData();
		return this.companyProfile$;
	}

	initPrefetchData(): void {
		this.store
			.select(this.appSelectors.selectUserRoles)
			.pipe(
				take(1),
				filter((userRole) => !!userRole && userRole.roles.length > 0),
				map((userRole) => {
					this.store.dispatch(CompanyProfileActions.getCompanyProfile({ companyId: userRole!.roles[0].companyId }));
				})
			)
			.subscribe();
	}
}
