<div class="d-flex flex-column">
    <form class="d-flex flex-column ls-mb-6" [formGroup]="form">
        <ls-text extraClasses="no-text-transform ls-mb-6" [displayConfig]="config(HEADER,'hl1')"></ls-text>
        <div class="d-flex flex-column">

            <ls-text extraClasses="no-text-transform ls-mb-3" [displayConfig]="config(PHONE,'q1')"></ls-text>
            <ls-phone-form-control *ngIf="display(PHONE)" class="phone-address-form ls-mb-6"
                [formControlName]="PHONE"></ls-phone-form-control>

            <div *ngIf="display(PRIMARY)" class="d-flex flex-column ls-mb-6">
                <ls-text extraClasses="no-text-transform ls-mb-2" [displayConfig]="config(PRIMARY,'q2')"></ls-text>
                <ls-text extraClasses="ls-mb-3" [displayConfig]="config(PRIMARY,'q2-p1')"></ls-text>
                <ls-address-form-control class="phone-address-form"
                    [formControlName]="PRIMARY"></ls-address-form-control>
            </div>
        </div>
    </form>
    <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="isInvalid()"
        #lsNextBtn>
        <ls-text [displayConfig]="config(BUTTONS,'next')"></ls-text><span class="material-icons md-18"> arrow_forward</span>
    </button>
</div>