import {
	RouterEvent,
	ActivationEnd,
	ActivationStart,
	ChildActivationEnd,
	ChildActivationStart,
	GuardsCheckEnd,
	GuardsCheckStart,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationSkipped,
	NavigationStart,
	ResolveEnd,
	ResolveStart,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router,
	RoutesRecognized,
	Scroll
} from "@angular/router";
export class RouteLogger {
	events: RouterEvent[] = [];
	lastUrl?: string;
	constructor(private router: Router) {
		this.handleLogging();
	}
	handleLogging() {
		this.router.events.subscribe((ev) => {
			const routerEvent = ev as RouterEvent;
			if (!routerEvent) return;
			const name = this.getName(routerEvent);
			const nextUrl = (ev as any)["urlAfterRedirects"] ?? routerEvent.url;
			if (this.lastUrl !== nextUrl && nextUrl !== undefined) {
				console.log(`${name}=>${nextUrl}`);
				this.lastUrl = nextUrl;
			} else {
				console.log(name);
			}
		});
	}
	getName(ev: RouterEvent) {
		let name = "";
		if (ev instanceof NavigationStart) {
			name = "NavigationStart";
		} else if (ev instanceof RouteConfigLoadStart) {
			name = "RouteConfigLoadStart";
		} else if (ev instanceof RouteConfigLoadEnd) {
			name = "RouteConfigLoadEnd";
		} else if (ev instanceof RoutesRecognized) {
			name = "RoutesRecognized";
		} else if (ev instanceof GuardsCheckStart) {
			name = "GuardsCheckStart";
		} else if (ev instanceof ChildActivationStart) {
			name = "ChildActivationStart";
		} else if (ev instanceof ActivationStart) {
			name = "ActivationStart";
		} else if (ev instanceof GuardsCheckEnd) {
			const guard = ev as GuardsCheckEnd;
			if (!guard.shouldActivate) console.log(`GuardCheckEnd=>shouldActivate=false`);
			name = "GuardsCheckEnd";
		} else if (ev instanceof ResolveStart) {
			name = "ResolveStart";
		} else if (ev instanceof ResolveEnd) {
			name = "ResolveEnd";
		} else if (ev instanceof ChildActivationEnd) {
			name = "ChildActivationEnd";
		} else if (ev instanceof ActivationEnd) {
			name = "ActivationEnd";
		} else if (ev instanceof NavigationEnd) {
			name = "NavigationEnd";
			console.log(`url=>${this.lastUrl}`);
			console.log("-----------------------------");
		} else if (ev instanceof NavigationCancel) {
			name = "NavigationCancel";
			const can = ev as NavigationCancel;
			if (can.reason) console.log(`NavigationCancel=>${can.reason}  ${can.code}`);
		} else if (ev instanceof NavigationError) {
			const error = ev as NavigationError;
			if (error.error) console.log(`NavigationCancel=>${error.error}`);
			name = "NavigationError";
		} else if (ev instanceof NavigationSkipped) {
			const skip = ev as NavigationSkipped;
			if (skip.reason) console.log(`NavigationCancel=>${skip.reason}`);
			name = "NavigationSkipped";
		} else if (ev instanceof Scroll) {
			name = "Scroll";
		}
		return name;
	}
}
