import {
	AfterViewInit,
	Component,
	OnDestroy,
	OnInit,
	SecurityContext,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import { FormControl, FormControlStatus, FormGroup, Validators } from "@angular/forms";
import { debounce, filter, map, takeUntil } from "rxjs/operators";
import { combineLatest, interval, Subject, take } from "rxjs";
import { Store } from "@ngrx/store";
import { AccountCreationActions } from "../../../OnboardingStateManagement";
import { AccountSubmissionForm, B2CQueryParams, CompanyProfile, EmailValidation, Phone } from "../../../../../Models";
import { Actions, ofType } from "@ngrx/effects";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LocalStorageService } from "../../../../../../Utils";
import { MsalService } from "@azure/msal-angular";
import { RedirectRequest } from "@azure/msal-browser";
import { environment } from "../../../../../../environments/environment";
import { CompanyProfileActions } from "../../../../../AppStateManagement";
import { ValidationActions } from "src/app/Modules/COT-Module/OnboardingStateManagement/Validation/validation-actions";
import { DomSanitizer } from "@angular/platform-browser";
import { Flow } from "../../../../../Models/Enums";
import {
	CountryCode,
	FeatureFlags,
	FeatureFlagSelectors,
	GenericLookup,
	GenericSelectors,
	LookupTables
} from "@limestone/ls-shared-modules";
import { PhoneFormControlComponent } from "src/app/Core";
import { AnnualRevenueRange } from "src/app/Models/Enums/AnnualRevenueRange";
import { FlowService } from "../../../../../../services/flow.service";

@Component({
	selector: "ls-ca-info",
	templateUrl: "./create-account-info.component.html",
	styleUrls: ["./create-account-info.component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class CreateAccountInfoComponent implements OnInit, OnDestroy, AfterViewInit {
	BUSINESS_NAME_CONTROL = "businessName";
	ANNUAL_REVENUE_CONTROL = "annualRevenue";
	FIRST_NAME_CONTROL = "firstName";
	LAST_NAME_CONTROL = "lastName";
	TITLE_CONTROL = "title";
	PHONE_CONTROL = "phone";
	BUSINESS_EMAIL_CONTROL = "businessEmail";
	TERMS_AGREEMENT_CONTROL = "termsAgreement";
	public caForm: FormGroup = new FormGroup({});
	continueDisabled = true;
	isDuplicate = false;
	emailValidationResult?: string;
	formError = "";
	static id = "create-accounts";
	flowId: Flow = Flow.ARF_PG;
	isSCF = false;
	newCustomerInitialization?: string;
	debtorCompanyId?: number;
	public companyProfile?: CompanyProfile;
	public is_Tessarion_ARFProductPicker_2024717_enabled?: boolean;
	public annualRevenueRanges?: GenericLookup<string>[];
	private annualRevSelect?: HTMLElement;

	private componentTeardown$ = new Subject();

	@ViewChild("phoneComponent") phoneComponent!: PhoneFormControlComponent;

	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private activatedRoute: ActivatedRoute,
		private localStorageService: LocalStorageService,
		private authService: MsalService,
		public genericSelectors: GenericSelectors,
		private sanitizer: DomSanitizer,
		private featureFlagSelectors: FeatureFlagSelectors,
		private flowService: FlowService,
		private router: Router
	) {}

	ngOnInit() {
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.AnnualRevenueRange)),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([arr, ff]) => !!arr && arr.length > 0 && !!ff),
				takeUntil(this.componentTeardown$),
				map(([arr, ff]) => {
					this.annualRevenueRanges = arr!;
					this.is_Tessarion_ARFProductPicker_2024717_enabled = ff?.find(
						(flag) => flag.id === FeatureFlags.Tessarion_ARFProductPicker_2024717
					)?.enabled;
					if (!this.setScfParams()) {
						this.setFlowId();
					}
				})
			)
			.subscribe();

		const elements = document.getElementsByClassName("form-control select-input");
		if (elements.length > 0 && (elements[0] as HTMLElement).tagName === "INPUT") {
			this.annualRevSelect = elements[0] as HTMLElement;
		}

		this.caForm = new FormGroup({
			businessName: new FormControl("", Validators.required),
			annualRevenue: new FormControl(null, Validators.required),
			firstName: new FormControl("", Validators.required),
			lastName: new FormControl("", Validators.required),
			title: new FormControl(null, Validators.required),
			phone: new FormControl(new Phone(), Validators.required),
			businessEmail: new FormControl("", Validators.required),
			termsAgreement: new FormControl(false, Validators.requiredTrue)
		});

		this.caForm.statusChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				map((status: FormControlStatus) => {
					this.continueDisabled = status === "INVALID";
				})
			)
			.subscribe();

		this.caForm
			.get(this.BUSINESS_EMAIL_CONTROL)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				debounce(() => interval(250)),
				filter((val: string) => {
					const regex = new RegExp(/.+@.+\..{2,}/g);
					return !!val && val.length >= 6 && regex.test(val);
				}),
				map(() => this.validateEmail())
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(ValidationActions.validationFailure),
				filter((act) => act.id === CreateAccountInfoComponent.id),
				map((action) => {
					const errorMap = action.result!.errors;
					switch (action.validationType) {
						case "Email": {
							if (errorMap.has("Email"))
								this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.setErrors({ error: errorMap.get("Email") });
							let emailErr = "";
							errorMap.forEach((errArray) => errArray.map((e) => (emailErr += e)));
							this.emailValidationResult = emailErr;
							break;
						}
						case `${AccountCreationActions.ACCOUNT_SUBMISSION}/SAVE`:
							if (errorMap.has("FirstName")) {
								let fNameErr = "";
								errorMap.get("FirstName")!.forEach((err) => (fNameErr += ` ${err}`));
								this.caForm.get(this.FIRST_NAME_CONTROL)?.setErrors({ error: fNameErr });
							}
							if (errorMap.has("LastName")) {
								let lNameErr = "";
								errorMap.get("LastName")!.forEach((err) => (lNameErr += ` ${err}`));
								this.caForm.get(this.LAST_NAME_CONTROL)?.setErrors({ error: lNameErr });
							}
							if (errorMap.has("Title")) {
								let titleErr = "";
								errorMap.get("Title")!.forEach((err) => (titleErr += ` ${err}`));
								this.caForm.get(this.TITLE_CONTROL)?.setErrors({ error: titleErr });
							}
							if (errorMap.has("ApproximateAnnualRevenue")) {
								let revenueErr = "";
								errorMap.get("ApproximateAnnualRevenue")!.forEach((err) => (revenueErr += ` ${err}`));
								this.caForm.get(this.ANNUAL_REVENUE_CONTROL)?.setErrors({ error: revenueErr });
							}
							if (errorMap.has("Duplicate Account")) {
								this.formError =
									this.sanitizer.sanitize(SecurityContext.HTML, errorMap.get("Duplicate Account")![0]) ?? "";
								this.isDuplicate = true;
								this.observeUntilChanged();
							}
							break;
						default:
							throw new Error("Unknown validation type.");
					}
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.setCompanyProfile),
				map((action) => {
					const cp: CompanyProfile = action.companyProfile;
					this.loginRedirect(false, cp.companyId, cp.users![0].emailAddress);
				})
			)
			.subscribe();

		this.checkForErrorState();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	ngAfterViewInit(): void {
		this.initializeFormData();
	}

	public setFlowId() {
		this.flowId = this.flowService.getFlowIdFromRouteOrDefault(this.activatedRoute.snapshot.pathFromRoot);
	}

	// Initialize form data when coming from product picker - ALD-1291
	initializeFormData() {
		if (!this.is_Tessarion_ARFProductPicker_2024717_enabled) return;

		const params = this.activatedRoute.snapshot.queryParams;
		if (Object.keys(params).length === 0) return;
		let touched = false;
		if (params["company"] !== undefined) {
			this.caForm.get(this.BUSINESS_NAME_CONTROL)?.patchValue(params["company"]);
			touched = true;
		}
		if (params["first_name"] !== undefined) {
			this.caForm.get(this.FIRST_NAME_CONTROL)?.patchValue(params["first_name"]);
			touched = true;
		}
		if (params["last_name"] !== undefined) {
			this.caForm.get(this.LAST_NAME_CONTROL)?.patchValue(params["last_name"]);
			touched = true;
		}
		if (params["email"] !== undefined) {
			this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.patchValue(params["email"]);
			touched = true;
		}
		if (params["title"] !== undefined) {
			this.caForm.get(this.TITLE_CONTROL)?.patchValue(params["title"]);
			touched = true;
		}
		if (params["phone"] !== undefined) {
			const number = params["phone"]?.replace(/\D/g, "") ?? "";
			if (number != "") {
				const phone = new Phone("1", number, "", CountryCode.US);
				this.caForm.get(this.PHONE_CONTROL)?.patchValue(phone);
				this.phoneComponent?.markAsDirty();
				touched = true;
			}
		}
		if (params["annual_revenue"] !== undefined) {
			this.caForm.get(this.ANNUAL_REVENUE_CONTROL)?.patchValue(this.getAnnualRevenue(params["annual_revenue"]));
			touched = true;
		}

		if (touched) {
			this.caForm.updateValueAndValidity();
		}
	}

	setScfParams(): boolean {
		const params = this.activatedRoute.snapshot.queryParams;
		if (params["invitationCode"]) {
			this.newCustomerInitialization = "?invitationCode=" + params["invitationCode"];
			const invitationCode = atob(params["invitationCode"]);
			const [debtorCompanyId, flow] = invitationCode.split(":");
			this.isSCF = flow === Flow.SCF_V1;
			if (this.flowId !== flow) {
				this.router.navigate([`../../${flow}/info`], {
					queryParamsHandling: "merge",
					replaceUrl: true,
					relativeTo: this.activatedRoute
				});
			}
			this.flowId = flow as Flow;
			this.debtorCompanyId = parseInt(debtorCompanyId, 10);
			return true;
		}
		return false;
	}

	checkForErrorState() {
		this.activatedRoute.queryParams
			.pipe(
				take(1),
				map((params: Params) => {
					if (params["error"]) {
						this.formError =
							"We already have an account associated with this email. Please try with a different email or <strong>log in</strong>.";
					}
				})
			)
			.subscribe();
	}

	controlHasError(controlName: string): boolean {
		if (this.caForm.get(controlName)) {
			return (
				this.caForm.get(controlName)!.invalid &&
				(this.caForm.get(controlName)!.dirty || this.caForm.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		return this.caForm.get(controlName)?.errors!["error"];
	}

	submit() {
		const phone: Phone = this.caForm.get(this.PHONE_CONTROL)?.value;
		const account = new AccountSubmissionForm(
			this.caForm.get(this.BUSINESS_NAME_CONTROL)?.value,
			this.caForm.get(this.ANNUAL_REVENUE_CONTROL)?.value,
			this.caForm.get(this.FIRST_NAME_CONTROL)?.value,
			this.caForm.get(this.LAST_NAME_CONTROL)?.value,
			this.caForm.get(this.TITLE_CONTROL)?.value,
			phone.phoneCode,
			phone.countryCode,
			phone.number,
			phone.extension,
			this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.value,
			this.caForm.get(this.TERMS_AGREEMENT_CONTROL)?.value
		);
		const params = this.activatedRoute.snapshot.queryParams;
		if (params["utm_campaign"] !== undefined) {
			account.utm_campaign = params["utm_campaign"];
		}
		if (params["utm_medium"] !== undefined) {
			account.utm_medium = params["utm_medium"];
		}
		if (params["utm_source"] !== undefined) {
			account.utm_source = params["utm_source"];
		}
		account.flowId = this.flowId;
		account.debtorCompanyId = this.debtorCompanyId;
		this.store.dispatch(AccountCreationActions.createAccount({ account }));
	}

	loginRedirect(isLogin = true, companyId?: number, email?: string) {
		let url = `/onboarding/${this.flowId}`;
		this.localStorageService.setB2CSignupState(isLogin);
		if (this.isSCF) {
			url = url + "/invitation" + this.newCustomerInitialization;
		} else {
			url = url + "/begin-application";
		}
		const rr: RedirectRequest = {
			scopes: environment.scopes,
			extraQueryParameters: new B2CQueryParams(
				companyId,
				email,
				this.localStorageService.getB2CSignupState(),
				this.flowId
			).toParamsObject(),
			redirectStartPage: window.origin + url
		};
		this.authService.loginRedirect(rr);
	}

	validateEmail() {
		const emailValidation = new EmailValidation(this.caForm.get(this.BUSINESS_EMAIL_CONTROL)?.value);
		this.store.dispatch(ValidationActions.validateEmail({ id: CreateAccountInfoComponent.id, email: emailValidation }));
	}

	observeUntilChanged() {
		const valueChanged$ = new Subject();
		this.caForm
			.get(this.BUSINESS_NAME_CONTROL)!
			.valueChanges.pipe(
				takeUntil(valueChanged$),
				map(() => {
					this.isDuplicate = false;
					valueChanged$.next(null);
					valueChanged$.complete();
				})
			)
			.subscribe();

		this.caForm
			.get(this.BUSINESS_EMAIL_CONTROL)!
			.valueChanges.pipe(
				takeUntil(valueChanged$),
				map(() => {
					this.isDuplicate = false;
					valueChanged$.next(null);
					valueChanged$.complete();
				})
			)
			.subscribe();
	}

	handleClose() {
		setTimeout(() => this.annualRevSelect?.blur(), 1);
	}

	public getAnnualRevenue(revenue: string) {
		const dollarValue = parseInt(revenue);
		if (isNaN(dollarValue)) return undefined;
		switch (true) {
			case dollarValue < 3000000:
				return AnnualRevenueRange.LT_THREE;
			case 3000000 <= dollarValue && dollarValue <= 10000000:
				return AnnualRevenueRange.BTW_3_TEN;
			case 10000000 < dollarValue && dollarValue <= 25000000:
				return AnnualRevenueRange.BTW_TEN_25;
			case 25000000 < dollarValue && dollarValue <= 500000000:
				return AnnualRevenueRange.BTW_25_500;
			case dollarValue > 500000000:
				return AnnualRevenueRange.GT_FIVEH;
			default:
				return undefined;
		}
	}
}
