<ng-container [ngSwitch]="displayConfig?.textStyle" *ngIf="displayConfig?.display">
    <!-- HEADER -->
    <h1 [id]="id" *ngSwitchCase="TextStyle.HEADLINE_LARGE" [class]="'l '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </h1>
    <h2 [id]="id" *ngSwitchCase="TextStyle.HEADLINE_MEDIUM" [class]="'l '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </h2>
    <h3 [id]="id" *ngSwitchCase="TextStyle.HEADLINE_SMALL" [class]="extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </h3>
    <!-- END HEADER -->
    <!-- TITLE -->
    <p [id]="id" *ngSwitchCase="TextStyle.TITLE_LARGE" [class]="'title l '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.TITLE_MEDIUM" [class]="'title m '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.TITLE_SMALL" [class]="'title s '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <!-- END TITLE -->
    <!-- LABEL -->
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_LARGE" [class]="'xl '+ extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_LARGE" [class]="'l '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_LARGE_BOLD" [class]="'l-bold '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_MEDIUM" [class]="'m '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_MEDIUM_BOLD" [class]="'m-bold '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_MEDIUM_CAPS" [class]="'m-caps '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_SMALL_CAPS" [class]="'s-caps '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_SMALL" [class]="'s '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_SMALL" [class]="'xs '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_SMALL_BOLD" [class]="'xs-bold '+extraClasses"
        [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_SMALL_BADGE" [class]="'xs-badge '+extraClasses"
        [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <label [id]="id" *ngSwitchCase="TextStyle.LABEL_EXTRA_EXTRA_SMALL" [class]="'xxs '+extraClasses" [for]="forLabel">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </label>
    <!-- END LABEL -->
    <!-- BODY -->
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_EXTRA_LARGE" [class]="'xl '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_LARGE" [class]="'l '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_LARGE_BOLD" [class]="'l-bold '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_MEDIUM" [class]="'m '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_MEDIUM_SEMI_BOLD" [class]="'m-bold '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_SMALL" [class]="'s '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_SMALL_SEMI_BOLD" [class]="'s-bold '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <p [id]="id" *ngSwitchCase="TextStyle.BODY_EXTRA_SMALL" [class]="'xs '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
    <!-- END BODY -->
    <!-- BUTTON -->
    <span [id]="id" *ngSwitchCase="TextStyle.BUTTON_LARGE" [class]="'btn-lg ' +extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </span>
    <span [id]="id" *ngSwitchCase="TextStyle.BUTTON_SMALL" [class]="'btn-sm ' +extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </span>
    <!-- END BUTTON -->
    <!--DEFAULT-->
    <p [id]="id" *ngSwitchDefault [class]="'l '+extraClasses">
        <span [innerHTML]="displayConfig?.text"></span>
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p>
</ng-container>
<ng-template #content>
    <ng-content></ng-content>
</ng-template>