import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { CompanyProfileRelationshipResolverService } from "../Modules/COT-Module/Services";

@Injectable()
export class CompanyProfileRelationshipDataResolver {
	constructor(private companyProfileRelationshipResolverService: CompanyProfileRelationshipResolverService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyProfileRelationship> {
		return this.companyProfileRelationshipResolverService.resolve(route, state);
	}
}
