import { Component, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship, CompanyProfile, DisplayConfig, Layout } from "src/app/Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { SharedText } from "../shared-text";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-relationship",
	templateUrl: "./customer-relationship.component.html",
	styleUrls: ["./customer-relationship.component.scss"]
})
export class CustomerRelationshipComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	RELATIONSHIP_DURATION = "relationshipDuration";
	PAYMENT_TERMS = "paymentTerms";
	customerRelationshipDurationForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;
	relationshipDurations: GenericLookup<string>[] = [];
	companyProfile?: CompanyProfile;
	max = 365;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	public initData(): void {
		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.RelationshipDuration))
			.pipe(
				filter((rd) => !!rd && rd.length > 0),
				takeUntil(this.componentTeardown$),
				map((rd) => (this.relationshipDurations = rd!))
			)
			.subscribe();

		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.setFormControls();
				})
			)
			.subscribe();
	}

	setFormControls(): void {
		this.setupControl(
			this.customerRelationshipDurationForm,
			this.RELATIONSHIP_DURATION,
			this.companyProfileRelationship?.relationshipDurationId,
			Validators.required
		),
			this.setupControl(
				this.customerRelationshipDurationForm,
				this.PAYMENT_TERMS,
				this.companyProfileRelationship?.relationshipDurationId === undefined &&
					this.companyProfileRelationship?.paymentTerms === 0
					? undefined
					: this.companyProfileRelationship?.paymentTerms,
				[Validators.required, Validators.min(0), Validators.max(this.max), Validators.pattern("^[0-9]+$")]
			);
	}

	continue() {
		if (this.customerRelationshipDurationForm.dirty) {
			const chosenRelationshipDuration = this.customerRelationshipDurationForm.get(this.RELATIONSHIP_DURATION)?.value;
			const chosenPaymentTerms = this.customerRelationshipDurationForm.get(this.PAYMENT_TERMS)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.relationshipDurationId = chosenRelationshipDuration;
			updatedCompanyProfileRelationship.paymentTerms = chosenPaymentTerms;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	isInvalid() {
		return this.customerRelationshipDurationForm.invalid;
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hs1", new DisplayConfig(SharedText.CustomerSetup.tellUsMore, TextStyle.HEADLINE_SMALL)]
				])
			)
		],
		[
			this.RELATIONSHIP_DURATION,
			new Layout(
				new Map<string, DisplayConfig>([
					["q1", new DisplayConfig("How long have you been working with this customer?", TextStyle.TITLE_MEDIUM)]
				])
			)
		],
		[
			this.PAYMENT_TERMS,
			new Layout(
				new Map<string, DisplayConfig>([
					["q2", new DisplayConfig("What are your payment terms with this customer?", TextStyle.TITLE_MEDIUM)]
				])
			)
		]
	]);
}
