import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { FormControl, FormGroupDirective } from "@angular/forms";
import { CurrencyCode } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-range",
	templateUrl: "./range.component.html",
	styleUrls: ["./range.component.scss"]
})
export class LimestoneElementRangeComponent implements AfterViewInit {
	private _currency: CurrencyCode = CurrencyCode.USD;
	@Input() set currency(currency: CurrencyCode) {
		this._currency = currency;
		this.handleInput(false);
	}
	get currency(): CurrencyCode {
		return this._currency;
	}
	private _value = 0;
	private control?: FormControl;
	@Input() set value(value: number) {
		this._value = value !== undefined ? value : 0;
		// Allows the range to be used directly in a form by using it in the <form> and passing a controlName
		if (this.controlName) {
			if (this.fgd.form.get(this.controlName!)) {
				this.fgd.form.get(this.controlName!)!.patchValue(value);
			} else {
				this.control = new FormControl(this.value);
				this.fgd.form.addControl(this.controlName!, this.control, { emitEvent: false });
			}
		}
	}
	get value(): number {
		return this._value;
	}
	@Input() min = 0;
	@Input() max = 10000000;
	@Input() step = 500000;
	@Input() controlName?: string;

	@Output() valueChange = new EventEmitter<number>();

	@ViewChild("rangeVal", { static: true }) rangeValueElement?: ElementRef;
	@ViewChild("range", { static: true }) rangeElement?: ElementRef;
	isInitialized = false;

	constructor(
		private currencyPipe: CurrencyPipe,
		private fgd: FormGroupDirective
	) {}

	ngAfterViewInit() {
		if (!this.isInitialized) {
			this.handleInput();
			this.isInitialized = true;
		}
	}

	handleInput(emit = true) {
		const inputVal: number = +this.rangeElement!.nativeElement!.value!;
		const currencyPipeTransform = this.currencyPipe.transform(inputVal, this.currency, "symbol-narrow", "1.0-0");
		const displayVal = inputVal === this.max ? "&ge; " + currencyPipeTransform : currencyPipeTransform;
		const percent = Number(((inputVal - this.min) * 100) / (this.max - this.min));
		this.rangeValueElement!.nativeElement!.innerHTML = `<span>${this.currency} ${displayVal}</span>`;
		// When loading range for the first time, width always returns 0.
		// in this case, return hard coded width that matches .range-value width.
		const width =
			this.rangeValueElement!.nativeElement.offsetWidth === 0 ? 136 : this.rangeValueElement!.nativeElement.offsetWidth;
		const offset = (width / 2) * -1;

		this.rangeValueElement!.nativeElement!.style.left = `calc(${percent}% + (${offset}px))`;
		if (emit) {
			this.valueChange.emit(inputVal);
			if (this.controlName && this.fgd.form.get(this.controlName!) && this.isInitialized) {
				this.control!.patchValue(inputVal);
				this.control!.markAsDirty();
			}
		}
	}
}
