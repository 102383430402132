<div class="resume-app-container d-flex flex-column">
  <h1 class="l ls-mb-6">Welcome back, {{userInfo?.firstName}}</h1>
  <div class="ls-mb-6">
    <p class="title m">To get your invoices financed, please complete and submit this application</p>
    <p class="ls-mb-0">You can return to the beginning to review what you or your peers have already entered or pick up where you left off. </p>
  </div>  
  <div class="d-flex flex-column flex-md-row justify-content-start justify-content-sm-between"> 
    <button type="button" class="btn btn-outline-tertiary btn-lg ls-mb-4 ls-mb-md-0" (click)="returnToBeginning()">Return to beginning</button>
    <button type="button" class="btn btn-primary btn-lg" (click)="continueApplication()">Resume application</button>
  </div>  
</div>