import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CompanyUser, Page } from "../app/Models";
import { map, Observable } from "rxjs";
import { HttpService } from "./HTTP";
import { PageRequest } from "@limestone/ls-shared-modules";

@Injectable()
export class CompanyLsUserService extends HttpService {
	constructor(public http: HttpClient) {
		super(http);
		this.path = "/api/companyLsUser/companyProfile";
	}

	getCompanyProfileUsers(companyId: number, pageRequest: PageRequest): Observable<CompanyUser[]> {
		let params = new HttpParams();
		params = params.set("page", pageRequest.page);
		params = params.set("size", pageRequest.size);
		if (pageRequest.sort) {
			pageRequest.sort.forEach((s) => (params = params.append("sort", s)));
		}
		let url = `${this.path}/${companyId}`;
		if (pageRequest.filter) {
			url += `?${pageRequest.filter}`;
		}
		return this.http
			.get<Page<CompanyUser>>(url, { params })
			.pipe(
				map((userPages: Page<CompanyUser>) =>
					userPages.content!.map(
						(user) =>
							new CompanyUser(
								user.companyId,
								user.userId,
								user.contactId,
								user.isInternal,
								user.firstName,
								user.lastName,
								user.emailAddress,
								user.title,
								user.b2cId,
								user.status,
								user.lastLogin,
								user.userRole,
								user.lastInviteSent
							)
					)
				)
			);
	}

	inviteCompanyProfileUser(user: CompanyUser): Observable<CompanyUser> {
		return this.http.post<CompanyUser>(`${this.path}/${user.companyId}/invite`, user).pipe(map((c) => user));
	}

	resendInviteCompanyProfileUser(user: CompanyUser): Observable<CompanyUser> {
		return this.http
			.post<CompanyUser>(`${this.path}/${user.companyId}/user/${user.userId}/resend-invite`, user)
			.pipe(
				map(
					(u) =>
						new CompanyUser(
							u.companyId,
							u.userId,
							u.contactId,
							u.isInternal,
							u.firstName,
							u.lastName,
							u.emailAddress,
							u.title,
							u.b2cId,
							u.status,
							u.lastLogin,
							u.userRole,
							u.lastInviteSent
						)
				)
			);
	}
}
