import { Phone } from "./Phone";
import { GenericLookup } from "@limestone/ls-shared-modules";

export class Contact {
	id?: number;
	firstName?: string;
	lastName?: string;
	phone?: Phone;
	email?: string;
	contactType?: GenericLookup<string>;

	constructor(
		id?: number,
		firstName?: string,
		lastName?: string,
		countryCode?: string,
		phoneCode?: string,
		phoneNumber?: string,
		phoneExt?: string,
		email?: string,
		contactType?: GenericLookup<string>
	) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.phone = new Phone(phoneCode, phoneNumber, phoneExt, countryCode);
		this.email = email;
		this.contactType = contactType;
	}
}
