import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "ls-css-loader",
	template: `<link id="theme" rel="stylesheet" type="text/css" [href]="path" />`
})
export class StylesServiceComponent implements OnInit {
	constructor(
		public sanitizer: DomSanitizer,
		@Inject(DOCUMENT) private document: Document
	) {}
	cssPath: any;

	ngOnInit() {
		const themeLink = this.document.getElementById("theme") as HTMLLinkElement;
		if (themeLink) {
			if (location.href.includes("cot/demo")) {
				this.cssPath = "demo.css";
			}
		}
	}
	set path(path) {
		this.cssPath = path;
	}

	get path() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(this.cssPath ?? "");
	}
}
