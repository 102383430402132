import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FeatureFlags, FeatureFlagSelectors } from "@limestone/ls-shared-modules";
import { filter, map, Subject, take } from "rxjs";
import { InfoDialogService } from "src/services";
import { Store } from "@ngrx/store";

@Component({
	selector: "ls-info-dialog-icon",
	template: `<span
			*ngIf="is103Enabled"
			class="ls-ml-1 material-icons li-tooltip-icon ls-icon-primary-tooltip hoverable"
			(click)="mouseClick($event)"
			>info</span
		>
		<div *ngIf="!is103Enabled"><ng-content></ng-content></div>`
})
export class InfoDialogIconComponent implements OnDestroy {
	is103Enabled = false;
	constructor(
		private dialogService: InfoDialogService,
		public store: Store,
		public ffs: FeatureFlagSelectors
	) {
		this.store
			.select(this.ffs.selectFeatureFlags)
			.pipe(
				filter((flags) => !!flags),
				take(1),
				map((flags) => {
					if (flags?.some((f) => f.id === FeatureFlags.Onboarding_V1_0_3 && f.enabled)) {
						this.is103Enabled = true;
					}
				})
			)
			.subscribe();
	}
	@Input() header?: string;
	@Input() content?: string;
	@Output() iconClick: EventEmitter<MouseEvent> = new EventEmitter();
	private componentTeardown$ = new Subject();
	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}
	mouseClick(event: MouseEvent): void {
		if (!this.header && !this.content) this.iconClick.emit(event);
		else this.dialogService.showInfoDialog(this.header, this.content);
	}
}
