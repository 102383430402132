import { Component, Input } from "@angular/core";
import { CompanyProfileRelationship } from "src/app/Models";

@Component({
	selector: "ls-platforms-used-readonly",
	templateUrl: "./platforms-used-readonly.component.html"
})
export class PlatformsUsedReadonlyComponent {
	private _companyProfileRelationship?: CompanyProfileRelationship;
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}

	constructor() {}
}
