<h2 class="l ls-mb-3">What other forms of financing does your company currently have?</h2>
<p class="l ls-mb-3">Whether it’s to help with cash flow, pay for new equipment, or fund other business-related expenses, we’d like to know what financial assistance, if any, your business has.</p>
<ls-accordion header="Why we're asking" [expanded]="false">
  <ul class="no-indent-list">
    <li>
      <p class="l ls-mb-2">
        We want to know if there’s conflicting ownership of the receivables we’re willing to buy.
      </p>
    </li>
    <li>
      <p class="l">
        You can receive more favorable pricing if there’s no conflicting ownership.
      </p>
    </li>
  </ul>
</ls-accordion>
<form class="d-flex flex-column ls-mt-8 ls-mb-11" [formGroup]="form">
  <div class="form-check d-block ls-mb-3 financing-options" *ngFor="let ef of existingFinances">
    <input [formControlName]="ef.id!"
      mdbCheckbox
      class="form-check-input"
      type="checkbox"
      value="{{ef.id}}"
      id="{{ef.id}}"
    />
    <div class="ls-ml-2">
      <label class="form-check-label xl" for="{{ef.id}}">{{ ef.name }}
        <ls-tooltip *ngIf="tooltips.has(ef.id!)" [position]="'top'">
          <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
          <div content>
            <label class="l-bold text-wrap">{{tooltips.get(ef.id!)!.title}}</label>
            <p class="m ls-mb-2 ls-mt-2 text-wrap">{{tooltips.get(ef.id!)!.body}}</p>
            <a class="ls-hyperlink" href="{{tooltips.get(ef.id!)!.link}}" target="_blank"><span>{{tooltips.get(ef.id!)!.linkText}}</span></a>
          </div>
        </ls-tooltip>
      </label>
    </div>
  </div>
  <ng-container *ngIf="form.value.OTR">
    <mdb-form-control class="ls-mt-3">
      <input mdbInput [formControlName]="OTHER_FINANCE_INPUT" mdbValidate type="text" [maxlength]="maxLength" id="OTHER_FINANCE_INPUT" class="form-control form-control-lg" />
      <label mdbLabel class="form-label" for="OTHER_FINANCE_INPUT">Specify</label>
      <div class="form-helper">
        <div class="form-counter">{{ characterCount }} / {{ maxLength }}</div>
      </div>
    </mdb-form-control>
  </ng-container>
</form>
<button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="isInvalid()"  #lsNextBtn>
  Next <span class="material-icons md-18"> arrow_forward</span>
</button>