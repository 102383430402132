export class Phone {
	countryCode?: string;
	number?: string;
	extension?: string;
	phoneCode?: string;

	constructor(phoneCode?: string, number?: string, extension?: string, countryCode?: string) {
		this.phoneCode = phoneCode;
		this.countryCode = countryCode;
		this.number = number;
		this.extension = extension;
	}

	formattedPhoneNumber() {
		if (this.phoneCode !== undefined && this.number !== undefined) {
			if (this.phoneCode === "1") {
				const cleaned = this.number.replace(/\D/g, "");
				const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
				if (match) {
					return "(" + match[1] + ") " + match[2] + "-" + match[3];
				}
			} else {
				return `+${this.phoneCode} ${this.number}`;
			}
		}
		return undefined;
	}
}
