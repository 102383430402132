import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Breadcrumb } from "../../Models/Interfaces/Breadcrumb";
import { DisplayConfig } from "src/app/Models";

@Component({
	selector: "ls-questionnaire-toggle",
	templateUrl: "./questionnaire-toggle.component.html",
	styleUrls: ["./questionnaire-toggle.component.scss"]
})
export class QuestionnaireToggleComponent {
	@Input() enabled = false;
	/// <summary>
	/// OBSOLETE - will be removed in favor of DisplayConfig when all components are converted
	/// </summary>
	@Input() header?: string;
	@Input() displayConfig?: DisplayConfig;
	@Input() breadcrumbs?: Array<Breadcrumb> = [];
	@Input() toggledState?: string;
	@Input() toggleLabel?: string;

	@Output() enabledChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

	constructor() {}
}
