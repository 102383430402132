import { Action, createReducer, on } from "@ngrx/store";
import { CompanyDocumentType } from "../../../../Models/Enums";
import { CompanyDocument } from "../../../../Models";
import { CompanyDocumentActions } from "./company-document-actions";
import copy from "fast-copy";

export interface CompanyDocumentState {
	MSA_Documents?: CompanyDocument[];
	IVH_Documents?: CompanyDocument[];
	RMH_Documents?: CompanyDocument[];
	TPI_Documents?: CompanyDocument[];
	EBR_Documents?: CompanyDocument[];
	VDC_Documents?: CompanyDocument[];
}

export const initialState: CompanyDocumentState = {};

abstract class CompanyDocumentReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(CompanyDocumentActions.setFiles, (state: CompanyDocumentState, { documentType, documents }) => {
			const stateCopy = copy(state);
			switch (documentType) {
				case CompanyDocumentType.MSA:
					stateCopy.MSA_Documents = documents;
					break;
				case CompanyDocumentType.IVH:
					stateCopy.IVH_Documents = documents;
					break;
				case CompanyDocumentType.RMH:
					stateCopy.RMH_Documents = documents;
					break;
				case CompanyDocumentType.TPI:
					stateCopy.TPI_Documents = documents;
					break;
				case CompanyDocumentType.EBR:
					stateCopy.EBR_Documents = documents;
					break;
				case CompanyDocumentType.VDC:
					stateCopy.VDC_Documents = documents;
					break;
			}
			return stateCopy;
		})
	);
}

export abstract class CompanyDocumentReducer {
	static reducer = (state: CompanyDocumentState | undefined, action: Action) => {
		return CompanyDocumentReducerHandler.appReducer(state, action);
	};
}
