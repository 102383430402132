<div class="d-flex flex-column">
	<ls-upload
			[files]="files"
			[nextButtonDisabled]="isNextButtonDisabled()"
			[allowedFileExtensions]="displayableFileExtensions"
			[maxFileCount]="maxFileCount"
			[maxFileSize]="maxFileSize"
			[disabled]="disabled"
			[submitted]="submitted"
			(nextClicked)="continue()"
			(sortChanged)="handleSortChange($event)"
			(openFileClicked)="openFile($event)"
			(deleteFileClicked)="deleteDocument($event)"
			(uploadedFiles)="uploadFiles($event)"
			(fileUploadError)="handleError($event)">
		<h1 header class="ls-mb-8 l">Evidence of Business Registration</h1>
		<div description class="d-flex flex-column ls-mb-8">
			<span>Please upload all relevant government-issued document(s) reflecting the incorporation, formation, registration, or organization of your company.</span>
		</div>
		<div *ngIf="errors && errors.length > 0" class="d-flex flex-column ls-mt-4" errors>
			<div *ngFor="let error of errors" class="ls-mb-2">
				<ls-alert alertType="danger" [small]="true"><p [innerHTML]="error" class="s ls-mb-0"></p></ls-alert>
			</div>
		</div>
	</ls-upload>
</div>
