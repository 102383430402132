<div class="d-flex flex-column">
  <h1 class="l ls-mb-8">Your company's industry</h1>
  <p class="l ls-mb-8">We&#39;d like to learn more about the industry your company does business in. If it&#39;s multiple industries, please select the industry you usually do business in.</p>
  <h6 class="l ls-mb-3">What industry is your company in?</h6>

  <form class="d-flex flex-column" [formGroup]="form">
    <mdb-form-control class="ls-mb-4">
      <input mdbInput 
        [formControlName]="INDUSTRY_SEARCH" 
        type="text" 
        placeholder="Search your industry" 
        class="form-control form-control-lg search-input" 
        id="industrySearch" 
        aria-describedby="search-icon" />
      <span class="input-search-icon material-icons search-icon" id="search-icon">search</span>
      <label mdbLabel class="form-label search-label" for="industrySearch">Industry</label>
    </mdb-form-control>

    <div class="w-100 ls-p-4 industry-list ls-mb-11">
      <div class="scroll">
        <div *ngFor="let industry of filteredIndustries" class="d-flex align-items-center ls-pb-3">
          <input mdbRadio [formControlName]="INDUSTRY" class="radio industry-input" type="radio" name="industry" id="{{industry.id}}" value="{{industry.id}}" />
          <label class="xl" for="{{industry.id}}">{{ industry.name }}</label>
        </div>
      </div>
    </div>
  </form>
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="isInvalid()" #lsNextBtn>
    Next <span class="material-icons md-18"> arrow_forward</span>
  </button>
</div>