import {
	Renderer2,
	HostListener,
	Directive,
	ElementRef,
	TemplateRef,
	ViewContainerRef,
	ContentChild
} from "@angular/core";

@Directive({ selector: "[lsTooltip]" })
export class TooltipDirective {
	private screenWidth: number;
	private screenHeight: number;
	private boundHeight?: number;
	private bound?: HTMLElement;
	constructor(
		private renderer: Renderer2,
		private elementRef: ElementRef,
		private viewContainerRef: ViewContainerRef
	) {
		this.screenWidth = window.innerWidth;
		this.screenHeight = window.innerHeight;
	}

	@ContentChild("tooltipTemplate") private tooltipTemplateRef: TemplateRef<any> | undefined;

	@HostListener("mouseenter") onMouseEnter(): void {
		if (this.tooltipTemplateRef) {
			const view = this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef);
			view.rootNodes.forEach((node: HTMLElement) => {
				const rem = 16;
				const { x, y } = node.getBoundingClientRect();
				const parentRef: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
				let offset;
				this.bound = this.elementRef.nativeElement.children[0];
				this.boundHeight = this.bound!.clientHeight;
				this.bound!.style.height = node.clientHeight + "px !important";
				this.renderer.setStyle(this.bound, "height", node.clientHeight + "px");
				if (parentRef.y - (node.clientHeight + rem) < 0) {
					offset = (parentRef.y - (node.clientHeight + rem)) / rem;
					this.renderer.setStyle(node, "bottom", offset + "rem");
				}
				if (node.clientHeight + rem + parentRef.y > this.screenHeight) {
					offset = (this.screenWidth - (parentRef.x + rem + node.clientWidth)) / rem;
					this.renderer.setStyle(node, "bottom", offset + "rem");
				}
				if (this.screenWidth < 768) {
					const midpoint = this.screenWidth / 2;
					if (parentRef.x > midpoint) {
						offset = (parentRef.x - midpoint + node.offsetWidth / 2) / rem;
						offset *= -1;
					} else {
						offset = (midpoint - parentRef.x - node.offsetWidth / 2) / rem;
					}
					this.renderer.setStyle(node, "left", offset + "rem");
				} else if (parentRef.x + (node.clientWidth + rem) > this.screenWidth) {
					offset = (this.screenWidth - (parentRef.x + rem + node.clientWidth)) / rem;
					this.renderer.setStyle(node, "left", offset + "rem");
				}
				this.renderer.appendChild(this.elementRef.nativeElement, node);
			});
		}
	}

	@HostListener("mouseleave") onMouseLeave(): void {
		if (this.viewContainerRef) {
			this.viewContainerRef.clear();
			this.renderer.setStyle(this.bound, "height", this.boundHeight + "px");
		}
	}

	@HostListener("window:resize", ["$event"]) onResize() {
		this.screenWidth = window.innerWidth;
		this.screenHeight = window.innerHeight;
	}

	@HostListener("click", ["$event"]) onMouseClick(event: Event): void {
		const target = event.target as HTMLElement;
		const isLink = target.closest("a");
		if (!isLink) {
			event.preventDefault();
		}
	}
}
