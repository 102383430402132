import { Component, OnInit } from "@angular/core";
import { ConnectFinancialsResultService } from "src/services/connect-financials-result.service";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";
import { BehaviorSubject, Subject } from "rxjs";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../../Services";

@Component({
	selector: "ls-account-platform-result",
	templateUrl: "./account-platform-result.component.html",
	styleUrls: ["./account-platform-result.component.scss"],
	providers: [ConnectFinancialsResultService]
})
export class AccountPlatformResultComponent extends RouteStepDataBaseComponent implements OnInit {
	public componentTeardown$ = new Subject();
	public success$: BehaviorSubject<boolean> = this.connectFinancialsResultService.success$;

	constructor(
		private connectFinancialsResultService: ConnectFinancialsResultService,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	public ngOnInit() {
		this.connectFinancialsResultService.init(CodatConnectionType.ACCOUNTING);
	}

	public tryAgain() {
		this.nav(1);
	}

	public continue() {
		this.nav();
	}
}
