<div class="begin-app-container d-flex flex-column ls-m-3 ls-mt-6 ls-m-sm-5 ls-mt-sm-11 ls-m-md-6 ls-mt-md-11 ls-m-xxl-11">
    <h1 class="expectation-header l ls-mb-8">{{ data.header }}</h1>
    <p class="expectation-subheader title m ls-mb-11 ls-mb-sm-8">{{ data.subHeader }}</p>
    <div class="expectation-container d-flex flex-column flex-sm-row justify-content-center">
        <div *ngFor="let expectation of data.expectations" class="expectation w-100">
            <div class="expectation-icon ls-icon-secondary-light d-flex justify-content-center align-items-center ls-icon-large d-md-none">
                <span class="material-icons-outlined">{{expectation.icon}}</span>
            </div>
            <div class="expectation-icon ls-icon-secondary-light d-md-flex justify-content-center align-items-center d-none d-lg-none ls-icon-large">
                <span class="material-icons-outlined">{{expectation.icon}}</span>
            </div>
            <div class="expectation-icon ls-icon-secondary-light d-lg-flex justify-content-center align-items-center d-none ls-icon-x-large">
                <span class="material-icons-outlined">{{expectation.icon}}</span>
            </div>
            <p class="title m">{{expectation.title}}</p>
            <p class="l">{{expectation.details}}</p>
        </div>
    </div>
    <p *ngIf="data.subContent" class="l ls-mb-11 ls-mb-sm-8 ls-mb-lg-11">{{ data.subContent }}</p>
    <button type="button" class="btn btn-primary btn-lg begin-app-btn" (click)="onClick()">Begin application</button>
</div>