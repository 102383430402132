<div class="grid ls-mt-8 ls-mt-md-11" style="--bs-gap:3.5rem;">
  <div class="g-col-8 g-col-md-4">
    <label class="s label-color ls-mb-2">Accounting Platform</label>
    <div class="ls-icon-badge" 
      [class.ls-badge-primary-light]="companyProfile?.isAccountingPlatformConnected"
      [class.ls-badge-error-light]="!companyProfile?.isAccountingPlatformConnected">
      <span class="material-icons" [class.ls-mr-0]="!companyProfile?.isAccountingPlatformConnected">
        {{ companyProfile?.isAccountingPlatformConnected ? 'link' : 'link_off' }}
      </span>
      <label class="xs-badge">{{ companyProfile?.isAccountingPlatformConnected ? 'Connected' : 'Not Connected' }}</label>
    </div>
  </div>
  <div class="g-col-12 g-col-md-6 ls-mb-md-8 ls-mb-lg-11">
    <button class="btn btn-sm"
      [class.btn-primary]="!companyProfile?.isAccountingPlatformConnected"
      [class.btn-outline-tertiary]="companyProfile?.isAccountingPlatformConnected"
      (click)="createOrDeleteAccountingConnection()">{{accountingButtonText}}</button>
  </div>
  <div class="g-col-8 g-col-md-4">
    <label class="s label-color ls-mb-2">Bank Accounts</label>
    <div class="ls-icon-badge" 
      [class.ls-badge-primary-light]="companyProfile?.isBankingPlatformConnected"
      [class.ls-badge-error-light]="!companyProfile?.isBankingPlatformConnected">
      <span class="material-icons" [class.ls-mr-0]="!companyProfile?.isBankingPlatformConnected">
        {{ companyProfile?.isBankingPlatformConnected ? 'link' : 'link_off' }}
      </span>
      <label class="xs-badge">{{ companyProfile?.isBankingPlatformConnected ? 'Connected' : 'Not Connected' }}</label>
    </div>
  </div>
  <div class="g-col-12 g-col-md-6">
    <button class="btn btn-sm"
      [class.btn-primary]="!companyProfile?.isBankingPlatformConnected"
      [class.btn-outline-tertiary]="companyProfile?.isBankingPlatformConnected"
      (click)="createOrDeleteBankConnections()">{{bankingButtonText}}</button>
  </div>
</div>