import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CompanyDocumentSelectors } from "../../../OnboardingStateManagement";
import { Actions } from "@ngrx/effects";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { FileUploadBaseComponent } from "../../../../../Core";
import { filter, map, takeUntil, withLatestFrom } from "rxjs";
import { CompanyProfileSelectors } from "src/app/AppStateManagement";
import { CompanyDocument, CompanyProfile, DisplayConfig, HtmlContent, Layout } from "src/app/Models";
import { RouteStepDataService } from "../../../Services";
import { UploadStatus } from "src/app/Models/Enums/UploadStatus";
import { DomSanitizer } from "@angular/platform-browser";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-business-registration",
	templateUrl: "./business-registration.component.html",
	styleUrls: ["./business-registration.component.scss"]
})
export class BusinessRegistrationComponent extends FileUploadBaseComponent {
	public companyProfile?: CompanyProfile;

	constructor(
		public router: Router,
		public store: Store,
		public companyDocumentSelectors: CompanyDocumentSelectors,
		public dialog: MdbModalService,
		public companyProfileSelectors: CompanyProfileSelectors,
		public actions$: Actions,
		public sanitizer: DomSanitizer,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, dialog, actions$, sanitizer, routeStepDataService);
		this.maxFileCount = 5;
		this.content = new HtmlContent({ layout: this.DefaultLayout });
		this.initData();
	}

	public isNextButtonDisabled(): boolean {
		const completeFiles = this.files?.filter((f) => f.status === UploadStatus.COMPLETE).length ?? 0;
		return completeFiles < 1;
	}

	public continue() {
		this.nav();
	}

	public initData(): void {
		this.store
			.select(this.companyDocumentSelectors.selectDocumentsFor(this.getDocType()))
			.pipe(
				takeUntil(this.getComponentTearDown()),
				withLatestFrom(this.store.select(this.companyProfileSelectors.selectCompanyProfile)),
				filter(([docs, cp]) => !!docs && !!cp),
				map(([docs, cp]) => {
					this.configure(docs, cp);
				})
			)
			.subscribe();
	}
	configure(docs: CompanyDocument[] | undefined, cp: CompanyProfile | undefined) {
		this.files = this.rebuildFilesListAfterUpload(docs!);
		this.disabled = this.files!.filter((f) => f.status !== UploadStatus.FAILED).length! >= this.maxFileCount;
		this.companyProfile = cp;
		this.setCompanyId(cp!.companyId!);
		this.submitted = cp!.hasSubmittedApplications ?? false;
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig("Evidence of Business Registration", TextStyle.HEADLINE_LARGE)],
					[
						"p1",
						new DisplayConfig(
							"Please upload all relevant government-issued document(s) reflecting the incorporation, formation, registration, or organization of your company.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		]
	]);
}
