<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="ppmForm.invalid" [nextButtonText]="nextButtonText" [displayNextButtonIcon]="false">
	<form [formGroup]="ppmForm" class="d-flex flex-column">
		<h2 class="no-text-transform ls-mb-6">How do you prefer to get paid?</h2>
		<div class="d-flex flex-column">
			<p class="l ls-mb-3">
				Different methods of payment affect the speed your application can be processed, any fees, and other
				factors.
			</p>
			<div class="method d-flex flex-row align-items-center" *ngFor="let ppm of preferredPaymentMethods; let isLast = last" [ngClass]="{'ls-mb-3': !isLast}">
				<input mdbRadio [formControlName]="PREFERRED_PAYMENT_METHOD" class="radio preferred-payment-input" type="radio"
							 name="preferredPaymentMethods" id="{{ppm.id}}" value="{{ppm.id}}" />
				<label class="xl frequency-label" for="{{ppm.id}}">
					{{ ppm.name }}
					<ls-tooltip *ngIf="ppm.id === VIRTUAL_CARD">
						<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
						<div content>
							<label class="l-bold text-wrap">Virtual Cards</label>
							<p class="m ls-mb-2 ls-mt-2 text-wrap">Also known as vCard, a Virtual Card is a single-use card that’s generated in a digital format that provides a secure way to get paid even faster. Your company can get paid for invoices earlier, helping you scale and grow with more cash on hand.</p>
							<a class="ls-hyperlink" href="https://raistone.com/vcard-for-suppliers/" target="_blank"><span>Learn more about vCard</span></a>
						</div>
					</ls-tooltip>
				</label>
			</div>
		</div>
	</form>
</ls-questionnaire>