import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import copy from "fast-copy";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup } from "@limestone/ls-shared-modules";
import { PlatformType } from "src/app/Models/Enums";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-instrument-platform",
	templateUrl: "./instrument-platform.component.html",
	styleUrls: ["./instrument-platform.component.scss"]
})
export class InstrumentPlatformComponent extends CustomerRelationshipBaseComponent {
	public companyProfileRelationship?: CompanyProfileRelationship;
	public platforms?: GenericLookup<string>[];
	selectedPlatform?: GenericLookup<string>;
	isValid = false;
	valueWasChanged = false;
	public type = PlatformType.INSTRUMENT_PLATFORM;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routStepDataService, companyProfileRelationshipSelectors);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					if (cpr?.instrumentPlatform?.id) this.isValid = true;
				})
			)
			.subscribe();
	}

	handleFormValueChange(formValue: GenericLookup<string>) {
		this.selectedPlatform = formValue;
		this.valueWasChanged = true;
	}

	handleFormStatusChange(status: boolean) {
		this.isValid = status;
	}

	continue() {
		if (this.valueWasChanged) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.instrumentPlatform = this.selectedPlatform;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
