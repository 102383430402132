<div class="d-flex flex-column">
	<h1 class="l ls-mb-5">Now, let's set up your customers</h1>
	<p class="l ls-mb-0_75">{{ firstParagraphText }}</p>
	<p class="l ls-mb-6">You must set up at least one customer in order to complete your application. You can always add more customers later.</p>

	<div *ngIf="!isCodatCustomerFetchComplete || (isCodatCustomerFetchComplete && companyCustomers.length === 0)" class="text-center ls-mt-6">
		<div *ngIf="!isCodatCustomerFetchComplete" class="spinner-border ls-spinner" role="status">
			<span class="visually-hidden">Loading...</span>
		</div>
		<img *ngIf="isCodatCustomerFetchComplete && companyCustomers.length === 0" class="image-container" src="../../../../../../assets/search-list-small.svg"/>
		<p class="m-bold ls-spinner-text">{{ !isCodatCustomerFetchComplete ? 'Loading your customers' : 'No customers found' }}</p>
	</div>

	<div *ngIf="isCodatCustomerFetchComplete && companyCustomers.length > 0">
		<form [formGroup]="customerForm" class="ls-mb-4">
			<mdb-form-control>
				<input mdbInput
						[formControlName]="CUSTOMER_SEARCH"
						type="text"
						placeholder="Customer Name"
						class="form-control form-control-lg search-input"
						id="customerSearch"
						aria-describedby="search-icon" />
				<span class="input-search-icon material-icons search-icon" id="search-icon">search</span>
				<label mdbLabel class="form-label search-label" for="customerSearch">Search your customer</label>
			</mdb-form-control>
		</form>

		<div class="w-100 ls-p-4 customer-list ls-mb-6">
			<div class="scroll">
				<div *ngFor="let customer of filteredCustomers" class="customer d-flex flex-row">
					<span class="material-icons md-24 ls-mr-0_75 hoverable" (click)="navToCustomer(customer.relationshipCompanyId!)">add</span>
					<label class="l-bold" for="{{customer.relationshipCompanyId}}">{{ customer.relationshipCompanyName }}</label>
				</div>
			</div>
		</div>
	</div>

	<div class="d-flex flex-row">
		<button type="button" (click)="addNewCustomer()" class="btn btn-primary btn-lg">
			<span>{{isCodatCustomerFetchComplete && companyCustomers.length > 0 ? 'Add a different customer' : 'Add a customer manually'}}</span>
		</button>
	</div>
</div>