import { createAction, props } from "@ngrx/store";
import { ActionType, LsAction } from "src/app/Core/LsAction";
import { CodatConnection, CompanyProfile, IntegrationDataConnection, LsHttpErrorResponse } from "src/app/Models";
import { DisplayedBankAcccount } from "src/app/Models/DisplayedBankAccount";

export abstract class CodatActions extends LsAction {
	/* eslint-disable */
  static INTEGRATIONS: Readonly<string> = 'INTEGRATIONS';
  static COMPANY_PLATFORM: Readonly<string> = 'COMPANY_PLATFORM';
  static NEW_COMPANY_PLATFORM: Readonly<string> = 'NEW_COMPANY_PLATFORM';
  static CONNECTION: Readonly<string> = 'CONNECTION';
  static COMPANY_PLATFORM_INTEGRATION: Readonly<string> = 'COMPANY_PLATFORM_INTEGRATION';
  static CONNECTION_INTEGRATION: Readonly<string> = 'COMPANY_INTEGRATION';
	static BANK_ACCOUNTS: Readonly<string> = 'BANK_ACCOUNTS';
  /* eslint-enable */

	static getIntegrations = createAction(CodatActions.getActionName(CodatActions.INTEGRATIONS, ActionType.GET));
	static setIntegrations = createAction(
		CodatActions.getActionName(CodatActions.INTEGRATIONS, ActionType.SET),
		props<{ integrationDataConnection: IntegrationDataConnection[] }>()
	);

	static getCompanyPlatform = createAction(CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.GET));
	static setCompanyPlatform = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.SET),
		props<{ companyProfile: CompanyProfile }>()
	);

	static notifyNewCompanyPlatform = createAction(
		CodatActions.getActionName(CodatActions.NEW_COMPANY_PLATFORM, ActionType.TRIGGER),
		props<{ accountingPlatform: string }>()
	);

	static createCompanyPlatform = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.SAVE),
		props<{ companyProfile: CompanyProfile; redirectPath: string }>()
	);

	static deleteCompanyPlatform = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.DELETE),
		props<{ companyProfile: CompanyProfile }>()
	);

	static deleteCompanyPlatformSuccessful = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.COMPLETE)
	);

	static companyPlatformSaveSuccessful = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.COMPLETE)
	);

	static companyPlatformSaveUnsuccessful = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM, ActionType.ERROR),
		props<{ errors: LsHttpErrorResponse }>()
	);

	static createConnection = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION, ActionType.SAVE),
		props<{ companyProfile: CompanyProfile; redirectPath: string }>()
	);

	static deleteConnection = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION, ActionType.DELETE),
		props<{ companyProfile: CompanyProfile }>()
	);

	static deleteConnectionSuccessful = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION, ActionType.COMPLETE)
	);

	static connectionSaveSuccessful = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION, ActionType.COMPLETE)
	);

	static connectionSaveUnsuccessful = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION, ActionType.ERROR),
		props<{ errors: LsHttpErrorResponse }>()
	);

	static createCompanyPlatformIntegration = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM_INTEGRATION, ActionType.SAVE),
		props<{ companyProfile: CompanyProfile; codatConnection: CodatConnection }>()
	);

	static companyPlatformIntegrationSaveSuccessful = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM_INTEGRATION, ActionType.COMPLETE)
	);

	static companyPlatformIntegrationSaveUnsuccessful = createAction(
		CodatActions.getActionName(CodatActions.COMPANY_PLATFORM_INTEGRATION, ActionType.ERROR),
		props<{ errors: LsHttpErrorResponse }>()
	);

	static createConnectionIntegration = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION_INTEGRATION, ActionType.SAVE),
		props<{ companyProfile: CompanyProfile; codatConnection: CodatConnection }>()
	);

	static connectionIntegrationSaveSuccessful = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION_INTEGRATION, ActionType.COMPLETE)
	);

	static connectionIntegrationSaveUnsuccessful = createAction(
		CodatActions.getActionName(CodatActions.CONNECTION_INTEGRATION, ActionType.ERROR),
		props<{ errors: LsHttpErrorResponse }>()
	);

	static getBankAccounts = createAction(
		CodatActions.getActionName(CodatActions.BANK_ACCOUNTS, ActionType.GET),
		props<{ companyId: number }>()
	);
	static setBankAccounts = createAction(
		CodatActions.getActionName(CodatActions.BANK_ACCOUNTS, ActionType.SET),
		props<{ bankAccounts: DisplayedBankAcccount[] }>()
	);

	static saveBankAccount = createAction(
		CodatActions.getActionName(CodatActions.BANK_ACCOUNTS, ActionType.SAVE),
		props<{ companyId: number; codatConnection: CodatConnection }>()
	);

	static saveBankAccountSaveSuccessful = createAction(
		CodatActions.getActionName(CodatActions.BANK_ACCOUNTS, ActionType.COMPLETE)
	);

	static saveBankAccountSaveUnsuccessful = createAction(
		CodatActions.getActionName(CodatActions.BANK_ACCOUNTS, ActionType.ERROR),
		props<{ errors: LsHttpErrorResponse }>()
	);
}
