import { UploadStatus } from "./Enums/UploadStatus";
import { IColumnHeader } from "./Interfaces/IColumnHeader";
import { CompanyDocumentType } from "./Enums";
import { SafeValue } from "@angular/platform-browser";
import { CompanyDocumentUploadError } from "./Enums/CompanyDocumentUploadError";

export class CompanyDocument {
	public companyId?: number;
	public fileName?: string;
	public fileSize?: string;
	public uploadDate?: Date;
	public status?: UploadStatus;
	public documentType?: CompanyDocumentType;
	public errors = new Map<CompanyDocumentUploadError, SafeValue | string | null>();

	constructor(
		companyId?: number,
		fileName?: string,
		fileSize?: string,
		uploadDate?: Date,
		status?: UploadStatus,
		documentType?: CompanyDocumentType,
		errors?: Map<CompanyDocumentUploadError, SafeValue | string | null>
	) {
		this.companyId = companyId;
		this.fileName = fileName;
		this.fileSize = fileSize;
		this.uploadDate = uploadDate;
		this.status = status ?? UploadStatus.COMPLETE;
		this.documentType = documentType;
		if (errors) this.errors = errors;
	}

	static fromPartial(partial: Partial<CompanyDocument>) {
		const doc = new CompanyDocument();
		Object.assign(doc, partial);
		return doc;
	}

	get key() {
		return `${this.fileName}_${this.status}_${this.uploadDate ? new Date(this.uploadDate).toISOString() : new Date().toISOString()}`;
	}
	static ColumnNames(): IColumnHeader[] {
		return [
			{ column: "File Name", relatedField: "fileName", canSort: true },
			{ column: "File Size", relatedField: "fileSize", canSort: true },
			{ column: "Date Uploaded", relatedField: "uploadDate", canSort: true },
			{ column: "Upload Status", relatedField: "status", canSort: true },
			{ column: "", relatedField: "", canSort: false }
		];
	}
}
