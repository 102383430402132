<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="isInvalid()">
	<h2 class="l ls-mb-8">Tell us more about your relationship with your customer</h2>
	<form [formGroup]="customerRelationshipDurationForm" class="d-flex flex-column">
		<h6 class="l ls-mb-3">How long have you been working with this customer?</h6>
		<div class="ls-mb-3 d-flex align-items-center" *ngFor="let relationshipDuration of relationshipDurations; let last = last" [class.ls-mb-8]="last">
			<input mdbRadio [formControlName]="RELATIONSHIP_DURATION"
						 class="radio relationship-duration-input"
						 type="radio"
						 [name]='RELATIONSHIP_DURATION'
						 id="{{relationshipDuration.id}}"
						 value="{{relationshipDuration.id}}" />
			<label class="xl" for="{{relationshipDuration.id}}">{{relationshipDuration.name}}</label>
		</div>
		<h6 class="l ls-mb-3">What are your payment terms with this customer?</h6>
		<div class="input-group terms">
			<ls-payment-terms-form-control [formControlName]="PAYMENT_TERMS"></ls-payment-terms-form-control>
		</div>
	</form>
</ls-questionnaire>