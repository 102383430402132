import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { IHtmlContent } from "src/app/Models/Interfaces/IRouteStepData";

@Component({
	selector: "ls-company-details-intro",
	templateUrl: "./company-details-intro.component.html",
	styleUrls: ["./company-details-intro.component.scss"]
})
export class CompanyDetailsIntroComponent extends RouteStepDataBaseComponent {
	public data: CompanyDetailsIntroContent;
	constructor(
		public router: Router,
		public store: Store<any>,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
		const htmlContent: any = this.activeRouteData!.htmlContent;
		this.data = new CompanyDetailsIntroContent(htmlContent.header, htmlContent.paragraphs);
	}

	onClick() {
		this.nav();
	}
}

class CompanyDetailsIntroContent implements IHtmlContent {
	public header: string;
	public paragraphs: string[];

	constructor(header: string, paragraphs: string[]) {
		this.header = header;
		this.paragraphs = paragraphs;
	}
}
