<div class="d-flex flex-column">
    <h1 class="l ls-mb-8">Share your bank account info</h1>
    <p class="l ls-mb-11">This will help us confirm the info for the bank account you want to receive payments.</p>
    <p class="title m ls-mb-3">Your bank details</p>
    <form class="d-flex flex-column ls-w-md-50" [formGroup]="form">
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="BANK_NAME" mdbValidate type="text" id="bankName"
                class="form-control form-control-lg" />
            <label mdbLabel class="form-label" for="bankName">Bank Name</label>
        </mdb-form-control>
        <ls-address-form-control [formControlName]="BANK_ADDRESS"></ls-address-form-control>

        <div class="ls-mt-11" [class.d-none]="!form.get(BANK_ADDRESS)?.value?.country">
            <ng-container *ngTemplateOutlet="(form.get(BANK_ADDRESS)?.value?.country ? 
            (isUsBased ? usBank : nonUsBank) : null); context: {form: form, label: BANK_ACCOUNT_LABEL}"></ng-container>
        </div>
    </form>
    <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg ls-mt-11" [disabled]="!form.valid"
        #lsNextBtn>
        Next <span class="material-icons md-18"> arrow_forward</span>
    </button>
</div>

<ng-template #usBank let-form="form" let-label="label">
    <div [formGroup]="form">
        <p class="title m ls-mb-3">{{label}}</p>
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="BANK_ALIAS" mdbValidate maxlength="80" type="text" [id]="BANK_ALIAS"
                class="form-control form-control-lg" />
            <label mdbLabel class="form-label" [for]="BANK_ALIAS">Bank Account Nickname</label>
        </mdb-form-control>
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="ABA_ROUTING" mdbValidate minlength="1" maxlength="9" type="text"
                id="abaRouting" class="form-control form-control-lg" (keydown)="onKeyboardEventNumbersOnly($event)"
                (paste)="onClipboardEventNumbersOnly($event)" />
            <label mdbLabel class="form-label" for="abaRouting">ABA / Routing Number</label>
            <mdb-error *ngIf="controlHasError(ABA_ROUTING)">
                Routing number must be 9 digits.
            </mdb-error>
        </mdb-form-control>
        <mdb-form-control>
            <input mdbInput [formControlName]="ACCOUNT_NUMBER" mdbValidate minLength="5" maxlength="17" type="text"
                id="accountNumber" class="form-control form-control-lg" (keydown)="onKeyboardEventNumbersOnly($event)"
                (paste)="onClipboardEventNumbersOnly($event)" />
            <label mdbLabel class="form-label" for="accountNumber">Account Number</label>
            <mdb-error *ngIf="controlHasError(ACCOUNT_NUMBER)">
                Account number must be between 5 and 17 characters.
            </mdb-error>
        </mdb-form-control>
    </div>
</ng-template>

<ng-template #nonUsBank let-form="form" let-label="label">
    <div [formGroup]="form">
        <mdb-form-control class="ls-mb-11">
            <input mdbInput [formControlName]="INTERMEDIARY_CORRESPONDENT_BANK_NAME" mdbValidate maxlength="80"
                type="text" id="icBankName" class="form-control form-control-lg" />
            <label mdbLabel class="form-label" for="icBankName">Intermediary / Correspondent Bank Name</label>
        </mdb-form-control>

        <p class="title m ls-mb-3">{{label}}</p>
        <mdb-form-control class="ls-mb-4">
            <input mdbInput [formControlName]="BANK_ALIAS" mdbValidate maxlength="80" type="text" [id]="BANK_ALIAS"
                class="form-control form-control-lg" />
            <label mdbLabel class="form-label" [for]="BANK_ALIAS">Bank Account Nickname</label>
        </mdb-form-control>
        <mdb-form-control [class.ls-mb-4]="!controlHasError(SWIFT_CODE)" [class.ls-mb-16]="controlHasError(SWIFT_CODE)">
            <input mdbInput [formControlName]="SWIFT_CODE" mdbValidate type="text" minlength="8" maxlength="11"
                id="swiftCode" class="form-control form-control-lg" (keydown)="onKeyboardEventNumbersAndLetters($event)"
                (paste)="onClipboardEventNumbersAndLetters($event)" />
            <label mdbLabel class="form-label" for="swiftCode">SWIFT Code</label>
            <mdb-error *ngIf="controlHasError(SWIFT_CODE)">
                <span [innerHTML]="getControlError(SWIFT_CODE)"></span>
            </mdb-error>
        </mdb-form-control>
        <mdb-form-control [ngClass]="{'ls-mb-lg-7 ls-mb-13': controlHasError(IBAN_ACCOUNT_NUMBER)}">
            <input mdbInput [formControlName]="IBAN_ACCOUNT_NUMBER" mdbValidate minlength="15" maxlength="34"
                type="text" id="iban" class="form-control form-control-lg"
                (keydown)="onKeyboardEventNumbersAndLetters($event)"
                (paste)="onClipboardEventNumbersAndLetters($event)" />
            <label mdbLabel class="form-label" for="iban">IBAN / Account Number</label>
            <mdb-error *ngIf="controlHasError(IBAN_ACCOUNT_NUMBER)">
                <span [innerHTML]="getControlError(IBAN_ACCOUNT_NUMBER)"></span>
            </mdb-error>

        </mdb-form-control>
    </div>
</ng-template>