import { HttpStatusCode } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, filter, map, Subject, takeUntil } from "rxjs";
import { CodatActions, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { CodatConnection, CompanyProfile } from "src/app/Models";
import { CodatConnectionType } from "src/app/Models/Enums/CodatConnectionType";

@Injectable()
export class ConnectFinancialsResultService implements OnDestroy {
	private _serviceDestroyed$ = new Subject();
	public success$ = new BehaviorSubject(false);
	public companyProfile?: CompanyProfile;
	public connectionType?: string;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _store: Store,
		private _companyProfileSelectors: CompanyProfileSelectors
	) {
		this._store
			.select(this._companyProfileSelectors.selectCompanyProfile)
			.pipe(
				filter((cp) => !!cp),
				takeUntil(this._serviceDestroyed$),
				map((cp) => {
					this.companyProfile = cp!;
				})
			)
			.subscribe();
	}

	public ngOnDestroy(): void {
		this._serviceDestroyed$.next(null);
		this._serviceDestroyed$.complete();
	}

	public init(connectionType: string) {
		this.connectionType = connectionType;
		if (this.companyProfile?.isAccountingPlatformConnected || this.companyProfile?.isBankingPlatformConnected) {
			this.success$.next(true);
		}
		this.handleCodatConnectionResponse();
	}

	private handleCodatConnectionResponse() {
		const params = this._activatedRoute.snapshot.queryParams;
		const statusCode = parseInt(params["statuscode"]);
		const connId: string = params["connectionId"];
		const platform: string = params["platform"];
		// Bypass for when user is already connected.
		if (!statusCode && !connId && !platform) {
			return;
		}
		if (!statusCode || !connId || !platform) {
			throw new Error("Invalid Codat connection response");
		}
		if (connId !== "" && (HttpStatusCode.Created === statusCode || HttpStatusCode.Ok === statusCode)) {
			const cc = new CodatConnection(connId, connId, platform);
			if (
				this.connectionType === CodatConnectionType.ACCOUNTING &&
				!this.companyProfile?.isAccountingPlatformConnected
			) {
				this._store.dispatch(
					CodatActions.createCompanyPlatformIntegration({
						companyProfile: this.companyProfile!,
						codatConnection: cc
					})
				);
			} else if (this.connectionType === CodatConnectionType.BANKING) {
				this._store.dispatch(
					CodatActions.createConnectionIntegration({
						companyProfile: this.companyProfile!,
						codatConnection: cc
					})
				);
			}
		}
		this.success$.next(HttpStatusCode.Created === statusCode || HttpStatusCode.Ok === statusCode);
	}
}
