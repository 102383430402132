<div class="company-details-introduction">
  <div class="container d-flex flex-column ls-cd-intro-header">
    <ls-text extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'hl1')"></ls-text>
    <ls-text id="p1" class="item" extraClasses="ls-mb-3" [displayConfig]="config(HEADER,'p1')"></ls-text>
    <ls-text id="p2" class="item" extraClasses="ls-mb-3" [displayConfig]="config(HEADER,'p2')"></ls-text>
    <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg">
      <ls-text [displayConfig]="config(BUTTONS,'next')"></ls-text><span class="material-icons md-18">
        arrow_forward</span>
    </button>
  </div>
</div>