import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { AppState } from "./app-reducer";
import copy from "fast-copy";

@Injectable()
export class AppSelectors {
	appState = (state: any) => state.root;

	selectUserRoles = createSelector(this.appState, (state: AppState) => copy(state.userRole));
	selectUserInfo = createSelector(this.appState, (state: AppState) => copy(state.userInfo));
	selectUserIP = createSelector(this.appState, (state: AppState) => copy(state.userIPAddress));
}
