import { CompanyProfile } from "../../../Models";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { filter, map, Observable, Subject, takeUntil } from "rxjs";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { CompanyProfileActions } from "../../../AppStateManagement";
import { environment } from "../../../../environments/environment";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { Router } from "@angular/router";
import { RouteStepDataService } from "../Services";

@Component({
	selector: "ls-company-details-base",
	template: ""
})
/**
 * @class CompanyDetailsBaseComponent is to be used only with company detail screens and not customer screens.
 */
export abstract class CompanyDetailsBaseComponent extends RouteStepDataBaseComponent implements OnInit, OnDestroy {
	public companyProfile?: CompanyProfile;
	protected componentTeardown$ = new Subject();
	public form: FormGroup = new FormGroup({});
	@ViewChild("lsNextBtn", { static: true }) nxtBtn?: ElementRef;
	private readonly warningId: string = "support-warning";

	constructor(
		public store: Store,
		public router: Router,
		public routeStepDataService: RouteStepDataService
	) {
		super(routeStepDataService);
	}

	ngOnInit() {
		this.init()
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((cp) => !!cp && cp.hasSubmittedApplications!),
				map(() => {
					this.form.disable();
					this.insertBefore();
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
		const warning = document.querySelector(`#${this.warningId}`);
		warning?.remove();
	}

	/**
	 * @method init Called during the ngOnInit life cycle.
	 * @return {Observable<CompanyProfile | undefined>} The company profile selected from state.
	 */
	public abstract init(): Observable<CompanyProfile | undefined>;

	/**
	 * @method emitSave Will save when the form is dirty and an application has not been submitted.
	 * @param updatedCompanyProfile The company profile that has been updated.
	 */
	public emitSave(updatedCompanyProfile: CompanyProfile): void {
		if (this.form.dirty && !this.companyProfile?.hasSubmittedApplications) {
			this.store.dispatch(CompanyProfileActions.updateCompanyProfile({ companyProfile: updatedCompanyProfile }));
		}
	}

	private insertBefore() {
		const warning = document.querySelector(`#${this.warningId}`);
		if (!warning) {
			const element = document.createElement("p");
			element.id = this.warningId;
			element.classList.add("ls-mb-4");
			element.innerHTML = `If you wish to modify the information above, please reach out to <a href="mailto:${environment.supportEmailAddress}"><strong>${environment.supportEmailAddress}</strong></a>`;
			this.nxtBtn?.nativeElement.parentNode.insertBefore(element, this.nxtBtn?.nativeElement);
		}
	}
}
