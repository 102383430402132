import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LimestoneElementsModule } from "../Elements/LimestoneElementsModule";
import { RouterOutlet } from "@angular/router";
import { AppRoutingModule } from "./Routing/app-routing.module";
import { StylesServiceComponent } from "../Utils";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor, HttpErrorService, UserIPInterceptor } from "../services/HTTP";
import { InvitationComponent, LogoutComponent } from "./Core";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import {
	AppEffects,
	AppReducer,
	AppSelectors,
	CompanyProfileRelationshipEffects,
	CompanyProfileRelationshipReducer,
	CompanyProfileRelationshipSelectors,
	CompanyProfileEffects,
	CompanyProfileReducer,
	CompanyProfileSelectors,
	GoogleEffects,
	CodatEffects,
	CodatReducer,
	CodatSelectors,
	SalesforceSupportEffects,
	SalesforceSupportSelectors,
	SalesforceSupportReducer,
	StepperReducer,
	StepperEffects,
	StepperSelectors
} from "./AppStateManagement";
import {
	CompanyDocumentService,
	CompanyProfileService,
	CompanyProgressService,
	ValidationService,
	RouteStepDataService
} from "./Modules/COT-Module/Services";
import {
	CompanyProfileResolver,
	UserInfoResolver,
	SettingsResolver,
	CompanyProfileRelationshipDataResolver,
	CompanyProfileRelationshipsDataResolver,
	ApplicationsDataResolver,
	GenericDataResolver,
	CodatDataResolver,
	SalesforceSupportCasesDataResolver,
	SalesforceSupportCaseDataResolver,
	UserIPResolver
} from "./Resolvers";
import {
	AppService,
	CompanyProfileRelationshipService,
	GridService,
	ExportService,
	GoogleService,
	CodatService,
	SalesforceSupportService,
	StepperService
} from "../services";
import { CompanyLsUserService } from "../services/company-ls-user.service";
import { ValidationEffects } from "./Modules/COT-Module/OnboardingStateManagement";
import { CommonModule } from "@angular/common";
import {
	GenericEffects,
	GenericReducer,
	GenericSelectors,
	GenericService,
	FeatureFlagEffects,
	FeatureFlagGuard,
	FeatureFlagReducer,
	LsSharedModule,
	FeatureFlagService,
	FEATURE_FLAG_CONFIG,
	FeatureFlagConfig
} from "@limestone/ls-shared-modules";
import {
	MsalInterceptor,
	MsalService,
	MsalGuard,
	MsalBroadcastService,
	MsalRedirectComponent,
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalModule
} from "@azure/msal-angular";
import { environment } from "src/environments/environment";

export const FF_CONFIG: FeatureFlagConfig = {
	isPortal: true
};

@NgModule({
	declarations: [AppComponent, StylesServiceComponent, LogoutComponent, InvitationComponent],
	imports: [
		CommonModule,
		NoopAnimationsModule,
		BrowserModule,
		FormsModule,
		LimestoneElementsModule,
		RouterOutlet,
		AppRoutingModule,
		HttpClientModule,
		StoreModule.forRoot({
			root: AppReducer.reducer,
			stepper: StepperReducer.reducer,
			companyProfile: CompanyProfileReducer.reducer,
			companyProfileRelationship: CompanyProfileRelationshipReducer.reducer,
			generic: GenericReducer.reducer,
			featureFlags: FeatureFlagReducer.reducer,
			codat: CodatReducer.reducer,
			salesforceSupport: SalesforceSupportReducer.reducer
		}),
		EffectsModule.forRoot([
			AppEffects,
			CompanyProfileEffects,
			ValidationEffects,
			CompanyProfileRelationshipEffects,
			GenericEffects,
			GoogleEffects,
			FeatureFlagEffects,
			CodatEffects,
			SalesforceSupportEffects,
			StepperEffects
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25 // Retains last 25 states
		}),
		ReactiveFormsModule,
		HttpClientModule,
		LsSharedModule,
		MsalModule.forRoot(environment.MsalInstanceConfig, environment.MsalGuardConfig, environment.MsalInterceptorConfig)
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UserIPInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: FEATURE_FLAG_CONFIG,
			useValue: FF_CONFIG
		},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		AppSelectors,
		AppService,
		StepperSelectors,
		CompanyProfileSelectors,
		CompanyProfileService,
		HttpErrorService,
		CompanyProfileResolver,
		ValidationService,
		CompanyProgressService,
		UserInfoResolver,
		CompanyLsUserService,
		CompanyDocumentService,
		ExportService,
		SettingsResolver,
		CompanyProfileRelationshipSelectors,
		CompanyProfileRelationshipService,
		CompanyProfileRelationshipDataResolver,
		CompanyProfileRelationshipsDataResolver,
		ApplicationsDataResolver,
		GridService,
		GenericSelectors,
		GenericService,
		GenericDataResolver,
		GoogleService,
		FeatureFlagGuard,
		CodatSelectors,
		CodatDataResolver,
		CodatService,
		SalesforceSupportSelectors,
		SalesforceSupportService,
		SalesforceSupportCasesDataResolver,
		SalesforceSupportCaseDataResolver,
		FeatureFlagService,
		StepperService,
		RouteStepDataService,
		UserIPResolver
	],
	bootstrap: [AppComponent, StylesServiceComponent, MsalRedirectComponent]
})
export class AppModule {}
