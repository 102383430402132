<div class="ls-m-4 home-container">
    <ng-container *ngTemplateOutlet="is_SCF_V1_0_0_Enabled ? scfFlagEnabled : scfFlagNotEnabled "></ng-container>
</div>
<ng-template #scfFlagNotEnabled>
    <h1 class="m ls-mb-10 no-text-transform">Welcome back, {{userInfo?.firstName!}}</h1>
    <p class="title m ls-mb-1_25">Thank you for submitting your application</p>
    <div>
        <p class="ls-mb-1_25">Our team will review the info you shared with us. We’re making sure we have everything we need to complete onboarding and accelerate your payments quickly.</p>
        <p class="ls-mb-0">You can continue to make changes to your application before our team reviews it.</p>
    </div>
    <button type="button" class="btn btn-primary btn-lg ls-mt-11" (click)="navToApplications()">Review recent application</button>
</ng-template>

<ng-template #scfFlagEnabled>
    <h1 class="l ls-mb-8 no-text-transform">Welcome back, {{userInfo?.firstName!}}</h1>
    <p class="title m ls-mb-5">What do you want to do today?</p>
    <div class="card-container">
        <div class="home-card ls-p-5 rounded-6" [class.ls-mr-4]="isFirst" *ngFor="let card of cards; let isFirst = first;">
            <div class="card-body">
              <h2>{{ card.title }}</h2>
              <p class="card-text m" [class.ls-mb-3]="isFirst" [class.ls-mb-4]="isLast" *ngFor="let paragraph of card.paragraphs; let isFirst = first; let isLast = last">{{ paragraph }}</p>
              <button (click)="isFirst ? navToApplications() : navToSupport()" type="button" class="btn btn-primary">{{ card.buttonText }}</button>
            </div>
        </div>
    </div>
</ng-template>