import { Component } from "@angular/core";
import { Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, map, Observable, takeUntil } from "rxjs";
import { CompanyAddress, CompanyProfile, DisplayConfig, HtmlContent, Layout, Phone } from "src/app/Models";
import { CompanyProfileSelectors } from "../../../../../AppStateManagement";
import { AddressType, CountryCode, GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CompanyDetailsBaseComponent } from "../../company-details-base.component";
import { RouteStepDataService } from "../../../Services";
import { TextStyle } from "src/app/Models/Enums";
import { SharedText } from "../../customer-setup/shared-text";

@Component({
	selector: "ls-company-phone-address",
	templateUrl: "./company-phone-address.component.html",
	styleUrls: ["./company-phone-address.component.scss"]
})
export class CompanyPhoneAddressComponent extends CompanyDetailsBaseComponent {
	constructor(
		public store: Store<any>,
		public router: Router,
		private companyProfileSelectors: CompanyProfileSelectors,
		private genericSelectors: GenericSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(store, router, routeStepDataService);
		this.setDefaultLayout(this.DefaultLayout);
	}

	public PHONE = "PHONE";
	public PRIMARY = "PRIMARY";
	public BILLING = "BILLING";

	public companyProfile?: CompanyProfile;
	public primaryOfficeAddress?: CompanyAddress;
	public billingAddress?: CompanyAddress;
	addressTypes: GenericLookup<string>[] = [];

	public toggled = false;
	public isUSorCABased?: boolean;

	setFormControls() {
		const phone = new Phone(
			this.companyProfile?.phoneCode,
			this.companyProfile?.phoneNumber,
			this.companyProfile?.phoneExt,
			this.companyProfile?.countryCode
		);
		this.setupControl(this.form, this.PHONE, phone, Validators.required);
		this.setupControl(this.form, this.PRIMARY, this.primaryOfficeAddress?.address, Validators.required);
		this.setupControl(this.form, this.BILLING, this.billingAddress?.address);
		if (!this.toggled) this.form.get(this.BILLING)?.disable();
		else this.form.get(this.BILLING)?.enable();
	}

	toggleBillingForm() {
		this.toggled = !this.toggled;
		this.toggled ? this.form.get(this.BILLING)?.enable() : this.form.get(this.BILLING)?.disable();
		if (this.toggled) {
			this.form.get(this.BILLING)?.setValue(this.billingAddress?.address);
		}
		this.form.get(this.BILLING)?.updateValueAndValidity();
		if (this.billingAddress) {
			this.billingAddress!.isActive = this.toggled;
		}
	}

	onClick() {
		if (this.form.dirty) {
			const updatedCompanyProfile = copy(this.companyProfile!);
			updatedCompanyProfile.addresses = [];

			const phone: Phone = this.form.get(this.PHONE)?.value;
			updatedCompanyProfile.phoneCode = phone.phoneCode;
			updatedCompanyProfile.phoneNumber = phone.number;
			updatedCompanyProfile.phoneExt = phone.extension;
			updatedCompanyProfile.countryCode = phone.countryCode;

			const pa = this.form.get(this.PRIMARY)?.value;
			if (pa) {
				pa.id = this.primaryOfficeAddress?.address?.id;
				const PrimaryAddress = new CompanyAddress(
					this.primaryOfficeAddress?.companyId,
					this.addressTypes.find((at) => at.id === AddressType.PRIMARY),
					true,
					pa
				);
				updatedCompanyProfile.addresses?.push(PrimaryAddress);
			}

			const ba = this.form.get(this.BILLING)?.value;
			if (ba && this.toggled) {
				ba.id = this.billingAddress?.address?.id;
				const billingAddress = new CompanyAddress(
					this.billingAddress?.companyId,
					this.addressTypes.find((at) => at.id === AddressType.BILLING),
					this.toggled,
					ba
				);
				updatedCompanyProfile.addresses?.push(billingAddress);
			} else if (this.billingAddress?.address) {
				const billingAddress = new CompanyAddress(
					this.billingAddress?.companyId,
					this.addressTypes.find((at) => at.id === AddressType.BILLING),
					this.toggled,
					this.billingAddress?.address
				);
				updatedCompanyProfile.addresses?.push(billingAddress);
			}
			this.isUSorCABased = this.isUsOrCABased(updatedCompanyProfile);

			this.emitSave(updatedCompanyProfile);
		}
		const index = this.isUSorCABased ? 0 : 1;
		this.nav(index);
	}

	isInvalid() {
		return this.form.invalid;
	}

	init(): Observable<CompanyProfile | undefined> {
		this.store
			.select(this.genericSelectors.selectLookup(LookupTables.AddressType))
			.pipe(
				filter((at) => !!at),
				takeUntil(this.componentTeardown$),
				map((at) => {
					this.addressTypes = at!;
				})
			)
			.subscribe();

		return this.store.select(this.companyProfileSelectors.selectCompanyProfile).pipe(
			filter((cp) => !!cp),
			takeUntil(this.componentTeardown$),
			map((cp) => {
				this.companyProfile = cp;
				this.primaryOfficeAddress = cp?.addresses?.find((a) => a.addressType?.id === AddressType.PRIMARY);
				this.billingAddress = cp?.addresses?.find((a) => a.addressType?.id === AddressType.BILLING);
				if (this.billingAddress?.isActive) {
					this.toggled = true;
				}

				this.isUSorCABased = this.isUsOrCABased(cp!);
				this.setFormControls();
				return cp;
			})
		);
	}

	isUsOrCABased(cp: CompanyProfile): boolean {
		return cp?.isAddressOfCountry(CountryCode.US) || cp?.isAddressOfCountry(CountryCode.CA);
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					["hl1", new DisplayConfig(SharedText.CustomerSetup.letsGetTheRest, TextStyle.HEADLINE_LARGE)]
				])
			)
		],
		[
			this.PHONE,
			new Layout(
				new Map<string, DisplayConfig>([
					["q1", new DisplayConfig("What’s the main office phone number?", TextStyle.TITLE_MEDIUM)]
				])
			)
		],
		[
			this.PRIMARY,
			new Layout(
				new Map<string, DisplayConfig>([
					["q2", new DisplayConfig("What’s your company’s primary place of business?", TextStyle.TITLE_MEDIUM)],
					["q2-p1", new DisplayConfig("It can also be your company’s headquarters.", TextStyle.BODY_LARGE)]
				])
			)
		],
		[
			this.BUTTONS,
			new Layout(new Map<string, DisplayConfig>([["next", new DisplayConfig("Next", TextStyle.BUTTON_LARGE)]]))
		]
	]);
}
