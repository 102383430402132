<form class="d-flex flex-column" [formGroup]="formGroup">
    <div class="d-flex flex-row w-100">

        <div id="phone-form-container" class="input-group" >
            <mdb-form-control class="always-active-label" id="country-code-control" >
                <div mdbDropdown id="country-code-dropdown" class="form-control dropdown overflow-hidden" [class.disabled]="formGroup.disabled">
                    <button mdbDropdownToggle [disabled]="formGroup.disabled" id="code-dropdown" class="dropdown-toggle form-control ls-pt-2"
                        type="button" aria-expanded="false">
                        <span class="option-icon-container d-none d-sm-inline">
                            <i class="flag flag-{{selectedCode?.countryCode?.toLowerCase()}}"></i>
                        </span>+{{selectedCode?.code?.trim()}}</button>
                    <ul mdbDropdownMenu class="dropdown-menu form-control select-input five-items">
                        <li>
                            <input [formControlName]="CC_Filter" [id]="CC_Filter" mdbInput mdbValidate type="text"
                                class="form-control form-control-sm" />
                        </li>
                        <li *ngFor="let code of filteredPhoneCodes" (click)="setCode(code, true)" class="hoverable">
                            <a class="dropdown-item flex-row justify-content-evenly">
                                <span class="option-icon-container ls-mr-3">
                                    <i class="flag flag-{{code.countryCode?.toLowerCase()}}"></i>
                                </span>
                                +{{ code.code }}
                            </a>
                        </li>
                    </ul>
                </div>
                <label mdbLabel for="country-code-control" class="form-label">Code</label>
            </mdb-form-control>

            <mdb-form-control id="phone-number-control">
                <input type="tel" [formControlName]="PHONE_NUMBER" min="8" mdbInput mdbValidate [id]="PHONE_NUMBER"
                    class="form-control form-control-lg" />
                <label mdbLabel class="form-label" [for]="PHONE_NUMBER">Phone Number</label>
            </mdb-form-control>
            <mdb-form-control id="phone-extension-control">
                <input [formControlName]="PHONE_EXT" min="0" mdbInput mdbValidate type="tel" [id]="PHONE_EXT"
                    class="form-control form-control-lg" (keydown)="onKeyDown($event)" />
                <label mdbLabel class="form-label" [for]="PHONE_EXT">Ext</label>
            </mdb-form-control>
        </div>


    </div>
    <div *ngIf="phoneValidationResult" id="phoneValidationError" class="form-text validation-error">
        {{phoneValidationResult}}</div>
</form>