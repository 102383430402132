import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import copy from "fast-copy";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { RouteStepDataService } from "../../../Services";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-autoxcel",
	templateUrl: "./autoxcel.component.html",
	styleUrls: ["./autoxcel.component.scss"]
})
export class AutoXcelComponent extends CustomerRelationshipBaseComponent {
	public companyProfileRelationship?: CompanyProfileRelationship;
	private _isAutoXcel = true;
	public AUTOEXCEL = "autoExcel";
	toggledState = "Disabled";
	get isAutoXcel() {
		return this._isAutoXcel;
	}
	set isAutoXcel(isAutoXcel: boolean) {
		this._isAutoXcel = isAutoXcel;
		this.toggledState = isAutoXcel ? "Enabled" : "Disabled";
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.isAutoXcel = cpr!.isAutoXcel!;
				})
			)
			.subscribe();
	}

	continue() {
		if (this.isAutoXcel != this.companyProfileRelationship?.isAutoXcel) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.isAutoXcel = this.isAutoXcel;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.AUTOEXCEL,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q1",
						new DisplayConfig(
							"Would you like to automatically accelerate payment on all your invoices to this customer?",
							TextStyle.HEADLINE_MEDIUM
						)
					],
					[
						"q1-p1",
						new DisplayConfig(
							"Also known as <a class='ls-hyperlink' href='https://raistone.com/help/autoxcel/' target='_blank'>autoXcel</a>, this feature allows you to choose to have all your customer invoices accelerated automatically instead of on an invoice-by-invoice basis.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"q1-p2",
						new DisplayConfig(
							"If you disable autoXcel, you or someone at your business will need to log in to our platform to manually select each invoice you’d like to get financed.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		]
	]);
}
