import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship, DisplayConfig, HtmlContent, Layout } from "src/app/Models";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { LookupKeys } from "src/app/Models/Enums";
import { YesNo, CollectionPreference, TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-notice-of-assignment",
	templateUrl: "./notice-of-assignment.component.html",
	styleUrls: ["./notice-of-assignment.component.scss"]
})
export class NoticeOfAssignmentComponent extends CustomerRelationshipBaseComponent implements AfterViewInit {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		public changeDetector: ChangeDetectorRef
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		if (!this.content?.layout?.get) this.content = new HtmlContent({ layout: this.DefaultLayout });
		this.initData();
	}

	public get TextStyle() {
		return TextStyle;
	}
	public get YesNo() {
		return YesNo;
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	selectedFinanceDisclosure?: string = YesNo.NO;
	FINANCE_DISCLOSURE = "financeDisclosure";
	COLLECTION_PREFERENCE = "collectionPreference";

	noticeOfAssignmentForm: FormGroup = new FormGroup({});
	companyProfileRelationship?: CompanyProfileRelationship;

	finyn: GenericLookup<string>[] = [];
	collections: GenericLookup<string>[] = [];

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
				})
			)
			.subscribe();

		combineLatest([
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinancialDisclosureYesNo)),
			this.store.select(this.genericSelectors.selectLookupByKey(LookupKeys.CollectionPreference))
		])
			.pipe(
				filter(([finyn, cp]) => !!finyn && !!cp),
				takeUntil(this.componentTeardown$),
				map(([finyn, cp]) => {
					this.finyn = finyn!;
					this.collections = cp!;
				})
			)
			.subscribe();
	}

	ngAfterViewInit(): void {
		this.setupControl(
			this.noticeOfAssignmentForm,
			this.FINANCE_DISCLOSURE,
			this.companyProfileRelationship?.financeDisclosureYesNoId,
			Validators.required
		);
		this.setupControl(
			this.noticeOfAssignmentForm,
			this.COLLECTION_PREFERENCE,
			this.companyProfileRelationship?.collectionPreferenceId,
			Validators.required
		);
		this.noticeOfAssignmentForm
			.get(this.FINANCE_DISCLOSURE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v: string) => {
					this.handleFinancialDisclosureChange(v);
				})
			)
			.subscribe();

		this.handleFinancialDisclosureChange(this.companyProfileRelationship!.financeDisclosureYesNoId);
	}
	private handleFinancialDisclosureChange(value?: string) {
		this.selectedFinanceDisclosure = value;
		//if the initial state is financial disclosure = NO, the required validator is not wired up
		//on page load without the change detection because the form control is not yet visible (I think)
		this.changeDetector.detectChanges();
		const collectionPreference = this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE);
		if (this.selectedFinanceDisclosure === YesNo.NO) {
			collectionPreference?.setValidators(Validators.required);
			collectionPreference?.updateValueAndValidity();
		} else {
			//do not validate the hidden control, db value will be set in getCompanyProfileRelationshipForUpdate
			collectionPreference?.clearValidators();
			collectionPreference?.reset(null, { emitEvent: false });
		}
	}
	isFormInvalid() {
		this.noticeOfAssignmentForm.updateValueAndValidity();
		return this.noticeOfAssignmentForm.invalid;
	}
	getCompanyProfileRelationshipForUpdate() {
		const financeDisclosureId = this.noticeOfAssignmentForm.get(this.FINANCE_DISCLOSURE)?.value;
		let collectionPreferenceId = this.noticeOfAssignmentForm.get(this.COLLECTION_PREFERENCE)?.value;

		if (financeDisclosureId === YesNo.YES || financeDisclosureId === YesNo.IDK) {
			collectionPreferenceId = CollectionPreference.INFORM;
		}
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);

		updatedCompanyProfileRelationship.financeDisclosureYesNoId = financeDisclosureId;
		updatedCompanyProfileRelationship.collectionPreferenceId = collectionPreferenceId;
		return updatedCompanyProfileRelationship;
	}
	continue() {
		if (this.noticeOfAssignmentForm.dirty) {
			const updatedCompanyProfileRelationship = this.getCompanyProfileRelationshipForUpdate();
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.FINANCE_DISCLOSURE,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q1",
						new DisplayConfig(
							"Would you prefer that we notify your customer to pay us directly in exchange for a lower discount rate?",
							TextStyle.TITLE_MEDIUM
						)
					],
					[
						"q1-p1",
						new DisplayConfig(
							"Disclosure of Accounts Receivable Financing relationships to your customers is a common practice, but it is not mandatory. At Raistone, we prioritize your business’s unique needs and goals, offering flexible options tailored to suit your financial strategy.",
							TextStyle.BODY_LARGE
						)
					],
					[
						"q1-p2",
						new DisplayConfig(
							"The manner in which you choose to communicate the financing relationship and manage collections can influence both the pricing and the structure of the financing we provide. Our customer support team is available to answer any questions you may have.",
							TextStyle.BODY_LARGE
						)
					],
					["q1-accordion", new DisplayConfig("Benefits of disclosing to your customer")],
					[
						"q1-a1",
						new DisplayConfig("Less resources and bandwidth needed by you and your company.", TextStyle.BODY_LARGE)
					],
					[
						"q1-a2",
						new DisplayConfig(
							"We can contact and collect on your behalf, resulting in optimal line efficiency",
							TextStyle.BODY_LARGE
						)
					]
				]),
				new Map<string | number, string>([
					[
						YesNo.YES,
						"If you decide to notify your customer to pay Raistone directly, we’ll provide them with a Notice of Assignment (NOA) to sign and return. We can manage the notification and do all the collections without your intervention. You can receive more favorable pricing if you notify your customer to pay Raistone"
					],
					[
						YesNo.NO,
						"If you decide to continue to collect payment from your customer, you’ll get less favorable pricing. You wouldn’t disclose this financing to your customer"
					]
				])
			)
		],
		[
			this.COLLECTION_PREFERENCE,
			new Layout(
				new Map<string, DisplayConfig>([
					["q2", new DisplayConfig("Going forward, how would you like your customer to pay?", TextStyle.TITLE_MEDIUM)],
					[
						"q2-p1",
						new DisplayConfig(
							"We can manage the notifications and do all the collections without your intervention.",
							TextStyle.BODY_LARGE
						)
					]
				])
			)
		]
	]);
}
