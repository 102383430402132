<div lsTooltip class="tooltip-container">
  <div class="tooltip-overlap" [ngClass]="
      {
        'ls-tooltip-left': position === 'left',
        'ls-tooltip-top': position === 'top',
        'ls-tooltip-bottom': position === 'bottom',
        'ls-tooltip-right': position === 'right'
      }
    ">
    <ng-content select="[reference]"></ng-content>
    <ng-template #tooltipTemplate>
      <div class="ls-tooltip-md" [ngClass]="
        {
          'small': small,
          'ls-tooltip-left': position === 'left',
          'ls-tooltip-top': position === 'top',
          'ls-tooltip-bottom': position === 'bottom',
          'ls-tooltip-right': position === 'right'
        }
      ">
        <ng-content select="[content]"></ng-content>
      </div>
    </ng-template>
  </div>
</div>
