import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EmailMessage } from "src/app/Models";
import { Attachment } from "src/app/Models/Attachment";
import { environment } from "src/environments/environment";

@Component({
	selector: "ls-case-message",
	templateUrl: "./case-message.component.html",
	styleUrls: ["./case-message.component.scss"]
})
export class CaseMessageComponent {
	@Input() message!: EmailMessage;
	@Input() expanded = false;
	@Input() userEmail!: string;
	@Output() downloadAttachment: EventEmitter<Attachment> = new EventEmitter();

	constructor() {}

	public downloadFile(index: number): void {
		this.downloadAttachment.emit(this.message.attachments![index]);
	}
}
