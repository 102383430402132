<div #tabContainer>
    <div class="d-flex flex-column d-none d-xl-block">
        <div class="d-flex flex-row tabs-header">
            <label (click)="tabChanged(i)" *ngFor="let name of tabNames; let i = index;" [class.active]="tabIndex === i" class="m-bold tab">
                {{ name }}
            </label>
        </div>
    </div>
    
    <div #responsiveTabContainer class="d-xl-none responsive-tab-header" (click)="toggleDropdownMenu()">
        <label class="l-bold">{{ tabNames[tabIndex!] }}</label>
        <span class="material-icons">
            more_vert
        </span>
        <div #dropdown [hidden]="!isDropdownOpen" class="responsive-tab-dropdown ls-p-2 w-100 rounded hov shadow-3">
            <span *ngFor="let name of tabNames; let i = index;" (click)="responsiveTabChanged($event, i)" class="dropdown-item hoverable" [class.active]="tabIndex === i">
                {{ name }}
            </span>
        </div>
    </div>

    <ng-content></ng-content>
</div>