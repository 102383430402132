import { Store } from "@ngrx/store";
import { RouteStepDataBaseComponent } from "src/app/Core/route-step-data-base.component";
import { RouteStepDataService } from "../../../Services";
import { Component, OnInit } from "@angular/core";
import { CodatSelectors, CompanyProfileSelectors } from "src/app/AppStateManagement";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { DisplayedBankAccount } from "src/app/Models/DisplayedBankAccount";
import { BankAccount } from "../../../../../Models";

@Component({
	selector: "ls-confirm-bank-account",
	templateUrl: "./confirm-bank-account.component.html",
	styleUrls: ["./confirm-bank-account.component.scss"]
})
export class ConfirmBankAccountComponent extends RouteStepDataBaseComponent implements OnInit {
	public bankName?: string;
	public accountNumber?: string;
	public confirmIndex = 0;
	public changeIndex = 1;
	public isManualBankAccount = false;

	constructor(
		public store: Store,
		public routeStepDataService: RouteStepDataService,
		private _codatSelectors: CodatSelectors,
		private _companyProfileSelectors: CompanyProfileSelectors
	) {
		super(routeStepDataService);
	}

	public ngOnInit(): void {
		combineLatest([
			this.store.select(this._codatSelectors.selectBankAccounts),
			this.store.select(this._companyProfileSelectors.selectCompanyProfile)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				filter(([ba, cp]) => !!cp),
				map(([bankAccounts, companyProfile]) => {
					this.setRoutingIndexes(companyProfile!.bankAccount);
					bankAccounts?.map((ba: DisplayedBankAccount) => {
						if (ba.name === companyProfile!.bankAccount?.alias) {
							this.bankName = ba.name;
							this.accountNumber = ba.accountNumber;
						}
					});
				})
			)
			.subscribe();
	}

	public setRoutingIndexes(bankAccount?: BankAccount): void {
		if (!bankAccount?.codatBankAccountId) {
			this.confirmIndex = 2;
			this.changeIndex = 3;
			this.isManualBankAccount = true;
		}
	}

	public onClick(index: number): void {
		let state = {};
		if (index === this.changeIndex && this.isManualBankAccount) {
			state = {
				bypassCodatGuard: true
			};
		}
		this.nav(index, undefined, undefined, state);
	}
}
