import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DisplayConfig } from "src/app/Models";

@Component({
	selector: "ls-accordion",
	templateUrl: "./accordion.component.html",
	styleUrls: ["./accordion.component.scss"]
})
export class LimestoneElementAccordionComponent {
	@Input() header = "";
	@Input() expanded = true;
	@Input() displayConfig?: DisplayConfig;
	@Output() expandedChanged = new EventEmitter<boolean>();

	constructor() {}

	toggleExpansion() {
		this.expanded = !this.expanded;
		this.expandedChanged.emit(this.expanded);
	}
}
