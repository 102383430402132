import { Country, StateProvince } from "@limestone/ls-shared-modules";

export class Address {
	id?: string | number;
	address1?: string;
	address2?: string;
	city?: string;
	state?: StateProvince;
	country?: Country;
	postalCode?: string;
	constructor(
		id?: string | number,
		address1?: string,
		address2?: string,
		city?: string,
		state?: StateProvince,
		country?: Country,
		postalCode?: string
	) {
		this.id = id;
		this.address1 = address1;
		this.address2 = address2;
		this.city = city;
		this.state = state;
		this.country = country;
		this.postalCode = postalCode;
	}

	toDisplayString(): string {
		let displayStr = "";
		if (this.id) {
			displayStr = `${this.address1}`;
			if (this.address2 !== undefined && this.address2 !== "") displayStr += ` ${this.address2}`;
			displayStr += `, ${this.city}`;
			if (this.state) displayStr += ` ${this.state?.code}`;
			displayStr += ` ${this.postalCode}`;
		}
		return displayStr;
	}
}
