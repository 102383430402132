import { Component } from "@angular/core";
import { ModalActions } from "../../../../Core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";

@Component({
	selector: "ls-delete-document",
	templateUrl: "./delete-document.component.html",
	styleUrls: ["./delete-document.component.scss"]
})
export class DeleteDocumentComponent {
	fileName?: string;

	constructor(public modalRef: MdbModalRef<DeleteDocumentComponent>) {}
	public get ModalActions(): typeof ModalActions {
		return ModalActions;
	}

	public close(action: ModalActions): void {
		this.modalRef.close(action);
	}
}
