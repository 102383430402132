<div class="d-flex flex-column">
    <div class="d-flex flex-row ls-mt-3" *ngFor="let attachment of attachments; let i = index;">
        <div class="d-flex ls-w-75">
            <span class="material-icons-outlined ls-mr-1">
                attach_file
            </span>
            <span class="text-truncate file-name">
                {{ attachment.name | slice:0:attachment.name!.length - 8 }}
            </span>
            <span class="file-name text-nowrap">{{ attachment.name | slice:attachment.name!.length - 8:attachment.name!.length }} <span class="file-size">({{ attachment.bodyLength! | convertFileSize }})</span></span>
        </div>
        <ng-container *ngTemplateOutlet="!attachmentsRemovable ? downloadOnly : removableAttachments; context: { $implicit: i }"></ng-container>
    </div>
</div>

<ng-template #downloadOnly let-index>
    <div class="d-flex ls-w-25 justify-content-end ls-pr-5 ls-pr-xxl-10 attachment-actions">
        <span class="material-icons-outlined" (click)="download(index)">
            file_download
        </span>
    </div>
</ng-template>

<ng-template #removableAttachments let-index>
    <div class="d-flex ls-w-25 justify-content-end ls-pr-3 attachment-actions">
        <span class="material-icons-outlined ls-mr-4" (click)="open(index)">
            file_download
        </span>
        <span class="material-icons-outlined" (click)="remove(index)">
            close
        </span>
    </div>
</ng-template>