import { Component, ViewChild } from "@angular/core";
import { CompanyProfileRelationship, DisplayConfig } from "src/app/Models";
import { Store } from "@ngrx/store";
import { filter, map, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import copy from "fast-copy";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { Actions } from "@ngrx/effects";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { RouteStepDataService } from "../../../Services";
import {
	FeatureFlag,
	FeatureFlags,
	FeatureFlagService,
	GenericLookup,
	GenericSelectors,
	LookupTables
} from "@limestone/ls-shared-modules";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TextStyle } from "../../../../../Models/Enums";

@Component({
	selector: "ls-instrument-approval-confirmation",
	templateUrl: "./instrument-approval-confirmation.component.html",
	styleUrls: ["./instrument-approval-confirmation.component.scss"]
})
export class InstrumentApprovalConfirmationComponent extends CustomerRelationshipBaseComponent {
	public companyProfileRelationship?: CompanyProfileRelationship;
	private _isInstrumentApprovalConfirmed = true;
	public isSunfyre_ARFPricingGauge_2024718Enabled = false;

	toggledState = "No";
	get isInstrumentApprovalConfirmed() {
		return this._isInstrumentApprovalConfirmed;
	}
	set isInstrumentApprovalConfirmed(isInstrumentApprovalConfirmed: boolean) {
		this._isInstrumentApprovalConfirmed = isInstrumentApprovalConfirmed;
		this.toggledState = isInstrumentApprovalConfirmed ? "Yes" : "No";
	}
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	invApprovalForm: FormGroup = new FormGroup({});
	INV_APPROVAL = "invoiceApproval";
	yn: GenericLookup<string>[] = [];

	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService,
		public featureFlagService: FeatureFlagService,
		public genericSelectors: GenericSelectors
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.isInstrumentApprovalConfirmed = cpr!.isInstrumentApprovalConfirmed!;
				})
			)
			.subscribe();

		this.featureFlagService
			.isEnabled(FeatureFlags.Sunfyre_ARFPricingGauge_2024718)
			.pipe(
				takeUntil(this.componentTeardown$),
				map((isEnabled) => {
					if (isEnabled) {
						this.store
							.select(this.genericSelectors.selectLookup(LookupTables.YesNo))
							.pipe(
								filter((yn) => !!yn),
								takeUntil(this.componentTeardown$),
								map((yn) => (this.yn = yn!))
							)
							.subscribe();
						this.invApprovalForm.addControl(this.INV_APPROVAL, new FormControl(null, Validators.required));
					} else {
						this.invApprovalForm.removeControl(this.INV_APPROVAL);
					}
					this.isSunfyre_ARFPricingGauge_2024718Enabled = isEnabled;
				})
			)
			.subscribe();
	}

	continue() {
		if (this.isInstrumentApprovalConfirmed != this.companyProfileRelationship?.isInstrumentApprovalConfirmed) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.isInstrumentApprovalConfirmed = this.isInstrumentApprovalConfirmed;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}

	protected readonly FeatureFlag = FeatureFlag;
	protected readonly FeatureFlags = FeatureFlags;

	public displays = new Map<string, DisplayConfig>([
		[
			"header",
			new DisplayConfig(
				"Would you prefer to have your customer provide invoice approvals in exchange for a lower discount rate?",
				TextStyle.HEADLINE_MEDIUM
			)
		],
		[
			"listHeader",
			new DisplayConfig(
				'Getting <a class="ls-hyperlink" href="https://raistone.com/help/customer-invoice-approval/" target="_blank">invoice approval from your customers</a> will result in:',
				TextStyle.BODY_LARGE
			)
		],
		[
			"bullet1",
			new DisplayConfig("Lower discount fee — we can provide you with better pricing.", TextStyle.BODY_LARGE)
		],
		[
			"bullet2",
			new DisplayConfig(
				"Increased advance rate — we’ll be able to buy a larger amount of each invoice.",
				TextStyle.BODY_LARGE
			)
		],
		["bullet3", new DisplayConfig("Larger line of credit we can provide your business.", TextStyle.BODY_LARGE)]
	]);
}
