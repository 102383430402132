<form class="d-flex flex-column" [formGroup]="formGroup">
  <div class="number d-flex flex-row">
    <mdb-form-control class="input-group-lg d-flex flex-row align-items-center default-width">
      <span *ngIf="isPaymentTermsNotNullOrUndefined()" class="input-group-text border-0 input-group-height ls-pr-0 net-display">Net</span>
      <input [formControlName]="PAYMENT_TERMS" mdbInput mdbValidate type="number"
             class="form-control form-control-lg terms"
             [class.default-width]="!isPaymentTermsNotNullOrUndefined()"
             [max]="max" min="0"/>
      <label mdbLabel class="form-label" [for]="PAYMENT_TERMS">Payment Terms</label>
      <span *ngIf="isPaymentTermsNotNullOrUndefined()" class="input-group-text border-0 input-group-height ls-pl-0 days-display">days</span>
      <mdb-error class="ls-mt-4" *ngIf="controlHasError(PAYMENT_TERMS)">
        {{getControlError(PAYMENT_TERMS)}}
      </mdb-error>
    </mdb-form-control>
  </div>
</form>
