<div class="d-flex flex-column">
	<div *ngIf="display(HEADER)">
		<ls-text [displayConfig]="config(HEADER, 'hm1')" extraClasses="ls-mb-6"></ls-text>
		<div class="ls-mb-6">
			<ls-text [displayConfig]="config(HEADER, 'p1')" extraClasses="ls-mb-3"></ls-text>
			<ls-text [displayConfig]="config(HEADER, 'p2')"></ls-text>
		</div>
	</div>
	<div *ngIf="display(BODY)">
		<div class="d-flex flex-column flex-md-row justify-content-start justify-content-between">
			<button type="button" (click)="continue()" class="btn btn-lg btn-primary ls-mb-4 ls-mb-md-0">
				<ls-text [displayConfig]="config(BODY, 'b1')"></ls-text>
			</button>
			<button type="button" (click)="navToAccounting()" class="btn btn-lg btn-secondary">
				<ls-text [displayConfig]="config(BODY, 'b2')"></ls-text>
			</button>
		</div>
	</div>

</div>