<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
	[isNextButtonDisabled]="isFormInvalid()" [nextButtonText]="data.firstButtonText">
	<div class="ls-mb-11">
		<h3 class="l ls-mb-3">{{data.header}}</h3>
		<p class="l">{{data.content}}</p>
	</div>
	<form [formGroup]="facilityConfigForm" class="d-flex flex-column">
		<div>
			<p class="title m ls-mb-3">Would you collaborate with us to obtain lien releases from existing creditors who may have a lien on your accounts receivable in exchange for a lower discount rate?</p>
			<p class="l ls-mb-3 text-wrap">We're going to search for any conflicting liens against the
				receivables we are looking to acquire.</p>
			<p class="l ls-mb-3 text-wrap">You can get more favorable pricing if there's no conflicting liens on
				the receivables we're looking to acquire.</p>

			<div *ngFor="let lc of yesNo" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="LIEN_COLLABORATION" class="radio" type="radio"
					name="lienCollaboration" [id]="'lc'+lc.id" [value]="lc.id" />
				<label class="xl" [for]="'lc'+lc.id">
					{{ lc.name }}

				</label>
			</div>
		</div>
		<div class="ls-mb-11">
			<p class="title m ls-mb-3">Would you prefer that we notify your customer to pay us directly in exchange for
				a lower discount rate?</p>
			<p class="l ls-mb-3 text-wrap">Disclosure of Accounts Receivable Financing relationships to your customers
				is a common practice, but it is not mandatory. At Raistone, we prioritize your business's unique needs
				and goals, offering flexible options tailored to suit your financial strategy.</p>
			<p class="l ls-mb-3 text-wrap">The manner in which you choose to communicate the financing relationship and
				manage collections can influence both the pricing and the structure of the financing we provide. Our
				customer support team is available to answer any questions you may have.</p>
			<div class="ls-mb-3">
				<ls-accordion header="Benefits of disclosing to your customer" [expanded]="false">
					<ul class="no-indent-list">
						<li>
							<p class="l ls-mb-2">
								Less resources and bandwidth needed by you and your company.
							</p>
						</li>
						<li>
							<p class="l">
								We can contact and collect on your behalf, resulting in optimal line efficiency.
							</p>
						</li>
					</ul>
				</ls-accordion>
			</div>
			<div *ngFor="let fd of finyn" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="FINANCE_DISCLOSURE" class="radio" type="radio"
					name="financeDisclosure" [id]="'fd'+fd.id" [value]="fd.id" />
				<label class="xl" [for]="'fd'+fd.id">
					{{ fd.name }}


					<ls-tooltip *ngIf="fd.id === YesNo.YES" [position]="'right'">
						<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
						<div content>
							<p class="m ls-mb-0 text-wrap">If you decide to notify your customer to pay Raistone
								directly, we'll provide them with a Notice of Assignment (NOA) to sign and return. We
								can manage the notification and do all the collections without your intervention. You
								can receive more favorable pricing if you notify your customer to pay Raistone.</p>
						</div>
					</ls-tooltip>
					<ls-tooltip *ngIf="fd.id === YesNo.NO" [position]="'right'">
						<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
						<div content>
							<p class="m ls-mb-0 text-wrap">If you decide to continue to collect payment from your
								customer, you'll get less favorable pricing. You wouldn't disclose this financing to
								your customer.</p>
						</div>
					</ls-tooltip>
				</label>
			</div>
		</div>
		<div class="ls-mb-11" *ngIf="selectedFinanceDisclosure === YesNo.NO">
			<p class="title m ls-mb-3">Going forward, how would you like your customer to pay?</p>
			<p class="l ls-mb-3 text-wrap">We can manage the notifications and do all the collections without your
				intervention.</p>


			<div *ngFor="let cp of collections" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="COLLECTION_PREFERENCE" class="radio" type="radio"
					name="collectionPreference" [id]="'cp'+cp.id" [value]="cp.id" />
				<label class="xl" [for]="'cp'+cp.id">
					{{ cp.name }}

				</label>
			</div>
		</div>
		<div class="ls-mb-11">
			<p class="title m ls-mb-3">Would you prefer to have your customer provide invoice approvals in exchange for
				a lower discount rate?</p>
			<p class="l ls-mb-3 text-wrap">Getting invoice approval from your customers will result in:</p>
			<ul>
				<li>Lower discount fee — we can provide you with better pricing.</li>
				<li>Increased advance rate — we'll be able to buy a larger amount of each invoice</li>
				<li>Larger line of credit we can provide your business.</li>
			</ul>

			<div *ngFor="let ia of yesNo" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="INVOICE_APPROVAL" class="radio" type="radio" name="invoiceApproval"
					[id]="'ia'+ia.id" [value]="ia.id" />
				<label class="xl" [for]="'ia'+ia.id">
					{{ ia.name }}

				</label>
			</div>
		</div>
		<div>
			<p class="title m ls-mb-3">How do you prefer to get paid?</p>
			<p class="l ls-mb-3 text-wrap">Different methods of payment affect the speed your application can be
				processed, any fees, and other factors.</p>


			<div *ngFor="let pm of ppm" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="PREFERRED_PAYMENT_METHOD" class="radio" type="radio"
					name="preferredPaymentMethod" [id]="pm.id" [value]="pm.id" />
				<label class="xl" [for]="pm.id">
					{{ pm.name }}
					<ls-tooltip *ngIf="pm.id === PreferredPaymentMethod.CARD">
						<span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
						<div content>
							<label class="l-bold text-wrap">Virtual Cards</label>
							<p class="m ls-mb-2 ls-mt-2 text-wrap">Also known as vCard, a Virtual Card is a single-use
								card that's generated in a digital format that provides a secure way to get paid even
								faster. Your company can get paid for invoices earlier, helping you scale and grow with
								more cash on hand.</p>
							<a class="ls-hyperlink" href="https://raistone.com/vcard-for-suppliers/"
								target="_blank"><span>Learn more about vCard</span></a>
						</div>
					</ls-tooltip>
				</label>
			</div>
		</div>
	</form>
</ls-questionnaire>
<ls-pricing-gauge></ls-pricing-gauge>