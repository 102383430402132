import { EmailMessage } from "./EmailMessage";
import { IColumnHeader } from "./Interfaces/IColumnHeader";

export class Case {
	recordId?: string;
	id?: number;
	email?: string;
	limestoneContactId?: number;
	caseNumber?: string;
	subject?: string;
	lastModifiedDate?: Date;
	viewDate?: Date;
	status?: string;
	createdDate?: Date;
	contactName?: string;
	ownerName?: string;
	messages?: EmailMessage[];
	newActivity?: boolean;

	constructor(
		recordId?: string,
		id?: number,
		email?: string,
		limestoneContactId?: number,
		caseNumber?: string,
		subject?: string,
		lastModifiedDate?: Date,
		viewDate?: Date,
		status?: string,
		createdDate?: Date,
		ownerName?: string,
		messages?: EmailMessage[],
		newActivity?: boolean
	) {
		this.recordId = recordId;
		this.id = id;
		this.email = email;
		this.limestoneContactId = limestoneContactId;
		this.caseNumber = caseNumber;
		this.subject = subject;
		this.lastModifiedDate = lastModifiedDate;
		this.viewDate = viewDate;
		this.status = status;
		this.createdDate = createdDate;
		this.ownerName = ownerName;
		this.messages = messages?.map(
			(message: any) =>
				new EmailMessage(
					message.recordId,
					message.fromAddress,
					message.toAddress,
					message.parentId,
					message.hasAttachment,
					message.htmlBody,
					message.messageDate,
					message.textBody,
					message.attachments,
					message.threadId
				)
		);
		this.newActivity = newActivity;
	}

	static ColumnNames: IColumnHeader[] = [
		{ column: "Subject", relatedField: "subject", canSort: false },
		{ column: "Case ID", relatedField: "caseNumber", canSort: false },
		{ column: "Last Activity", relatedField: "lastModifiedDate", canSort: false },
		{ column: "Status", relatedField: "status", canSort: false }
	];

	static DefaultSort: string = Case.ColumnNames[3].relatedField;
}
