<div class="d-flex flex-column">
  <h1 class="l ls-mb-8">We’ll need some financial details</h1>
  <div class="ls-mb-11">
    <p class="l ls-mb-3">
      Having current financial and customer data is necessary for our team to streamline the onboarding and underwriting process. To make this process easier and quicker, we can connect directly and securely to your accounting system and business bank account(s).
    </p>
    <p class="l ls-mb-0">
      Direct connection allows us to simplify the application process, filling in required information on your behalf. To learn more about connecting your accounting system and business bank account(s), <a class="ls-hyperlink" href="https://raistone.com/help/connecting-your-accounts/" target="_blank">please see our FAQ page</a>.
    </p>
  </div>
  <div class="d-flex flex-sm-row flex-column justify-content-between">
    <button type="button" (click)="onClick()" class="btn btn-primary btn-lg ls-mb-3 ls-mb-md-0">Connect my accounts</button>
    <button type="button" (click)="openModal()" class="btn btn-lg btn-primary-borderless">I'll do it later</button>
  </div>
</div>