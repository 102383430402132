<form class="d-flex flex-column" [formGroup]="formGroup">
  <div class="d-flex ls-mb-4"
       [class.flex-row]="orientation === 'row'"
       [class.flex-column]="orientation === 'column'">
    <mdb-form-control class="flex-fill" [class.ls-mr-3]="orientation === 'row'"
                      [class.ls-mb-3]="orientation === 'column'">
      <input [formControlName]="FIRST_NAME" mdbInput mdbValidate type="text" maxlength="40" id="fName" class="form-control form-control-lg" />
      <label mdbLabel class="form-label" for="fName">First Name</label>
      <mdb-error *ngIf="controlHasError(FIRST_NAME)">{{getControlError(FIRST_NAME)}}</mdb-error>
    </mdb-form-control>
    <mdb-form-control class="flex-fill">
      <input [formControlName]="LAST_NAME" mdbInput mdbValidate type="text" maxLength="80" id="lName" class="form-control form-control-lg" />
      <label mdbLabel class="form-label" for="lName">Last Name</label>
      <mdb-error *ngIf="controlHasError(LAST_NAME)">{{getControlError(LAST_NAME)}}</mdb-error>
    </mdb-form-control>
  </div>
  <div class="ls-mb-4" *ngIf="!hidePhoneControl">
    <ls-phone-form-control [formControlName]="PHONE"></ls-phone-form-control>
  </div>
  <div class="name d-flex flex-column">
    <mdb-form-control [class.ls-mb-3]="emailControlHasErrors()">
      <input [formControlName]="EMAIL" mdbInput mdbValidate type="email" id="email" class="form-control form-control-lg" (blur)="validateEmail()"/>
      <label mdbLabel class="form-label" for="email">Email</label>
      <mdb-error *ngIf="controlHasError(EMAIL)">{{emailValidationResult}}</mdb-error>
    </mdb-form-control>
  </div>
</form>