import { createAction, props } from "@ngrx/store";
import { ActionType, LsAction } from "src/app/Core/LsAction";
import { Application, CompanyProfileRelationship, Page } from "src/app/Models";
import { PageRequest } from "@limestone/ls-shared-modules";
import { Product } from "src/app/Models/Enums";

export abstract class CompanyProfileRelationshipActions extends LsAction {
	/* eslint-disable */
  static COMPANY_PROFILE_RELATIONSHIP: Readonly<string> = 'COMPANY_PROFILE_RELATIONSHIP';
  static COMPANY_PROFILE_RELATIONSHIPS: Readonly<string> = 'COMPANY_PROFILE_RELATIONSHIPS';
  static UNENROLLED_COMPANY_PROFILE_RELATIONSHIPS: Readonly<string> = 'UNENROLLED_COMPANY_PROFILE_RELATIONSHIPS';
  static APPLICATIONS: Readonly<string> = 'APPLICATIONS';
  static APPLICATION: Readonly<string> = 'APPLICATION';
  /* eslint-enable */

	static getCompanyProfileRelationship = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.GET
		),
		props<{ companyProfileId: number; relationshipCompanyId: number }>()
	);

	static setCompanyProfileRelationship = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.SET
		),
		props<{ companyProfileRelationship: CompanyProfileRelationship }>()
	);

	static updateCompanyProfileRelationship = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.UPDATE
		),
		props<{ companyProfileRelationship: CompanyProfileRelationship; isEnrolledCodatCustomer?: boolean }>()
	);

	static createCompanyProfileRelationship = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.SAVE
		),
		props<{ companyProfileRelationship: CompanyProfileRelationship }>()
	);

	static companyProfileRelationshipSaveSuccessful = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.COMPLETE
		)
	);

	static companyProfileRelationshipSaveUnsuccessful = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.ERROR
		),
		props<{ errors: string[] }>()
	);

	static getCompanyProfileRelationships = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIPS,
			ActionType.GET
		),
		props<{ companyProfileId: number; product?: Product }>()
	);

	static setCompanyProfileRelationships = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIPS,
			ActionType.SET
		),
		props<{ companyProfileRelationships: CompanyProfileRelationship[] }>()
	);

	static getUnenrolledCompanyProfileRelationships = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.UNENROLLED_COMPANY_PROFILE_RELATIONSHIPS,
			ActionType.GET
		),
		props<{ companyProfileId: number; product?: Product }>()
	);

	static setUnenrolledCompanyProfileRelationships = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.UNENROLLED_COMPANY_PROFILE_RELATIONSHIPS,
			ActionType.SET
		),
		props<{ companyProfileRelationships: CompanyProfileRelationship[] }>()
	);

	static getApplications = createAction(
		CompanyProfileRelationshipActions.getActionName(CompanyProfileRelationshipActions.APPLICATIONS, ActionType.GET),
		props<{ companyProfileId: number; pr: PageRequest }>()
	);

	static setApplications = createAction(
		CompanyProfileRelationshipActions.getActionName(CompanyProfileRelationshipActions.APPLICATIONS, ActionType.SET),
		props<{ page: Page<Application> }>()
	);

	static submitApplications = createAction(
		CompanyProfileRelationshipActions.getActionName(CompanyProfileRelationshipActions.APPLICATIONS, ActionType.SAVE),
		props<{ companyProfileId: number; product?: Product }>()
	);

	static submitApplication = createAction(
		CompanyProfileRelationshipActions.getActionName(CompanyProfileRelationshipActions.APPLICATION, ActionType.SAVE),
		props<{ companyProfileRelationship: CompanyProfileRelationship }>()
	);

	static updateCustomerProgress = createAction(
		CompanyProfileRelationshipActions.getActionName(
			CompanyProfileRelationshipActions.COMPANY_PROFILE_RELATIONSHIP,
			ActionType.TRIGGER
		),
		props<{ companyProfileRelationship: CompanyProfileRelationship }>()
	);
}
