import { LsAction } from "../../../../Core";
import { createAction, props } from "@ngrx/store";
import { ActionType } from "../../../../Core/LsAction";
import { AccountSubmissionForm } from "../../../../Models";

export abstract class AccountCreationActions extends LsAction {
	/* eslint-disable */
    static ACCOUNT_SUBMISSION: Readonly<string> = 'ACCOUNT_SUBMISSION';
    /* eslint-enable */

	static createAccount = createAction(
		AccountCreationActions.getActionName(AccountCreationActions.ACCOUNT_SUBMISSION, ActionType.SAVE),
		props<{ account: AccountSubmissionForm }>()
	);
}
