import { IColumnHeader } from "./Interfaces/IColumnHeader";
import { GenericLookup } from "@limestone/ls-shared-modules";

export class CompanyUser {
	public companyId?: number;
	public userId?: number;
	public contactId?: number;
	public isInternal?: boolean;
	public firstName?: string;
	public lastName?: string;
	public emailAddress?: string;
	public title?: string;
	public b2cId?: string;
	public status?: GenericLookup<string>;
	public lastLogin?: Date;
	public userRole?: GenericLookup<string>;
	public lastInviteSent?: Date;

	constructor(
		companyId?: number,
		userId?: number,
		contactId?: number,
		isInternal?: boolean,
		firstName?: string,
		lastName?: string,
		emailAddress?: string,
		title?: string,
		b2cId?: string,
		status?: GenericLookup<string>,
		lastLogin?: Date,
		userRole?: GenericLookup<string>,
		lastInviteSent?: Date
	) {
		this.companyId = companyId;
		this.userId = userId;
		this.contactId = contactId;
		this.isInternal = isInternal;
		this.firstName = firstName;
		this.lastName = lastName;
		this.emailAddress = emailAddress;
		this.title = title;
		this.b2cId = b2cId;
		this.status = status;
		this.lastLogin = lastLogin;
		this.userRole = userRole;
		this.lastInviteSent = lastInviteSent;
	}

	static ColumnNames: IColumnHeader[] = [
		{ column: "User", relatedField: "firstName", canSort: true },
		{ column: "Status", relatedField: "StatusId", canSort: true },
		{ column: "Role", relatedField: "UserRoleId", canSort: true },
		{ column: "", relatedField: "", canSort: false }
	];
}
