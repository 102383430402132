<div class="d-flex flex-column">
  <form class="d-flex flex-column ls-mb-11" [formGroup]="form">
    <ls-text extraClasses="no-text-transform ls-mb-6" [displayConfig]="config(HEADER,'hl1')"></ls-text>
    <div class="d-flex flex-column" *ngIf="display(STATE_OF_FORMATION)">
      <ls-text extraClasses="no-text-transform ls-mb-3" [displayConfig]="config(STATE_OF_FORMATION,'q1')"></ls-text>
      <mdb-form-control class="half-max-width">
        <mdb-select [filter]="true" [formControlName]="STATE_OF_FORMATION" [id]="STATE_OF_FORMATION" [optionHeight]="56"
          class="form-control form-control-lg" mdbValidate>
          <mdb-option *ngFor="let state of stateProvinces" [value]="state.code">{{ state.name }}</mdb-option>
        </mdb-select>
        <label mdbLabel class="form-label" [for]="STATE_OF_FORMATION">
          <ls-text [displayConfig]="config(STATE_OF_FORMATION, 'label')"></ls-text>
        </label>
      </mdb-form-control>
    </div>
  </form>
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="form.invalid"
    #lsNextBtn>
    Next <span class="material-icons md-18"> arrow_forward</span>
  </button>
</div>