<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
	[isNextButtonDisabled]="isInvalid()">
	<ls-text id="hs1" extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'hs1')"></ls-text>
	<form [formGroup]="customerRelationshipDurationForm" class="d-flex flex-column">
		<div *ngIf="display(RELATIONSHIP_DURATION)">
			<ls-text id="q1" extraClasses="ls-mb-3" [displayConfig]="config(RELATIONSHIP_DURATION,'q1')"></ls-text>
			<div *ngIf="display(RELATIONSHIP_DURATION)" class="ls-mb-3 d-flex align-items-center">
				<ls-radio-buttons [lookups]="relationshipDurations" [controlName]="RELATIONSHIP_DURATION"
					[toolTips]="toolTips(RELATIONSHIP_DURATION)"></ls-radio-buttons>
			</div>
		</div>
		<div *ngIf="display(PAYMENT_TERMS)">
			<ls-text id="q2" extraClasses="ls-mb-3" [displayConfig]="config(PAYMENT_TERMS,'q2')"></ls-text>
			<div class="input-group terms">
				<ls-payment-terms-form-control [formControlName]="PAYMENT_TERMS"></ls-payment-terms-form-control>
			</div>
		</div>
	</form>
</ls-questionnaire>