<div class="ca-info-container d-flex flex-column ls-m-3 ls-m-sm-5 ls-m-md-6 ls-m-xxl-11 ls-mt-xxl-6 chatbot-margin">
    <div class="raistone-logo ls-mb-6"><img src="../../../../../../assets/Raistone-color.svg"/></div>
    <div class="ca-info-container-body d-flex flex-column flex-sm-row justify-content-between">
        <div class="ca-general-info-container d-flex flex-column ls-mr-0 ls-mr-sm-3 ls-mr-lg-1_25 ls-mr-xxl-17 ls-mb-5 ls-mb-md-0">
            <h1 class="l ls-mb-4 ls-mb-sm-6">Get access to your money on your terms</h1>
            <p class="title l ls-mb-4 ls-mb-sm-6" *ngIf="!isSCF">Thank you for your interest in Accounts Receivable Financing</p>
            <p class="l ls-mb-4 ls-mb-sm-1_25">Accelerate payment on your invoices that are due in weeks or months and get paid up to 100% advance rate, minus discount fee.</p>
            <p class="l ls-mb-4 ls-mb-sm-6">To get you up and running – and paid sooner – we will ask for information about your company and your customers.</p>
            <p class="l ls-mb-0">By creating an account, your info will be securely stored and your progress will be saved automatically.</p>
        </div>
        <div class="ca-general-info-form shadow-1-strong ls-p-4 ls-p-md-5 ls-p-xl-6">
            <p class="title m ls-mb-xxl-6 ls-mb-3">Create an account</p>
            <form class="d-flex flex-column" [formGroup]="caForm">
                <mdb-form-control class="ls-mb-4">
                    <input [formControlName]="BUSINESS_NAME_CONTROL" mdbInput mdbValidate type="text" id="bizName" class="form-control form-control-lg" />
                    <label mdbLabel class="form-label" for="bizName">Legal Business Name</label>
                </mdb-form-control>
                <mdb-form-control class="ls-mb-4">
                    <mdb-select [formControlName]="ANNUAL_REVENUE_CONTROL" mdbValidate [id]="ANNUAL_REVENUE_CONTROL" 
                                class="form-control form-control-lg" [optionHeight]="56"
                                (closed)="handleClose()" >
                      <mdb-option *ngFor="let arr of annualRevenueRanges" [value]="arr.id">{{ arr.name }}</mdb-option>
                    </mdb-select>
                    <label mdbLabel class="form-label" [for]="ANNUAL_REVENUE_CONTROL">Approximate Annual Revenue</label>
                  </mdb-form-control>
                <div class="w-100 ls-mb-4 d-flex flex-fill justify-content-between">
                    <mdb-form-control class="ls-mr-3 w-50">
                        <input [formControlName]="FIRST_NAME_CONTROL" mdbInput mdbValidate type="text" maxlength="40" id="fName" class="form-control form-control-lg" />
                        <label mdbLabel class="form-label" for="fName">First Name</label>
                        <mdb-error *ngIf="controlHasError(FIRST_NAME_CONTROL)">{{getControlError(FIRST_NAME_CONTROL)}}</mdb-error>
                    </mdb-form-control>
                    <mdb-form-control class="w-50">
                        <input [formControlName]="LAST_NAME_CONTROL" mdbInput mdbValidate type="text" maxLength="80" id="lName" class="form-control form-control-lg" />
                        <label mdbLabel class="form-label" for="lName">Last Name</label>
                        <mdb-error *ngIf="controlHasError(LAST_NAME_CONTROL)">{{getControlError(LAST_NAME_CONTROL)}}</mdb-error>
                    </mdb-form-control>
                </div>
                <mdb-form-control class="ls-mb-4">
                    <input [formControlName]="TITLE_CONTROL" mdbInput mdbValidate type="text" maxlength="80" id="title" class="form-control form-control-lg" />
                    <label mdbLabel class="form-label" for="title">Title</label>
                    <mdb-error *ngIf="controlHasError(TITLE_CONTROL)">{{getControlError(TITLE_CONTROL)}}</mdb-error>
                </mdb-form-control> 
                <div class="ls-mb-4">
                    <ls-phone-form-control #phoneComponent [formControlName]="PHONE_CONTROL"></ls-phone-form-control>
                </div>
                <div class="ls-mb-xxl-6 ls-mb-4">
                    <mdb-form-control>
                        <input [formControlName]="BUSINESS_EMAIL_CONTROL" mdbInput mdbValidate type="email" id="email" class="form-control form-control-lg" (blur)="validateEmail()"/>
                        <label mdbLabel class="form-label" for="email">Business Email</label>
                        <mdb-error *ngIf="caForm.get(BUSINESS_EMAIL_CONTROL)?.invalid && (caForm.get(BUSINESS_EMAIL_CONTROL)?.dirty || caForm.get(BUSINESS_EMAIL_CONTROL)?.touched)">{{emailValidationResult}}</mdb-error>
                    </mdb-form-control>
                </div>
                <div class="form-check d-flex flex-row ls-mb-3">
                    <input [formControlName]="TERMS_AGREEMENT_CONTROL"
                            mdbCheckbox
                            class="form-check-input ls-mr-0_75"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                    />
                    <label class="form-check-label xs-bold" for="flexCheckDefault">I agree to Raistone's <a target="_blank" href="https://raistone.com/terms-conditions/">Terms & Conditions</a> and <a target="_blank" href="https://raistone.com/privacy-policy/">Privacy Policy</a></label>
                </div>
            </form>
            <button type="button" class="btn btn-primary btn-lg w-100" [disabled]="continueDisabled || isDuplicate" (click)="submit()">Continue</button>
            <div class="d-flex flex-row ls-mt-lg-6" *ngIf="formError.length > 0">
                <ls-alert alertType="danger"><p class="m ls-mb-0" [innerHTML]="formError"></p></ls-alert>
            </div>
            <div class="justify-content-center d-flex flex-row ls-mt-4 ls-mt-lg-6">
                <p class="l ls-mb-0">Already have an account?</p><button class="btn-primary-borderless" (click)="loginRedirect()">Log in</button>
            </div>
        </div>
    </div>
</div>
