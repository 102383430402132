import { OnboardApplicationStatus } from "@limestone/ls-shared-modules";

export class OnboardApplicationStatusUtil {
	// Static method to map string to enum and return CSS class
	static getApplicationStatusClass(applicationStatusId: string): string {
		switch (applicationStatusId) {
			case OnboardApplicationStatus.INPROGRESS:
				return "ls-badge-app-inprogress";
			case OnboardApplicationStatus.SUBMITTED:
				return "ls-badge-app-submitted";
			case OnboardApplicationStatus.UNDREVIEW:
				return "ls-badge-app-undreview";
			case OnboardApplicationStatus.COMPLETE:
				return "ls-badge-app-complete";
			case OnboardApplicationStatus.APPROVED:
				return "ls-badge-app-approved";
			case OnboardApplicationStatus.AWAITSIG:
				return "ls-badge-app-awaitsig";
			case OnboardApplicationStatus.INACTIVE:
				return "ls-badge-app-inactive";
			case OnboardApplicationStatus.PREAPPROVE:
				return "ls-badge-app-preapprove";
			case OnboardApplicationStatus.RDYTOTRANS:
				return "ls-badge-app-rdytotrans";
			case OnboardApplicationStatus.TRMSACCEPT:
				return "ls-badge-app-trmsaccept";
			default:
				return "";
		}
	}
}
