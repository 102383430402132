import { DisplayConfig } from "./DisplayConfig";
/// <summary>
/// This represents a section of content in a component.
/// </summary>
export class Layout {
	/// <summary>
	/// Controls visibility of a section of html in a component
	/// </summary>
	display = true;
	/// <summary>
	///  key: id of the element
	///  value: display config of the element
	/// </summary>
	blocks?: Map<string, DisplayConfig>;
	/// <summary>
	/// key: the id of the lookup value
	/// value: text to be displayed
	/// </summary>
	toolTips?: Map<string | number, string>;
	/**
	 *
	 */
	constructor(blocks?: Map<string, DisplayConfig>, toolTips?: Map<string | number, string>, display = true) {
		this.blocks = blocks;
		this.toolTips = toolTips;
		this.display = display;
	}
}
