import { LsAction } from "../../Core";
import { createAction, props } from "@ngrx/store";
import { ActionType } from "../../Core/LsAction";
import { QuestionSet } from "../../Models";

export abstract class ScoringActions extends LsAction {
	/* eslint-disable */
  static SCORING:Readonly<string> = "SCORING";
    /* eslint-enable */

	static getScoring = createAction(ScoringActions.getActionName(ScoringActions.SCORING, ActionType.GET));
	static setScoring = createAction(
		ScoringActions.getActionName(ScoringActions.SCORING, ActionType.SET),
		props<{ pricingGauge: Map<string, QuestionSet> }>()
	);
}
