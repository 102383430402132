import { LookupKey, LookupTables, SortDirection } from "@limestone/ls-shared-modules";
import { Context } from "./Enums";

export class LookupKeys {
	static get FinancialDisclosureYesNo(): LookupKey {
		return new LookupKey(
			LookupTables.YesNo,
			SortDirection.DESC,
			true,
			true,
			undefined,
			undefined,
			Context.FinanceDisclosure
		);
	}
	static get YesNo(): LookupKey {
		return new LookupKey(LookupTables.YesNo, SortDirection.DESC, true, true);
	}
	static get CollectionPreference(): LookupKey {
		return new LookupKey(LookupTables.CollectionPreference, SortDirection.ASC, true, true);
	}
}
