<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" [isNextButtonDisabled]="customerContactForm.invalid">
  <h1 class="m no-text-transform ls-mb-6">Share your customer’s information</h1>
  <p *ngIf="!companyProfileRelationship?.relationshipCompanyName" class="l ls-mb-8">Please enter the information of the customer whose receivables you would like to finance.</p>

  <form [formGroup]="customerContactForm">
    <div class="customer-contact d-flex flex-column">

      <mdb-form-control class="legal-name ls-mb-8">
        <input mdbInput [formControlName]="LEGAL_NAME" mdbValidate type="text" id="legalName"
               class="form-control form-control-lg" />
        <label mdbLabel class="form-label" for="legalName">Legal Business Name</label>
      </mdb-form-control>

      <div class="d-flex flex-column">
        <label class="s-caps ls-mb-3">Customer Billing Address</label>
        <ls-address-form-control [formControlName]="BILLING"></ls-address-form-control>
      </div>
    </div>
  </form>
</ls-questionnaire>