<div class="ls-mt-8" [formGroup]="customerSettingsForm" [class.grid]="!readonly">
	<ng-container *ngIf="!readonly; else readonlyView">
		<div class="g-col-12 g-col-md-6">
			<div class="g-col-12 ls-mb-8">
				<span>
					<label class="s">Customer Notification
						<ls-info-dialog-icon [header]="SharedText.CustomerSettings.notifyHeader"
							[content]="SharedText.CustomerSettings.notifyContent">
							<ls-tooltip>
								<span class="material-icons li-tooltip-icon ls-icon-primary-tooltip"
									reference>info</span>
								<div content>
									<label class="l-bold text-wrap">Notifying your customer</label>
									<p class="m text-wrap">If you decide to notify your customer to pay Raistone
										directly, we'll provide them with a
										Notice of Assignment (NOA) to sign and return.</p>
									<p class="m text-wrap">We can manage the notification and do all the collections
										without your intervention.</p>
									<p class="m text-wrap ls-mb-0">You can receive more favorable pricing if you notify
										your customer to pay
										Raistone directly.</p>
								</div>
							</ls-tooltip>

						</ls-info-dialog-icon>
					</label>


				</span>
				<mdb-form-control class="ls-mt-3">
					<mdb-select [formControlName]="CUSTOMER_NOTIFICATION" [id]="CUSTOMER_NOTIFICATION"
						[optionHeight]="56" mdbValidate class="form-control form-control-lg">
						<mdb-option *ngFor="let fd of financeDisclosures" [value]="fd.id">{{fd.name}}</mdb-option>
					</mdb-select>
					<label mdbLabel class="form-label" [for]="CUSTOMER_NOTIFICATION">Customer Notification</label>
				</mdb-form-control>
			</div>
			<div class="g-col-12 ls-mb-8">
				<div class="d-flex flex-column">
					<span class="ls-mb-3">
						<label class="s">autoXcel<ls-info-dialog-icon [header]="SharedText.AutoXcel.tooltipHeader"
								[content]="SharedText.AutoXcel.tooltipContent">
								<ls-tooltip>
									<span class="material-icons li-tooltip-icon ls-icon-primary-tooltip"
										reference>info</span>
									<div content>
										<label class="l-bold text-wrap">autoXcel</label>
										<p class="m text-wrap ls-mb-0">Automatically accelerating payment of your
											invoices lowers pricing and speeds up
											financing. By disabling autoXcel, you or someone at your business must log
											into our platform to manually
											select each invoice you’d like to get financed.</p>
									</div>
								</ls-tooltip>

							</ls-info-dialog-icon></label>

					</span>
					<div class="d-flex flex-row align-items-center">
						<ls-toggle [controlName]="AUTOXCEL"
							[toggled]="companyProfileRelationship!.isAutoXcel!"></ls-toggle>
						<label class="xs-bold ls-ml-3">{{customerSettingsForm.get(AUTOXCEL)?.value! | booleanDisplay :
							'EnabledDisabled'}}</label>
					</div>
				</div>
			</div>
			<div class="g-col-12 ls-mb-5">
				<span>
					<label class="s">Preferred Payment Method<ls-info-dialog-icon (iconClick)="showPaymentInfo()">
							<label class="s">Preferred Payment Method</label>
							<ls-tooltip>
								<span class="material-icons li-tooltip-icon ls-icon-primary-tooltip"
									reference>info</span>
								<div content>
									<label class="l-bold text-wrap">Payment methods</label>
									<p class="m text-wrap ls-mb-0">Different methods of payment affect the speed your
										application can be processed,
										any fees, and other factors.</p>
								</div>
							</ls-tooltip>

						</ls-info-dialog-icon></label>

				</span>
				<mdb-form-control class="ls-mt-3">
					<mdb-select [formControlName]="PAYMENT_METHOD" [id]="PAYMENT_METHOD" [optionHeight]="56" mdbValidate
						class="form-control form-control-lg">
						<mdb-option *ngFor="let payment of preferredPayments"
							[value]="payment.id">{{payment.name}}</mdb-option>
					</mdb-select>
					<label mdbLabel class="form-label" [for]="PAYMENT_METHOD">Preferred Payment Method</label>
				</mdb-form-control>
			</div>
		</div>
		<div class="g-col-12 g-col-md-6 ls-pl-md-17">
			<div class="g-col-12 ls-mb-8">
				<div class="d-flex flex-column">
					<span class="ls-mb-3">
						<label class="s">Invoice Approvals
							<ls-info-dialog-icon [header]="SharedText.InvoiceApprovals.tooltipHeader"
								[content]="SharedText.InvoiceApprovals.tooltipContent">
								<ls-tooltip>
									<span class="material-icons li-tooltip-icon ls-icon-primary-tooltip"
										reference>info</span>
									<div content>
										<label class="l-bold text-wrap">Invoice approvals</label>
										<p class="m text-wrap ls-mb-0">Your customer can provide approval status for
											your invoices. Getting invoice
											approval from your customers will result in better pricing.</p>
									</div>
								</ls-tooltip>

							</ls-info-dialog-icon>
						</label>

					</span>
					<div class="d-flex flex-row align-items-center">
						<ls-toggle [controlName]="INSTRUMENT_APPROVAL_CONFIRMED"
							[toggled]="companyProfileRelationship!.isInstrumentApprovalConfirmed!"></ls-toggle>
						<label class="xs-bold ls-ml-3">{{customerSettingsForm.get(INSTRUMENT_APPROVAL_CONFIRMED)?.value!
							| booleanDisplay : 'YesNo'}}</label>
					</div>
				</div>
			</div>
			<div class="g-col-12">
				<div class="d-flex flex-column">
					<span class="ls-mb-3">
						<label class="s">Expedited Financing
							<ls-info-dialog-icon [header]="SharedText.ExpeditedFinancing.tooltipHeader"
								[content]="SharedText.ExpeditedFinancing.tooltipContent">
								<ls-tooltip>
									<span class="material-icons li-tooltip-icon ls-icon-primary-tooltip"
										reference>info</span>
									<div content>
										<label class="l-bold text-wrap">Expedited financing</label>
										<p class="m text-wrap ls-mb-0">Before funding begins, we confirm your bank
											account via a passthrough payment. If
											you want to begin funding prior to confirming your bank account, we can do
											so at a higher price. Usually
											this adds a business day to the funding process.</p>
									</div>
								</ls-tooltip>

							</ls-info-dialog-icon>
						</label>

					</span>
					<div class="d-flex flex-row align-items-center">
						<ls-toggle [controlName]="FINANCING_EXPEDITED"
							[toggled]="companyProfileRelationship!.isFinanceExpedited!"></ls-toggle>
						<label class="xs-bold ls-ml-3">{{customerSettingsForm.get(FINANCING_EXPEDITED)?.value! |
							booleanDisplay : 'YesNo'}}</label>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #readonlyView>
	<ls-customer-settings-readonly
		[companyProfileRelationship]="companyProfileRelationship"></ls-customer-settings-readonly>
</ng-template>