import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FileUploadBaseComponent } from "src/app/Core";
import { CompanyProfile } from "src/app/Models";
import { CompanyDocumentSelectors } from "../../../OnboardingStateManagement";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { CompanyProfileSelectors } from "src/app/AppStateManagement";
import { Actions } from "@ngrx/effects";
import { RouteStepDataService } from "../../../Services";
import { UploadStatus } from "src/app/Models/Enums/UploadStatus";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "ls-bank-letter",
	templateUrl: "./bank-letter.component.html",
	styleUrls: ["./bank-letter.component.scss"]
})
export class BankLetterComponent extends FileUploadBaseComponent {
	public companyProfile?: CompanyProfile;
	public isUSBank?: boolean;
	public headerText = "Bank letter";

	constructor(
		public router: Router,
		public store: Store,
		public companyDocumentSelectors: CompanyDocumentSelectors,
		public dialog: MdbModalService,
		public companyProfileSelectors: CompanyProfileSelectors,
		public actions$: Actions,
		public sanitizer: DomSanitizer,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, dialog, actions$, sanitizer, routeStepDataService);
		this.maxFileCount = 1;
		this.initData();
	}

	public isNextButtonDisabled(): boolean {
		const completedFile = this.files?.find((f) => f.status === UploadStatus.COMPLETE);
		return !completedFile;
	}

	public continue() {
		this.nav();
	}
	public configureText(company: CompanyProfile): void {
		this.isUSBank = company.hasUSBankAccount;
		this.headerText = company.hasUSBankAccount ? "Voided check or Bank letter" : "Bank letter";
	}
	public initData(): void {
		combineLatest([
			this.store.select(this.companyDocumentSelectors.selectDocumentsFor(this.getDocType())),
			this.store.select(this.companyProfileSelectors.selectCompanyProfile)
		])
			.pipe(
				filter(([docs, cp]) => !!docs && !!cp),
				takeUntil(this.getComponentTearDown()),
				map(([docs, cp]) => {
					this.companyProfile = cp!;
					this.configureText(this.companyProfile!);
					this.files = this.rebuildFilesListAfterUpload(docs!);
					this.disabled = this.files!.filter((f) => f.status !== "Failed").length! >= this.maxFileCount;
					this.setCompanyId(this.companyProfile!.companyId!);
					this.submitted = this.companyProfile!.hasSubmittedApplications ?? false;
				})
			)
			.subscribe();
	}
}
