import { Component, Input } from "@angular/core";
import { ControlContainer, FormControl, FormGroupDirective } from "@angular/forms";
import { FeatureFlags, FeatureFlagSelectors, GenericLookup } from "@limestone/ls-shared-modules";
import { filter, map, take } from "rxjs";
import { RadioButtonTooltip } from "src/app/Models/RadioButtonTooltip";
import { Store } from "@ngrx/store";

@Component({
	selector: "ls-radio-buttons",
	templateUrl: "./radio-buttons.component.html",
	styleUrls: ["./radio-buttons.component.scss"],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RadioButtonsComponent {
	is103Enabled = false;
	control?: FormControl;
	@Input() lookups?: GenericLookup<string | number>[];
	@Input() toolTips?: Map<string | number, RadioButtonTooltip>;
	@Input() legacyToolTips?: Map<string | number, string>;
	@Input() controlName?: string;
	@Input() set value(value: string | number | undefined) {
		if (this.controlName) {
			this.configureForm(value);
		}
	}
	@Input() extraClasses?: string;
	constructor(
		public fgd: FormGroupDirective,
		public store: Store,
		public ffs: FeatureFlagSelectors
	) {
		this.store
			.select(this.ffs.selectFeatureFlags)
			.pipe(
				filter((flags) => !!flags),
				take(1),
				map((flags) => {
					if (flags?.some((f) => f.id === FeatureFlags.Onboarding_V1_0_3 && f.enabled)) {
						this.is103Enabled = true;
					}
				})
			)
			.subscribe();
	}

	configureForm(value: string | number | undefined) {
		if (this.controlName) {
			if (this.fgd.form.get(this.controlName!)) {
				this.fgd.form.get(this.controlName!)!.patchValue(value);
			} else {
				this.control = new FormControl(value);
				this.fgd.form.addControl(this.controlName!, this.control, { emitEvent: false });
			}
		}
	}
	getTooltip(id: number | string | undefined) {
		if (!id) return undefined;
		const idAsString = id.toString();
		return this.toolTips?.get(idAsString);
	}
}
