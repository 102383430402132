import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import copy from "fast-copy";
import { CompanyProfileRelationshipState } from "./company-profile-relationship-reducer";

@Injectable()
export class CompanyProfileRelationshipSelectors {
	appState = (state: any) => state.companyProfileRelationship;

	selectCompanyProfileRelationship = createSelector(this.appState, (state: CompanyProfileRelationshipState) =>
		copy(state.companyProfileRelationship)
	);

	selectCompanyProfileRelationships = createSelector(this.appState, (state: CompanyProfileRelationshipState) =>
		copy(state.companyProfileRelationships)
	);
	selectUnenrolledCompanyProfileRelationships = createSelector(
		this.appState,
		(state: CompanyProfileRelationshipState) => copy(state.unenrolledCompanyProfileRelationships)
	);

	selectApplications = createSelector(this.appState, (state: CompanyProfileRelationshipState) => copy(state.page));
}
