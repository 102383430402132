import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Subject, filter, map, take, takeUntil, withLatestFrom } from "rxjs";
import {
	AppSelectors,
	AppState,
	CompanyProfileSelectors,
	SalesforceSupportActions,
	SalesforceSupportSelectors
} from "src/app/AppStateManagement";
import { Case } from "src/app/Models";
import { CaseStatus } from "../../../../../Models/Enums";
import { Attachment } from "src/app/Models/Attachment";

@Component({
	selector: "ls-support-case-detail",
	templateUrl: "./support-case-detail.component.html",
	styleUrls: ["./support-case-detail.component.scss"]
})
export class SupportCaseDetailComponent implements OnInit, OnDestroy, AfterViewInit {
	private componentTeardown$: Subject<any> = new Subject<any>();
	public detail: Case = {};
	public get CaseStatus(): typeof CaseStatus {
		return CaseStatus;
	}
	public showReply = false;
	public userEmail?: string;
	public attachments?: any;
	public userId?: number;
	public threadId?: string;
	public replyToId?: string;
	public maxCharsPerLine!: number;

	@ViewChild("fileName") fileName!: ElementRef;

	constructor(
		private readonly _salesforceSupportSelectors: SalesforceSupportSelectors,
		private readonly _appSelectors: AppSelectors,
		private readonly _companyProfileSelectors: CompanyProfileSelectors,
		private readonly _store: Store<AppState>,
		private readonly _actions$: Actions,
		private cdr: ChangeDetectorRef
	) {}

	public ngOnInit(): void {
		this._store
			.select(this._appSelectors.selectUserRoles)
			.pipe(
				take(1),
				withLatestFrom(this._store.select(this._companyProfileSelectors.selectCompanyProfile)),
				filter(([user, cp]) => !!user && !!cp),
				map(([user, cp]) => {
					this.userEmail = cp?.users?.find((u) => u.b2cId === user?.userId)?.emailAddress;
					this.userId = cp?.users?.find((u) => u.b2cId === user?.userId)?.userId;
				})
			)
			.subscribe();

		this._store
			.select(this._salesforceSupportSelectors.selectCase)
			.pipe(
				filter((caseDetail) => !!caseDetail),
				takeUntil(this.componentTeardown$),
				map((caseDetail) => {
					this.detail = caseDetail!;
					this.threadId = caseDetail?.messages?.map((m) => m.threadId)[0];
					this.replyToId = caseDetail?.messages?.map((m) => m.recordId)[0];
					this.attachments = caseDetail?.messages?.flatMap((email) => email.attachments);
					this.cdr.detectChanges();
					this.calculateMaxFileLength();
				})
			)
			.subscribe();

		this._actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(SalesforceSupportActions.emailMessageSendSuccessful),
				map(() => {
					this._store.dispatch(SalesforceSupportActions.getCase({ caseId: this.detail.id! }));
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this._store.dispatch(SalesforceSupportActions.updateCase({ supportCase: this.detail }));
		this.calculateMaxFileLength();
	}

	public ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	@HostListener("window:resize", ["$event"])
	onResize() {
		this.cdr.detectChanges();
		this.calculateMaxFileLength();
	}

	public calculateMaxFileLength(): void {
		if (this.fileName) {
			const maxLines = 2;
			const containerWidth = this.fileName.nativeElement.offsetWidth / maxLines;
			const charWidth = 6;
			this.maxCharsPerLine = Math.floor(containerWidth / charWidth);
		}
	}

	public truncateMultiLineFileName(name: string, bodyLength: string): string | void {
		if (this.maxCharsPerLine) {
			if (name.length <= this.maxCharsPerLine) {
				return name;
			} else {
				const requiredEndSegmentLength = 8;
				const firstLine = name.slice(0, this.maxCharsPerLine);
				let secondLine = name.slice(this.maxCharsPerLine);
				if (secondLine.length > this.maxCharsPerLine) {
					secondLine =
						secondLine.slice(0, this.maxCharsPerLine - (requiredEndSegmentLength + bodyLength.length)) +
						"..." +
						secondLine.slice(-8);
				}
				return `${firstLine}\n${secondLine}`;
			}
		}
	}

	public toggleShowReply(): void {
		this.showReply = !this.showReply;
	}

	public download(attachment: Attachment): void {
		this._store.dispatch(
			SalesforceSupportActions.downloadAttachment({
				userId: this.userId!,
				caseId: this.detail!.id!,
				blobPath: attachment.blobPath!,
				fileName: attachment.name!
			})
		);
	}
}
