import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { TextStyle } from "src/app/Models/Enums";
import { DisplayConfig } from "../../app/Models";
import { Guid } from "../../Utils";

@Component({
	selector: "ls-text",
	templateUrl: "./text.component.html",
	styleUrls: ["./text.component.scss"]
})
export class TextComponent implements AfterViewInit {
	/**
	 * The id of the element. Generated GUID if not provided
	 */
	@Input() id?: string;

	/**
	 * The display configuration for the element.
	 * @param displayConfig
	 */
	@Input() set displayConfig(displayConfig: DisplayConfig | undefined) {
		this._displayConfig = displayConfig;
		if (displayConfig && !this.forLabel && this.isLabel()) {
			console.warn(
				`TextComponent: displayConfig is a label with text '${displayConfig?.text}', but no forLabel was provided. This will cause the label to not be associated with an input.`
			);
		}
	}
	get displayConfig(): DisplayConfig | undefined {
		return this._displayConfig;
	}
	private _displayConfig?: DisplayConfig;

	/**
	 * The id of the input element this label is for. Required when DisplayConfig.TextStyle is a label.
	 * @param forLabel
	 */
	@Input() set forLabel(forLabel: string | undefined) {
		this._for = forLabel;
	}
	get forLabel(): string | undefined {
		return this._for;
	}
	private _for?: string;

	/**
	 * Extra classes to apply to the element.
	 */
	@Input() extraClasses?: string = "";
	constructor(element: ElementRef) {
		this.nativeElement = element.nativeElement;
		if (!this.id) {
			this.id = `text${new Guid().New(false)}`;
		}
	}
	nativeElement: HTMLElement;
	ngAfterViewInit() {
		if (!this.displayConfig?.display) this.nativeElement.remove();
	}
	protected readonly TextStyle = TextStyle;

	private isLabel() {
		return (
			this.displayConfig?.textStyle === TextStyle.LABEL_EXTRA_LARGE ||
			this.displayConfig?.textStyle === TextStyle.LABEL_LARGE ||
			this.displayConfig?.textStyle === TextStyle.LABEL_LARGE_BOLD ||
			this.displayConfig?.textStyle === TextStyle.LABEL_MEDIUM ||
			this.displayConfig?.textStyle === TextStyle.LABEL_MEDIUM_CAPS ||
			this.displayConfig?.textStyle === TextStyle.LABEL_MEDIUM_BOLD ||
			this.displayConfig?.textStyle === TextStyle.LABEL_SMALL ||
			this.displayConfig?.textStyle === TextStyle.LABEL_SMALL_CAPS ||
			this.displayConfig?.textStyle === TextStyle.LABEL_EXTRA_SMALL ||
			this.displayConfig?.textStyle === TextStyle.LABEL_EXTRA_SMALL_BOLD ||
			this.displayConfig?.textStyle === TextStyle.LABEL_EXTRA_SMALL_BADGE ||
			this.displayConfig?.textStyle === TextStyle.LABEL_EXTRA_EXTRA_SMALL
		);
	}
}
