/// <summary>
/// Used as the header on many customer setup pages
/// </summary>
class Headers {
	readonly tellUsMore = "Tell us more about your relationship with your customer";
	readonly letsGetTheRest = "Let’s get the rest of your company info";
}
/// <summary>
/// User in ARF_PG facility-configuration and ARF_V1 notice of assignment
/// </summary>
class FinancialDisclosure {
	readonly question =
		"Would you prefer that we notify your customer to pay us directly in exchange for a lower discount rate?";
	readonly paragraph1 =
		"Disclosure of Accounts Receivable Financing relationships to your customers is a common practice, but it is not mandatory. At Raistone, we prioritize your business’s unique needs and goals, offering flexible options tailored to suit your financial strategy.";
	readonly paragraph2 =
		"The manner in which you choose to communicate the financing relationship and manage collections can influence both the pricing and the ure of the financing we provide. Our customer support team is available to answer any questions you may have.";
	readonly accordionHeader = "Benefits of disclosing to your customer";
	readonly accordionItem1 = "Less resources and bandwidth needed by you and your company.";
	readonly accordionItem2 = "We can contact and collect on your behalf, resulting in optimal line efficiency";
	readonly toolTipYes =
		"If you decide to notify your customer to pay Raistone directly, we’ll provide them with a Notice of Assignment (NOA) to sign and return. We can manage the notification and do all the collections without your intervention. You can receive more favorable pricing if you notify your customer to pay Raistone";
	readonly toolTipNo =
		"If you decide to continue to collect payment from your customer, you’ll get less favorable pricing. You wouldn’t disclose this financing to your customer";
}

/// <summary>
/// User in ARF_PG facility-configuration and ARF_V1 notice of assignment
/// </summary>
class CollectionPreference {
	readonly question = "Going forward, how would you like your customer to pay?";
	readonly paragraph1 = "We can manage the notifications and do all the collections without your intervention.";
}
export class SharedText {
	static CustomerSetup = new Headers();
	static FinancialDisclosure = new FinancialDisclosure();
	static CollectionPreference = new CollectionPreference();
	static readonly vCardToolTip =
		"<label class='l-bold text-wrap'>Virtual Cards</label> <p class='m ls-mb-2 ls-mt-2 text-wrap'>Also known as vCard, a Virtual Card is a single-use card that’s generated in a digital format that provides a secure way to get paid even faster. Your company can get paid for invoices earlier, helping you scale and grow with more cash on hand.</p> <a class='ls-hyperlink' href='https://raistone.com/vcard-for-suppliers/' target='_blank'><span>Learn more about vCard</span></a>";
}
