import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Flow } from "../app/Models/Enums";
import { InviteContext } from "../app/Models";
import { FlowService } from "./flow.service";

@Injectable({
	providedIn: "root"
})
export class InvitationService {
	private flow?: Flow;
	private debtorCompanyId?: number;
	constructor(
		private activatedRoute: ActivatedRoute,
		private flowService: FlowService
	) {}

	/// Set the invitation context from the query params
	/// Returns true if the invitation context was set; otherwise, false.
	setInvitationContext(): boolean {
		const params = this.activatedRoute.snapshot.queryParams;
		if (params["invitationCode"]) {
			const invitationCode = atob(params["invitationCode"]);
			const [debtorCompanyId, flow] = invitationCode.split(":");
			this.flow = Flow[flow as keyof typeof Flow];
			this.debtorCompanyId = parseInt(debtorCompanyId, 10);
			return true;
		}
		return false;
	}

	getFlow(): Flow | undefined {
		return this.flow;
	}

	getDebtorCompanyId(): number | undefined {
		return this.debtorCompanyId;
	}

	createInviteContext(companyId: number): InviteContext {
		return new InviteContext(companyId, this.debtorCompanyId, this.flow, this.flowService.mapFlowToProduct(this.flow));
	}
}
