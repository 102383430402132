import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable, combineLatest, filter, map, take } from "rxjs";
import {
	AppSelectors,
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors
} from "../AppStateManagement";
import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";

@Injectable()
export class ApplicationDetailResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors,
		private companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors
	) {}

	applicationDetail(relationshipCompanyId: number): Observable<any> {
		return combineLatest([
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
			this.store.select(this.companyProfileRelationshipSelectors.selectApplications)
		]).pipe(
			filter(
				([cpr, apps]) =>
					!!cpr &&
					cpr.relationshipCompanyId === relationshipCompanyId &&
					!!apps &&
					apps!.content!.find((application) => application.companyId === cpr.relationshipCompanyId)
						?.applicationStatusId === cpr.applicationStatusId
			),
			take(1)
		);
	}

	resolve(route: ActivatedRouteSnapshot): Observable<any> {
		const relationshipCompanyId = parseInt(route.paramMap.get("relationshipCompanyId")!, 10);
		this.initPrefetchData(relationshipCompanyId, route);
		return this.applicationDetail(relationshipCompanyId);
	}

	initPrefetchData(relationshipCompanyId: number, route: ActivatedRouteSnapshot): void {
		combineLatest([
			this.store.select(this.appSelectors.selectUserRoles),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship),
			this.store.select(this.companyProfileRelationshipSelectors.selectApplications)
		])
			.pipe(
				take(1),
				filter(
					([userRole, cpr, apps]) =>
						!!userRole &&
						userRole.roles.length > 0 &&
						(!cpr ||
							cpr.relationshipCompanyId !== relationshipCompanyId ||
							(!!apps &&
								apps!.content!.find((application) => application.companyId === cpr?.relationshipCompanyId)
									?.applicationStatusId !== cpr?.applicationStatusId))
				),
				map(([userRole, cpr, apps]) => {
					this.store.dispatch(
						CompanyProfileRelationshipActions.getCompanyProfileRelationship({
							companyProfileId: userRole!.roles[0].companyId,
							relationshipCompanyId: relationshipCompanyId
						})
					);
				})
			)
			.subscribe();
	}
}
