<form class="d-flex flex-column" [formGroup]="form">
  <h1 class="l ls-mb-8">Tell us more about your financing needs</h1>
  <div class="d-flex flex-column ls-mb-8">
    <h6 class="l ls-mb-3">How much are you looking to finance per year?</h6>
    <mdb-form-control class="half-max-width">
      <mdb-select [formControlName]="RANGE" [id]="RANGE" [optionHeight]="56" class="form-control form-control-lg" mdbValidate>
        <mdb-option *ngFor="let fr of ranges" [value]="fr.id">{{ fr.name }}</mdb-option>
      </mdb-select>
      <label mdbLabel class="form-label" [for]="RANGE">Financing Amount</label>
    </mdb-form-control>
  </div>
  <div class="d-flex flex-column ls-mb-11">
    <h6 class="l ls-mb-3">How often does your company intend to use financing?</h6>
    <div class="ls-mb-3 d-flex align-items-center" *ngFor="let ff of frequencies">
      <input mdbRadio [formControlName]="FREQUENCY" class="radio frequency-input" type="radio" name="frequency"
        id="{{ff.id}}" value="{{ff.id}}"/>
      <label class="xl frequency-label" for="{{ff.id}}">{{ ff.name }}</label>
    </div>
  </div>
  
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg"
    [disabled]="isInvalid()"  #lsNextBtn>
    Next <span class="material-icons md-18"> arrow_forward</span>
  </button>
</form>