import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ObservableInput, throwError, timer } from "rxjs";
import { catchError, map, retry, tap } from "rxjs/operators";
import { CodatConnection, CompanyProfile, IntegrationDataConnection } from "src/app/Models";
import { DisplayedBankAccount } from "src/app/Models/DisplayedBankAccount";
import { HttpService } from "src/services/HTTP";

@Injectable()
export class CodatService extends HttpService {
	constructor(private http: HttpClient) {
		super(http);
		this.path = "/api/codat";
	}

	getIntegrations(): Observable<IntegrationDataConnection[]> {
		return this.http
			.get<IntegrationDataConnection[]>(`${this.path}/integrations`)
			.pipe(
				map((idcs: IntegrationDataConnection[]) =>
					idcs.map((idc) => new IntegrationDataConnection(idc.key, idc.name, idc.logoUrl))
				)
			);
	}

	createCodatCompanyPlatform(cp: CompanyProfile): Observable<string> {
		return this.http.post(`${this.path}/companyProfile/${cp.companyId}/connect-account-platform`, cp, {
			responseType: "text"
		});
	}

	createCodatConnection(cp: CompanyProfile): Observable<string> {
		return this.http.post(`${this.path}/companyProfile/${cp.companyId}/connect-bank`, cp, { responseType: "text" });
	}

	saveCodatAccountingConnection(cp: CompanyProfile, cc: CodatConnection): Observable<CodatConnection> {
		return this.http.post(`${this.path}/companyProfile/${cp.companyId}/save-connection/account-platform`, cc);
	}

	saveCodatBankConnection(cp: CompanyProfile, cc: CodatConnection): Observable<CodatConnection> {
		return this.http.post(`${this.path}/companyProfile/${cp.companyId}/save-connection/bank`, cc);
	}

	deleteCodatCompanyPlatform(cp: CompanyProfile): Observable<CompanyProfile> {
		return this.http
			.delete<CompanyProfile>(`${this.path}/companyProfile/${cp.companyId}/account-platform`, { body: cp })
			.pipe(map((cp) => cp));
	}

	deleteCodatConnection(cp: CompanyProfile): Observable<CompanyProfile> {
		return this.http
			.delete<CompanyProfile>(`${this.path}/companyProfile/${cp.companyId}/banking`, { body: cp })
			.pipe(map((cp) => cp));
	}

	notifyNewAccountingPlatform(accountingPlatform: string): Observable<any> {
		const headers = new HttpHeaders({ "Content-Type": "text/plain" });
		return this.http.post(`${this.path}/notify-new-accounting-platform`, accountingPlatform, { headers });
	}
	static retryOnResponseCode409 = (error: HttpErrorResponse, retryCount: number): ObservableInput<any> => {
		if (error.status === 409) return timer(5000);
		else throw throwError(() => error);
	};
	getBankAccounts(companyId: number): Observable<DisplayedBankAccount[]> {
		return this.http
			.get<DisplayedBankAccount[]>(`${this.path}/companyProfile/${companyId}/bank-accounts`, {
				observe: "response"
			})
			.pipe(
				retry({ count: 5, delay: CodatService.retryOnResponseCode409 }),
				map((response) => {
					return response.body ?? [];
				})
			);
	}

	saveBankAccount(companyId: number, codatConnection: CodatConnection): Observable<CodatConnection> {
		return this.http.post(`${this.path}/companyProfile/${companyId}/save-connection/bank-accounts`, codatConnection);
	}
}
