import { Question } from "src/app/Models/Question";
import { Operation } from "../../app/Models/Enums/Operation";
import { OperationAction, Score } from "src/app/Models";
import { environment } from "src/environments/environment";
import { BehaviorSubject, map, Subject } from "rxjs";
import { FormGroup } from "@angular/forms";
export class CalculatorService {
	constructor(
		public questions: Question[],
		/**
		 * the "Low" value, will be calculated if not provided
		 */
		minScore?: number,
		/**
		 * the "High" value, will be calculated if not provided
		 */
		maxScore?: number,
		formGroup?: FormGroup
	) {
		this.verbose = environment.environmentName !== "Production";
		if (maxScore !== undefined) this.maxScore = maxScore;
		else this.maxScore = this.determineMaxScore();
		if (minScore !== undefined) this.minScore = minScore;
		else this.minScore = this.determineMinScore();
		this.formGroup = formGroup;
		this.formGroup?.valueChanges.pipe(map((v) => this.calculate(v))).subscribe();
	}
	public formGroup?: FormGroup;
	private readonly verbose;
	public readonly maxScore: number;
	public readonly minScore: number;
	private calculatedScores = new Map<string, number>();
	public percent = new Subject<number>();
	/**
	 * assumes question will only add points to themselves,
	 * if this is not the case pass in a max score in the constructor and that will be used
	 */
	determineMaxScore() {
		return this.questions
			.flatMap((q) => Math.max(...this.findScores(q.questionId, q.scores)))
			.reduce((total, points) => (total += points));
	}
	findScores(id: string, scores: Score[]) {
		const result = scores.flatMap((s) =>
			s.operations.filter((a) => a.operationType === Operation.Add && a.targetQuestionId === id).map((p) => p.points)
		);
		return result.length === 0 ? [0] : result;
	}
	/**
	 * assumes question will only add points to themselves,
	 * if this is not the case pass in a max score in the constructor and that will be used
	 */
	determineMinScore() {
		return this.questions
			.flatMap((q) => Math.min(...this.findScores(q.questionId, q.scores)))
			.reduce((total, points) => (total += points));
	}

	calculate(values?: any): number {
		if (this.maxScore === 0) return 0;
		this.questions.forEach((q) => {
			this.calculatedScores.set(q.questionId, 0);
		});
		this.runCalculations(Operation.Add, values);
		this.runCalculations(Operation.ReduceBy, values);

		let points: number = [...this.calculatedScores.values()].reduce((total, value) => (total += value), 0);
		let max = this.maxScore;
		if (this.minScore !== 0) {
			points -= this.minScore;
			max -= this.minScore;
		}
		const result = (points / max) * 100;
		const rounded = Math.round(result * 100) / 100;
		if (this.verbose) {
			console.log(this.calculatedScores);
			console.log(`${points} / ${max} = ${result}`);
			console.log(`PricingGauge=${rounded}`);
			console.log("--------------------------------------");
		}
		this.percent.next(rounded);
		//return value for testing
		return rounded;
	}

	private runCalculations(selectedOperation: Operation, values?: any) {
		this.questions.forEach((q) => {
			const selectedAnswer = values[q.questionId];
			if (selectedAnswer !== undefined) {
				//Assumes a single score for the given answer
				const score = q.scores.find((a) => a.answer === selectedAnswer);
				if (score != undefined) {
					score.operations.forEach((op) => {
						if (op.operationType === selectedOperation) {
							switch (selectedOperation) {
								case Operation.Add: {
									this.Add(q, op);
									break;
								}
								case Operation.ReduceBy: {
									this.ReduceBy(q, op);
									break;
								}
							}
						}
					});
				}
			}
		});
	}

	private Add(q: Question, op: OperationAction) {
		const currentScore = this.calculatedScores.get(op.targetQuestionId)!;
		const newScore = currentScore + op.points;
		if (this.verbose) {
			console.log(`Q-${q.questionId} ${currentScore} + ${op.points} = ${newScore}`);
		}
		this.calculatedScores.set(op.targetQuestionId, newScore);
	}

	/**
	 * ALD-1363 reduction
	 * 80% reduction in the basis points works as follows
	 * if the current score for the question is 90
	 * 90 – (90*.80) = 18
	 * 90 - (72) = 18
	 */
	private ReduceBy(q: Question, op: OperationAction) {
		const currentScore = this.calculatedScores.get(op.targetQuestionId)!;
		const reduction = currentScore * op.points;
		const newScore = currentScore - reduction;

		if (this.verbose) {
			console.log(
				`Q-${q.questionId}->Q-${op.targetQuestionId} (${op.points}) ${currentScore} - ${reduction} = ${newScore}`
			);
		}

		this.calculatedScores.set(op.targetQuestionId, newScore);
	}
}
