import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageService } from "../../../Utils";

@Component({
	selector: "ls-invitation",
	template: ""
})
export class InvitationComponent implements OnInit {
	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit() {
		this.getParamsAndNavigate();
	}

	getParamsAndNavigate() {
		const params = this.activatedRoute.snapshot.paramMap;
		if (!params.has("flowId")) throw new Error("FlowId is missing");
		const queryParams = this.activatedRoute.snapshot.queryParamMap;
		if (queryParams.has("companyId") && queryParams.has("userEmail")) {
			const companyIdStr = queryParams.get("companyId");
			const userEmail = queryParams.get("userEmail");
			if (!!companyIdStr && !!userEmail) {
				const companyId = parseInt(companyIdStr);
				this.localStorageService.setCompanyId(companyId);
				this.localStorageService.setEmailAddress(userEmail);
				this.localStorageService.setB2CSignupState();
			}
		}
		this.router.navigate([`/onboarding/${params.get("flowId")}/begin-application`], {
			relativeTo: this.activatedRoute
		});
	}
}
