import { ActionType, LsAction } from "@limestone/ls-shared-modules";
import { createAction, props } from "@ngrx/store";
import { Case, EmailMessage, LsHttpErrorResponse } from "src/app/Models";

export abstract class SalesforceSupportActions extends LsAction {
	/* eslint-disable */
  static CASE: Readonly<string> = 'CASE';
  static CASES: Readonly<string> = 'CASES';
  static MESSAGE: Readonly<string> = 'MESSAGE';
  static ATTACHMENT: Readonly<string> = 'Attachment';
  /* eslint-enable */

	static getCase = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASE, ActionType.GET),
		props<{ caseId: number }>()
	);

	static setCase = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASE, ActionType.SET),
		props<{ supportCase: Case }>()
	);

	static updateCase = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASE, ActionType.UPDATE),
		props<{ supportCase: Case }>()
	);

	static caseSaveSuccessful = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASE, ActionType.COMPLETE)
	);

	static caseSaveUnsuccessful = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASE, ActionType.ERROR),
		props<{ errors: string[] }>()
	);

	static getCases = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASES, ActionType.GET)
	);

	static setCases = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.CASES, ActionType.SET),
		props<{ cases: Case[] }>()
	);

	static sendEmailMessage = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.MESSAGE, ActionType.UPDATE),
		props<{ emailMessage: EmailMessage; files: File[] | undefined; userId: number; caseId: number }>()
	);

	static emailMessageSendSuccessful = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.MESSAGE, ActionType.COMPLETE)
	);

	static emailMessageSendUnsuccessful = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.MESSAGE, ActionType.ERROR),
		props<{ error: LsHttpErrorResponse }>()
	);

	static downloadAttachment = createAction(
		SalesforceSupportActions.getActionName(SalesforceSupportActions.ATTACHMENT, ActionType.GET),
		props<{ userId: number; caseId: number; blobPath: string; fileName: string }>()
	);
}
