import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
	Validators
} from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, debounce, filter, interval, map, Subject, takeUntil } from "rxjs";
import { Phone, PhoneValidation } from "src/app/Models";
import { defaultAllowedKey, Guid } from "src/Utils";
import { ValidationActions } from "../../../Modules/COT-Module/OnboardingStateManagement/Validation/validation-actions";
import { CountryCode, FeatureFlagSelectors, GenericSelectors, PhoneCode } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-phone-form-control",
	templateUrl: "./phone-form-control.component.html",
	styleUrls: ["./phone-form-control.component.scss"],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: PhoneFormControlComponent, multi: true },
		{ provide: NG_VALIDATORS, useExisting: PhoneFormControlComponent, multi: true }
	]
})
export class PhoneFormControlComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
	protected componentTeardown$ = new Subject();
	public COUNTRY_CODE!: string;
	public PHONE_CODE!: string;
	public PHONE_NUMBER!: string;
	public CC_Filter!: string;
	public PHONE_EXT!: string;
	public CODE_CONTAINER!: string;

	phoneValidationResult?: string;
	formError = "";
	public codeIconToggled = false;

	public formGroup: FormGroup = new FormGroup({});
	private id: string;

	public phoneCodes: PhoneCode[] = [];
	public filteredPhoneCodes: PhoneCode[] = [];
	public selectedCode?: PhoneCode = new PhoneCode("1", true, CountryCode.US);
	constructor(
		private store: Store<any>,
		private actions$: Actions,
		private guid: Guid,
		private genericSelectors: GenericSelectors,
		private featureFlagSelectors: FeatureFlagSelectors
	) {
		this.id = this.guid.New();
		this.PHONE_CODE = "phoneCode";
		this.COUNTRY_CODE = "countryCode";
		this.PHONE_NUMBER = "number";
		this.CC_Filter = "CC_Filter";
		this.PHONE_EXT = "extension";

		this.filteredPhoneCodes = this.phoneCodes;

		this.formGroup.addControl(this.PHONE_CODE, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.PHONE_NUMBER, new FormControl(null, [Validators.required, Validators.minLength(8)]));
		this.formGroup.addControl(this.COUNTRY_CODE, new FormControl(null, Validators.required));
		this.formGroup.addControl(this.CC_Filter, new FormControl(null, Validators.required));
		this.formGroup.addControl(
			this.PHONE_EXT,
			new FormControl(null, [Validators.pattern("^[0-9]*$"), Validators.maxLength(10)])
		);
		this.setCode(this.selectedCode);
	}

	setCode(code: PhoneCode | undefined, triggerValidation = false) {
		this.formGroup.get(this.PHONE_NUMBER)?.setErrors(null);
		const phoneCode = this.formGroup.get(this.PHONE_CODE);
		const countryCode = this.formGroup.get(this.COUNTRY_CODE);

		phoneCode?.setValue(code?.code, { emitEvent: true });
		countryCode?.setValue(code?.countryCode, { emitEvent: true });
		if (code?.code === "1") {
			this.formGroup
				.get(this.PHONE_NUMBER)
				?.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
		} else {
			this.formGroup
				.get(this.PHONE_NUMBER)
				?.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(15)]);
		}
		if (triggerValidation) {
			this.dispatchValidationRequest();
			this.formGroup.get(this.PHONE_NUMBER)?.updateValueAndValidity();
		}
		this.selectedCode = code;
	}

	writeValue(phone?: Phone): void {
		if (phone?.countryCode) this.formGroup.get(this.COUNTRY_CODE)?.setValue(phone.countryCode, { emitEvent: false });
		if (phone?.phoneCode) this.formGroup.get(this.PHONE_CODE)?.setValue(phone.phoneCode, { emitEvent: false });
		this.formGroup.get(this.PHONE_NUMBER)?.setValue(phone?.number, { emitEvent: false });
		this.formGroup.get(this.PHONE_EXT)?.setValue(phone?.extension, { emitEvent: false });
		if (phone?.phoneCode) this.setCode(new PhoneCode(phone?.phoneCode, true, phone?.countryCode));
		this.phoneValidationObservers();
	}

	registerOnChange(onChange: (value: Phone | null) => void): void {
		this.formGroup.valueChanges.pipe(takeUntil(this.componentTeardown$), map(onChange)).subscribe();
	}

	onTouched() {}

	markAsDirty() {
		this.formGroup.get(this.COUNTRY_CODE)?.markAsDirty();
		this.formGroup.get(this.PHONE_CODE)?.markAsDirty();
		this.formGroup.get(this.PHONE_NUMBER)?.markAsDirty();
		this.formGroup.get(this.PHONE_EXT)?.markAsDirty();
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		isDisabled ? this.formGroup.disable() : this.formGroup.enable();
	}

	validate(): ValidationErrors | null {
		if (this.formGroup.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, this.COUNTRY_CODE);
		errors = this.addControlErrors(errors, this.PHONE_CODE);
		errors = this.addControlErrors(errors, this.PHONE_NUMBER);
		errors = this.addControlErrors(errors, this.PHONE_EXT);
		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };

		const controlErrors = this.formGroup.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}

		return errors;
	}

	registerOnValidatorChange?(fn: () => void): void {
		this.validatorChange = fn;
	}

	validatorChange() {}

	ngOnInit() {
		combineLatest([
			this.store.select(this.genericSelectors.selectPhoneCodes),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				takeUntil(this.componentTeardown$),
				map(([pc]) => {
					this.phoneCodes = pc! ?? [];
					const code = this.formGroup?.get(this.PHONE_CODE)?.value?.code;
					if (code) {
						this.filterPhoneCodes(code);
					}
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(ValidationActions.validationFailure),
				filter((act) => act.id === this.id),
				map((action) => {
					const errorMap = action.result!.errors;
					switch (action.validationType) {
						case "Phone": {
							if (errorMap.has("Number"))
								this.formGroup.get(this.PHONE_NUMBER)?.setErrors({ error: errorMap.get("Number") });
							if (errorMap.has("PhoneCode"))
								this.formGroup.get(this.PHONE_CODE)?.setErrors({ error: errorMap.get("PhoneCode") });
							if (errorMap.has("CountryCode"))
								this.formGroup.get(this.COUNTRY_CODE)?.setErrors({ error: errorMap.get("CountryCode") });
							if (errorMap.has("Extension"))
								this.formGroup.get(this.PHONE_EXT)?.setErrors({ error: errorMap.get("Extension") });

							let errorStr = "";
							errorMap.forEach((errArray, key) => {
								if (key === "Number" && action.fieldValue === this.formGroup.get(this.PHONE_NUMBER)?.value) {
									errorStr += ` ${errArray[0]} \n`;
								}
								if (key !== "Number") {
									errArray.map((e) => (errorStr += ` ${e} \n`));
								}
							});
							this.validatorChange();
							this.phoneValidationResult = errorStr;
							break;
						}
						default:
							throw new Error("Unknown validation type.");
					}
				})
			)
			.subscribe();
		this.formGroup
			.get(this.CC_Filter)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((v: string) => {
					this.filterPhoneCodes(v);
				})
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	phoneValidationObservers() {
		this.formGroup
			.get(this.PHONE_NUMBER)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				debounce(() => interval(500)),
				filter(() => {
					const phone = this.formGroup.get(this.PHONE_NUMBER)?.value;
					return !!phone && phone.length >= 1 && this.formGroup.get(this.PHONE_NUMBER)!.dirty;
				}),
				map(() => this.dispatchValidationRequest())
			)
			.subscribe();

		this.formGroup
			.get(this.PHONE_CODE)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				filter((phone) => {
					const code = this.formGroup.get(this.PHONE_CODE)?.value;
					return !!phone && phone.length >= 1 && !!code && this.formGroup.get(this.PHONE_CODE)!.dirty;
				}),
				map(() => this.dispatchValidationRequest())
			)
			.subscribe();

		this.formGroup
			.get(this.PHONE_EXT)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				filter((phone) => {
					const ext = this.formGroup.get(this.PHONE_EXT)?.value;
					return !!phone && phone.length >= 1 && !!ext && this.formGroup.get(this.PHONE_EXT)!.dirty;
				}),
				map(() => this.dispatchValidationRequest())
			)
			.subscribe();
	}

	toggleCode() {
		if (this.formGroup.get(this.PHONE_CODE)?.value) {
			this.codeIconToggled = true;
		} else {
			this.codeIconToggled = !this.codeIconToggled;
		}
	}

	dispatchValidationRequest() {
		const phoneValidation = new PhoneValidation(
			this.formGroup.get(this.PHONE_CODE)?.value,
			this.formGroup.get(this.PHONE_NUMBER)?.value,
			this.formGroup.get(this.PHONE_EXT)?.value,
			this.formGroup.get(this.COUNTRY_CODE)?.value
		);
		this.store.dispatch(ValidationActions.validatePhone({ id: this.id, phone: phoneValidation }));
	}

	controlHasError(controlName: string): boolean {
		if (this.formGroup.get(controlName)) {
			return (
				this.formGroup.get(controlName)!.invalid &&
				(this.formGroup.get(controlName)!.dirty || this.formGroup.get(controlName)!.touched)
			);
		}
		return false;
	}

	getControlError(controlName: string): string {
		return this.formGroup.get(controlName)?.errors!["error"];
	}

	keyPressedIsDigit(keyPressed: string) {
		const regex = new RegExp(/([0-9])+/g);
		const validKey = regex.test(keyPressed);
		const enteredValueIsValid = validKey || defaultAllowedKey(keyPressed);

		return enteredValueIsValid;
	}

	onKeyDown($event: any) {
		const keyPressed = $event.key;
		if (!this.keyPressedIsDigit(keyPressed)) {
			$event.preventDefault();
		}
	}

	filterPhoneCodes(input: string | undefined): void {
		if (input == undefined) {
			this.filteredPhoneCodes = this.phoneCodes;
		} else {
			this.filteredPhoneCodes = this.phoneCodes.filter(
				(code) => code?.id?.includes(input!) || code?.countryCode?.toLowerCase().includes(input?.toLowerCase())
			);
		}
	}
}
