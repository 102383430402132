<div class="d-flex flex-column">
  <h1 class="l ls-mb-6">Now, let’s set up your customers</h1>
  <div class="ls-mb-6">
    <p class="l ls-mb-3">Set up which customer(s) you want advanced payments from. You must set up at least one customer in order to complete your application. You can always add more customers later.</p>
    <p class="l">To make this process quicker and easier, you can connect your accounting platform. We’ll gather most of your customer information directly from there to save you time searching for and calculating that info.</p>
  </div>
  <div class="d-flex flex-column flex-md-row justify-content-start justify-content-between">
    <button type="button" (click)="continue()" class="btn btn-lg btn-primary ls-mb-4 ls-mb-md-0">Add a customer</button>
    <button type="button" (click)="navToAccounting()" class="btn btn-lg btn-secondary">Connect accounting</button>
  </div>
</div>