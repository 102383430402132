import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { combineLatest, filter, map, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "../../../../../Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { CurrencyCode, GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-currency-payment",
	templateUrl: "./currency-payment.component.html",
	styleUrls: ["./currency-payment.component.scss"]
})
export class CurrencyPaymentComponent extends CustomerRelationshipBaseComponent {
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		private genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.initData();
	}

	CURRENCY = "currency";
	ONBOARD_PAYMENT_METHOD = "onboardPaymentMethod";

	currencies: GenericLookup<string>[] = [];
	onboardPaymentMethods: GenericLookup<string>[] = [];
	filteredOnboardPaymentMethods: GenericLookup<string>[] = [];
	companyProfileRelationship?: CompanyProfileRelationship;
	customerRelationship2Form: FormGroup = new FormGroup({});
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	public initData(): void {
		combineLatest([
			this.store.select(this.genericSelectors.selectLookup(LookupTables.Currency)),
			this.store.select(this.genericSelectors.selectLookup(LookupTables.OnboardPaymentMethod)),
			this.store.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
		])
			.pipe(
				filter(([cur, pm, cpr]) => !!cur && !!pm && !!cpr),
				takeUntil(this.componentTeardown$),
				map(([cur, pm, cpr]) => {
					this.currencies = cur!;
					this.onboardPaymentMethods = pm!;
					this.companyProfileRelationship = cpr!;
				})
			)
			.subscribe();

		this.customerRelationship2Form = new FormGroup({
			currency: new FormControl(
				this.companyProfileRelationship?.isOtherCurrency
					? CurrencyCode.OTHER
					: this.companyProfileRelationship?.currencyCode,
				Validators.required
			),
			onboardPaymentMethod: new FormControl(
				this.companyProfileRelationship?.onboardPaymentMethodId,
				Validators.required
			)
		});
	}

	isFormInvalid() {
		return this.customerRelationship2Form.invalid;
	}

	continue() {
		if (this.customerRelationship2Form.dirty) {
			const selectedCurrency = this.customerRelationship2Form.get(this.CURRENCY)?.value;
			const selectedOnboardPaymentMethod = this.customerRelationship2Form.get(this.ONBOARD_PAYMENT_METHOD)?.value;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.currencyCode = selectedCurrency;
			updatedCompanyProfileRelationship.onboardPaymentMethodId = selectedOnboardPaymentMethod;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			const index = this.companyProfileRelationship?.isManualSetup ? 0 : 1;
			this.navigateToNextScreen(index);
		}
	}
}
