import { Flow, Product } from "./Enums";

export class InviteContext {
	companyId?: number;
	relationshipCompanyId?: number;
	flowId?: Flow;
	product?: Product;

	constructor(companyId?: number, relationshipCompanyId?: number, flowId?: Flow, product?: Product) {
		this.companyId = companyId;
		this.relationshipCompanyId = relationshipCompanyId;
		this.flowId = flowId;
		this.product = product;
	}
}
