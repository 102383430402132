import { Injectable } from "@angular/core";
import { FeatureFlagService } from "@limestone/ls-shared-modules";
import { map, take } from "rxjs";

@Injectable()
export class FeatureFlagContentService {
	constructor(private featureFlagService: FeatureFlagService) {}
	handleContent(featureFlagName: string, enabled?: () => void, disabled?: () => void) {
		this.featureFlagService
			.isEnabled(featureFlagName)
			.pipe(
				take(1),
				map((isEnabled) => {
					if (isEnabled && enabled !== undefined) {
						enabled();
					} else if (!isEnabled && disabled !== undefined) {
						disabled();
					}
				})
			)
			.subscribe();
	}
}
