export class HttpErrorResponse {
	public status?: number;
	public title?: string;
	public traceId?: string;
	public type?: string;
	public errors: Map<string, string[]> = new Map<string, string[]>();

	constructor(status?: number, title?: string, traceId?: string, type?: string, errors?: any) {
		this.status = status;
		this.title = title;
		this.traceId = traceId;
		this.type = type;
		if (errors) this.setErrorsFromHttpResponse(errors);
	}

	private setErrorsFromHttpResponse(errObj: any): void {
		Object.keys(errObj).forEach((k) => {
			this.errors.set(k, errObj[k]);
		});
	}
}
