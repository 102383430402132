export class IntegrationDataConnection {
	key?: string;
	name?: string;
	logoUrl?: string;

	constructor(key?: string, name?: string, logoUrl?: string) {
		this.key = key;
		this.name = name;
		this.logoUrl = logoUrl;
	}
}
