<div *ngIf="!readOnly; else readOnlyView" class="contact-form-container ls-mt-8">
    <form [formGroup]="customerContactForm">
        <div class="d-flex flex-column">
            <mdb-form-control class="ls-mb-8">
                <input [formControlName]="LEGAL_NAME" mdbInput mdbValidate type="text" id="legalName"
                    class="form-control form-control-lg"/>
                <label mdbLabel class="form-label" for="legalName">Legal Business Name</label>
            </mdb-form-control>

            <div class="d-flex flex-column ls-mb-8">
                <label class="s-caps ls-mb-3">Billing Address</label>
                <ls-address-form-control [formControlName]="BILLING"></ls-address-form-control>
            </div>
        </div>
    </form>
</div>
  
<ng-template #readOnlyView>
    <div class="grid ls-mt-8" style="--bs-gap:3.5rem;">
        <div class="g-col-12 g-col-md-6">
            <label class="s">Legal Business Name</label>
            <p class="l-bold">{{ companyProfileRelationship?.relationshipCompanyName }}</p>
        </div>
        <div class="g-col-12 g-col-md-6">
            <label class="s">Billing Address</label>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.address1 }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.address2 }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.city }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.state?.name }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.postalCode }}</p>
            <p class="l-bold ls-mb-0">{{ companyProfileRelationship?.billingAddress?.country?.name }}</p>
        </div>
    </div>
</ng-template>