import { Component, Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Component({
	template:
		'<div class="d-flex flex-column">\n' +
		'       <p class="d-flex align-items-center ls-mt-1" reference><span class="material-icons ls-mr-2">autorenew</span>Calculating...</p>\n' +
		"</div>"
})
export class CalculatingComponent {}
@Directive({ selector: "[lsIsCalculating]" })
export class IsCalculatingDirective {
	@Input()
	set lsIsCalculating(isCalculating: boolean) {
		this.vcRef.clear();

		if (isCalculating) {
			// create and embed an instance of the loading component
			this.vcRef.createComponent(CalculatingComponent);
		} else {
			// embed the contents of the host template
			this.vcRef.createEmbeddedView(this.templateRef);
		}
	}

	constructor(
		private templateRef: TemplateRef<any>,
		private vcRef: ViewContainerRef
	) {}
}
