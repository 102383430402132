<ls-questionnaire #questionnaireForm (nextButtonClicked)="continue()" [isNextButtonDisabled]="isFormInvalid()" [breadcrumbs]="breadcrumbs">
    <h3 class="ls-mb-8">Tell us more about your relationship with your customer</h3>
    <form [formGroup]="customerRevenueForm" class="d-flex flex-column">
        <div  class="d-flex flex-column ls-mb-8">
            <p class="title m ls-mb-3">What are your approximate annual sales with this customer?</p>
            <mdb-form-control class="half-max-width">
                <mdb-select [formControlName]="ONBOARD_REVENUE_RANGE" [id]="ONBOARD_REVENUE_RANGE" mdbValidate
                            [optionHeight]="56" class="form-control form-control-lg">
                    <mdb-option *ngFor="let onboardRevenueRange of onboardRevenueRanges" [value]="onboardRevenueRange.id">{{ currencyCode + ' ' + onboardRevenueRange.name }}</mdb-option>
                </mdb-select>
                <label mdbLabel class="form-label" [for]="ONBOARD_REVENUE_RANGE">Amount</label>
            </mdb-form-control>
        </div>
    </form>
</ls-questionnaire>