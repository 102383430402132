import { Component, OnDestroy, OnInit } from "@angular/core";
import { combineLatest, filter, map, Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { UserInfo } from "../../../../Models";
import { Store } from "@ngrx/store";
import { AppSelectors } from "../../../../AppStateManagement";
import { FeatureFlagSelectors, FeatureFlags } from "@limestone/ls-shared-modules";
@Component({
	selector: "ls-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit, OnDestroy {
	protected componentTeardown$ = new Subject();
	constructor(
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public store: Store,
		public appSelectors: AppSelectors,
		public featureFlagSelectors: FeatureFlagSelectors
	) {}

	public userInfo?: UserInfo;
	public is_SCF_V1_0_0_Enabled?: boolean;
	public cards: HomeCard[] = [
		{
			title: "Apply for Financing",
			paragraphs: [
				`Get paid weeks or months earlier, while your customers’ payment terms stay the 
				same. All without taking on a loan.`,
				`Submit an application and someone from our team of financial experts will be in 
				touch to help you access faster financing, larger credit lines, higher advance rates, 
				and lower-cost products.`
			],
			buttonText: "View Applications"
		},
		{
			title: "Ask for Support",
			paragraphs: [
				`Need help? No problem! A member of the Raistone support team is standing by to help at	
				any time. Just click “Chat with an Expert” at the bottom right of your screen.`,
				`You can also go to the Support tab to check out any messages from the Raistone support team.`
			],
			buttonText: "View Support Cases"
		}
	];

	ngOnInit() {
		combineLatest([
			this.store.select(this.appSelectors.selectUserInfo),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		])
			.pipe(
				filter(([userInfo, ff]) => !!userInfo && !!ff),
				takeUntil(this.componentTeardown$),
				map(([userInfo, ff]) => {
					this.userInfo = userInfo;
					this.is_SCF_V1_0_0_Enabled = ff?.find((flag) => flag.id === FeatureFlags.SCF_Onboarding_V1_0_0)?.enabled;
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	navToApplications() {
		this.router.navigate([`applications`], { relativeTo: this.activatedRoute });
	}

	navToSupport() {
		this.router.navigate([`support`], { relativeTo: this.activatedRoute });
	}
}

interface HomeCard {
	title: string;
	paragraphs: string[];
	buttonText: string;
}
