import { Component } from "@angular/core";
import { ModalActions } from "../../../../Core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { CompanyUser } from "../../../../Models/CompanyUser";

@Component({
	selector: "ls-delete-user-persuasion",
	templateUrl: "./delete-user.component.html",
	styleUrls: ["./delete-user.component.scss"]
})
export class DeleteUserComponent {
	user?: CompanyUser;

	constructor(public modalRef: MdbModalRef<DeleteUserComponent>) {}
	public get ModalActions(): typeof ModalActions {
		return ModalActions;
	}

	public close(action: ModalActions): void {
		this.modalRef.close(action);
	}
}
