import { Component } from "@angular/core";
import { MdbModalRef } from "mdb-angular-ui-kit/modal";
import { ModalActions } from "../../../../Core";
import { CompanyProfileRelationship } from "../../../../Models";

@Component({
	selector: "ls-submit-partial-application",
	templateUrl: "./submit-partial-application.component.html",
	styleUrls: ["./submit-partial-application.component.scss"]
})
export class SubmitPartialApplicationComponent {
	completedCustomers: CompanyProfileRelationship[] = [];
	incompleteCustomers: CompanyProfileRelationship[] = [];

	constructor(public modalRef: MdbModalRef<SubmitPartialApplicationComponent>) {}
	public get ModalActions(): typeof ModalActions {
		return ModalActions;
	}

	completeCustomersList(): string {
		return this.toDisplayList(this.completedCustomers.map((cpr) => cpr.relationshipCompanyName ?? ""));
	}

	incompleteCustomersList(): string {
		return this.toDisplayList(this.incompleteCustomers.map((cpr) => cpr.relationshipCompanyName ?? ""));
	}

	toDisplayList(array: string[]): string {
		const length = array.length;
		if (length === 0) {
			return "";
		} else if (length === 1) {
			return array[0];
		} else if (length === 2) {
			return `${array[0]} and ${array[1]}`;
		} else {
			const last = array[length - 1];
			const str = array.slice(0, -1).join(", ");
			return `${str}, and ${last}`;
		}
	}
	public close(action: ModalActions): void {
		this.modalRef.close(action);
	}
}
