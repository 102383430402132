<div class="d-flex flex-column">
	<div class="w-100 d-flex flex-row justify-content-center sticky-top">
		<ls-alert *ngIf="successMessage" [alertType]="successMessage.type" [icon]="successMessage.icon" [small]="true">
			<label class="xs">{{successMessage.message}}</label>
		</ls-alert>
	</div>
	<div class="d-flex flex-column flex-xl-row justify-content-between ls-mb-3">
		<div class="d-flex flex-column">
			<span class="ls-mb-0_75 title s">Application {{companyProfileRelationship?.companyProfileId}}-{{companyProfileRelationship?.relationshipCompanyId}}</span>
			<h2>{{ companyProfileRelationship?.relationshipCompanyName }}</h2>
		</div>

		<div class="d-flex flex-row align-items-center">
			<div [ngClass]="onboardApplicationStatusUtil.getApplicationStatusClass(companyProfileRelationship?.applicationStatusId!)">
				<label class="ls-mb-0 s-caps">{{ companyProfileRelationship?.applicationStatusName }}</label>
			</div>
			<div *ngIf="isSUBMITTED && !readOnly" class="ls-ml-7 d-md-inline d-none">
				<button class="btn btn-primary" (click)="onClick()" [disabled]="formGroup.invalid || formGroup.pristine">
					Save Changes
				</button>
			</div>
		</div>
	</div>

	<ls-tabs [(tabIndex)]="tabIndex" [tabNames]="tabNames">
		<form [formGroup]="formGroup">
			<ls-application-contact-info [hidden]="tabIndex !== 0" [companyProfileRelationship]="companyProfileRelationship!" [readOnly]="readOnly">
			</ls-application-contact-info>
	
			<ls-customer-relationship [hidden]="tabIndex !== 1" [companyProfileRelationship]="companyProfileRelationship!" [readOnly]="readOnly">
			</ls-customer-relationship>
	
			<ls-platforms-used [hidden]="tabIndex !== 2" [companyProfileRelationship]="companyProfileRelationship!" [readOnly]="readOnly">
			</ls-platforms-used>
	
			<ls-customer-settings [hidden]="tabIndex !== 3" [companyProfileRelationship]="companyProfileRelationship!" [readonly]="readOnly">
			</ls-customer-settings>
		</form>
	</ls-tabs>

	<button *ngIf="isSUBMITTED && !readOnly" class="btn btn-primary d-md-none ls-mt-8" (click)="onClick()" [disabled]="formGroup.invalid || formGroup.pristine">
		Save Changes
	</button>
</div>