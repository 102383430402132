import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { filter, map, takeUntil } from "rxjs";
import { Store } from "@ngrx/store";
import { CompanyProfileRelationship } from "src/app/Models";
import copy from "fast-copy";
import { Actions } from "@ngrx/effects";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GenericLookup, GenericSelectors, LookupTables } from "@limestone/ls-shared-modules";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "src/app/Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { RouteStepDataService } from "../../../Services";
import { LookupKeys } from "src/app/Models/Enums";

@Component({
	selector: "ls-lien-information",
	templateUrl: "./lien-information.component.html",
	styleUrls: ["./lien-information.component.scss"]
})
export class CustomerSetupLienInformationComponent extends CustomerRelationshipBaseComponent {
	companyProfileRelationship?: CompanyProfileRelationship;
	YESNO = "YESNO";
	public yesNo?: GenericLookup<string>[];
	lienForm: FormGroup = new FormGroup({});
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public genericSelectors: GenericSelectors,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((cpr) => !!cpr),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectLookupByKey(LookupKeys.YesNo))
			.pipe(
				takeUntil(this.componentTeardown$),
				filter((yn) => !!yn && yn.length > 0),
				map((yn) => {
					this.yesNo = yn!;
				})
			)
			.subscribe();

		this.lienForm = new FormGroup({
			YESNO: new FormControl(this.companyProfileRelationship?.lienCollaborationYesNoId, Validators.required)
		});
	}

	continue() {
		if (this.lienForm.dirty) {
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship!.lienCollaborationYesNoId = this.lienForm.get(this.YESNO)?.value;
			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
}
