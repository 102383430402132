import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, takeUntil, map } from "rxjs";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { LookupKeys, TextStyle } from "src/app/Models/Enums";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { GenericLookup, GenericSelectors } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";
import { FormGroup, Validators } from "@angular/forms";

@Component({
	selector: "ls-requested-financing",
	templateUrl: "./requested-financing.component.html",
	styleUrls: ["./requested-financing.component.scss"]
})
export class RequestedFinancingComponent extends CustomerRelationshipBaseComponent implements AfterViewInit {
	companyProfileRelationship?: CompanyProfileRelationship;
	financeRangeId?: string;
	financeRange: GenericLookup<string>[] = [];
	currencyCode?: string;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;
	FINANCE_RANGE = "financeRange";
	financeRangeForm: FormGroup = new FormGroup({});
	CURRENCY_CODE_TOKEN = "!CURRENCY_CODE!";
	constructor(
		public actions$: Actions,
		public store: Store<any>,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public genericSelectors: GenericSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.currencyCode = !cpr!.isOtherCurrency ? cpr!.currencyCode! : this.currencyCode;
					this.replacementTokens.set(this.CURRENCY_CODE_TOKEN, this.currencyCode ?? "");
				})
			)
			.subscribe();

		this.store
			.select(this.genericSelectors.selectLookupByKey(LookupKeys.FinanceRange))
			.pipe(
				filter((fr) => !!fr),
				takeUntil(this.componentTeardown$),
				map((fr) => {
					this.financeRange = fr!;
				})
			)
			.subscribe();

		this.setupControl(
			this.financeRangeForm,
			this.FINANCE_RANGE,
			this.companyProfileRelationship?.financeRangeId,
			Validators.required
		);
	}
	ngAfterViewInit(): void {
		this.financeRangeForm.valueChanges
			.pipe(
				map((value) => {
					this.financeRangeId = value[this.FINANCE_RANGE];
				})
			)
			.subscribe();
	}

	hasValue() {
		if (this.companyProfileRelationship?.financeRangeId) {
			this.financeRangeId = this.companyProfileRelationship?.financeRangeId;
			return true;
		}
		if (this.financeRangeId != undefined && this.financeRangeId != null) {
			return true;
		}
		return false;
	}

	continue() {
		const selectedFinanceRangeId = this.financeRangeId;
		const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
		updatedCompanyProfileRelationship.financeRangeId = selectedFinanceRangeId;

		this.store.dispatch(
			CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
				companyProfileRelationship: updatedCompanyProfileRelationship
			})
		);
	}

	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q1",
						new DisplayConfig(
							`How much are you looking to finance with this customer (${this.CURRENCY_CODE_TOKEN})?`,

							TextStyle.HEADLINE_MEDIUM
						)
					]
				])
			)
		]
	]);
}
