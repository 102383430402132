import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import copy from "fast-copy";
import { filter, takeUntil, map } from "rxjs";
import { CompanyProfileRelationship, DisplayConfig, Layout } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";
import { Actions } from "@ngrx/effects";
import { CustomerRelationshipBaseComponent } from "../customer-relationship-base.component";
import { QuestionnaireComponent } from "../../../../../Core";
import {
	CompanyProfileRelationshipActions,
	CompanyProfileRelationshipSelectors,
	CompanyProfileSelectors
} from "src/app/AppStateManagement";
import { CurrencyCode } from "@limestone/ls-shared-modules";
import { RouteStepDataService } from "../../../Services";

@Component({
	selector: "ls-requested-financing",
	templateUrl: "./requested-financing.component.html",
	styleUrls: ["./requested-financing.component.scss"]
})
export class RequestedFinancingComponent extends CustomerRelationshipBaseComponent {
	companyProfileRelationship?: CompanyProfileRelationship;
	requestedCreditLimit = 0;
	currencyCode = CurrencyCode.USD;
	@ViewChild("questionnaireForm") childForm!: QuestionnaireComponent;

	constructor(
		public actions$: Actions,
		public store: Store,
		public router: Router,
		public companyProfileRelationshipSelectors: CompanyProfileRelationshipSelectors,
		public companyProfileSelectors: CompanyProfileSelectors,
		public routeStepDataService: RouteStepDataService
	) {
		super(router, store, actions$, companyProfileSelectors, routeStepDataService, companyProfileRelationshipSelectors);
		this.setDefaultLayout(this.DefaultLayout);
		this.initData();
	}

	public initData(): void {
		this.store
			.select(this.companyProfileRelationshipSelectors.selectCompanyProfileRelationship)
			.pipe(
				filter((cpr) => !!cpr),
				takeUntil(this.componentTeardown$),
				map((cpr) => {
					this.companyProfileRelationship = cpr;
					this.currencyCode = !cpr!.isOtherCurrency ? cpr!.currencyCode! : this.currencyCode;
					this.requestedCreditLimit = cpr!.requestedCreditLimit!;
				})
			)
			.subscribe();
	}

	onRangeValueChange(rangeValue: number) {
		this.requestedCreditLimit = rangeValue;
	}

	continue() {
		if (this.requestedCreditLimit != this.companyProfileRelationship?.requestedCreditLimit) {
			const selectedFinancingAmount = this.requestedCreditLimit;
			const updatedCompanyProfileRelationship = copy(this.companyProfileRelationship!);
			updatedCompanyProfileRelationship.requestedCreditLimit = selectedFinancingAmount;

			this.store.dispatch(
				CompanyProfileRelationshipActions.updateCompanyProfileRelationship({
					companyProfileRelationship: updatedCompanyProfileRelationship
				})
			);
		} else {
			this.navigateToNextScreen();
		}
	}
	DefaultLayout = new Map<string, Layout>([
		[
			this.HEADER,
			new Layout(
				new Map<string, DisplayConfig>([
					[
						"q1",
						new DisplayConfig("How much are you looking to finance with this customer?", TextStyle.HEADLINE_MEDIUM)
					]
				])
			)
		]
	]);
}
