<form [formGroup]="formGroup" class="d-flex flex-column flex-lg-row ls-mt-6">
    <div class="ls-w-100 ls-w-lg-50">
        <mdb-form-control>
            <mdb-select [filter]="true"
                [formControlName]="PLATFORM"
                [id]="PLATFORM"
                [optionHeight]="56" 
                [compareWith]="comparePlatform"
                class="form-control form-control-lg">
                <mdb-option *ngFor="let p of platforms" [value]="p">{{p.name}}</mdb-option>
            </mdb-select>
            <label mdbLabel class="form-label" [for]="PLATFORM">{{label}}</label>
        </mdb-form-control>
    </div>
    <div [hidden]="hideOtherPlatformInput" class="ls-w-100 ls-w-lg-50 ls-pl-lg-4 ls-mt-3 ls-mt-lg-0">
        <mdb-form-control>
          <input mdbInput [formControlName]="OTHER_PLATFORM" mdbValidate type="string" [maxlength]="maxLength" [id]="OTHER_PLATFORM" class="form-control form-control-lg" />
          <label mdbLabel class="form-label" [for]="OTHER_PLATFORM">Specify</label>
        </mdb-form-control>
    </div>
</form>