import { UploadStatus } from "./Enums/UploadStatus";
import { IColumnHeader } from "./Interfaces/IColumnHeader";
import { CompanyDocumentType } from "./Enums";

export class CompanyDocument {
	public companyId?: number;
	public fileName?: string;
	public fileSize?: string;
	public uploadDate?: Date;
	public status?: UploadStatus;
	public documentType?: CompanyDocumentType;

	constructor(
		companyId?: number,
		fileName?: string,
		fileSize?: string,
		uploadDate?: Date,
		status?: UploadStatus,
		documentType?: CompanyDocumentType
	) {
		this.companyId = companyId;
		this.fileName = fileName;
		this.fileSize = fileSize;
		this.uploadDate = uploadDate;
		this.status = status ?? UploadStatus.COMPLETE;
		this.documentType = documentType;
	}

	static ColumnNames(): IColumnHeader[] {
		return [
			{ column: "File Name", relatedField: "fileName", canSort: true },
			{ column: "File Size", relatedField: "fileSize", canSort: true },
			{ column: "Date Uploaded", relatedField: "uploadDate", canSort: true },
			{ column: "Upload Status", relatedField: "status", canSort: true },
			{ column: "", relatedField: "", canSort: false }
		];
	}
}
