import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MdbDropdownDirective } from "mdb-angular-ui-kit/dropdown";
import { LsRole } from "@limestone/ls-shared-modules";

@Component({
	selector: "ls-user-role-dropdown",
	templateUrl: "./user-role-dropdown.component.html",
	styleUrls: ["./user-role-dropdown.component.scss"]
})
export class UserRoleDropdownComponent {
	@ViewChild("dropdown") dropdown!: MdbDropdownDirective;

	@Input() public options?: any[];
	@Input() public userRoleId?: string;
	@Input() public roleName?: string;
	@Input() public canEdit = false;
	@Input() public set isEditing(isEditing: boolean) {
		if (!isEditing) this.dropdown?.hide();
	}
	@Output() public userRoleChanged: EventEmitter<any> = new EventEmitter<any>();
	@Output() public opened: EventEmitter<any> = new EventEmitter<any>();
	@Output() public hidden: EventEmitter<any> = new EventEmitter<any>();

	public LsRole = LsRole;

	public onChange(role: string) {
		this.userRoleChanged.emit(role);
	}

	public openDropdown($event: MouseEvent): void {
		this.opened.emit($event);
	}

	public dropdownHidden(): void {
		this.hidden.emit();
	}
}
