<ls-questionnaire-toggle #questionnaireForm *ngIf="!isSunfyre_ARFPricingGauge_2024718Enabled; else instApprovalV2"
        toggleLabel="Confirm Invoice Approvals"
        [(enabled)] = isInstrumentApprovalConfirmed
        [toggledState] = "toggledState"
        header="Do you want to wait until Raistone receives invoice approvals from your customer before getting financing?"
        [breadcrumbs]="breadcrumbs"
        (buttonClicked)="continue()"
		id="questionnaireFormToggle">
  <div>
    <p class="l ls-mb-0">Getting <a class="ls-hyperlink" href="https://raistone.com/help/customer-invoice-approval/" target="_blank">invoice approval from your customers</a> will result in:</p>
    <ul class="ls-mb-0">
      <li>Lower discount fee — we can provide you with better pricing.</li>
      <li>Increased advance rate — we’ll be able to buy a larger amount of each invoice.</li>
      <li>Larger line of credit we can provide your business.</li>
    </ul>
  </div>
</ls-questionnaire-toggle>

<ng-template #instApprovalV2>
	<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()" 
					  [isNextButtonDisabled]="invApprovalForm.invalid" id="questionnaireForm">
		<form class="d-flex flex-column ls-mt-2" [formGroup]="invApprovalForm">
			<ls-text id="header" [displayConfig]="displays.get('header')" extraClasses="ls-mb-6"></ls-text>
			<div class="ls-mb-6">
				<ls-text id="listHeader" [displayConfig]="displays.get('listHeader')" extraClasses="ls-mb-0"></ls-text>
				<ul class="ls-mb-0">
					<li><ls-text id="bullet1" [displayConfig]="displays.get('bullet1')" extraClasses="ls-mb-0"></ls-text></li>
					<li><ls-text id="bullet2" [displayConfig]="displays.get('bullet2')" extraClasses="ls-mb-0"></ls-text></li>
					<li><ls-text id="bullet3" [displayConfig]="displays.get('bullet3')" extraClasses="ls-mb-0"></ls-text></li>
				</ul>
			</div>
			<div *ngFor="let fd of yn" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="INV_APPROVAL" class="radio" type="radio"
					   [id]="'fd'+fd.id" [value]="fd.id" />
				<label class="xl" [for]="'fd'+fd.id">
					{{ fd.name }}
				</label>
			</div>
		</form>
	</ls-questionnaire>
</ng-template>