import { Injectable } from "@angular/core";

import { UserInfo } from "../Models";
import { Store } from "@ngrx/store";
import { filter, map, Observable, take } from "rxjs";
import { AppSelectors, AppActions } from "../AppStateManagement";

@Injectable()
export class UserInfoResolver {
	constructor(
		private store: Store<any>,
		private appSelectors: AppSelectors
	) {}

	userInfo$ = this.store.select(this.appSelectors.selectUserInfo).pipe(
		filter((ui) => !!ui),
		take(1)
	);

	resolve(): Observable<UserInfo | undefined> {
		this.initPrefetchData();
		return this.userInfo$;
	}

	initPrefetchData(): void {
		this.store
			.select(this.appSelectors.selectUserInfo)
			.pipe(
				take(1),
				filter((userInfo) => !userInfo),
				map(() => {
					this.store.dispatch(AppActions.getUserInfo());
				})
			)
			.subscribe();
	}
}
