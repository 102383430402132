import { Action, createReducer, on } from "@ngrx/store";
import { Case } from "src/app/Models";
import { SalesforceSupportActions } from "./salesforce-support-actions";

export interface SalesforceSupportState {
	cases?: Case[];
	supportCase?: Case;
}

export const initialState: SalesforceSupportState = {};

abstract class SalesforceReducerHandler {
	static appReducer = createReducer(
		initialState,
		on(SalesforceSupportActions.setCase, (state: SalesforceSupportState, { supportCase }) => ({
			...state,
			supportCase
		})),
		on(SalesforceSupportActions.setCases, (state: SalesforceSupportState, { cases }) => ({
			...state,
			cases
		}))
	);
}

export abstract class SalesforceSupportReducer {
	static reducer = (state: SalesforceSupportState | undefined, action: Action) => {
		return SalesforceReducerHandler.appReducer(state, action);
	};
}
