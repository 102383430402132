import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { combineLatest, filter, Observable, of } from "rxjs";
import { CompanyProgressService, CompanyResolverService, RouteStepDataService } from "../Modules/COT-Module/Services";
import { mergeMap, take } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { CompanyProfileSelectors } from "../AppStateManagement";

@Injectable()
export class CompanyProfileResolver {
	constructor(
		private companyResolverService: CompanyResolverService,
		private companyProgressService: CompanyProgressService,
		private store: Store,
		private companyProfileSelectors: CompanyProfileSelectors,
		private routeStepDataService: RouteStepDataService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const flowId = route.paramMap.get("flowId") ?? undefined;
		if (state.url.startsWith("/onboarding") && !flowId) {
			throw new Error("FlowId is missing");
		}
		this.companyResolverService
			.resolve(route, state)
			.pipe(
				take(1),
				mergeMap((companyProfile) => {
					if (state.url.startsWith("/onboarding")) {
						const key = this.routeStepDataService.getActiveRouteDataKey(state.url);
						return this.companyProgressService.getOnboardCompanyProgress(
							companyProfile!.companyId!,
							undefined,
							flowId,
							key
						);
					}
					return of();
				})
			)
			.subscribe();

		return combineLatest([
			this.store.select(this.companyProfileSelectors.selectCompanyProfile),
			this.store.select(this.companyProfileSelectors.selectOnboardCompanyProgress)
		]).pipe(
			filter(([cp, ocp]) => {
				return state.url.startsWith("/onboarding") ? !!cp && !!ocp : !!cp;
			}),
			take(1)
		);
	}
}
