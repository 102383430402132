<div>
    <form [formGroup]="radioButtonForm">
        <div *ngFor="let lookup of lookups" [class]="'ls-pb-3 d-flex align-items-center '+extraClasses">
            <input *ngIf="!!controlName" mdbRadio [formControlName]="controlName!" class="radio" type="radio"
                [id]="controlName+'_'+lookup.id" [value]="lookup.id" />
            <label class="xl" [for]="controlName+'_'+lookup.id">
                {{ lookup.name }}
                <ls-tooltip *ngIf="!!getTooltip(lookup!.id)">
                    <span class="material-icons md-18 ls-icon-primary-tooltip" reference>info</span>
                    <div content>
                        <ls-text [extraClasses]="'ls-mb-2 ls-mt-2 text-wrap'"
                            [displayConfig]="getTooltip(lookup!.id)"></ls-text>
                    </div>
                </ls-tooltip>

            </label>
        </div>
    </form>
</div>