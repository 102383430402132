<div class="role-dropdown-container">
    <div mdbDropdown #dropdown class="btn-group" (dropdownHidden)="dropdownHidden()">
       <span mdbLabel class="d-flex align-items-center"
          [class.ls-badge-user-role-contributor]="userRoleId !== LsRole.EADMIN"
          [class.ls-badge-user-role-admin]="userRoleId === LsRole.EADMIN">
          <span class="material-icons">{{ userRoleId === LsRole.EADMIN ? 'verified_user' : 'person' }}</span>
          <label class="xs">{{ roleName }}</label>
       </span>
       <button
         type="button"
         class="btn btn-sm dropdown-toggle dropdown-toggle-split"
         mdbDropdownToggle
         aria-expanded="false"
         [class.visually-hidden]="!canEdit"
       >
          <span class="material-icons-outlined" (click)="openDropdown($event)">
            expand_more
          </span>
       </button>
       <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-start dropdown-menu-lg-end">
         <li *ngFor="let option of options" [class.active]="userRoleId === option.roleId" class="rounded-5" (click)="onChange(option.roleId.toString())">
            <div class="dropdown-item d-flex flex-column">
                <span mdbLabel class="d-flex align-items-center menu-item-label"
                    [class.ls-badge-user-role-contributor]="option.roleId !== LsRole.EADMIN"
                    [class.ls-badge-user-role-admin]="option.roleId === LsRole.EADMIN">
                    <span class="material-icons">{{ option.iconType }}</span>
                    <label class="xs">{{ option.name }}</label>
                </span>
                <span class="secondary-label text-wrap">{{ option.subText }}</span>
            </div>
        </li>
       </ul>
    </div>
</div>