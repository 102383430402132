<div class="d-flex flex-column" *ngIf="display(HEADER)">
  <ls-text extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'hl1')"></ls-text>
  <ls-text extraClasses="ls-mb-8" [displayConfig]="config(HEADER,'p1')"></ls-text>
  <ls-text extraClasses="ls-mb-3" [displayConfig]="config(HEADER,'hm2')"></ls-text>
  <form class="d-flex flex-column" [formGroup]="form">
    <mdb-form-control class="ls-mb-4">
      <input mdbInput [formControlName]="INDUSTRY_SEARCH" type="text"
        [placeholder]="config(INDUSTRY_SEARCH,'placeholder')?.text" class="form-control form-control-lg search-input"
        id="industrySearch" aria-describedby="search-icon" />
      <span class="input-search-icon material-icons search-icon" id="search-icon">search</span>
      <label mdbLabel class="form-label search-label" for="industrySearch">
        <ls-text [displayConfig]="config(INDUSTRY_SEARCH,'label')"></ls-text>
      </label>
    </mdb-form-control>
    <div class="w-100 ls-p-4 industry-list ls-mb-11">
      <div class="scroll">
        <div *ngFor="let industry of filteredIndustries" class="d-flex align-items-center ls-pb-3">
          <input mdbRadio [formControlName]="INDUSTRY" class="radio industry-input" type="radio" name="industry"
            id="{{industry.id}}" value="{{industry.id}}" />
          <label class="xl" for="{{industry.id}}">{{ industry.name }}</label>
        </div>
      </div>
    </div>
  </form>
  <button type="button" (click)="onClick()" class="btn btn-icon btn-primary btn-lg" [disabled]="isInvalid()" #lsNextBtn>
    <ls-text [displayConfig]="config(BUTTONS,'next')"></ls-text><span class="material-icons md-18"> arrow_forward</span>
  </button>
</div>