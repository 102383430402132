/// <summary>
/// Used as the header on many customer setup pages

import { RadioButtonTooltip } from "src/app/Models/RadioButtonTooltip";

/// </summary>
class Headers {
	readonly tellUsMore = "Tell us more about your relationship with your customer";
	readonly letsGetTheRest = "Let’s get the rest of your company info";
}
/// <summary>
/// User in ARF_PG facility-configuration and ARF_V1 notice of assignment
/// </summary>
class FinancialDisclosure {
	readonly question =
		"Would you prefer that we notify your customer to pay us directly in exchange for a lower discount rate?";
	readonly paragraph1 =
		"Disclosure of Accounts Receivable Financing relationships to your customers is a common practice, but it is not mandatory. At Raistone, we prioritize your business’s unique needs and goals, offering flexible options tailored to suit your financial strategy.";
	readonly paragraph2 =
		"The manner in which you choose to communicate the financing relationship and manage collections can influence both the pricing and the ure of the financing we provide. Our customer support team is available to answer any questions you may have.";
	readonly accordionHeader = "Benefits of disclosing to your customer";
	readonly accordionItem1 = "Less resources and bandwidth needed by you and your company.";
	readonly accordionItem2 = "We can contact and collect on your behalf, resulting in optimal line efficiency";

	readonly toolTipHeader = "Provide your customer with a NOA";
	readonly toolTipContent =
		"If you decide to notify your customer to pay Raistone directly, we’ll provide them with a Notice of Assignment (NOA) to sign and return.<br/>We can manage the notification and do all the collections without your intervention.<br/>You can receive more favorable pricing if you notify your customer to pay Raistone.";

	readonly yesTooltipHeader = "Notifying your customer";
	readonly yesTooltipContent =
		"If you decide to notify your customer to pay Raistone directly, we’ll provide them with a Notice of Assignment (NOA). A Notice of Assignment is a formal notification sent to your customer informing them that their obligation to pay an invoice has been assigned to a third party, in this case Raistone. The notice directs your customer to make payments directly to Raistone, as Raistone has purchased the rights to the receivable.<br/>We can manage the notification and do all the collections without your intervention.<br/>You can receive more favorable pricing if you notify your customer about this financing and direct them to pay into a Raistone-owned account.";

	readonly noTooltipHeader = "Not notifying your customer";
	readonly noTooltipContent =
		"If you decide not to disclose this financing to your customer, you’ll get less favorable pricing.<br/>Additional credit underwriting of your company, which may include further financial disclosure and other documents, may be required.";

	readonly yesTooltip = new RadioButtonTooltip(this.yesTooltipHeader, this.yesTooltipContent);
	readonly noTooltip = new RadioButtonTooltip(this.noTooltipHeader, this.noTooltipContent);

	readonly legacyToolTipYes = this.yesTooltipContent;
	readonly legacyToolTipNo =
		"If you decide to continue to collect payment from your customer, you’ll get less favorable pricing. You wouldn’t disclose this financing to your customer";
}
/// <summary>
/// User customer settings, customer settings read only and facility configuration
/// </summary>
class InvoiceApprovals {
	readonly tooltipHeader = "Invoice approvals";
	readonly tooltipContent =
		"If your customer agrees to approve each invoice for payment, the pricing on your financing will improve.";
}
/// <summary>
/// User customer settings and customer settings read only
/// </summary>
class CustomerSettings {
	readonly notifyHeader = "Notifying your customer";
	readonly notifyContent =
		"If you decide to notify your customer to pay Raistone directly, we’ll provide them with a Notice of Assignment (NOA). A Notice of Assignment is a formal notification sent to your customer informing them that their obligation to pay an invoice has been assigned to a third party, in this case Raistone. The notice directs your customer to make payments directly to Raistone, as Raistone has purchased the rights to the receivable.<br/>We can manage the notification and do all the collections without your intervention.<br/>You can receive more favorable pricing if you notify your customer about this financing and direct them to pay into a Raistone-owned account.";
}
/// <summary>
/// User customer settings, customer settings read only and autoexcel
/// </summary>
class AutoXcel {
	readonly tooltipHeader = "What is autoXcel?";
	readonly tooltipContent =
		"autoXcel is a feature that allows you to automatically accelerate payment on your invoices. Raistone’s autoXcel functionality allows you to get paid early automatically once an approved invoice is uploaded to our system. Alternatively, you have the option to manually select invoices for acceleration on an invoice-by-invoice basis through Raistone’s supplier portal.<br/>Automatically accelerating payment of your invoices helps to lower pricing and speed up financing. If you choose to disable autoXcel, you or someone at your business will need to log in and manually select each invoice you’d like to finance.";
}
/// <summary>
/// User customer settings, customer settings read only and expedited financing
/// </summary>
class ExpeditedFinancing {
	readonly tooltipHeader = "Expedited financing";
	readonly tooltipContent =
		"Before funding begins, we confirm that your customer is paying into the new bank account established for this financing arrangement.  This can take a few weeks to begin to receive payments to the new bank account.<br/>If you choose to start the funding sooner, we offer a few options to receive this expedited funding such as delivering the funding on a virtual card.";
}
/// <summary>
/// User in ARF_PG facility-configuration and ARF_V1 notice of assignment
/// </summary>
class CollectionPreference {
	readonly question = "Going forward, how would you like your customer to pay?";
	readonly paragraph1 = "We can manage the notifications and do all the collections without your intervention.";
	readonly informTooltipHeader = "Inform your customer to pay into a Raistone-owned account";
	readonly informTooltipContent =
		"There is no need to disclose the financing arrangement to your customer. Simply instruct them to make payments to your new bank account. While the account is owned by Raistone, it is uniquely assigned to your business for all transactions. Raistone will provide the account details upon approval.";
	readonly collectTooltipHeader = "Non-disclosure to your customer";
	readonly collectTooltipContent =
		"If you decide to continue to collect payment from your customer, you’ll get less favorable pricing.<br/>You wouldn’t disclose this financing to your customer.";
	readonly informTooltip = new RadioButtonTooltip(this.informTooltipHeader, this.informTooltipContent);
	readonly collectTooltip = new RadioButtonTooltip(this.collectTooltipHeader, this.collectTooltipContent);
}
/// <summary>
/// User in ARF_PG facility-configuration and ARF_V1 lien information
/// </summary>
class LienCollaboration {
	readonly tooltipHeader = "Conflicting liens";
	readonly tooltipContent =
		"<A lien is a legal right that a creditor has on your assets, such as your company’s accounts receivables, used as collateral to secure the performance of an obligation.<br/>From Raistone’s perspective, a conflicting lien is a legal right that another creditor has in the receivables Raistone intends to finance that is superior to Raistone’s rights in the same receivables.>";
}
class VirtualCard {
	private headerText = "Virtual Cards";
	private contentText =
		"Also known as vCard, a Virtual Card is a single-use card that’s generated in a digital format that provides a secure way to get paid even faster. Your company can get paid for invoices earlier, helping you scale and grow with more cash on hand.";
	private link =
		"<a class='ls-hyperlink' href='https://raistone.com/vcard-for-suppliers/' target='_blank'><span>Learn more about vCard</span></a>";
	readonly tooltipHeader = this.headerText;
	readonly tooltipContent = `<div class='ls-mb-11'>${this.contentText}</div>${this.link}`;
	readonly tooltip = new RadioButtonTooltip(this.tooltipHeader, this.tooltipContent);
	readonly legacyToolTip = `<label class='l-bold text-wrap'>${this.headerText}</label> <p class='m ls-mb-2 ls-mt-2 text-wrap'>${this.contentText}</p> ${this.link}";`;
}
export class SharedText {
	static AutoXcel = new AutoXcel();
	static ExpeditedFinancing = new ExpeditedFinancing();
	static CollectionPreference = new CollectionPreference();
	static CustomerSettings = new CustomerSettings();
	static CustomerSetup = new Headers();
	static FinancialDisclosure = new FinancialDisclosure();
	static InvoiceApprovals = new InvoiceApprovals();
	static LienCollaboration = new LienCollaboration();
	static VirtualCard = new VirtualCard();
}
