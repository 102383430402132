import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { filter, map, Subject, takeUntil } from "rxjs";
import { CompanyProfileRelationship } from "src/app/Models";
import { GenericSelectors, LsPlatform } from "@limestone/ls-shared-modules";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, FormGroupDirective, Validators } from "@angular/forms";

@Component({
	selector: "ls-platforms-used",
	templateUrl: "./platforms-used.component.html",
	styleUrls: ["./platforms-used.component.scss"]
})
export class PlatformsUsedComponent implements OnInit, OnDestroy {
	private componentTeardown$: Subject<any> = new Subject<any>();
	private _companyProfileRelationship?: CompanyProfileRelationship;
	@Input() set companyProfileRelationship(companyProfileRelationship: CompanyProfileRelationship | undefined) {
		if (companyProfileRelationship) {
			this._companyProfileRelationship = companyProfileRelationship;
			this.setFormGroup();
		}
	}
	get companyProfileRelationship(): CompanyProfileRelationship | undefined {
		return this._companyProfileRelationship;
	}
	@Input() readOnly = true;

	public platformsUsedForm: FormGroup = new FormGroup({});
	public static FORM_CONTROL_NAME = "platformsUsed";
	public instrumentPlatforms?: LsPlatform[];
	public approvalPlatforms?: LsPlatform[];
	public remittancePlatforms?: LsPlatform[];
	public INSTRUMENT_PLATFORM = "instrumentPlatform";
	public INSTRUMENT_APPROVAL_PLATFORM = "instrumentApprovalPlatform";
	public REMITTANCE_PLATFORM = "remittancePlatform";

	constructor(
		public store: Store<any>,
		private fgd: FormGroupDirective,
		public genericSelectors: GenericSelectors
	) {}

	ngOnInit() {
		this.store
			.select(this.genericSelectors.selectPlatforms)
			.pipe(
				filter((p) => !!p && p.length > 0),
				takeUntil(this.componentTeardown$),
				map((p) => {
					this.instrumentPlatforms = p!.filter((p) => p.isInstrumentPlatform);
					this.approvalPlatforms = p!.filter((p) => p.isApprovalsPlatform);
					this.remittancePlatforms = p!.filter((p) => p.isRemittancePlatform);
				})
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	setFormGroup() {
		this.platformsUsedForm.addControl(
			this.INSTRUMENT_PLATFORM,
			new FormControl(this.companyProfileRelationship?.instrumentPlatform, Validators.required)
		);
		this.platformsUsedForm.addControl(
			this.INSTRUMENT_APPROVAL_PLATFORM,
			new FormControl(this.companyProfileRelationship?.instrumentApprovalPlatform, Validators.required)
		);
		this.platformsUsedForm.addControl(
			this.REMITTANCE_PLATFORM,
			new FormControl(this.companyProfileRelationship?.remittancePlatform, Validators.required)
		);
		this.fgd.form?.addControl(PlatformsUsedComponent.FORM_CONTROL_NAME, this.platformsUsedForm, { emitEvent: false });
	}
}
